import React from 'react';
import { StyleSheet, View, Text, Image, Link } from '@react-pdf/renderer';
import { stringOrNumber } from '../../auditorUtils';

function SwaleTemplate({ nodeInfo, image, title, documentLinks }) {
    const styles = StyleSheet.create({
        title: {
            fontSize: 14,
            textAlign: 'center',
            marginBottom: '3%',
        },
        image: {
            width: '100%',
        },
        link: {
            textAlign: 'center',
            fontSize: 9,
            marginBottom: '2vh',
            marginTop: '2vh',
        },
        // TABLE
        table: {
            padding: 5,
        },
        tableTitle: {
            width: '100%',
            textAlign: 'center',
            fontSize: 14,
            marginBottom: '3vh',
        },
        tableRow: {
            flexDirection: 'row',
        },
        columnTitle: {
            flex: 1,
            textAlign: 'center',
            fontSize: 9,
            borderWidth: 1,
            borderStyle: 'solid',
            color: 'red',
            paddingTop: 20,
        },
        tableCol: {
            flex: 1,
            textAlign: 'center',
            fontSize: 8,
            border: 1,
            padding: 5,
        },
    });

    return (
        <>
            <Text style={styles.tableTitle}>Specifications and Typical Drawings - {title}</Text>
            {image && <Image style={styles.image} src={image} />}
            {documentLinks
                ? documentLinks.map((link, index) => {
                      return (
                          <Link
                              key={link['manufacture_device_document_link']}
                              src={link['manufacture_device_document_link']}
                              style={styles.link}
                          >
                              {link['document_name'] ? link['document_name'] : `document ${index}`}
                          </Link>
                      );
                  })
                : null}
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <Text style={styles.columnTitle}>Treatment Device Name</Text>
                    <Text style={styles.columnTitle}>Length (m)</Text>
                    <Text style={styles.columnTitle}>Top Width (m)</Text>
                    <Text style={styles.columnTitle}>Base Width (m)</Text>
                    <Text style={styles.columnTitle}>Depth (m)</Text>
                    <Text style={styles.columnTitle}>Bed Slope (%)</Text>
                </View>
                {nodeInfo.map((node, index) => {
                    return (
                        <View style={styles.tableRow} key={index}>
                            <Text style={styles.tableCol}>{node['Node Name'] || 'N/A'}</Text>
                            <Text style={styles.tableCol}>
                                {node['Storage Properties - Length (metres)']
                                    ? stringOrNumber(+node['Storage Properties - Length (metres)'])
                                    : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node['Storage Properties - Top Width (metres)']
                                    ? stringOrNumber(
                                          +node['Storage Properties - Top Width (metres)'],
                                      )
                                    : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node['Storage Properties - Base Width (metres)']
                                    ? stringOrNumber(
                                          +node['Storage Properties - Base Width (metres)'],
                                      )
                                    : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node['Storage Properties - Depth (metres)']
                                    ? stringOrNumber(+node['Storage Properties - Depth (metres)'])
                                    : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node['Storage Properties - Bed Slope (%)']
                                    ? stringOrNumber(+node['Storage Properties - Bed Slope (%)'])
                                    : 'N/A'}
                            </Text>
                        </View>
                    );
                })}
            </View>
        </>
    );
}

export default SwaleTemplate;
