import { createSlice } from "@reduxjs/toolkit";

const postdevInfoSlice = createSlice({
    name: "postdevInfo",
    initialState: {
        postDevSlope: "1",
        postDevFlowLength: "100",
        postDevSlopeUncontrolled: "",
        postDevFlowLengthUncontrolled: "",
        postDevAreas: [
            {
                id: 0,
                areaName: "",
                surfaceArea: "",
                runoffCoefficient: "",
                surfaceType: "Paved",
                uncontrolled: "0",
                impervious: true,
            },
        ],
    },
    reducers: {
        handlePostDevSlope: (state, action) => {
            state.postDevSlope = action.payload;
        },
        handlePostDevFlowLength: (state, action) => {
            state.postDevFlowLength = action.payload;
        },
        handlePostDevSlopeUncontrolled: (state, action) => {
            state.postDevSlopeUncontrolled = action.payload;
        },
        handlePostDevFlowLengthUncontrolled: (state, action) => {
            state.postDevFlowLengthUncontrolled = action.payload;
        },
        handlePostDevAreas: (state, action) => {
            state.postDevAreas = action.payload;
        },
        handlePostDevInfo: (_, action) => {
            return action.payload;
        },
    },
});

export const {
    handlePostDevSlope,
    handlePostDevFlowLength,
    handlePostDevSlopeUncontrolled,
    handlePostDevFlowLengthUncontrolled,
    handlePostDevAreas,
    handlePostDevInfo,
} = postdevInfoSlice.actions;

export const postdevInfo = (state) => state.postdevInfo;
export default postdevInfoSlice.reducer;
