import { lineNumber } from "./globalConstant";

// Global variable to save the node name
let name = "";
let isImpervious = false;

/**
 * Save the node information into object
 *
 * @param {string} msf MSF data
 * @param {Number} line MSF data line number
 * @param {string} nodeType node type
 * @returns
 */
function createNode(msf, line, nodeType) {
  let node = {};
  if (nodeType.includes("UrbanSourceNode")) {
    for (let index = 0; index < lineNumber[nodeType]; index++) {
      const lineData = msf[line + index];
      if (lineData.includes("Node Name")) name = lineData.split(",")[1];
      if (lineData.includes("Areas - Impervious (%)")) {
        const imperviousRate = lineData.split(",")[1];
        isImpervious = imperviousRate === "100" ? true : false;
      }
    }
  } else if (nodeType.includes("BioRetentionNodeV4")) {
    node["nodeName"] = name;
    node["isImpervious"] = isImpervious;
    node["nodeType"] = nodeType;
    node["eddHeight"] = msf[line + 10]
      ? parseFloat(msf[line + 10].split(",")[1]) * 1000
      : "N/A";
    node["surfaceArea"] = msf[line + 11].split(",")[1];
    node["filterArea"] = msf[line + 12].split(",")[1];
    node["filterPermeability"] = msf[line + 14].split(",")[1];
    node["filterLayerHeight"] = parseFloat(msf[line + 15].split(",")[1]) * 1000;
    node["bioHeight"] =
      parseFloat(node["filterLayerHeight"]) +
      parseFloat(node["eddHeight"]) +
      350;
    node["treatmentType"] = msf[line + 1].split(",")[1];
  } else if (nodeType.includes("MediaFiltrationNode")) {
    node["nodeName"] = name;
    node["isImpervious"] = isImpervious;
    node["nodeType"] = nodeType;
    node["surfaceArea"] = msf[line + 11].split(",")[1];
    node["filterArea"] = msf[line + 13].split(",")[1];
    node["filterPermeability"] = msf[line + 16].split(",")[1];
    node["filterDepth"] = parseFloat(msf[line + 14].split(",")[1]) * 1000;
    node["treatmentType"] = msf[line + 1].split(",")[1];
  } else if (nodeType.includes("RainWaterTankNode")) {
    node["nodeName"] = name;
    node["isImpervious"] = isImpervious;
    node["dailyDemand"] = msf[line + 14].split(",")[1];
    node["surfaceArea"] = msf[line + 22].split(",")[1];
  }
  return node;
}

export function getNodeInfoFromMsf(
  imperviousNodes,
  perviousNodes,
  msfDataPassable
) {
  const msfData_split_eol = msfDataPassable.split("\n");

  let imperviousNodesBuf = [...imperviousNodes];
  let perviousNodesBuf = [...perviousNodes];
  for (const line in msfData_split_eol) {
    if (msfData_split_eol[+line].includes("Node Type")) {
      let nodeType = msfData_split_eol[+line].split(",")[1];
      let nodeData = createNode(msfData_split_eol, +line, nodeType);
      if (
        !nodeType.includes("UrbanSourceNode") &&
        !nodeType.includes("ReceivingNode") &&
        Object.keys(nodeData).length !== 0
      ) {
        if (nodeData.isImpervious) {
          imperviousNodesBuf = imperviousNodesBuf.map((item) => {
            if (nodeData.nodeName.includes(item.impAreaName)) {
              return { ...item, ...nodeData };
            }
            return item;
          });
        } else {
          perviousNodesBuf = perviousNodesBuf.map((item) => {
            if (nodeData.nodeName.includes(item.pAreaName)) {
              return { ...item, ...nodeData };
            }
            return item;
          });
        }
      }
    }
  }

  return {
    completeImperviousNodes: imperviousNodesBuf,
    completePerviousNodes: perviousNodesBuf,
  };
}
