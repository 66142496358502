import React from "react";
import { StyleSheet, View, Text, Image, Link } from "@react-pdf/renderer";

function RainwaterTankTemplate({ nodeInfo, image, title, documentLinks }) {
  const styles = StyleSheet.create({
    title: {
      fontSize: 14,
      textAlign: "center",
      marginBottom: "3%",
    },
    image: {
      width: "100%",
    },
    link: {
      textAlign: "center",
      fontSize: 9,
      marginBottom: 5,
    },
    redText: {
      color: "red",
    },
    // TABLE
    table: {
      padding: 5,
    },
    tableTitle: {
      width: "100%",
      textAlign: "center",
      fontSize: 12,
    },
    tableRow: {
      flexDirection: "row",
    },
    tableCol: {
      flex: 1,
      textAlign: "center",
      fontSize: 9,
      border: 1,
      padding: 5,
    },
  });

  return (
    <>
      <Text style={styles.tableTitle}>
        Specifications and Typical Drawings - {title}
      </Text>
      {image && <Image style={styles.image} src={image} />}
      {documentLinks
        ? documentLinks.map((link, index) => {
            return (
              <Link
                key={link["manufacture_device_document_link"]}
                src={link["manufacture_device_document_link"]}
                style={styles.link}
              >
                {link["document_name"]
                  ? link["document_name"]
                  : `document ${index}`}
              </Link>
            );
          })
        : null}
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <Text style={styles.tableCol}>Catchment Name</Text>
          <Text style={styles.tableCol}>Surface Area(m²)</Text>
          <Text style={styles.tableCol}>No. of Occupants or Bedroom</Text>
          <Text style={styles.tableCol}>REUSE DEMAND (L/day)</Text>
          <Text style={styles.tableCol}>VOL FOR REUSE (L)</Text>
          <Text style={styles.tableCol}>10% VOL FOR DETENTION(L)</Text>
          <Text style={styles.tableCol}>110% VOL RAINWATER TANK (L)</Text>
        </View>
        {nodeInfo.map((node) => {
          return (
            <View style={styles.tableRow} key={node["impAreaName"]}>
              <Text style={styles.tableCol}>
                {node["impAreaName"] ? node["impAreaName"] : "N/A"}
              </Text>
              <Text style={styles.tableCol}>
                {node["surfaceArea"] ? node["surfaceArea"] : "N/A"}
              </Text>
              <Text style={styles.tableCol}>
                {node["impAreaBrooms"] ? node["impAreaBrooms"] : "N/A"}
              </Text>
              <Text style={styles.tableCol}>
                {node["dailyDemand"]
                  ? (+node["dailyDemand"] * 1000).toFixed(2)
                  : "N/A"}
              </Text>
              <Text style={styles.tableCol}>
                {node["impAreaTreatSize"] ? node["impAreaTreatSize"] : "N/A"}
              </Text>
              <Text style={styles.tableCol}>
                {node["impAreaTreatSize"]
                  ? (+node["impAreaTreatSize"] * 0.1).toFixed(2)
                  : "N/A"}
              </Text>
              <Text style={styles.tableCol}>
                {node["impAreaTreatSize"]
                  ? (+node["impAreaTreatSize"] * 1.1).toFixed(2)
                  : "N/A"}
              </Text>
            </View>
          );
        })}
      </View>
    </>
  );
}
export default RainwaterTankTemplate;
