import React from "react";
import { StyleSheet, Text, View } from "@react-pdf/renderer";

export default function ReportFooter({ showContactInfo }) {
  const styles = StyleSheet.create({
    section: {
      position: "absolute",
      width: "100vw",
      bottom: 10,
    },
    section_footer: {
      display: "flex",
      flexDirection: "row",
      position: "relative",
    },
    normal_text: {
      fontSize: 10,
    },
    normal_text_bottom: {
      fontSize: 10,
      marginBottom: "2%",
      color: "rgb(50, 50, 50)",
    },
    pageNumber: {
      fontSize: 10,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
  });

  return (
    <>
      <View fixed style={styles.section}>
        <View
          style={{
            width: "90%",
            height: "1px",
            backgroundColor: "grey",
            margin: "0 auto 10px auto",
          }}
        ></View>
        {showContactInfo && (
          <View style={styles.section_footer}>
            <View
              style={{
                width: "45%",
                flex: "1",
                margin: "0 10px 10px 0",
              }}
            >
              <Text style={styles.normal_text}>Questions? Please contact:</Text>
              <Text style={styles.normal_text_bottom}>
                Mircea: mircea@cleanstormwater.com.au{" "}
              </Text>
              <Text style={styles.normal_text_bottom}>
                Gregory: greg@cleanstormwater.com.au
              </Text>
            </View>
          </View>
        )}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} of ${totalPages}`
          }
        />
      </View>
    </>
  );
}
