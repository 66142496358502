import React from 'react';
import { StyleSheet, View, Page, Text } from '@react-pdf/renderer';

import ReportHeader from '../ReportHeader';
import Table from '../Table';
import ReportFooter from '../ReportFooter';

// using https://lingojam.com/SuperscriptGenerator to generate superscripts (<sup>2</sup> is not accept by React PDF)

const defaultStyles = {
    borderStyle: '1px solid #658cbb',
    textAlignment: 'center',
    headerBorder: true,
    fontSize: 9,
    headerFontSize: 9,
    headerTextAlign: 'center',
    tableWidth: '100%',
    marginTop: 15,
    headerTextDecoration: 'none',
};

const createStyles = (newStyles = defaultStyles) => {
    return {
        section: { marginTop: newStyles.marginTop },
        table: {
            display: 'table',
            width: newStyles.tableWidth,
            border: newStyles.borderStyle,
            borderBottom: 0,
            borderRight: 0,
            borderLeft: 0,
            borderTop: newStyles.headerBorder ? newStyles.borderStyle : 0,
        },

        tableRow: {
            flexDirection: 'row',
            borderBottom: newStyles.borderStyle,
        },

        headerCell: {
            fontSize: newStyles.headerFontSize,
            textAlign: newStyles.headerTextAlign,
            borderRight: newStyles.headerBorder ? newStyles.borderStyle : 0,
            borderLeft: newStyles.headerBorder ? newStyles.borderStyle : 0,
            height: '100%',
            padding: 5,
            textDecoration: newStyles.headerTextDecoration,
        },

        tableCell: {
            fontSize: newStyles.fontSize,
            textAlign: newStyles.textAlignment,
            borderRight: newStyles.borderStyle,
            borderLeft: newStyles.borderStyle,
            height: '100%',
            padding: 5,
        },
    };
};

const generateBoydsMethodData = (data) => {
    return [
        {
            columns: ['Required Storage Volume, Vreq (m³) = Vi * r'],
            items: [],
            styles: createStyles({
                ...defaultStyles,
                borderStyle: '',
                textAlignment: 'left',
                headerTextAlign: 'left',
                headerFontSize: 12,
                fontSize: 10,
            }),
            cellWidths: ['100%'],
        },

        {
            columns: ['Inflow Volume, Vi (m³) = Tdur * Qpeak_post * 60/1000'],
            items: [
                ['Where'],
                ['Tdur = storm duration in minutes'],
                [
                    'Qpeak_post = post development peak flow in m³, calculated using rational method based on storm duration.',
                ],
            ],
            styles: createStyles({
                ...defaultStyles,
                borderStyle: '',
                textAlignment: 'left',
                headerTextAlign: 'left',
                headerFontSize: 12,
                fontSize: 10,
            }),
            cellWidths: ['100%'],
        },

        {
            columns: ['Inflow Ratio, r = (Qpeak_post – Qpre) / Qpeak_post'],
            items: [
                ['Where'],
                [
                    'Qpre = permissible site discharge in L/s (either calculated using rational method or provided by authority / guidelines',
                ],
                [
                    'Qpeak_post = post development peak flow in L/s, calculated using rational method based on storm duration',
                ],
            ],
            styles: createStyles({
                ...defaultStyles,
                borderStyle: '',
                textAlignment: 'left',
                headerTextAlign: 'left',
                headerFontSize: 12,
                fontSize: 10,
            }),
            cellWidths: ['100%'],
        },
    ];
};

const BoydsMethodCalculationTemplate = ({ data }) => {
    const styles = StyleSheet.create({
        body: {
            paddingTop: 30,
            paddingBottom: 30,
            paddingHorizontal: 35,
            marginBottom: 10,
        },
        section: {
            marginBottom: 10,
            padding: 15,
            display: 'flex',
            flexDirection: 'row',
        },
        mainTitle: {
            fontSize: 18,
            fontweight: 1000,
            textAlign: 'center',
            marginTop: 5,
            marginBottom: 5,
        },
        catchmentTimes: {
            marginTop: 10,
            fontSize: 15,
            width: '100%',
            justifyContent: 'end',
            display: 'flex',
        },
        disclaimer: {
            fontSize: 9,
            marginTop: 15,
            lineHeight: 1.3,
        },
    });

    return (
        <Page style={styles.body} wrap>
            <ReportHeader reportHeaderUrl={data.reportHeaderIconUrl} />

            <Text style={styles.mainTitle}>
                Boyd’s Method Required Storage Volume, Vreq Calculation{' '}
            </Text>
            <View>
                {generateBoydsMethodData(data).map((table, index) => (
                    <>
                        {table.styles ? (
                            <Table
                                tableKey={index}
                                columnNames={table.columns}
                                tableData={table.items}
                                headerWidths={table.headerWidths}
                                cellWidths={table.cellWidths}
                                customCellWidths={table.customCellWidths}
                                tableStyles={table.styles}
                            />
                        ) : (
                            <></>
                        )}
                    </>
                ))}
            </View>
            <ReportFooter />
        </Page>
    );
};

export default BoydsMethodCalculationTemplate;
