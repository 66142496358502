import React from 'react';
import { StyleSheet, View, Text, Image, Link } from '@react-pdf/renderer';
import { stringOrNumber } from '../../auditorUtils';

function TreePitTemplate({ nodeInfo, image, title, documentLinks }) {
    const styles = StyleSheet.create({
        title: {
            fontSize: 14,
            textAlign: 'center',
            marginBottom: '3%',
        },
        image: {
            width: '100%',
        },
        link: {
            textAlign: 'center',
            fontSize: 9,
            marginBottom: '2vh',
            marginTop: '2vh',
        },
        superscript: {
            fontSize: 5,
            lineHeight: 5,
        },
        // TABLE
        columnTitle: {
            flex: 1,
            textAlign: 'center',
            fontSize: 9,
            borderWidth: 1,
            borderStyle: 'solid',
            color: 'red',
            paddingTop: 20,
        },
        table: {
            padding: 5,
        },
        tableTitle: {
            width: '100%',
            textAlign: 'center',
            fontSize: 14,
            marginBottom: '3vh',
        },
        tableRow: {
            flexDirection: 'row',
        },
        tableCol: {
            flex: 1,
            textAlign: 'center',
            fontSize: 8,
            border: 1,
            padding: 5,
        },
    });

    return (
        <>
            <Text style={styles.tableTitle}>Specifications and Typical Drawings - {title}</Text>
            {image && <Image style={styles.image} src={image} />}
            {documentLinks
                ? documentLinks.map((link, index) => {
                      return (
                          <Link
                              key={link['manufacture_device_document_link']}
                              src={link['manufacture_device_document_link']}
                              style={styles.link}
                          >
                              {link['document_name'] ? link['document_name'] : `document ${index}`}
                          </Link>
                      );
                  })
                : null}
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <Text style={styles.columnTitle}>Treatment Device Name</Text>
                    <Text style={styles.columnTitle}>
                        Surface Area(m<Text style={styles.superscript}>2</Text>)
                    </Text>
                    <Text style={styles.columnTitle}>
                        Filter Area(m<Text style={styles.superscript}>2</Text>)
                    </Text>
                    <Text style={styles.columnTitle}>EDD height (mm)</Text>
                    <Text style={styles.columnTitle}>Filter height (mm)</Text>
                    <Text style={styles.columnTitle}>Filter Layer Permeability (mm/hr)</Text>
                </View>
                {nodeInfo.map((node, index) => (
                    <View style={styles.tableRow} key={index}>
                        <Text style={styles.tableCol}>{node['Node Name'] || 'N/A'}</Text>
                        <Text style={styles.tableCol}>
                            {node['Storage Properties - Surface Area (square metres)']
                                ? stringOrNumber(
                                      node['Storage Properties - Surface Area (square metres)'],
                                  )
                                : 'N/A'}
                        </Text>
                        <Text style={styles.tableCol}>
                            {node['Filter and Media Properties - Filter Area (square metres)']
                                ? stringOrNumber(
                                      node[
                                          'Filter and Media Properties - Filter Area (square metres)'
                                      ],
                                  )
                                : 'N/A'}
                        </Text>
                        <Text style={styles.tableCol}>
                            {node['Storage Properties - Extended Detention Depth (metres)']
                                ? stringOrNumber(
                                      node[
                                          'Storage Properties - Extended Detention Depth (metres)'
                                      ] * 1000,
                                  )
                                : 'N/A'}
                        </Text>
                        <Text style={styles.tableCol}>
                            {node['Filter and Media Properties - Filter Depth (metres)']
                                ? stringOrNumber(
                                      node['Filter and Media Properties - Filter Depth (metres)'] *
                                          1000,
                                  )
                                : 'N/A'}
                        </Text>
                        <Text style={styles.tableCol}>
                            {node[
                                'Filter and Media Properties - Saturated Hydraulic Conductivity (mm/hr)'
                            ]
                                ? stringOrNumber(
                                      node[
                                          'Filter and Media Properties - Saturated Hydraulic Conductivity (mm/hr)'
                                      ],
                                  )
                                : 'N/A'}
                        </Text>
                    </View>
                ))}
            </View>
        </>
    );
}

export default TreePitTemplate;
