import React from "react";
import { StyleSheet, View, Page, Text } from "@react-pdf/renderer";
// components
import ReportFooter from "../ReportFooter";
import ReportHeader from "../ReportHeader";
import { DevelopedModel, ExistingModel } from "./TreatmentTrainsTemplates";
import TreatmentTrainsImage from "../TreatmentTrainsImage";
import {
  EndOfLineReductions,
  IndividualTreatmentReductions,
  PerviousReductions,
} from "./ReductionComponents";
import {
  DevelopedModelIndividualTreatmentResiduals,
  ExistingModelIndividualTreatmentResiduals,
  PerviousResiduals,
} from "./ResidualComponents";
import SpecificationsAndTypicalDrawings from "../specifications-and-typical-drawings/SpecificationsAndTypicalDrawings";

export default function SuCalculatorReportTemplate({
  wqMethod,
  hasWQ,
  impervious,
  pervious,
  overallResults,
  treatmentNodesObject,
  reportTemplates,
  manufactureDeviceImages,
  manufactureDeviceDocumentLinks,
  imperviousNodes,
  perviousNodes,
  postDevImperviousNodes,
  postDevPerviousNodes,
  eolTreatment,
  eolTreatmentSize,
  reportHeaderIconUrl,
  treatmentTrainsImageUrl,
}) {
  const styles = StyleSheet.create({
    body: {
      paddingTop: 30,
      paddingBottom: 10,
      paddingHorizontal: 35,
    },
    footerSection: {
      position: "absolute",
      width: "100vw",
      bottom: 10,
      textAlign: "center",
      marginHorizontal: "auto",
    },
    container: {
      height: "70%",
    },
    title: {
      textAlign: "center",
      fontSize: 20,
      margin: 10,
    },
    text1: {
      textAlign: "center",
      fontSize: 15,
      fontWeight: 700,
      margin: 10,
    },
    table: {
      display: "table",
      width: "100%",
    },
    tableRow: {
      width: "100%",
      flexDirection: "row",
      textAlign: "center",
    },
    tableCell: {
      height: "100%",
      width: "100%",
      padding: 5,
      border: "1px solid #658cbb",
      fontSize: 10,
    },
  });

  return (
    <>
      {hasWQ && (
        <>
          {/* Treatment Trains */}
          <Page style={styles.body} size="A4">
            <View>
              <ReportHeader reportHeaderUrl={reportHeaderIconUrl} />
            </View>
            {wqMethod.includes("NorBE") ? (
              <ExistingModel
                wqMethod={wqMethod}
                imperviousNodes={imperviousNodes}
                perviousNodes={perviousNodes}
                eolTreatment={eolTreatment}
                eolTreatmentSize={eolTreatmentSize}
              />
            ) : (
              <DevelopedModel
                imperviousNodes={imperviousNodes}
                perviousNodes={perviousNodes}
              />
            )}
            <View style={styles.footerSection}>
              <ReportFooter />
            </View>
          </Page>
          {/* Treatment Trains Image */}
          <Page style={styles.body} size="A4">
            <View>
              <ReportHeader reportHeaderUrl={reportHeaderIconUrl} />
            </View>
            <TreatmentTrainsImage
              treatmentTrainsImageUrl={treatmentTrainsImageUrl}
            />
            <View style={styles.footerSection}>
              <ReportFooter />
            </View>
          </Page>
          {/* Individual Reduction */}
          <Page style={styles.body} size="A4">
            <View>
              <ReportHeader reportHeaderUrl={reportHeaderIconUrl} />
            </View>

            {wqMethod.includes("NorBE") ? (
              <>
                <Text style={styles.title}>
                  Individual Treatment Residuals - Existing Model
                </Text>
                {postDevImperviousNodes && (
                  <ExistingModelIndividualTreatmentResiduals
                    postDevImperviousNodes={postDevImperviousNodes}
                  />
                )}
                {pervious && (
                  <PerviousResiduals
                    postDevPerviousNodes={postDevPerviousNodes}
                  />
                )}
              </>
            ) : (
              <>
                <Text style={styles.title}>
                  Individual Treatment Reductions
                </Text>
                {impervious && wqMethod.includes("Standard") && (
                  <IndividualTreatmentReductions
                    imperviousNodes={imperviousNodes}
                  />
                )}
                {impervious && wqMethod.includes("EOL") && (
                  <EndOfLineReductions
                    eolTreatment={eolTreatment}
                    overallResults={overallResults}
                  />
                )}
                {pervious && perviousNodes && (
                  <PerviousReductions perviousNodes={perviousNodes} />
                )}
              </>
            )}
            <View style={styles.footerSection}>
              <ReportFooter />
            </View>
          </Page>

          {/* Individual Residual - Developed Model */}
          {wqMethod.includes("NorBE") && (
            <Page style={styles.body} size="A4">
              <View>
                <ReportHeader reportHeaderUrl={reportHeaderIconUrl} />
              </View>
              <Text style={styles.title}>
                Individual Treatment Residuals - Developed Model
              </Text>
              {postDevImperviousNodes && (
                <DevelopedModelIndividualTreatmentResiduals
                  wqMethod={wqMethod}
                  eolTreatment={eolTreatment}
                  postDevImperviousNodes={postDevImperviousNodes}
                  overallResults={overallResults}
                />
              )}
              {wqMethod.includes("EOL") && (
                <EndOfLineReductions
                  eolTreatment={eolTreatment}
                  overallResults={overallResults}
                />
              )}
              {pervious && (
                <PerviousResiduals
                  postDevPerviousNodes={postDevPerviousNodes}
                />
              )}
              <View style={styles.footerSection}>
                <ReportFooter />
              </View>
            </Page>
          )}
          {/* Specification & Typical Drawings */}
          <SpecificationsAndTypicalDrawings
            treatmentNodesObject={treatmentNodesObject}
            reportTemplates={reportTemplates}
            manufactureDeviceImages={manufactureDeviceImages}
            manufactureDeviceDocumentLinks={manufactureDeviceDocumentLinks}
            reportHeaderIconUrl={reportHeaderIconUrl}
          />
        </>
      )}
    </>
  );
}
