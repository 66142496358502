import React from 'react';
import { Image, StyleSheet, View } from '@react-pdf/renderer';

/**
 * @param {string} reportHeaderUrl
 * @returns
 */
export default function ReportHeader({ reportHeaderUrl }) {
    const styles = StyleSheet.create({
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'right',
            marginBottom: 10,
            height: '7vh',
        },
        leftSide: {
            flex: 3,
        },
        image: {
            flex: 1,
            padding: 10,
            objectFit: 'scale-down',
        },
    });
    return (
        <View style={styles.container} fixed>
            <View style={styles.leftSide} />
            {reportHeaderUrl && <Image style={styles.image} src={reportHeaderUrl} />}
        </View>
    );
}
