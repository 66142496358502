import React from 'react';
import { Table, Button } from 'react-bootstrap';

/**
 * 
 * @param {*} template The template to compare against
 * @param {*} rows The rows from the uploaded msf 
 * @param {Function} onClose A function to close the modal when the user confirms or cancels
 * @returns 
 */
const ChecklistTable = ({ template, rows, onClose }) => {
    return (
        <div className="container">
            <h4>
                Compare {rows.find((row) => row.rowName === 'Node Name').rowValue} to
                guidelines for {template.match_name}
            </h4>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Property Name</th>
                        <th>User Value</th>
                        <th>Min Value</th>
                        <th>Max Value</th>
                    </tr>
                </thead>
                <tbody>
                    {template.checkList.map((item, index) => {
                        let userValue = Number(
                            rows.find((row) => row.rowName === item.property_name)?.rowValue,
                        );
                        const imperviousness = rows.find((row) =>
                            row.rowName.includes('Areas - Impervious (%)'),
                        )?.rowValue;
                        const min =
                            item.min_value !== null && item.min_value !== undefined
                                ? Number(item.min_value)
                                : null;
                        const max =
                            item.max_value !== null && item.max_value !== undefined
                                ? Number(item.max_value)
                                : null;
                        const isUserValueValid = userValue !== undefined && userValue !== null;
                        const isOutOfMinMaxRange =
                            max !== null && (userValue < min || userValue > max);
                        const isOutOfMinRange = max === null && userValue < min;
                        const isValueOutOfRange =
                            isUserValueValid && (isOutOfMinMaxRange || isOutOfMinRange);
                        const displayedValue =
                            imperviousness === '100' &&
                            (item.property_name.includes('Pervious') ||
                                item.property_name.includes('Base'))
                                ? 'Irrelevant'
                                : userValue;
                        //Refactor needed in the future
                        const tdStyle = {
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            color:
                                isValueOutOfRange && displayedValue !== 'Irrelevant'
                                    ? 'red'
                                    : 'inherit',
                        };

                        return (
                            <tr key={index}>
                                <td>{item.property_name}</td>
                                <td style={tdStyle}>{displayedValue}</td>
                                <td style={tdStyle}>{min}</td>
                                <td style={tdStyle}>{max}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <Button variant="secondary" onClick={onClose}>
                Close
            </Button>
        </div>
    );
};

export default ChecklistTable;
