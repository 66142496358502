import React from 'react';
import { StyleSheet, View, Text, Image, Page } from '@react-pdf/renderer';

function ReportAppendixs({ reportNoteAppendixItems }) {
    const styles = StyleSheet.create({
        title: {
            fontSize: 14,
            textAlign: 'left',
            marginBottom: '4%',
        },

        content: {
            fontSize: 10,
            textAlign: 'left',
            marginBottom: '3%',
            lineHeight: '2',
        },

        body: {
            paddingTop: 30,
            paddingBottom: 10,
            paddingHorizontal: 35,
        },
        image: { marginVertical: 30, marginLeft: 5, maxWidth: 600 },
    });

    return (
        <>
            {reportNoteAppendixItems.map((item, index) => (
                <Page key={item.id} style={styles.body}>
                    <Text style={styles.title}>{item.title}</Text>
                    <View>
                        <Text style={styles.content}>{item.appendixData}</Text>

                        {item.uploadedImage && item.uploadedImage !== '' && (
                            <View>
                                <Image style={styles.image} src={item.uploadedImage} />
                            </View>
                        )}
                    </View>
                </Page>
            ))}
        </>
    );
}

export default ReportAppendixs;
