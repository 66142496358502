import axios from 'axios';
import { LAMBDA_API } from '../globalConstant';

export const OSD_LAMBDA_API = 'https://883w4dnxnl.execute-api.ap-southeast-2.amazonaws.com/dev';
const USER_LOGIN = 'https://c36rlgk0ha.execute-api.ap-southeast-2.amazonaws.com/stage';
const USER_RELATED = 'https://xgr5xf78jh.execute-api.ap-southeast-2.amazonaws.com/user';
const GET_METHODS_API = LAMBDA_API + '/get-methods';
const SAVE_AND_LOAD_MODEL = 'https://y7dtd8y3bj.execute-api.ap-southeast-2.amazonaws.com/stage';
const JCO_BACKEND = 'https://ror5uurede.execute-api.ap-southeast-2.amazonaws.com/stage';

const dbquery_get = async (API, query) => {
    return await axios.get(API, query);
};

const dbquery_post = async (payload, API) => {
    return (await axios.post(API, payload)).data.results;
};

const lambdaQuery = async (query, API) => {
    return (await axios.post(API, query)).data;
};

export const getTreatmentTypes = async (type, mlb_name) => {
    const payload = { params: { type: type, mlb_name: mlb_name } };
    return await dbquery_get(OSD_LAMBDA_API + '/osd-api', payload);
};

export const verifyJwt = async (token, refreshToken) => {
    const payload = {
        endpoint: 'verifyJwt',
        params: { token, refreshToken },
    };
    return await dbquery_post(payload, USER_LOGIN);
};

export const checkSubscriptionStatus = async (user_id, tool_name) => {
    const payload = {
        endpoint: 'checkSubscriptionStatus',
        params: { user_id, tool_name },
    };
    return await dbquery_post(payload, USER_RELATED);
};

export const checkSDCredit = async (user_id, email) => {
    const payload = {
        endpoint: 'checkSDCredit',
        params: { user_id, email },
    };
    return await dbquery_post(payload, USER_RELATED);
};

export const verifyUserAccess = async (user_id, service_id) => {
    const payload = {
        endpoint: 'verifyUserAccess',
        params: { user_id, service_id },
    };
    return await dbquery_post(payload, USER_RELATED);
};

export const deductSDCredit = async (user_id, service_id) => {
    const payload = {
        endpoint: 'deductSDCredit',
        params: { user_id, service_id },
    };
    return await dbquery_post(payload, USER_RELATED);
};

export const isInCompanySubscription = async (email, tool_name) => {
    const payload = {
        endpoint: 'isInCompanySubscription',
        params: { email, tool_name },
    };
    return await dbquery_post(payload, USER_RELATED);
};

export const getUserGuideNewFeatures = async (tool) => {
    const payload = {
        params: { endpoint: 'getPopUpAdvertisementByTool', tool },
    };
    return (await dbquery_get(GET_METHODS_API, payload)).data;
};

export const getMethodsAPI = (payload) => {
    return dbquery_get(GET_METHODS_API, { params: payload });
};

export const getProjectByProjId = async (project_id) => {
    const payload = {
        endpoint: 'getProjectByProjId',
        params: { project_id },
    };
    return await lambdaQuery(payload, SAVE_AND_LOAD_MODEL);
};

export const getTargetReductionsAndWqMethod = async (state, mlb_name) => {
    const payload = {
        endpoint: 'getTargetReductionsAndWqMethod',
        params: { state, mlb_name },
    };
    return await lambdaQuery(payload, JCO_BACKEND);
};

export const checkIsCompanyAdminAccount = async (email) => {
    const payload = {
        endpoint: 'checkIsCompanyAdminAccount',
        params: {
            email,
        },
    };
    return await lambdaQuery(
        payload,
        'https://4bjkati12a.execute-api.ap-southeast-2.amazonaws.com/stage',
    );
};

export const checkIsCompanySubAccount = async (email) => {
    const payload = { endpoint: 'checkIsCompanySubAccount', params: { email } };
    return await lambdaQuery(
        payload,
        'https://4bjkati12a.execute-api.ap-southeast-2.amazonaws.com/stage',
    );
};

export const saveNewSUModel = (
    project_id,
    eol_data,
    impervious_area,
    pervious_area,
    save_name,
    projectInfo,
    hasReport,
    user_email,
) => {
    const payload = {
        endpoint: 'saveSuModel',
        params: {
            project_id,
            eol_data,
            impervious_area,
            pervious_area,
            save_name,
            development_type: projectInfo.developmentType,
            permit_number: projectInfo.planningPermitNo,
            has_report: hasReport,
            user_email,
        },
    };

    return lambdaQuery(payload, SAVE_AND_LOAD_MODEL);
};

export const getSavedModel = (tool, model_id) => {
    const endpoint = { wsud: 'getSuModelByModelId', osds4vips: 'getOsdModelByModelId' };
    const payload = {
        endpoint: endpoint[tool] ?? 'getSuModelByModelId',
        params: { model_id },
    };

    return lambdaQuery(payload, SAVE_AND_LOAD_MODEL);
};

export const saveNewOSDModel = (
    project_id,
    projectInfo,
    preferred_osd_method,
    osd_method,
    AEPS,
    has_report,
    osd_template_details,
    manufacture_data,
    result_data,
    user_email,
) => {
    const payload = {
        endpoint: 'saveOsdModel',
        params: {
            project_id,
            osd_model_details: {
                preferred_osd_method,
                osd_method,
                AEPS: { AEPS },
                development_type: projectInfo.developmentType,
                permit_number: projectInfo.planningPermitNo,
                has_report,
            },
            osd_template_details: osd_template_details,
            manufacture_data,
            result_data,
            user_email,
        },
    };

    return lambdaQuery(payload, SAVE_AND_LOAD_MODEL);
};

