import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Dropdown, Button, Row, Col, Modal } from 'react-bootstrap';
import ChecklistTable from './ChecklistTable';
import FileSaver from 'file-saver';

function NonCompliantCheck({
    nonCompliantNodes,
    compliantNodes,
    msfData,
    setMsfData,
    setCompliantNodes,
    setNonCompliantNodes,
    fileName,
    setReportBlob,
    setMadeCompliantNodes,
    madeCompliantNodes,
}) {
    const [showModal, setShowModal] = useState(false);
    const [selectedTemplates, setSelectedTemplates] = useState({});
    const [showResetModal, setShowResetModal] = useState(false);
    const [selectedResetNodes, setSelectedResetNodes] = useState([]);
    const hasNonCompliantNodes = nonCompliantNodes && nonCompliantNodes.length > 0;
    const hasCompliantNodes = compliantNodes && compliantNodes.length > 0;
    const originalNonCompliantNodesRef = useRef(nonCompliantNodes);
    useEffect(() => {
        if (!originalNonCompliantNodesRef.current.length) {
            originalNonCompliantNodesRef.current = [...nonCompliantNodes];
        }
    }, [nonCompliantNodes]);
    /**
     *
     * @param {string} msf original msf
     * @param {string} nodeId node currently being processed
     * @param {object} compliantTemplate the compliant template to audit against
     * @returns full msf with updated node
     */
    const handleNodeSelection = (nodeName) => {
        setSelectedResetNodes((prevSelected) => {
            if (prevSelected.includes(nodeName)) {
                return prevSelected.filter((name) => name !== nodeName);
            } else {
                return [...prevSelected, nodeName];
            }
        });
    };

    const handleResetMadeCompliant = () => {
        if (selectedResetNodes.length === 0) return;

        setMadeCompliantNodes((prevNodes) =>
            prevNodes.filter((node) => !selectedResetNodes.includes(node.nodeName)),
        );

        setNonCompliantNodes((prevNodes) => [
            ...prevNodes,
            ...originalNonCompliantNodesRef.current.filter((node) =>
                selectedResetNodes.includes(node.nodeName),
            ),
        ]);

        setSelectedResetNodes([]);
        setReportBlob(null);
    };

    const updateMsf = (msf, nodeId, compliantTemplate) => {
        // TODO: further optimise so no need to concat the original msf repeatedly
        // number of lines of a specific node
        const n = Object.keys(compliantTemplate).length;
        const [startIndex, endIndex] = findMsfNodeIndex(msf, nodeId);

        if (startIndex === -1) {
            console.error(`Node ${nodeId} not found in MSF`);
            return msf;
        }

        const lines = msf.split('\r\n');

        const msfFragment = lines.slice(startIndex, endIndex);
        const updatedMsfFrament = updateNode(msfFragment, compliantTemplate);

        // create a new array with updated lines
        const updatedLines = lines
            .slice(0, startIndex)
            .concat(updatedMsfFrament, lines.slice(endIndex));
        return updatedLines.join('\r\n');
    };

    /**
     *
     * @param {string[]} nodeMsf msflines of the current processing node
     * @param {object} compliantTemplate compliant template to process
     * @returns {string[]} audited node with legit values
     */
    const updateNode = (nodeMsf, compliantTemplate) => {
        // create a dictionary for faster lookup of template values per property_name
        const compliantTemplateDict = Object.keys(compliantTemplate).reduce((acc, key) => {
            const { property_name, min_value, max_value, def_value } = compliantTemplate[key];
            acc[property_name] = { min: min_value, max: max_value, def: def_value };
            return acc;
        }, {});

        const updatedNode = nodeMsf.map((line) => {
            const [key, value, comment] = line.split(',');
            if (key in compliantTemplateDict) {
                const { min, max, def } = compliantTemplateDict[key];
                if (+value < +min || +value > +max) {
                    return `${key},${def || min},${comment}`;
                }
            }
            // if not out of bounds or not checked just return original line
            return line;
        });

        return updatedNode;
    };

    /**
     *
     * @param {string} nodeId node ID of the node to find
     * @returns start and end Index of the node in msf
     */
    const findMsfNodeIndex = (msf, nodeId) => {
        const lines = msf.split('\n');
        const startIndex = lines.findIndex((line) => line.startsWith(`Node ID,${nodeId}`));
        const endIndex = lines.findIndex(
            (line, index) => index > startIndex && line.startsWith(`Node Type,`),
        );
        return [startIndex, endIndex];
    };

    const createCompliantNode = (node, template) => {
        const newNode = { ...node };
        template.checkList.forEach((rule) => {
            newNode.rows.forEach((row) => {
                if (row.rowName === rule.property_name) {
                    const currentValue = parseFloat(row.rowValue);
                    const minValue = parseFloat(rule.min_value);
                    const maxValue = parseFloat(rule.max_value);

                    if (currentValue > maxValue) {
                        row.rowValue = maxValue.toString();
                    } else if (currentValue < minValue) {
                        row.rowValue = minValue.toString();
                    }
                }
            });
        });

        return {
            nodeName: newNode.nodeName,
            nonCompliantRows: {},
            compliantTemplates: [template],
            rows: newNode.rows,
        };
    };
    const handleMakeCompliant = () => {
        const remainingNonCompliantNodes = [];
        let newMsf = msfData.msf;

        nonCompliantNodes.forEach((node, nodeIndex) => {
            if (selectedTemplates[nodeIndex]) {
                const compliantTemplate = selectedTemplates[nodeIndex];

                const nodeId = node.rows.find((row) => row.rowName === 'Node ID').rowValue;
                newMsf = updateMsf(newMsf, nodeId, compliantTemplate.checkList);

                const compliantNode = createCompliantNode(node, compliantTemplate);
                setMadeCompliantNodes((prev) => [...prev, compliantNode]);
            } else {
                remainingNonCompliantNodes.push(node);
            }
        });
        setSelectedTemplates({});
        setNonCompliantNodes(remainingNonCompliantNodes);
        setShowModal(false);
        triggerDownload(newMsf);
        setReportBlob(null);
        setMsfData({ ...msfData, msf: newMsf });
    };

    const triggerDownload = (msfData) => {
        if (!msfData) return;

        let msfString = '';
        const lines = msfData.split(/\r?\n/);
        lines.forEach((line) => {
            msfString += `${line}\r\n`;
        });

        const blob = new Blob([msfString], { type: 'text/plain' });
        const modifiedFileName = `${fileName}_compliant.msf`;
        FileSaver.saveAs(blob, modifiedFileName);
    };

    const handleTemplateSelection = (nodeIndex, templateIndex) => {
        const selectedTemplate = nonCompliantNodes[nodeIndex].nonCompliantTemplates[templateIndex];
        setSelectedTemplates({
            ...selectedTemplates,
            [nodeIndex]: selectedTemplate,
        });
    };

    return (
        <div className="mx-3">
            {hasNonCompliantNodes && (
                <>
                    <h3 style={{ marginBottom: '2%' }}>Non Compliant Nodes</h3>
                    <Row>
                        {nonCompliantNodes.map((node, index) => (
                            <NonCompliantNode
                                nodeProperties={node}
                                key={'non-compliant' + index}
                                onTemplateSelect={(templateIndex) =>
                                    handleTemplateSelection(index, templateIndex)
                                }
                                selectedTemplate={selectedTemplates[index]}
                            />
                        ))}
                    </Row>
                    {/* Always enable the button */}
                    <Button variant="primary" onClick={() => setShowModal(true)}>
                        Make Nodes Compliant
                    </Button>
                </>
            )}
            {hasCompliantNodes && (
                <>
                    <div
                        style={{
                            marginBottom: '2%',
                            borderTop: hasNonCompliantNodes ? '1px solid black' : '',
                            marginTop: '3%',
                        }}
                    >
                        <h3 style={{ marginTop: '2%' }}>Compliant Nodes</h3>
                    </div>
                    <Row style={{ marginBottom: '2%', alignItems: 'flex-start' }}>
                        {compliantNodes.map((node, index) => (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-start',
                                    border: '1px solid black',
                                    width: '33.333333%',
                                    padding: '1rem',
                                    gap: '1vw',
                                }}
                                key={'compliant' + index}
                            >
                                <p style={{ fontSize: '1rem', textAlign: 'left' }}>
                                    {node.nodeName}
                                </p>
                            </div>
                        ))}
                    </Row>
                </>
            )}
            {madeCompliantNodes.length > 0 && (
                <>
                    <div
                        style={{
                            marginBottom: '2%',
                            borderTop: '1px solid black',
                            marginTop: '3%',
                        }}
                    >
                        <h3 style={{ marginTop: '2%' }}>Nodes Made Compliant</h3>
                    </div>
                    <Row style={{ marginBottom: '2%', alignItems: 'flex-start' }}>
                        {madeCompliantNodes.map((node, index) => (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    border: '1px solid black',
                                    width: '33.333333%',
                                    padding: '1rem',
                                    gap: '1vw',
                                }}
                                key={'made-compliant' + index}
                            >
                                <p style={{ fontSize: '1rem', textAlign: 'left' }}>
                                    {node.nodeName}
                                </p>
                            </div>
                        ))}
                    </Row>
                    <Button
                        variant="warning"
                        onClick={() => setShowResetModal(true)}
                        disabled={madeCompliantNodes.length === 0}
                    >
                        Reset Nodes
                    </Button>
                </>
            )}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Templates for Compliance</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {nonCompliantNodes.map((node, nodeIndex) => (
                        <Row key={nodeIndex} className="align-items-center mb-3">
                            <Col xs={6}>
                                <p className="mb-0">{node.nodeName}</p>
                            </Col>
                            <Col xs={6} className="d-flex justify-content-end">
                                <Dropdown
                                    onSelect={(eventKey) =>
                                        handleTemplateSelection(nodeIndex, parseInt(eventKey))
                                    }
                                >
                                    <Dropdown.Toggle variant="success" id={`dropdown-${nodeIndex}`}>
                                        {selectedTemplates[nodeIndex]
                                            ? selectedTemplates[nodeIndex].match_name
                                            : 'Select Template'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {node.nonCompliantTemplates.map(
                                            (template, templateIndex) => (
                                                <Dropdown.Item
                                                    key={templateIndex}
                                                    eventKey={templateIndex}
                                                >
                                                    {template.match_name}
                                                </Dropdown.Item>
                                            ),
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Row>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleMakeCompliant}>
                        Apply Compliance
                    </Button>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showResetModal} onHide={() => setShowResetModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Nodes to Reset</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {madeCompliantNodes.map((node, index) => (
                        <div
                            key={index}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '1rem',
                                justifyContent: 'space-between',
                                borderTop: index % 2 === 1 ? '1px solid #ccc' : 'none',
                                paddingTop: index % 2 === 1 ? '1vh' : '0',
                            }}
                        >
                            <span
                                style={{ marginRight: '10px', width: '200px', textAlign: 'left' }}
                            >
                                {node.nodeName}
                            </span>
                            <input
                                type="checkbox"
                                checked={selectedResetNodes.includes(node.nodeName)}
                                onChange={() => handleNodeSelection(node.nodeName)}
                                style={{ marginLeft: '10px', width: '20px', height: '20px' }}
                            />
                        </div>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowResetModal(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            handleResetMadeCompliant();
                            setShowResetModal(false);
                        }}
                    >
                        Reset Selected Nodes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

function NonCompliantNode(props) {
    const { nodeProperties, onTemplateSelect } = props;

    if (!nodeProperties) {
        return <p>Error: nodeProperties is undefined</p>;
    }

    const handleViewChecklists = (template) => {
        openNewWindowWithReactComponent(template);
    };

    const openNewWindowWithReactComponent = (template) => {
        const newWindow = window.open('', '_blank', 'width=800,height=600');
        newWindow.document.write('<div id="root"></div>');
        newWindow.document.title = `Auditor: Compare ${
            nodeProperties.rows.find((row) => row.rowName === 'Node Name').rowValue
        } to guidelines for ${template.match_name}`;
        newWindow.document.close();

        const rootElement = newWindow.document.getElementById('root');

        const handleClose = () => {
            newWindow.close();
        };

        const renderComponent = () => {
            ReactDOM.render(
                <ChecklistTable
                    template={template}
                    rows={nodeProperties.rows}
                    onClose={handleClose}
                />,
                rootElement,
            );
        };

        if (newWindow.document.readyState === 'complete') {
            renderComponent();
        } else {
            newWindow.onload = renderComponent;
        }
    };

    const divStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        border: '1px solid black',
        width: '33.333333%',
        padding: '1rem',
        gap: '1vw',
    };

    const dropdownMenuStyle = {
        maxHeight: '30vh',
        overflowY: 'auto',
    };

    return (
        <div style={divStyle}>
            <p style={{ fontSize: '1rem', textAlign: 'left' }}>{nodeProperties.nodeName}</p>
            <Dropdown className="ms-auto">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Audit against
                </Dropdown.Toggle>
                <Dropdown.Menu style={dropdownMenuStyle}>
                    {nodeProperties.nonCompliantTemplates.map((template, index) => (
                        <Dropdown.Item key={index} onSelect={() => onTemplateSelect(index)}>
                            {template.match_name}
                            <Button variant="link" onClick={() => handleViewChecklists(template)}>
                                View Checklists
                            </Button>
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

export default NonCompliantCheck;
