import { useState, useEffect } from 'react';
import { sails_api } from '../utils/globalConstant';

export const useCyValue = (
    method,
    designStorm,
    mapInfo,
    { preDevAreas, setPreDevAreas },
    { postDevAreas, setPostDevAreas },
) => {
    const [loading, setLoading] = useState(true);

    const [calculatedCy, setCalculatedCy] = useState({
        cyPre: { impervious: null, pervious: null },
        cyPost: { impervious: null, pervious: null },
    });

    /**
     * Calculate the runoff coefficient in the backend
     */
    useEffect(() => {
        if (!designStorm || !mapInfo) return;
        const osdRoute = '/OSD/osdResults/calculateCy';
        const payload = {
            latitude: mapInfo.coordinate.lat,
            longitude: mapInfo.coordinate.lng,
            osd: {
                method: method,
                preDevAep: designStorm.preDevDesignStorm,
                postDevAep: designStorm.postDevDesignStorm,
                impervious: false,
            },
        };
        setLoading(true);
        sails_api
            .post(osdRoute, payload)
            .then((res) => {
                if (res.data.message) {
                    alert(res.data.message);
                    return;
                }
                // Update the calculated value of Cy
                const updatedState = { ...calculatedCy };
                updatedState.cyPre.impervious = res.data.results.cyPre.impervious;
                updatedState.cyPre.pervious = res.data.results.cyPre.pervious;
                updatedState.cyPost.impervious = res.data.results.cyPost.impervious;
                updatedState.cyPost.pervious = res.data.results.cyPost.pervious;
                setCalculatedCy(updatedState);
            })
            .catch((err) => {
                console.log(err);
                alert('Computing runoff coefficient (cy) result error!');
            })
            .finally(() => {
                setLoading(false);
            });
    }, [mapInfo, designStorm]);

    /**
     * Update the runoff coeffecient
     */
    useEffect(() => {
        const { cyPre, cyPost } = calculatedCy;
        // Update Cy pre for each are
        const updatedStatePre = [...preDevAreas];
        preDevAreas.forEach(({ impervious }, idx) => {
            if (impervious === null) return;
            updatedStatePre[idx].cy = impervious ? cyPre.impervious : cyPre.pervious;
        });
        setPreDevAreas(updatedStatePre);

        // Update Cy Post for each area
        const updatedStatePost = [...postDevAreas];
        postDevAreas.forEach(({ impervious }, idx) => {
            if (impervious === null) return;
            const cy = impervious ? cyPost.impervious : cyPost.pervious;
            updatedStatePost[idx].cy = cy;
        });
        setPostDevAreas(updatedStatePost);
    }, [preDevAreas.length, postDevAreas.length, calculatedCy]);

    return { calculatedCy, loading };
};
