import React from 'react';
import { StyleSheet, Document, pdf, Page, View, Text, Image } from '@react-pdf/renderer';
// components
import BasicInformation from '../BasicInformation';
import ModifiedRationalReportTemplate from './ModifiedRationalReportTemplate';
import ReportFooter from '../ReportFooter';
import ReportHeader from '../ReportHeader';
import { getImageUrlFromS3 } from '../../api/s3';
import OsdRangeOfDurationsCalculatedPage from './../sharedPages/OsdRangeOfDurationsCalculatedPage';

const getModifiedRationalReport = (
    data,
    manufactureImageLinks,
    manufactureDocuments,
    imageSource,
) => {
    const styles = StyleSheet.create({
        page: {
            paddingTop: 30,
            paddingBottom: 10,
            paddingHorizontal: 35,
        },
        imageContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
        },
        image: {
            paddingTop: 30,
            height: 350,
            width: 400,
        },
        mainTitle: {
            fontSize: 18,
            fontweight: 700,
            textAlign: 'center',
            marginTop: 5,
            marginBottom: 20,
        },
    });

    return (
        <Document>
            <BasicInformation
                reportHeaderUrl={data.reportHeaderIconUrl}
                address={{
                    suburb: data.suburb,
                    state: data.state,
                    postcode: data.postcode,
                    council: data.council,
                    address: data.address,
                    coordinate: data.coordinate,
                }}
                basicInfo={{
                    developmentType: data.developmentType,
                    permit: data.planningPermitNo,
                    projectName: 'N/A',
                    assessor: data.assessorName,
                    email: data.assessorEmail,
                }}
            />
            {/* OSD Summary */}
            <ModifiedRationalReportTemplate
                data={data}
                imageSource={imageSource}
                manufactureImageLinks={manufactureImageLinks}
                manufactureDocuments={manufactureDocuments}
            />
            {/* Storm Duration Chart */}
            <Page style={styles.page} size="A4">
                <View>
                    <ReportHeader reportHeaderUrl={data.reportHeaderIconUrl} />
                </View>

                <View>
                    <Text style={styles.mainTitle}>
                        Range of Durations used to Determine Max Volume Required
                    </Text>
                </View>
                <View style={styles.imageContainer}>
                    <Image src={data.osd.tDurChart} style={styles.image} />
                </View>
                {/* Footer */}
                <ReportFooter />
            </Page>
            {/* Storm Duration Table */}
            <OsdRangeOfDurationsCalculatedPage
                vreq_n_data={data.osdResult.report.vreq_n_data}
                reportHeaderIconUrl={data.reportHeaderIconUrl}
            />
        </Document>
    );
};

// main function
export const generateModifiedRationalReport = async (
    data,
    manufactureImageLinks,
    manufactureDocuments,
) => {
    // Get report image from S3
    const reportImageurl = await getImageUrlFromS3(
        'osd-report-images',
        'modified-rational-storage-volume-diagram.png',
    )
        .then((res) => res)
        .catch((err) => console.error('Fetching image from S3 failed!', err));

    const response = await fetch(reportImageurl, {
        headers: {
            'Access-Control-Allow-Origin': 'https://www.stormupdated.com.au',
            Origin: 'https://www.stormupdated.com.au',
        },
    });
    const blob = await response.blob();
    const imageSource = URL.createObjectURL(blob);

    try {
        const reportBlob = await pdf(
            getModifiedRationalReport(
                data,
                manufactureImageLinks,
                manufactureDocuments,
                imageSource,
            ),
        ).toBlob();
        return reportBlob;
    } catch (err) {
        console.error(err);
    }
};
