import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import './TCCalculatorContainer.css';
import RenderDoc from '../RenderDoc/RenderDoc';

const TCCalculatorContainer = ({
    children,
    title,
    selectedMethod,
    onMethodChange,
    methods = [],
    result = {},
    inProgress,
    onSubmit,
    deleteIcon,
    methodToolTip,
}) => {
    // Construct options
    const methodOptions = methods.map((method, i) => {
        return (
            <option key={i} value={method.id}>
                {method.label}
            </option>
        );
    });

    const resultValue = result.value ?? 'N/A';

    return (
        <section className="tc-calculator-container">
            {title && (
                <div className="tc-calculator-header">
                    <h4 className="tc-calculator-title">{title}</h4>
                </div>
            )}
            <div className="tc-calculator-body">
                <div>
                    <Form.Label className="d-flex align-item-center justify-content-between">
                        <div>Method</div> <div>{deleteIcon}</div>
                    </Form.Label>
                    <Form.Control
                        as="select"
                        defaultValue=""
                        onChange={(e) =>
                            onMethodChange(methods.find((method) => method.id === e.target.value))
                        }
                        value={selectedMethod}
                    >
                        <option value="">Select Method ...</option>
                        {methodOptions}
                    </Form.Control>
                </div>
                {selectedMethod ? children : null}
            </div>
            <div className="tc-calculator-footer">
                <div className="d-flex gap-2">
                    
                    {methodToolTip && (
                        <OverlayTrigger
                            placement="top"
                            overlay={(props) => (
                                <Popover {...props}>
                                    <Popover.Content>
                                        <RenderDoc blocks={methodToolTip.blocks} />
                                    </Popover.Content>
                                </Popover>
                            )}
                        >
                            <Button
                                variant="info"
                                disabled={selectedMethod === undefined || inProgress}
                            >
                                Help❓
                            </Button>
                        </OverlayTrigger>
                    )}
                </div>
                <p>
                    {result.label ?? 'Result'}: {inProgress ? 'Calculating...' : resultValue}
                </p>
            </div>
        </section>
    );
};

export default TCCalculatorContainer;
