// modules
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
// slices
import { selectMapInfo, setMapInfo } from '../../utils/redux/mapInfoSlice';
// css
import './OsdPanel.css';

export default function OsdPanel({ tabs, selectedTab, handleTabChange }) {
    const dispatch = useDispatch();
    const mapInfo = useSelector(selectMapInfo);

    /**
     * @param {string} currentTab - The ID of the clicked tab
     */
    const handleTabButtonClick = (currentTab, bgColor) => {
        handleTabChange(currentTab, bgColor);
        dispatch(setMapInfo({ ...mapInfo, osdMethod: currentTab }));
    };

    return (
        <>
            <div
                className="tab"
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    marginBottom: '0.5rem',
                    borderRadius: '10px',
                }}
            >
                {/* tab bar */}
                {tabs.map(({ currentTab, label, bgColor, disabled }) => {
                    return (
                        <button
                            key={label}
                            className={selectedTab === currentTab ? 'tab-active' : 'tab'}
                            style={{
                                '--active-bg-color': bgColor ?? '',
                                minWidth: 'fit-content',
                                flexGrow: 1,
                            }}
                            onClick={() => handleTabButtonClick(currentTab, bgColor)}
                            disabled={disabled}
                        >
                            {label}
                        </button>
                    );
                })}
            </div>
        </>
    );
}
