import React from 'react';
import { StyleSheet, View, Text, Page } from '@react-pdf/renderer';
import ReportHeader from '../ReportHeader';
import ReportFooter from '../ReportFooter';

const processData = (data) => {
    if (!data) return [];
    const filteredData = data.filter((entry) => entry.value !== '');
    const rows = [];
    for (let i = 0; i < filteredData.length; i += 2) {
        if (filteredData[i] && filteredData[i + 1]) {
            rows.push({
                input: filteredData[i].value,
                output: filteredData[i + 1].value,
            });
        }
    }
    return rows;
};

const combineRows = (gp, tss, tn, tp, flow) => {
    const combined = [];
    const maxLength = Math.max(gp.length, tss.length, tn.length, tp.length, flow.length);

    for (let i = 0; i < maxLength; i++) {
        combined.push([
            gp[i]?.input || '',
            gp[i]?.output || '',
            tss[i]?.input || '',
            tss[i]?.output || '',
            tn[i]?.input || '',
            tn[i]?.output || '',
            tp[i]?.input || '',
            tp[i]?.output || '',
            flow[i]?.input || '',
            flow[i]?.output || '',
        ]);
    }

    return combined;
};

const GPTCaptureTable = ({ extractedValues, companyIcon }) => {
    const styles = StyleSheet.create({
        title1: {
            fontSize: 14,
            textAlign: 'left',
            marginBottom: '5%',
        },
        content: {
            fontSize: 10,
            textAlign: 'left',
            marginBottom: '3%',
            lineHeight: 2,
        },
        row: {
            flexDirection: 'row',
            borderBottomWidth: 1,
            borderBottomColor: '#000',
            paddingTop: 4,
            paddingBottom: 4,
        },
        table: {
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#000',
        },
        tableRow: {
            flexDirection: 'row',
            borderBottomWidth: 1,
            borderBottomColor: '#000',
            padding: 4,
        },
        headerCell: {
            flex: 1,
            fontWeight: 'bold',
        },
        tableCell: {
            flex: 1,
        },
        body: {
            paddingTop: 30,
            paddingBottom: 10,
            paddingHorizontal: 35,
        },
    });

    const tableStyles = StyleSheet.create({
        section: {
            marginBottom: 30,
            marginTop: 30,
            padding: 10,
        },
        title: {
            fontSize: 14,
            textAlign: 'center',
            marginBottom: '3%',
        },
        table: {
            padding: 15,
        },
        tableTitle: {
            width: '100%',
            textAlign: 'center',
            fontSize: 14,
            marginBottom: '15%',
        },
        tableRow: {
            flexDirection: 'row',
        },
        tableCol: {
            flex: 1,
            textAlign: 'center',
            fontSize: 9,
            border: 1,
            padding: 5,
        },
        tableColLong: {
            flex: 1,
            textAlign: 'center',
            fontSize: 9,
            border: 1,
            padding: 5,
            minWidth: 70,
        },
    });

    return (
        <Page style={styles.body}>
            {extractedValues.map((row, index) => {
                const gptName = row.name;
                const gp = row.values.GP || [];
                const tss = row.values.TSS || [];
                const tn = row.values.TN || [];
                const tp = row.values.TP || [];
                const flow = row.values.Flow || [];

                const gpRows = processData(gp);
                const tssRows = processData(tss);
                const tnRows = processData(tn);
                const tpRows = processData(tp);
                const flowRows = processData(flow);

                const combinedRows = combineRows(gpRows, tssRows, tnRows, tpRows, flowRows);

                return (
                    <View style={tableStyles.section} key={index}>
                        <ReportHeader reportHeaderUrl={companyIcon} />
                        <View style={tableStyles.table}>
                            <View style={tableStyles.tableRow}>
                                <Text style={tableStyles.tableCol}>
                                    Pollutant Capture for {gptName}
                                </Text>
                            </View>
                            <View style={tableStyles.tableRow}>
                                <Text style={tableStyles.tableCol}>Concentration</Text>
                            </View>
                            <View style={tableStyles.tableRow}>
                                <Text style={tableStyles.tableCol}>GP</Text>
                                <Text style={tableStyles.tableCol}>TSS</Text>
                                <Text style={tableStyles.tableCol}>TP</Text>
                                <Text style={tableStyles.tableCol}>TN</Text>
                                <Text style={tableStyles.tableCol}>Flow</Text>
                            </View>
                            <View style={tableStyles.tableRow}>
                                <Text style={tableStyles.tableCol}>Input</Text>
                                <Text style={tableStyles.tableCol}>Output</Text>
                                <Text style={tableStyles.tableCol}>Input</Text>
                                <Text style={tableStyles.tableCol}>Output</Text>
                                <Text style={tableStyles.tableCol}>Input</Text>
                                <Text style={tableStyles.tableCol}>Output</Text>
                                <Text style={tableStyles.tableCol}>Input</Text>
                                <Text style={tableStyles.tableCol}>Output</Text>
                                <Text style={tableStyles.tableCol}>Input</Text>
                                <Text style={tableStyles.tableCol}>Output</Text>
                            </View>

                            {combinedRows.map((row, rowIndex) => (
                                <View style={tableStyles.tableRow} key={rowIndex}>
                                    {row.map((col, colIndex) => (
                                        <Text style={tableStyles.tableCol} key={colIndex}>
                                            {col}
                                        </Text>
                                    ))}
                                </View>
                            ))}
                        </View>
                    </View>
                );
            })}
            <ReportFooter />
        </Page>
    );
};

export default GPTCaptureTable;
