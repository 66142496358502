import React, { useState } from "react";
import styled, { keyframes } from "styled-components";

// Keyframe for slide animation
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled components
const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${fadeIn} 1s ease-in;
`;

const Slide = styled.div`
  display: flex;
  flex: 0 0 100%;
  &:nth-child(n + 2) {
    animation: ${fadeIn} 0.5s ease-in-out;
  }
`;

const Button = styled.button`
  background-color: #008cba;

  color: white;
  font-size: 1rem;

  padding: 5px 10px;
  margin: 0 5px;

  border: none;
  border-radius: 10px;

  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #005f6b;
  }
`;

/**
 * A simple slideshow component that takes an array of content and maps each item to a slide.
 * @param {Array} children - An Array of components which you wish you present in each slide. Please contain your component with <></>.
 * @returns {JSX.Element} - Returns the SimpleSlides component.
 */
export const SimpleSlides = ({ children }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = 3; // Replace with the total number of slides
  // Convert the children to an array
  const modifiedChildren = React.Children.map(children, (child, index) => {
    return (
      <Slide style={{ display: currentSlide === index ? "block" : "none" }}>
        {child}
      </Slide>
    );
  });

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === totalSlides - 1 ? 0 : prevSlide + 1
    );
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? totalSlides - 1 : prevSlide - 1
    );
  };

  return (
    <SlideContainer>
      {modifiedChildren && modifiedChildren.length > 1 && (
        <div className="mb-3">
          <Button onClick={handlePrevSlide}>{"<"}</Button>
          <Button onClick={handleNextSlide}>{">"}</Button>
        </div>
      )}
      {modifiedChildren}
    </SlideContainer>
  );
};
