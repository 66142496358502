import { createSlice } from "@reduxjs/toolkit";

const idmTemplateSlice = createSlice({
    name: "idmTemplateInfo",
    initialState: {
        totalSiteArea: "",
        imperviousArea: "",
        selectedDevelopment: "Multi-unit development (newer residential areas)",
        allowableDischargeRate: 65,
        storageRequired: {
            vertical: 8,
            horizontal: 10,
        },
        climateAdjustment: 0,
        climateDisplay: 0,
        tankHeight: "",
        dataUploaded: false,
    },
    reducers: {
        setTotalSiteArea: (state, action) => {
            state.totalSiteArea = action.payload;
        },
        setImperviousArea: (state, action) => {
            state.imperviousArea = action.payload;
        },
        setSelectedDevelopment: (state, action) => {
            state.selectedDevelopment = action.payload;
        },
        setAllowableDischargeRate: (state, action) => {
            state.allowableDischargeRate = action.payload;
        },
        setStorageRequired: (state, action) => {
            state.storageRequired = action.payload;
        },
        setClimateAdjustment: (state, action) => {
            state.climateAdjustment = action.payload;
        },
        setClimateDisplay: (state, action) => {
            state.climateDisplay = action.payload;
        },
        setTankHeight: (state, action) => {
            state.tankHeight = action.payload;
        },
        setDataUploaded: (state, action) => {
            state.dataUploaded = action;
        },
        setIDMTemplateState: (state, action) => {
            return action.payload; // Set the entire state with the provided payload
        },
    },
});

export const {
    setTotalSiteArea,
    setImperviousArea,
    setSelectedDevelopment,
    setAllowableDischargeRate,
    setStorageRequired,
    setClimateAdjustment,
    setClimateDisplay,
    setTankHeight,
    setIDMTemplateState,
    setDataUploaded,
} = idmTemplateSlice.actions;
export const idmTemplate = (state) => state.idmTemplateInfo;
export default idmTemplateSlice.reducer;
