import { z } from 'zod';

export const preDevAreasSchema = z.object({
    areas: z.array(
        z.object({
            id: z.number(),
            areaName: z.string(),
            surfaceArea: z.coerce.number(),
            // Handle both boolean as string or as boolean
            impervious: z
                .null()
                .or(z.enum(['true', 'false']).transform((value) => value === 'true'))
                .or(z.coerce.boolean()),
            cy: z.coerce.number(),
            // cyWeighted: z.coerce.number(),
        }),
    ),
});

export const postDevAreasSchema = z.object({
    areas: z.array(
        z.object({
            id: z.number(),
            areaName: z.string(),
            surfaceArea: z.coerce.number(),
            uncontrolled: z.coerce.number().default(0),
            // Handle both boolean as string or as boolean
            impervious: z
                .null()
                .or(z.enum(['true', 'false']).transform((value) => value === 'true'))
                .or(z.coerce.boolean()),
            cy: z.coerce.number(),
            // cyWeighted: z.coerce.number(),
        }),
        // .refine((area) => area.uncontrolled < area.surfaceArea),
    ),
});
