import React from 'react';
import { StyleSheet, View, Page, Text } from '@react-pdf/renderer';
// components
import ReportFooter from '../ReportFooter';
import ReportHeader from '../ReportHeader';

/**
 * OSD IDM Report
 *
 * @param {string} reportHeaderUrl
 * @param {string} osdMethod
 * @param {string} totalSiteArea
 * @param {object} templateData
 * @param {object} osdResult
 * @param {string} OSDCompany
 * @param {string} OSDMaterial
 * @param {string} storageType
 * @param {Array} manufactureImageLinks
 * @param {Array} manufactureDocumentNames
 * @param {Array} manufactureDocumentLinks
 * @returns
 */
export default function IdmReportTemplate({
    reportHeaderUrl,
    osdMethod,
    totalSiteArea,
    siteAreaDrainingToOSD,
    templateData,
    osdResult,
    OSDCompany,
    OSDMaterial,
    storageType,
}) {
    const styles = StyleSheet.create({
        body: {
            paddingTop: 30,
            paddingBottom: 10,
            paddingHorizontal: 35,
        },
        section: {
            marginBottom: 10,
            padding: 15,
            display: 'flex',
            flexDirection: 'row',
        },
        mainTitle: {
            fontSize: 20,
            fontweight: 700,
            textAlign: 'center',
            marginTop: 5,
            marginBottom: 5,
        },
        title1: {
            fontSize: 15,
            paddingInline: 50,
            marginTop: 10,
        },
    });

    return (
        <>
            {/* First Page */}
            <Page style={styles.body} size="A4">
                {/* Header */}
                <View>
                    <ReportHeader reportHeaderUrl={reportHeaderUrl} />
                </View>
                {/* Content */}
                <View>
                    <View>
                        <Text style={styles.mainTitle}>OSD Design Summary Report</Text>
                    </View>
                    <View>
                        <Text style={styles.title1}>
                            Method used for OSD calculations: {osdMethod}
                        </Text>
                    </View>
                    {/* OSD System Specified */}
                    <View>
                        <Text style={{ fontSize: 10, marginTop: 10 }}>
                            Device Name: {OSDCompany}
                        </Text>
                        <Text style={{ fontSize: 10, marginTop: 10 }}>
                            Device Type: {OSDMaterial} - {storageType}
                        </Text>
                    </View>
                    <View style={{ fontSize: 10, marginTop: 10 }}>
                        <Text>Total Site Area (m²): {totalSiteArea}</Text>
                    </View>
                    <View style={{ fontSize: 10, marginTop: 10 }}>
                        <Text>Site Area Draining to OSD (m²): {siteAreaDrainingToOSD}</Text>
                    </View>
                    <View style={{ height: 10 }} />
                    {/* Development Type Table */}
                    <View>
                        <DevelopmentTypeTable
                            templateData={templateData}
                            storageType={storageType}
                        />
                    </View>
                    <View style={{ height: 20 }} />
                    {/* Flow Calculation and Storage Details */}
                    <View>
                        <FlowCalculationsAndStorageDetails
                            templateData={templateData}
                            osdResult={osdResult}
                            storageType={storageType}
                        />
                    </View>
                </View>
                <View style={{ height: 250 }} />
                {/* Footer */}

                <ReportFooter />
            </Page>
        </>
    );
}

const DevelopmentTypeTable = ({ templateData, storageType }) => {
    const styles = StyleSheet.create({
        table: { flexDirection: 'column', textAlign: 'center' },
        row: { flexDirection: 'row' },
        headerTop: {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 5,
            fontSize: 8,
            width: 20,
            borderTop: '1px solid black',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
        },
        headerTopMiddle: {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: 5,
            fontSize: 8,
            width: 20,
            borderTop: '1px solid black',
            borderRight: '1px solid black',
        },
        headerTopLeft: {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            padding: 5,
            fontSize: 8,
            width: 20,
            borderTop: '1px solid black',
            borderBottom: '1px solid black',
        },
        headerTopRight: {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            padding: 5,
            fontSize: 8,
            width: 20,
            borderTop: '1px solid black',
            borderRight: '1px solid black',
            borderBottom: '1px solid black',
        },
        headerBottom: {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: 5,
            fontSize: 8,
            width: 20,
            borderBottom: '1px solid black',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
        },
        headerBottomMiddle: {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: 5,
            fontSize: 8,
            width: 20,
            borderBottom: '1px solid black',
            borderRight: '1px solid black',
        },
        headerBottomLeft: {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            padding: 5,
            fontSize: 8,
            width: 20,
            borderRight: '1px solid black',
            borderBottom: '1px solid black',
        },
        headerBottomRight: {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            padding: 5,
            fontSize: 8,
            width: 20,
            borderRight: '1px solid black',
            borderBottom: '1px solid black',
        },
        cell: {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 5,
            fontSize: 8,
            width: 20,
            border: '1px solid black',
        },
    });

    const firstCol =
        templateData.selectedDevelopment === 'Multi-unit development (newer residential areas)'
            ? 'C=0.5 for a 20% AEP to C=0.8 for a 20% AEP'
            : templateData.selectedDevelopment ===
                'Multi-unit development (older residential areas)'
              ? 'C=0.5 for a 20% 1EY event to C=0.8 for a 20% AEP'
              : templateData.selectedDevelopment === 'Industrial development (older areas)'
                ? 'C=0.5 for a 20% 1EY event to C=0.9 for a 10% AEP'
                : templateData.selectedDevelopment === 'Industrial development (newer areas)'
                  ? 'C=0.9 for a 20% 1EY event to C=0.9 for a 10% AEP'
                  : templateData.selectedDevelopment === 'Commercial development' &&
                    'C=0.9 for a 1EY event to C=0.9 for a 5% AEP';
    const thirdCol =
        storageType === 'below ground pipe' ? 'N/A' : templateData.storageRequired.vertical;
    const forthCol =
        storageType === 'below ground pipe' ? templateData.storageRequired.horizontal : 'N/A';

    return (
        <>
            <View style={styles.table}>
                {/* Header */}
                <View style={styles.row}>
                    <View style={styles.headerTop}>
                        <Text>TYPE OF</Text>
                    </View>
                    <View style={styles.headerTopMiddle}>
                        <Text>ORIGINAL AND REQUIRED</Text>
                    </View>
                    <View style={styles.headerTopMiddle}>
                        <Text>DESIGN PARAMETERS ALLOWABLE</Text>
                    </View>
                    <View style={styles.headerTopLeft}>
                        <Text>Storage Required </Text>
                    </View>
                    <View style={styles.headerTopRight}>
                        <Text>( litters / m )</Text>
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={styles.headerBottom}>
                        <Text>DEVELOPMENT</Text>
                    </View>
                    <View style={styles.headerBottomMiddle}>
                        <Text>DESIGN PARAMETERS</Text>
                    </View>
                    <View style={styles.headerBottomMiddle}>
                        <Text>DISCHARGE RATE (litres/sec/ha)</Text>
                    </View>
                    <View style={styles.headerBottomLeft}>
                        <Text>VERTICAL (Tank/Basin)</Text>
                    </View>
                    <View style={styles.headerBottomRight}>
                        <Text>HORIZONTAL (Pipe)</Text>
                    </View>
                </View>
                {/* Content */}
                <View style={styles.row}>
                    <View style={styles.cell}>
                        <Text>{templateData.selectedDevelopment}</Text>
                    </View>
                    <View style={styles.cell}>
                        <Text>{firstCol}</Text>
                    </View>
                    <View style={styles.cell}>
                        <Text>{templateData.allowableDischargeRate}</Text>
                    </View>
                    <View style={styles.cell}>
                        <Text>{thirdCol}</Text>
                    </View>
                    <View style={styles.cell}>
                        <Text>{forthCol}</Text>
                    </View>
                </View>
            </View>
        </>
    );
};
const FlowCalculationsAndStorageDetails = ({ templateData, osdResult, storageType }) => {
    const styles = StyleSheet.create({
        superscript: {
            fontSize: 8,
            position: 'relative',
            top: -8,
        },
        tableTitle: { fontSize: 16, padding: 5 },
        table: { flexDirection: 'column', textAlign: 'left' },
        row: { flexDirection: 'row', border: '1px solid black' },
        cellLeft: {
            flexGrow: 1,
            display: 'flex',
            padding: 5,
            fontSize: 8,
            width: 20,
        },
        cellRight: {
            flexGrow: 1,
            display: 'flex',
            padding: 5,
            fontSize: 8,
            width: 20,
        },
    });

    const calculatedPsd =
        storageType === 'below ground pipe'
            ? osdResult.pipeCalcResult.permissionDischarge
            : osdResult.tankCalcResult.permissionDischarge;
    const requiredStorageVolume = templateData.storageRequiredCalculated;
    const climateAdjustment = templateData.climateAdjustment;
    const adjustedRequiredStorageVolume =
        storageType === 'below ground pipe'
            ? osdResult.pipeCalcResult.onSiteDetentionVolume
            : osdResult.tankCalcResult.onSiteDetentionVolume;
    const tankHeight = templateData.tankHeight ? templateData.tankHeight : 'N/A';
    const orificeDiameter =
        storageType === 'below ground pipe'
            ? osdResult.pipeCalcResult.orificeDiameter
            : osdResult.tankCalcResult.orificeDiameter;

    return (
        <>
            <View style={styles.table}>
                <View style={styles.row}>
                    <Text style={styles.tableTitle}>Flow Calculations and Storage Details</Text>
                </View>
                {/* Calculated PSD (L/s) */}
                <View style={styles.row}>
                    <View style={styles.cellLeft}>
                        <Text>Calculated PSD (L/s):</Text>
                    </View>
                    <View style={styles.cellRight}>
                        <Text>{calculatedPsd}</Text>
                    </View>
                </View>
                {/* Required Storage Volume (m2): */}
                <View style={styles.row}>
                    <View style={styles.cellLeft}>
                        <Text>
                            Required Storage Volume (m
                            <Text style={styles.superscript}>2</Text>):
                        </Text>
                    </View>
                    <View style={styles.cellRight}>
                        <Text>{requiredStorageVolume}</Text>
                    </View>
                </View>
                {/* Climate Change (% increase of required storage volume) */}
                <View style={styles.row}>
                    <View style={styles.cellLeft}>
                        <Text>Climate Change (% increase of required storage volume):</Text>
                    </View>
                    <View style={styles.cellRight}>
                        <Text>{climateAdjustment}</Text>
                    </View>
                </View>
                {/* Adjusted Required Storage Volume (m3) */}
                <View style={styles.row}>
                    <View style={styles.cellLeft}>
                        <Text>
                            Adjusted Required Storage Volume (m
                            <Text style={styles.superscript}>3</Text>):
                        </Text>
                    </View>
                    <View style={styles.cellRight}>
                        <Text>{adjustedRequiredStorageVolume}</Text>
                    </View>
                </View>
                {/* Height of Storage Above Orifice(m) */}
                <View style={styles.row}>
                    <View style={styles.cellLeft}>
                        <Text>Height of Storage Above Orifice(m):</Text>
                    </View>
                    <View style={styles.cellRight}>
                        <Text>{tankHeight}</Text>
                    </View>
                </View>
                {/* Orifice Diameter (mm) */}
                <View style={styles.row}>
                    <View style={styles.cellLeft}>
                        <Text>Orifice Diameter (mm):</Text>
                    </View>
                    <View style={styles.cellRight}>
                        <Text>{orificeDiameter}</Text>
                    </View>
                </View>
            </View>
        </>
    );
};
