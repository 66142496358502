import { sails_api } from '../../../../../utils/globalConstant';

export const liveResultCalculator = async (
    area,
    state,
    cityCouncil,
    rainfallStation,
    catchmentType,
    rainwaterDemand,
    isPervious,
) => {
    var bedrooms = parseFloat(area['numberOfOccupants']);
    var bassRoot = area['apiBaseRoute'];
    var treatmentType = area['treatmentType'];
    if (treatmentType && treatmentType.includes('SQIDEP')) {
        var index = treatmentType.indexOf('SQIDEP');
        treatmentType = treatmentType.substring(index + 'SQIDEP'.length).trim();
    }
    var areaType = area['areaType'];
    var treatmentSize = parseFloat(area.treatmentSize);
    var newTreatmentSizeValue = null;
    const areaSize = parseFloat(area.areaSize);
    const warnings = [];

    if (area['treatmentType'] !== 'None') {
        if (area.treatmentSize === '') {
            return {
                alert: 'Please specify the treatment size to continue',
            };
        }
        if (
            area.apiBaseRoute === 'getBioretentionResults' ||
            area.apiBaseRoute === 'PerviousBioretentionResults'
        ) {
            if (treatmentSize / areaSize > area.bounds?.maxRatio) {
                warnings.push(`⚠️ Treatment size greater than 
                    ${(areaSize * area.bounds?.maxRatio).toFixed(3).replace(/\.?0+$/, '')} 
                    m² will not result in significant additional treatment.`);
            }
        } else if (area.apiBaseRoute === 'getRainwatertankResults') {
            if (treatmentSize / areaSize < 0.1) {
                // update user value to the minimum size
                warnings.push(
                    `⚠️ Rainwater Tank size should be atleast 0.1L/m² of catchment. Your Rainwater Tank size has been updated to ${
                        0.1 * areaSize.toFixed(3)
                    }L  to reflect this.`,
                );
                newTreatmentSizeValue = 0.1 * areaSize.toFixed(3);
            }
            if (treatmentSize / areaSize > area.bounds?.maxRatio) {
                warnings.push(
                    `⚠️ A rainwater tank volume greater than ${(areaSize * area.bounds.maxRatio)
                        .toFixed(3)
                        .replace(
                            /\.?0+$/,
                            '',
                        )}L will not result in significant additional treatment.`,
                );
            }
        }
    } else {
        bassRoot = isPervious ? 'NoPerviousTreatmentResults' : 'getNoTreatmentResults';
        treatmentType = 'None';
    }

    let url_string;
    if (treatmentType === 'None') {
        url_string =
            bassRoot +
            '/' +
            state +
            '/' +
            cityCouncil +
            '/' +
            rainfallStation +
            '/' +
            catchmentType +
            '/' +
            areaType +
            '/' +
            area['areaSize'] +
            (isPervious ? '/' : `/${treatmentType}`);
    } else if (bassRoot === 'LinearReduction') {
        url_string =
            bassRoot +
            '/' +
            state +
            '/' +
            cityCouncil +
            '/' +
            rainfallStation +
            '/' +
            catchmentType +
            '/' +
            areaType +
            '/' +
            area['areaSize'] +
            '/' +
            treatmentType +
            '/' +
            treatmentSize +
            '/JC';
    } else if (
        bassRoot === 'getBioretentionResults' ||
        bassRoot === 'PerviousBioretentionResults'
    ) {
        url_string =
            bassRoot +
            '/' +
            state +
            '/' +
            cityCouncil +
            '/' +
            rainfallStation +
            '/' +
            catchmentType +
            '/' +
            areaType +
            '/' +
            area['areaSize'] +
            '/' +
            treatmentType +
            '/' +
            treatmentSize;
    } else if (bassRoot === 'getHydrochannelResults') {
        url_string =
            bassRoot +
            '/' +
            state +
            '/' +
            cityCouncil +
            '/' +
            rainfallStation +
            '/' +
            catchmentType +
            '/' +
            areaType +
            '/' +
            area['areaSize'] +
            '/' +
            treatmentType +
            '/' +
            treatmentSize +
            '/' +
            rainwaterDemand;
    } else {
        url_string =
            bassRoot +
            '/' +
            state +
            '/' +
            cityCouncil +
            '/' +
            rainfallStation +
            '/' +
            catchmentType +
            '/' +
            areaType +
            '/' +
            area['areaSize'] +
            '/' +
            treatmentType +
            '/' +
            treatmentSize +
            '/' +
            bedrooms +
            '/' +
            rainwaterDemand;
    }

    return await sails_api
        .get(url_string)
        .then(async (response) => {
            return {
                ...response.data,
                warnings,
                newTreatmentSizeValue,
            };
        })
        .catch(() => {
            return {
                alert: 'Error calculating results. Please check your inputs',
            };
        });
};
