// modules
import axios from "axios";
// utils
import { LAMBDA_API } from "./globalConstant";

export const fetchAllSideBarAdvertisement = async () => {
  let adsImageFromS3 = [];

  const formData = new FormData();
  const params = { bucket: "advertisement-images" };
  formData.append("params", JSON.stringify(params));
  formData.append("endpoint", "getAllFilesFromS3");

  await axios.post(`${LAMBDA_API}/s3`, formData).then((res) => {
    adsImageFromS3.push(res.data.results);
  });

  return adsImageFromS3;
};

export const fetchAllSidebarAdvertisementLinks = async () => {
  let adsLinkFromDB = [];

  const payload = {
    endpoint: "getAllConnectedSidebarAdvertisementLinks",
    params: {},
  };
  await axios.post(`${LAMBDA_API}/cms`, payload).then((res) => {
    adsLinkFromDB.push(res.data.results);
  });

  return adsLinkFromDB;
};
