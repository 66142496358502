import React from 'react';
import { StyleSheet, View, Text, Image, Page } from '@react-pdf/renderer';
import ReportHeader from '../ReportHeader';
import ReportFooter from '../ReportFooter';

const SourceFlowNodeTable = ({ extractedValues, companyIcon }) => {
    const styles = StyleSheet.create({
        title1: {
            fontSize: 14,
            textAlign: 'left',
            marginBottom: '2%',
        },
        content: {
            fontSize: 10,
            textAlign: 'left',
            marginBottom: '2%',
            lineHeight: 2,
        },
        row: {
            flexDirection: 'row',
            borderBottomWidth: 1,
            borderBottomColor: '#000',
            paddingTop: 4,
            paddingBottom: 4,
        },
        table: {
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#000',
        },
        tableRow: {
            flexDirection: 'row',
            borderBottomWidth: 1,
            borderBottomColor: '#000',
            padding: 4,
        },
        headerCell: {
            flex: 1,
            fontWeight: 'bold',
        },
        tableCell: {
            flex: 1,
        },
        body: {
            paddingTop: 30,
            paddingBottom: 10,
            paddingHorizontal: 35,
        },
    });
    const tableStyles = StyleSheet.create({
        section: {
            marginBottom: 30,
            marginTop: 30,
            padding: 10,
        },
        title: {
            fontSize: 14,
            textAlign: 'center',
            marginBottom: '3%',
        },
        // TABLE
        table: {
            padding: 15,
        },
        tableTitle: {
            width: '100%',
            textAlign: 'center',
            fontSize: 14,
            marginBottom: '8%',
        },
        tableRow: {
            flexDirection: 'row',
        },
        tableCol: {
            flex: 1,
            textAlign: 'center',
            fontSize: 9,
            border: 1,
            padding: 5,
        },
        tableColLong: {
            flex: 1,
            textAlign: 'center',
            fontSize: 9,
            border: 1,
            padding: 5,
            minWidth: 70,
        },
    });
    return (
        <Page style={styles.body}>
            <ReportHeader reportHeaderUrl={companyIcon} />
            <View style={tableStyles.section}>
                <Text style={tableStyles.tableTitle}>
                    Pollutant and Soil Characteristics for Source Nodes
                </Text>

                {extractedValues.map((item, index) => (
                    <React.Fragment key={index}>
                        <Text style={{ ...styles.title1, textAlign: 'center' }}>
                            Source Node: {item.name}
                        </Text>
                        <Text style={{ ...styles.title1, textAlign: 'center' }}>
                            Compliant with : {item.match_name}
                        </Text>
                        {Number(item.values.imperviousness) !== 100 ? (
                            <View style={tableStyles.table}>
                                <View style={tableStyles.tableRow}>
                                    <Text style={tableStyles.tableCol}>
                                        Soil Characteristics for {item.name}
                                    </Text>
                                </View>
                                <View style={tableStyles.tableRow}>
                                    <Text style={tableStyles.tableCol}>Soil Storage Capacity</Text>
                                    <Text style={tableStyles.tableCol}>
                                        {item.values.soilStorageCap}
                                    </Text>
                                    <Text style={tableStyles.tableCol}>
                                        Infiltration capacity coefficient a
                                    </Text>
                                    <Text style={tableStyles.tableCol}>
                                        {item.values.infiltCapCoeff}
                                    </Text>
                                    <Text style={tableStyles.tableCol}>
                                        Infiltration capacity exponent b
                                    </Text>
                                    <Text style={tableStyles.tableCol}>
                                        {item.values.infiltCapExpo}
                                    </Text>
                                </View>
                                <View style={tableStyles.tableRow}>
                                    <Text style={tableStyles.tableCol}>Field Capacity</Text>
                                    <Text style={tableStyles.tableCol}>{item.values.fieldCap}</Text>
                                    <Text style={tableStyles.tableCol}>
                                        Initial storage (% capacity)
                                    </Text>
                                    <Text style={tableStyles.tableCol}>
                                        {item.values.initialStorage}
                                    </Text>
                                    <Text style={tableStyles.tableCol}>Initial depth (mm)</Text>
                                    <Text style={tableStyles.tableCol}>
                                        {item.values.initialDepth}
                                    </Text>
                                </View>
                                <View style={tableStyles.tableRow}>
                                    <Text style={tableStyles.tableCol}>
                                        Daily base flow rate (%)
                                    </Text>
                                    <Text style={tableStyles.tableCol}>
                                        {item.values.dailyBaseFlowRate}
                                    </Text>
                                    <Text style={tableStyles.tableCol}>
                                        Daily deep seepage rate (%)
                                    </Text>
                                    <Text style={tableStyles.tableCol}>
                                        {item.values.dailyDeepSeepageRate}
                                    </Text>
                                    <Text style={tableStyles.tableCol}>
                                        Daily recharge rate (%)
                                    </Text>
                                    <Text style={tableStyles.tableCol}>
                                        {item.values.dailyRechargeRate}
                                    </Text>
                                </View>
                                <View style={tableStyles.tableRow}>
                                    <Text style={tableStyles.tableCol}>
                                        Rainfall Threshold (mm)
                                    </Text>
                                    <Text style={tableStyles.tableCol}>
                                        {item.values.rainfallThreshold}
                                    </Text>
                                </View>
                            </View>
                        ) : (
                            <View style={tableStyles.table}>
                                <View style={tableStyles.tableRow}>
                                    <Text style={tableStyles.tableCol}>
                                        This node is a 100 % impervious so baseflow and soil
                                        characteristics are irrelevant
                                    </Text>
                                </View>
                                <View style={tableStyles.tableRow}>
                                    <Text style={tableStyles.tableCol}>
                                        Rainfall Threshold (mm)
                                    </Text>
                                    <Text style={tableStyles.tableCol}>
                                        {item.values.rainfallThreshold}
                                    </Text>
                                </View>
                            </View>
                        )}
                        <View style={tableStyles.table}>
                            <View style={tableStyles.tableRow}>
                                <Text style={tableStyles.tableCol}>
                                    Pollutant Parameters for {item.name}
                                </Text>
                            </View>
                            <View style={tableStyles.tableRow}>
                                <Text style={tableStyles.tableCol}>Storm Flows</Text>
                                <Text style={tableStyles.tableCol}>log(mg/l)</Text>
                                <Text style={tableStyles.tableCol}>Generation Method</Text>
                                <Text style={tableStyles.tableCol}>Base Flows</Text>
                                <Text style={tableStyles.tableCol}>log(mg/l)</Text>
                                <Text style={tableStyles.tableCol}>Generation Method</Text>
                            </View>
                            {/* TSS Mean table */}
                            <View style={tableStyles.tableRow}>
                                <Text style={tableStyles.tableCol}>TSS Mean</Text>
                                <Text style={tableStyles.tableCol}>
                                    {item.values.stormFlowTssMean}
                                </Text>
                                <Text style={tableStyles.tableCol}>
                                    Correlation r² = {item.values.stormFlowTssSerialCorrelation}
                                </Text>
                                <Text style={tableStyles.tableCol}>TSS Mean</Text>
                                <Text style={tableStyles.tableCol}>
                                    {Number(item.values.imperviousness) === 100
                                        ? 'Irrelevant'
                                        : item.values.baseFlowTssMean}
                                </Text>
                                <Text style={tableStyles.tableCol}>
                                    Correlation r² ={' '}
                                    {Number(item.values.imperviousness) === 100
                                        ? 'N/A'
                                        : item.values.baseFlowTssSerialCorrelation}
                                </Text>
                            </View>
                            {/* TSS Standard Deviation table */}
                            <View style={tableStyles.tableRow}>
                                <Text style={tableStyles.tableCol}>TSS Standard Deviation</Text>
                                <Text style={tableStyles.tableCol}>
                                    {item.values.stormFlowTssStdDev}
                                </Text>
                                <Text style={tableStyles.tableCol}>
                                    {item.values.stormFlowTssMethod === '1' ? 'Stochastic' : 'Mean'}
                                </Text>
                                <Text style={tableStyles.tableCol}>TSS Standard Deviation</Text>
                                <Text style={tableStyles.tableCol}>
                                    {Number(item.values.imperviousness) === 100
                                        ? 'Irrelevant'
                                        : item.values.baseFlowTssStdDev}
                                </Text>
                                <Text style={tableStyles.tableCol}>
                                    {Number(item.values.imperviousness) === 100
                                        ? 'N/A'
                                        : item.values.baseFlowTssMethod === '1'
                                          ? 'Stochastic'
                                          : 'Mean'}
                                </Text>
                            </View>
                            {/* TP Mean table */}
                            <View style={tableStyles.tableRow}>
                                <Text style={tableStyles.tableCol}>TP Mean</Text>
                                <Text style={tableStyles.tableCol}>
                                    {item.values.stormFlowTpMean}
                                </Text>
                                <Text style={tableStyles.tableCol}>
                                    Correlation r² = {item.values.stormFlowTpSerialCorrelation}
                                </Text>
                                <Text style={tableStyles.tableCol}>TP Mean</Text>
                                <Text style={tableStyles.tableCol}>
                                    {Number(item.values.imperviousness) === 100
                                        ? 'Irrelevant'
                                        : item.values.baseFlowTpMean}
                                </Text>
                                <Text style={tableStyles.tableCol}>
                                    Correlation r² ={' '}
                                    {Number(item.values.imperviousness) === 100
                                        ? 'N/A'
                                        : item.values.baseFlowTpSerialCorrelation}
                                </Text>
                            </View>
                            {/* TP Standard Deviation table */}
                            <View style={tableStyles.tableRow}>
                                <Text style={tableStyles.tableCol}>TP Standard Deviation</Text>
                                <Text style={tableStyles.tableCol}>
                                    {item.values.stormFlowTpStdDev}
                                </Text>
                                <Text style={tableStyles.tableCol}>
                                    {item.values.stormFlowTpMethod === '1' ? 'Stochastic' : 'Mean'}
                                </Text>
                                <Text style={tableStyles.tableCol}>TP Standard Deviation</Text>
                                <Text style={tableStyles.tableCol}>
                                    {Number(item.values.imperviousness) === 100
                                        ? 'Irrelevant'
                                        : item.values.baseFlowTpStdDev}
                                </Text>
                                <Text style={tableStyles.tableCol}>
                                    {Number(item.values.imperviousness) === 100
                                        ? 'N/A'
                                        : item.values.baseFlowTpMethod === '1'
                                          ? 'Stochastic'
                                          : 'Mean'}
                                </Text>
                            </View>
                            {/* TN Mean table */}
                            <View style={tableStyles.tableRow}>
                                <Text style={tableStyles.tableCol}>TN Mean</Text>
                                <Text style={tableStyles.tableCol}>
                                    {item.values.stormFlowTnMean}
                                </Text>
                                <Text style={tableStyles.tableCol}>
                                    Correlation r² = {item.values.stormFlowTnSerialCorrelation}
                                </Text>
                                <Text style={tableStyles.tableCol}>TN Mean</Text>
                                <Text style={tableStyles.tableCol}>
                                    {Number(item.values.imperviousness) === 100
                                        ? 'Irrelevant'
                                        : item.values.baseFlowTnMean}
                                </Text>
                                <Text style={tableStyles.tableCol}>
                                    Correlation r² ={' '}
                                    {Number(item.values.imperviousness) === 100
                                        ? 'N/A'
                                        : item.values.baseFlowTnSerialCorrelation}
                                </Text>
                            </View>
                            {/* TN Standard Deviation table */}
                            <View style={tableStyles.tableRow}>
                                <Text style={tableStyles.tableCol}>TN Standard Deviation</Text>
                                <Text style={tableStyles.tableCol}>
                                    {item.values.stormFlowTnStdDev}
                                </Text>
                                <Text style={tableStyles.tableCol}>
                                    {item.values.stormFlowTnMethod === '1' ? 'Stochastic' : 'Mean'}
                                </Text>
                                <Text style={tableStyles.tableCol}>TN Standard Deviation</Text>
                                <Text style={tableStyles.tableCol}>
                                    {Number(item.values.imperviousness) === 100
                                        ? 'Irrelevant'
                                        : item.values.baseFlowTnStdDev}
                                </Text>
                                <Text style={tableStyles.tableCol}>
                                    {Number(item.values.imperviousness) === 100
                                        ? 'N/A'
                                        : item.values.baseFlowTnMethod === '1'
                                          ? 'Stochastic'
                                          : 'Mean'}
                                </Text>
                            </View>
                        </View>
                        <View break></View>
                    </React.Fragment>
                ))}
            </View>
            <ReportFooter />
        </Page>
    );
};

export default SourceFlowNodeTable;
