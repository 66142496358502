import { createSlice } from "@reduxjs/toolkit";

const defaultTemplateSlice = createSlice({
    name: "defaultTemplateInfo",
    initialState: {
        useOsd4wForm: false,
        osd4wTd: "",
        osd4wStorageIntensity: "",
        osd4wFlowIntensity: "",
        climateAdjustment: "",
        tankHeight: "",
        selectedTab: "",
        dataUploaded: false,
    },
    reducers: {
        setUseOsd4wForm: (state, action) => {
            state.useOsd4wForm = action.payload;
        },
        setOsd4wTd: (state, action) => {
            state.osd4wTd = action.payload;
        },
        setOsd4wStorageIntensity: (state, action) => {
            state.osd4wStorageIntensity = action.payload;
        },
        setOsd4wFlowIntensity: (state, action) => {
            state.osd4wFlowIntensity = action.payload;
        },
        setClimateAdjustment: (state, action) => {
            state.climateAdjustment = action.payload;
        },
        setTankHeight: (state, action) => {
            state.tankHeight = action.payload;
        },
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload;
        },
        setDataUploaded: (state, action) => {
            state.dataUploaded = action;
        },
        setDefaultTemplate: (_, action) => {
            return action.payload;
        },
    },
});

export const {
    setUseOsd4wForm,
    setOsd4wTd,
    setOsd4wStorageIntensity,
    setOsd4wFlowIntensity,
    setClimateAdjustment,
    setTankHeight,
    setDefaultTemplate,
    setSelectedTab,
    setDataUploaded,
} = defaultTemplateSlice.actions;
export const defaultTemplate = (state) => state.defaultTemplateInfo;
export default defaultTemplateSlice.reducer;
