import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    areaName: '',
    areaType: '',
    areaSize: '',
    treatmentType: 'None',
    treatmentSize: '',
    numberOfOccupants: '',
    result: null,
    resultUpdateRequired: false,
    apiBaseRoute: '',
    bounds: null,
};
const areaSlice = createSlice({
    name: 'area',
    initialState: {
        pervious: [],
        impervious: [{ ...initialState }],
    },
    reducers: {
        updateArea: (state, action) => {
            const { type, index, updatedRow } = action.payload;
            if (type === 'pervious') {
                state.pervious[index] = updatedRow;
            } else {
                state.impervious[index] = updatedRow;
            }
        },

        updateAllArea: (state, action) => {
            const { type, newArea } = action.payload;
            if (type === 'pervious') {
                state.pervious = newArea;
            } else {
                state.impervious = newArea;
            }
        },

        setUpdateAllAreaResults: (state, action) => {
            state.impervious = state.impervious.map((area) => {
                return { ...area, resultUpdateRequired: action.payload };
            });
            state.pervious = state.pervious.map((area) => {
                return { ...area, resultUpdateRequired: action.payload };
            });
        },

        addNewArea: (state, action) => {
            const type = action.payload;
            if (type === 'pervious') {
                state.pervious = [...state.pervious, { ...initialState }];
            } else {
                state.impervious = [...state.impervious, { ...initialState }];
            }
        },
        setAreaResults: (state, action) => {
            const { type, index, results } = action.payload;
            if (type === 'pervious') {
                state.pervious[index].resultUpdateRequired = false;
                state.pervious[index].result = results;
            } else {
                state.impervious[index].resultUpdateRequired = false;
                state.impervious[index].result = results;
            }
        },
        removeArea: (state, action) => {
            const { type, index } = action.payload;
            if (type === 'pervious') {
                state.pervious = state.pervious
                    .slice(0, index)
                    .concat(state.pervious.slice(index + 1));
            } else {
                state.impervious = state.impervious
                    .slice(0, index)
                    .concat(state.impervious.slice(index + 1));
            }
        },
        setArea: (_, action) => {
            return action.payload;
        },
    },
});

export const {
    updateArea,
    updateAllArea,
    addNewArea,
    setAreaResults,
    setIsOpen,
    removeArea,
    setArea,
    setUpdateAllAreaResults,
} = areaSlice.actions;
export const selectAreas = (state) => state.area;
export default areaSlice.reducer;
