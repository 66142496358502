import React from "react";
import { StyleSheet, View, Text } from "@react-pdf/renderer";

export default function OverallReductionsAndResults({
  overallResults,
  targetReductions,
}) {
  const styles = StyleSheet.create({
    container: {
      width: "100%",
      height: "70%",
      marginBottom: 10,
      paddingRight: 10,
    },
    text1: {
      textAlign: "center",
      fontSize: 15,
      fontWeight: 700,
      margin: 10,
    },
    text2: {
      textAlign: "center",
      fontSize: 12,
      fontWeight: 700,
      margin: 10,
    },
    table: {
      display: "table",
      width: "100%",
    },
    tableRow: {
      width: "100%",
      flexDirection: "row",
      textAlign: "center",
    },
    tableCell: {
      height: "100%",
      width: "100%",
      padding: 5,
      border: "1px solid #658cbb",
      fontSize: 10,
    },
  });

  const OverallReductionsTableHeaders = [
    "TN Reduction",
    "TP Reduction",
    "TSS Reduction",
    "GP Reduction",
    "Flow Reduction",
  ];
  const achievedReductionColumns = [
    "tnReduction",
    "tpReduction",
    "tssReduction",
    "gpReduction",
    "flowReduction",
  ];
  const targetReductionColumns = ["tn", "tp", "tss", "gp"];

  const existingSiteColumns = [
    "tnPollutantPreDev",
    "tpPollutantPreDev",
    "tssPollutantPreDev",
    "gpPollutantPreDev",
    "flowPollutantPreDev",
  ];
  const developedSiteColumns = [
    "tnPollutantPostDev",
    "tpPollutantPostDev",
    "tssPollutantPostDev",
    "gpPollutantPostDev",
    "flowPollutantPostDev",
  ];

  return (
    <View style={styles.container}>
      {/* Overall Reductions */}
      <Text style={styles.text1}>Overall Reductions</Text>
      <View style={styles.tableRow}>
        {OverallReductionsTableHeaders.map((header) => (
          <Text key={header} style={styles.tableCell}>
            {header}
          </Text>
        ))}
      </View>
      <View style={styles.tableRow}>
        <Text style={styles.tableCell}>
          Achieved Reductions (% from existing)
        </Text>
        {achievedReductionColumns.map((columnName) => {
          return (
            <Text key={columnName} style={styles.tableCell}>
              {overallResults.overallReductions[columnName]}
            </Text>
          );
        })}
      </View>
      <View style={styles.tableRow}>
        <Text style={styles.tableCell}>
          Target Reductions (% from existing)
        </Text>
        {targetReductionColumns.map((columnName) => {
          return (
            <Text key={columnName} style={styles.tableCell}>
              {targetReductions[columnName]}
            </Text>
          );
        })}
        <Text style={styles.tableCell}>N/A</Text>
      </View>
      {/* Overall Results */}
      <Text style={styles.text1}> Overall Results</Text>
      <View style={styles.tableRow}>
        {OverallReductionsTableHeaders.map((header) => (
          <Text key={header} style={styles.tableCell}>
            {header}
          </Text>
        ))}
      </View>
      <View style={styles.tableRow}>
        <Text style={styles.tableCell}>Existing Site</Text>
        {existingSiteColumns.map((columnName) => {
          return (
            <Text key={columnName} style={styles.tableCell}>
              {overallResults.overallPollutantsPreDev[columnName]}
            </Text>
          );
        })}
      </View>
      <View style={styles.tableRow}>
        <Text style={styles.tableCell}>Developed Site</Text>
        {developedSiteColumns.map((columnName) => {
          return (
            <Text key={columnName} style={styles.tableCell}>
              {overallResults.overallPollutantsPostDev[columnName]}
            </Text>
          );
        })}
      </View>
    </View>
  );
}
