import { Text } from '@react-pdf/renderer';
import Table from './Table';
import { createStyles, defaultStyles } from './common';

const round = (num) => {
    return check(
        parseFloat(num)
            .toFixed(2)
            .replace(/\.?0+$/, ''),
    );
};

const check = (num) => {
    if (num === undefined || num === null || isNaN(num) | (num === '')) return 'N/A';
    return num;
};

const formatObjectName = (name) => {
    if (name.toLowerCase().includes('area')) return name + ' (m²)';
    if (name.toLowerCase().includes('volume')) return name + ' (m³)';
    // if (name.toLowerCase().includes('psd')) return name + ' *';
    return name;
};

const getClimatePercentage = (num) => {
    if (num === 1 || round(num) === 'N/A') {
        return 'No climate change adjustment';
    } else if (num < 1) {
        return '-' + round((1 - num) * 100) + '%';
    } else {
        return '+' + round((num - 1) * 100) + '%';
    }
};

const getAdjustedStorageVolume = (num, adjustment) => {
    console.log(num, adjustment);
    return round(num * adjustment);
};

export const catchmentDetailsTable = (
    site_calcs,
    runoff_coefficient,
    { isPsdProvided = false },
) => {
    if (isPsdProvided)
        return {
            columns: ['', 'Pre-Development', 'Post-Development'],
            items: site_calcs.map((obj) => {
                if (obj.double_cols || obj.triple_cols) {
                    return [obj.name, round(obj.value)];
                }

                return [formatObjectName(obj.name), 'N/A', round(obj.post_dev)];
            }),
            cellWidths: ['44%', '28%', '28%'],
            customCellWidths: site_calcs.reduce((result, obj, index) => {
                if (obj.double_cols || obj.triple_cols) {
                    result[index] = ['44%', '56%'];
                }
                return result;
            }, {}),
            styles: createStyles(),
        };

    return {
        columns: [
            '',
            'Pre-Development',
            'Post-Development Controlled',
            'Post-Development Uncontrolled',
        ],

        items: [...site_calcs].flatMap((obj, index) => {
            if (obj.name) {
                if (obj.double_cols || obj.triple_cols) {
                    return [[formatObjectName(obj.name), round(obj.value)]];
                }

                /* TC */
                if (obj.name.toLowerCase().includes('tc')) {
                    site_calcs.splice(index, 0, {
                        adoptedTCrow: true,
                    });

                    return [
                        [
                            formatObjectName(obj.name),
                            round(+obj.pre_dev),
                            obj.post_dev ? round(+obj.post_dev) : round(obj.tc_post_adopted),
                            round(+obj.uncontrolled),
                        ],
                        ['Adoped Tc Post', 'N/A', round(obj.tc_post_adopted)],
                    ];
                }

                if (obj.name === 'C10') {
                    return [
                        [
                            formatObjectName(obj.name),
                            round(obj.pre_dev),
                            round(obj.post_dev),
                            round(obj.uncontrolled),
                        ],
                        [
                            'Runoff Coefficient (Cy)',
                            round(runoff_coefficient.pre_dev),
                            round(runoff_coefficient.post_dev.controlled),
                            round(runoff_coefficient.post_dev.uncontrolled) || 'N/A',
                        ],
                    ];
                }

                return [
                    [
                        formatObjectName(obj.name),
                        round(obj.pre_dev),
                        round(obj.post_dev),
                        round(obj.uncontrolled),
                    ],
                ];
            } else return [[]];
        }),

        cellWidths: ['40%', '20%', '20%', '20%'],
        customCellWidths: [...site_calcs].reduce((result, obj, index) => {
            if (obj.double_cols || obj.triple_cols) {
                result[index] = ['40%', '60%'];
            }
            if (obj.adoptedTCrow) {
                site_calcs.splice(index, 1);
                result[index + 1] = ['40%', '20%', '40%'];
            }
            return result;
        }, {}),
        styles: createStyles(),
    };
};

export const stormDetailsTable = (storm_details) => {
    return {
        columns: ['Storm Details'],
        items: storm_details.map((obj) => [obj.name, round(obj.value)]),
        styles: createStyles({
            ...defaultStyles,
            headerBorder: false,
            headerFontSize: 13,
            headerTextAlign: 'left',
            tableWidth: '50%',
        }),
        cellWidths: ['70%', '30%'],
    };
};

/**
 * @param {string} title - Table title
 * @param {object[]} cValue - c-value post dev results
 */
export const cValueTable = (
    title,
    cValue,
    intermediateResults,
    { isPsdProvided, isPreDev, isControlled, showAppendix },
) => {
    if (isPsdProvided) return {};

    const { total_area, C_10, I_10 } = intermediateResults;
    const hasUncontrolledArea = total_area?.total_uncontrolled_area > 0;

    if (!isPreDev && !isControlled && !hasUncontrolledArea) return {};

    const tableHeader = [
        'Catchment Name',
        isControlled ? 'Area, m2 (controlled)' : null,
        !isPreDev && !isControlled ? 'Area, m2 (uncontrolled)' : null,
        'Total Impervious Area, m2 (b)',
        'Fraction Impervious (b)',
        'C10 (b)',
        'C-Value (a)',
        isControlled ? 'Weighted C-Value (controlled) (e)' : null,
        !isPreDev && !isControlled ? 'Weighted C-Value (uncontrolled)' : null,
    ].filter((value) => value !== null);

    const tableRows = [...cValue].flatMap((obj) => {
        // Each row
        const cyIsProvided = obj.fractionImpervious === null;
        let cySuffix = cyIsProvided ? '(d)' : '(c)';

        let totalImperviousArea = 'N/A';

        if (obj.fractionImpervious !== null) {
            const area = isControlled ? obj.areaControlled : obj.areaUncontrolled;
            totalImperviousArea = obj.fractionImpervious ? area : 0;
        }

        return [
            [
                formatObjectName(obj.catchmentName),
                isControlled ? round(obj.areaControlled) : null,
                !isPreDev && !isControlled
                    ? typeof obj.areaUncontrolled === 'number'
                        ? round(obj.areaUncontrolled)
                        : 'N/A'
                    : null,
                totalImperviousArea,
                obj.fractionImpervious !== null ? round(obj.fractionImpervious) : 'N/A',
                obj.c10 ? round(obj.c10) : 'N/A',
                obj.cy ? `${round(obj.cy)} ${cySuffix}` : 'N/A',
                isControlled ? (obj.cyWeighted ? round(obj.cyWeighted) : 'N/A') : null,
                !isPreDev && !isControlled
                    ? typeof obj.cyWeightedUncontrolled === 'number'
                        ? round(obj.cyWeightedUncontrolled)
                        : 'N/A'
                    : null,
            ].filter((value) => value !== null),
        ];
    });

    const calculateTotalCyWeighted = (cyWeighted) =>
        cValue.reduce((acc, area) => (cyWeighted ? acc + area[cyWeighted] : acc), 0);

    let totalCyWeighted;

    if (isPreDev) totalCyWeighted = calculateTotalCyWeighted('cyWeighted');
    else if (!isPreDev && isControlled)
        totalCyWeighted = calculateTotalCyWeighted('cyWeightedControlled');
    else if (!isPreDev && !isControlled)
        totalCyWeighted = calculateTotalCyWeighted('cyWeightedUncontrolled');

    const tableFooter = [
        '',
        isControlled ? '' : null,
        !isPreDev && !isControlled ? '' : null,
        '',
        '',
        '',
        isControlled
            ? 'Total Weighted C-Value (controlled) (f)'
            : 'Total Weighted C-Value (uncontrolled)',
        round(totalCyWeighted),
    ].filter((value) => value !== null);

    const cellWidths = ['30%', '15%', '15%', '10%', '10%', '10%', '10%'];

    return {
        columns: [title],
        items: [tableHeader, ...tableRows, tableFooter],
        cellWidths,
        styles: createStyles({
            ...defaultStyles,
            headerBorder: false,
            headerFontSize: 12,
            headerTextAlign: 'left',
        }),
        appendix:
            showAppendix || (!isPreDev && isControlled && !hasUncontrolledArea) ? (
                <>
                    <Text style={{ fontSize: 10, marginTop: 10 }}>Notes:</Text>
                    <Text style={{ fontSize: 10, marginTop: 5 }}>
                        (a) C-Value refers to a coefficient of discharge (also known as Cy,
                        calculated using QUDM & AR&R method) or volumetric runoff coefficient (also
                        known as Cv, typically estimated by Engineer or referenced by Guidelines
                        like IDM Victoria). They are functionally similar within the rational method
                        equation (difference is the methodology used to determine these values.). It
                        is recommended that the same methodology is used for all catchments to
                        ensure consistency.
                    </Text>
                    <Text style={{ fontSize: 10, marginTop: 5 }}>
                        (b) Only used in calculation of Cy values (see QUDM page)
                    </Text>
                    <Text style={{ fontSize: 10, marginTop: 5 }}>
                        (c) A coefficient of discharge (Cy) calculated using QUDM & AR&R method (see
                        QUDM page)
                    </Text>
                    <Text style={{ fontSize: 10, marginTop: 5 }}>
                        (d) C-Value specified by engineer.
                    </Text>
                    <Text style={{ fontSize: 10, marginTop: 5 }}>
                        (e) This is a weighted average that represents the catchment's contribution
                        to the total C-Value of the development
                    </Text>
                    <Text style={{ fontSize: 10, marginTop: 5 }}>
                        (f) This is the total C-Value used to represent the development in the
                        rational method calculation.
                    </Text>
                    <Text style={{ fontSize: 10, marginTop: 5 }}>
                        Where applicable, table below shows values required for calculation of Cy
                        values.
                    </Text>
                    <Table
                        columnNames={['Parameter', 'Value']}
                        tableData={[
                            ['Intensity @ 60 min duration for 10% AEP, I10', round(C_10.c_10)],
                            ['Pervious 10% AEP runoff coefficient, C10', round(I_10)],
                        ]}
                        tableStyles={createStyles({
                            ...defaultStyles,
                            tableWidth: '50%',
                        })}
                        cellWidths={['70%', '30%']}
                    />
                </>
            ) : undefined,
    };
};

export const flowAndVolumeTable = (flow_and_vol, max_volume, climateAdjustment) => {
    return {
        columns: ['Flow and Volume'],
        items: flow_and_vol
            .map((obj) => [formatObjectName(obj.name), round(obj.value)])
            .concat([
                climateAdjustment !== '1'
                    ? [
                          'Climate Change (% increase of required storage volume):',
                          getClimatePercentage(parseFloat(climateAdjustment)),
                      ]
                    : [],
                climateAdjustment !== '1'
                    ? [
                          'Adjusted Required Storage Volume (m³):',
                          getAdjustedStorageVolume(max_volume, climateAdjustment),
                      ]
                    : [],
            ]),
        styles: createStyles({
            ...defaultStyles,
            headerBorder: false,
            headerFontSize: 13,
            headerTextAlign: 'left',
            tableWidth: '70%',
        }),
        cellWidths: ['70%', '30%'],
    };
};

export const tankHeightTable = (orifice) => {
    if (!orifice.tankHeight) return {};

    return {
        columns: ['Height of Storage Above Orifice (m)', round(orifice.tankHeight)],
        items: [['Orifice Diameter (mm)', round(orifice.orificeDiameter)]],
        cellWidths: ['70%', '30%'],
        styles: createStyles({
            ...defaultStyles,
            textAlignment: 'left',
            headerTextAlign: 'left',
            tableWidth: '45%',
        }),
        title: 'Orifice Calculation',
    };
};
