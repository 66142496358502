import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

export default function ReleaseNoteModal({ modalHeader, modalContent }) {
    const [openModal, setOpenModal] = useState(true);
    return (
        <Modal show={openModal} centered>
            <Modal.Header>
                <b>{modalHeader}</b>
                <Button
                    variant="info"
                    onClick={() => setOpenModal(false)}
                    style={{ marginLeft: '1vw' }}
                >
                    X
                </Button>
            </Modal.Header>
            <Modal.Body>{modalContent}</Modal.Body>
        </Modal>
    );
}
