import React from 'react';

import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import Table from '../Table';
import { createStyles, defaultStyles } from '../common';

function addCommasToNumber(number) {
    // Convert the number to a string
    let numberString = number.toString();

    // Use a regular expression to add commas every three digits from the right
    numberString = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return numberString;
}

const SuLiteReportTemplate = ({
    userInfo,
    projectInfo,
    mapInfo,
    imperviousNodes,
    eolTreatment,
    eolTreatmentSize,
    overallResults,
    siteArea,
    calculateTnRate,
    rainwaterDemand,
}) => {
    const generateAllTableData = () => {
        const today = new Date();
        const date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();

        return [
            {
                columns: ['Date', date],
                items: [
                    ['Municipality:', mapInfo.council],
                    ['Rainfall Station:', mapInfo.rainfallStation],
                    ['Address:', `${mapInfo.address} ${mapInfo.suburb} ${mapInfo.postcode}`],
                    ['Assessor Name:', userInfo.assessorName],
                    ['Assessor Email:', userInfo.assessorEmail],
                    ['Development Type:', projectInfo.developmentType],
                    ['Allotment Size (m²):', addCommasToNumber(siteArea)],
                    ['STORM Rating (%):', calculateTnRate],
                    ['Planning Permit Number:', projectInfo.planningPermitNo],
                ],
                styles: createStyles({
                    ...defaultStyles,
                    borderStyle: 'none',
                    textAlignment: 'left',
                    headerTextAlign: 'left',
                    marginBottom: '5vh',
                }),
                cellWidths: ['50%', '50%'],
            },

            {
                columns: [
                    'Description',
                    'Impervious Area Type',
                    'Impervious Area (m²)',
                    'Treatment Type',
                    'Treatment Area (m²) / Volume (L)',
                    'Occupants / No. of Bedrooms',
                    'TN STORM Rating',
                ],
                items:
                    eolTreatment !== ''
                        ? imperviousNodes
                              .map((node) =>
                                  imperviousColumnNames.map((columnName) =>
                                      node[columnName] === '' ? 'N/A' : node[columnName],
                                  ),
                              )
                              .concat([
                                  [
                                      'End-of-Line Device',
                                      'N/A',
                                      'N/A',
                                      eolTreatment,
                                      eolTreatmentSize,
                                      'N/A',
                                      overallResults.eolResults.tn,
                                  ],
                              ])
                        : imperviousNodes.map((node) =>
                              imperviousColumnNames.map((columnName) =>
                                  node[columnName] === '' ? 'N/A' : node[columnName],
                              ),
                          ),
                styles: createStyles({
                    ...defaultStyles,
                    titleFontSize: 10,
                }),
                cellWidths: ['12%', '13%', '13%', '17%', '15%', '15%', '15%'],
                title: `Demand: ${rainwaterDemand}, ${rainwaterDemand === 'Toilet' ? 20 : 62}L/Occupant/Day`,
            },
        ];
    };
    const styles = StyleSheet.create({
        body: {
            paddingHorizontal: '7vw',
            paddingVertical: '5vh',
            fontSize: 10,
        },
        title: {
            fontSize: 20,
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: '5vh',
        },
    });

    const imperviousColumnNames = [
        'impAreaName',
        'impAreaType',
        'impAreaSize',
        'impAreaTreatType',
        'impAreaTreatSize',
        'impAreaBrooms',
        'tn',
    ];

    return (
        <Page style={styles.body} size="A4">
            <Text style={styles.title}>STORMupdated Rating Report</Text>
            <View style={{ marginBottom: 50 }}>
                {generateAllTableData().map((table, index) => (
                    <Table
                        key={'table' + index}
                        columnNames={table.columns}
                        tableData={table.items}
                        headerWidths={table.headerWidths}
                        cellWidths={table.cellWidths}
                        tableStyles={table.styles}
                        title={table.title}
                    />
                ))}
            </View>
        </Page>
    );
};

export default SuLiteReportTemplate;
