import React from 'react';
import { StyleSheet, Text, View, Image, Page } from '@react-pdf/renderer';
// Components
import ReportFooter from './ReportFooter';
import ReportHeader from './ReportHeader';

/**
 * This template is used to present the basic information including user and the porject
 * @param {Object} address
 * @property {string} suburb - The suburb of the address.
 * @property {string} state - The state of the address.
 * @property {string} postcode - The postcode of the address.
 * @property {string} council - The council of the address.
 * @property {string} address - The detailed address information.
 *
 * @param {Object} basicInfo
 * @property {string} developmentType
 * @property {string} permit
 * @property {string} projectName
 * @property {string} assessor
 * @property {string} email
 *
 * @param {string} reportHeaderUrl
 *
 * @returns
 */

const mapZoomRatio = 17;

export default function BasicInformation({ address, basicInfo, reportHeaderUrl }) {
    const styles = StyleSheet.create({
        page: {
            paddingTop: 30,
            paddingBottom: 10,
            paddingHorizontal: 35,
        },
        title1: {
            fontSize: 14,
            textAlign: 'left',
            marginBottom: '3%',
        },
        content: {
            fontSize: 9,
            textAlign: 'left',
            marginBottom: '1%',
        },
        date: {
            fontSize: 10,
            textAlign: 'right',
        },
        coordinate: {
            width: '100%',
            textAlign: 'center',
            fontSize: 10,
        },
        bold: {
            fontWeight: 700,
        },
        table3: {
            borderTop: 1,
            borderColor: '#D3D3D3',
            padding: 10,
            marginBottom: 10,
        },
        tableRow: {
            flexDirection: 'row',
            marginBottom: '3%',
        },
        tableLabel: {
            flex: 1,
            fontSize: 9,
            textAlign: 'left',
        },
        tableValue: {
            flex: 3,
            fontSize: 9,
            textAlign: 'left',
        },
    });

    const today = new Date();
    const date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
    const addressLine2 = address['suburb'] + ', ' + address['state'] + ' ' + address['postcode'];

    return (
        <Page style={styles.page} size="A4" wrap={false}>
            {/* Header */}
            <View>
                <ReportHeader reportHeaderUrl={reportHeaderUrl} />
            </View>
            {/* Content */}
            <View>
                {/* BASIC INFORMATION */}
                <Text style={styles.title1}>Basic Information:</Text>
                <Text style={styles.date}>Date: {date}</Text>
                <View style={styles.table3}>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableLabel}>Assessor Name:</Text>
                        <Text style={styles.tableValue}>{basicInfo['assessor']}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableLabel}>Assessor Email:</Text>
                        <Text style={styles.tableValue}>{basicInfo['email']}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableLabel}>City Council:</Text>
                        <Text style={styles.tableValue}>{address['council']}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableLabel}>Address Line 1:</Text>
                        <Text style={styles.tableValue}>{address['address']}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableLabel}>Address Line 2:</Text>
                        <Text style={styles.tableValue}>{addressLine2}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableLabel}>Development Type:</Text>
                        <Text style={styles.tableValue}>{basicInfo['developmentType']}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableLabel}>Planning Permit No:</Text>
                        <Text style={styles.tableValue}>{basicInfo['permit']}</Text>
                    </View>
                </View>
                {/* MODEL DETAILS */}
                <Text style={styles.title1}>Site Details:</Text>
                <View style={styles.table3}>
                    <View style={styles.tableRow}>
                        <Text style={styles.coordinate}>
                            <Text style={styles.bold}> LAT:</Text> {address.coordinate['lat']},{' '}
                            <Text style={styles.bold}>LNG: </Text>
                            {address.coordinate['lng']}
                        </Text>
                    </View>
                    <View>
                        <Image
                            height={'100%'}
                            width={'100%'}
                            src={`https://maps.googleapis.com/maps/api/staticmap?center=${address.coordinate.lat},${address.coordinate.lng}&zoom=${mapZoomRatio}&size=800x400&maptype=satellite&markers=color:red%7C${address.coordinate.lat},${address.coordinate.lng}&key=AIzaSyCEkdpqBmFDnCOqHTJ_rZXaxeTNHjGIGPs`}
                        />
                    </View>
                </View>
            </View>
            {/* Footer */}
            <ReportFooter />
        </Page>
    );
}
