// this method is used to deal with the situation where
// if hasPsd === true and councilPSD is larger than Qa_dash

import { getTreatmentTypes } from './api/lambda';
import { getImageUrlFromS3 } from './api/s3';

//     onSiteDetentionVolume === 0
export const assessOnsiteDetentionVolume = (resultData, councilPSD) => {
    let bufResult = resultData;
    // above_ground_storage
    if (parseFloat(councilPSD) > resultData.above_ground_storage.Qa_dash) {
        const buf = {
            ...bufResult.above_ground_storage,
            onSiteDetentionVolume: 0,
        };
        bufResult = { ...bufResult, above_ground_storage: buf };
    }
    // below_ground_pipe
    if (parseFloat(councilPSD) > resultData.below_ground_pipe.Qa_dash) {
        const buf = {
            ...bufResult.below_ground_pipe,
            onSiteDetentionVolume: 0,
        };
        bufResult = { ...bufResult, below_ground_pipe: buf };
    }
    // below_ground_tank
    if (parseFloat(councilPSD) > resultData.below_ground_tank.Qa_dash) {
        const buf = {
            ...bufResult.below_ground_tank,
            onSiteDetentionVolume: 0,
        };
        bufResult = { ...bufResult, below_ground_tank: buf };
    }

    return bufResult;
};

const unNeededTreatmentNodesForBeta = [
    'InSitu Concrete OSD',
    'SPELFilter Vault short',
    'SPELFilter Vault tall',
    'CDS',
    'Vortex Separators',
    'Pit Inserts',
    'Hydrodynamic Separators',
    'Proprietary Filter Cartridges',
    'Low Headloss Filter Devices',
    'Vortceptor',
    'Ecosol Net Tech',
    'Ecosol Trash Rack',
    'FDHC',
    'SediPipe L',
    'HydroSTON Permeable Pavement',
];

// fetch the company details when the button was clicked
export const getCompanyDetails = async (storage_type, rainfallStation) => {
    try {
        const allTreatmentNodes = await getTreatmentTypes(storage_type, rainfallStation).then(
            (response) => {
                return response.data.osd_devices;
            },
        );
        const filteredNodes = allTreatmentNodes.filter(
            (node) => !unNeededTreatmentNodesForBeta.includes(node.osd_device_name),
        );

        const nodes = await Promise.all(
            filteredNodes.map(async (node) => {
                return {
                    ...node,
                    url: await getImageUrlFromS3(
                        'jco-node-images',
                        `treatment-thumbnail/${node.osd_device_name.toLowerCase()}.png`,
                    ),
                };
            }),
        );
        return nodes;
    } catch (err) {
        console.error('Opps! Something wrong, please try again later!');
    }
};
