import React, { useState } from 'react';
import { Button, Modal, Tabs, Tab, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
// util
import {
    setUseReportNote,
    setProjectDescriptionSelection,
    setProjectType,
    setAddress,
    setCouncil,
    setRequirements,
    setGuidelines,
    setNewTemplateData,
    setProjectDescriptionUploadedImage,
    addReportNoteAppendixItem,
    updateReportNoteAppendixItem,
    removeReportNoteAppendixItem,
    selectReportNoteSection,
} from '../../utils/redux/reportNoteSectionSlice';
// components
import ProjectDescription from './PorjectDescription';
import AppendixItem from './AppendixItem';

export default function ReportNoteSection() {
    const dispatch = useDispatch();
    const {
        useReportNote,
        projectDescriptionSelection,
        projectType,
        address,
        council,
        requirements,
        guidelines,
        newTemplateData,
        projectDescriptionUploadedImage,
        reportNoteAppendixItems,
    } = useSelector(selectReportNoteSection);

    const [show, setShow] = useState(false);
    const [currentPage, setCurrentPage] = useState();
    const [confirmSaveProjDesc, setConfirmSaveProjDesc] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handlePageChange = (page) => {
        setCurrentPage(+page);
    };

    return (
        <div className="py-3">
            <Form.Check
                type="checkbox"
                id="custom-checkbox"
                label="Use Report Note"
                checked={useReportNote}
                onChange={(e) => dispatch(setUseReportNote(e.target.checked))}
            />
            <Button variant="warning" onClick={handleShow}>
                Add Notes
            </Button>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header className="d-flex gap-2">
                    <Button
                        className="mb-2"
                        variant="info"
                        onClick={() => {
                            dispatch(addReportNoteAppendixItem());
                        }}
                        disabled={!confirmSaveProjDesc}
                    >
                        Add Page
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => {
                            dispatch(removeReportNoteAppendixItem(currentPage));
                            setCurrentPage(0);
                        }}
                        disabled={reportNoteAppendixItems.length === 0 || currentPage === 0}
                    >
                        Remove Current
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Tabs activeKey={currentPage} onSelect={handlePageChange}>
                        <Tab key={0} eventKey={0} title={`Project Description`} className="py-2">
                            <ProjectDescription
                                projectDescriptionSelection={projectDescriptionSelection}
                                setProjectDescriptionSelection={setProjectDescriptionSelection}
                                projectType={projectType}
                                setProjectType={setProjectType}
                                address={address}
                                setAddress={setAddress}
                                council={council}
                                setCouncil={setCouncil}
                                requirements={requirements}
                                setRequirements={setRequirements}
                                guidelines={guidelines}
                                setGuidelines={setGuidelines}
                                newTemplateData={newTemplateData}
                                setNewTemplateData={setNewTemplateData}
                                projectDescriptionUploadedImage={projectDescriptionUploadedImage}
                                setProjectDescriptionUploadedImage={
                                    setProjectDescriptionUploadedImage
                                }
                                setConfirmSaveProjDesc={setConfirmSaveProjDesc}
                            />
                        </Tab>
                        {reportNoteAppendixItems.map((item) => (
                            <Tab
                                key={item.id}
                                eventKey={item.id}
                                title={`Appendix ${item.id}`}
                                disabled={!confirmSaveProjDesc}
                            >
                                <AppendixItem
                                    item={item}
                                    updateReportNoteAppendixItem={updateReportNoteAppendixItem}
                                />
                            </Tab>
                        ))}
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleClose}>
                        Done
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
