// SummarySection.jsx

import React from "react";
import { useSelector } from "react-redux";
import { selectAreas } from "../../../../utils/redux/WSUD/areaSlice";
import styles from "./SummarySection.module.css";
import { calculateTotalArea, countTreatmentNodes } from "../../../../utils/wsudAreaUtils";


const SummarySection = () => {
    const areas = useSelector(selectAreas);

    // Calculate the summary values based on the areas in Redux store
    const perviousArea = calculateTotalArea(areas["pervious"]);
    const imperviousArea = calculateTotalArea(areas["impervious"]);
    const numOfCatchments =
        areas["impervious"].length + areas["pervious"].length;
    const numOfPerviousAreas = areas["pervious"].length;
    const numOfImperviousAreas = areas["impervious"].length;
    const numOfTreatmentNodes =
        countTreatmentNodes(areas["pervious"]) +
        countTreatmentNodes(areas["impervious"]); // Assuming each catchment is a treatment node

    return (
        <div className={styles["summary-component"]}>
            <div className={styles["summary-item"]}>
                <div className={styles["summary-label"]}>Total Site Area: </div>
                <div className={styles["summary-value"]}>
                    {perviousArea + imperviousArea} m²
                </div>
            </div>

            <div className={styles["summary-item"]}>
                <div className={styles["summary-label"]}>
                    Total Catchment Area:
                </div>
                <div className={styles["summary-value"]}>
                    {perviousArea + imperviousArea} m²
                </div>
            </div>

            <div className={styles["summary-item"]}>
                <div className={styles["summary-label"]}>Impervious Area: </div>
                <div className={styles["summary-value"]}>
                    {imperviousArea} m²
                </div>
            </div>

            <div className={styles["summary-item"]}>
                <div className={styles["summary-label"]}>Pervious Area: </div>
                <div className={styles["summary-value"]}>{perviousArea} m²</div>
            </div>

            <div className={styles["summary-item"]}>
                <div className={styles["summary-label"]}>
                    No. of Catchments:
                </div>
                <div className={styles["summary-value"]}>{numOfCatchments}</div>
            </div>

            <div className={styles["summary-item"]}>
                <div className={styles["summary-label"]}>
                    No. of Pervious Areas:
                </div>
                <div className={styles["summary-value"]}>
                    {numOfPerviousAreas}
                </div>
            </div>

            <div className={styles["summary-item"]}>
                <div className={styles["summary-label"]}>
                    No. of Impervious Areas:
                </div>
                <div className={styles["summary-value"]}>
                    {numOfImperviousAreas}
                </div>
            </div>

            <div className={styles["summary-item"]}>
                <div className={styles["summary-label"]}>
                    No. of Treatment Nodes:
                </div>
                <div className={styles["summary-value"]}>
                    {numOfTreatmentNodes}
                </div>
            </div>
        </div>
    );
};

export default SummarySection;
