// modules
import React, { useState, useEffect } from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import PaymentButton from '../../PaymentButton/PaymentButton';

export default function CalculateOsdResultButton({
    AEPS,
    coeffs,
    hasPsd,
    councilPSD,
    sumPreDevAreas,
    sumPostDevAreas,
    tcPre,
    hasTcPost,
    tcPost,
    hasTcU,
    tcU,
    preDevAreas,
    postDevAreas,
    tankHeight,
    osdCompany,
    storageType,
    computeOSDResults,
    userInfo,
}) {
    const [uncontrolledAreaInvalid, setUncontrolledAreaInvalid] = useState(-1);
    const [slopeFlowPathInvalid, setSlopeFlowPathInvalid] = useState(false);
    const [coefficientInvalid, setCoefficientInvalid] = useState(false);
    const [allFileldsFilledInvalid, setAllFileldsFilledInvalid] = useState(false);
    const [slopeAndFlowpathNotZeroInvalid, setSlopeAndFlowpathNotZeroInvalid] = useState(false);

    // slope, length of flow path and Times of Concentration
    useEffect(() => {
        if ((hasTcPost && (!tcPost || +tcPost <= 0)) || (hasTcU && (tcU === '' || +tcU <= 0))) {
            setSlopeFlowPathInvalid(true);
        } else {
            setSlopeFlowPathInvalid(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasPsd, tcPre, hasTcPost, tcPost, hasTcU, tcU]);
    // coefficient, uncontrolled area
    useEffect(() => {
        /* All Fileds Filled */
        setAllFileldsFilledInvalid(false);
        if (hasPsd && !councilPSD) setAllFileldsFilledInvalid(true);
        for (const index in preDevAreas) {
            if (
                !hasPsd &&
                (!preDevAreas[index].runoffCoefficient || !preDevAreas[index].surfaceArea)
            )
                setAllFileldsFilledInvalid(true);
            /* Check coefficient Invalid */
            setCoefficientInvalid(false);
            if (
                !hasPsd &&
                (+preDevAreas[index].runoffCoefficient > 1 ||
                    +preDevAreas[index].runoffCoefficient < 0.001)
            ) {
                setCoefficientInvalid(true);
            }
        }
        /* Uncontrolled Area Invalid */
        setSlopeAndFlowpathNotZeroInvalid(false);
        setUncontrolledAreaInvalid(-1);
        setCoefficientInvalid(false);
        for (const index in postDevAreas) {
            if (postDevAreas[index].uncontrolled > 0) {
                if (!hasPsd) setSlopeAndFlowpathNotZeroInvalid(true);
            }
            if (!postDevAreas[index].runoffCoefficient || !postDevAreas[index].surfaceArea) {
                setAllFileldsFilledInvalid(true);
            }
            if (+postDevAreas[index].uncontrolled > +postDevAreas[index].surfaceArea) {
                setUncontrolledAreaInvalid(index);
            }
            if (
                +postDevAreas[index].runoffCoefficient > 1 ||
                +postDevAreas[index].runoffCoefficient < 0.001
            ) {
                setCoefficientInvalid(true);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preDevAreas, postDevAreas, hasPsd, councilPSD]);

    const isTCValueWithinBounds = (value) => {
        if (!value) return true;
        return value >= 5 && value <= 20;
    };

    return (
        <>
            {/* Validation */}
            <div className="mt-2 mb-2">
                <Alert variant="danger" show={coeffs === null && AEPS === null}>
                    Please upload an intensity report for your location
                </Alert>
                <Alert
                    variant="danger"
                    show={
                        !(
                            isTCValueWithinBounds(tcPre) &&
                            isTCValueWithinBounds(tcPost) &&
                            isTCValueWithinBounds(tcU)
                        )
                    }
                >
                    Time of Concentration values must be between 5 and 20
                </Alert>
                <Alert variant="danger" show={allFileldsFilledInvalid}>
                    Please fill in all the fields
                </Alert>
                {/* Sleop, Length of Flow Path */}
                <Alert variant="danger" show={slopeFlowPathInvalid}>
                    The Slope, Length Of Flow Path OR Times of Concentration should NOT be or less
                    than 0.
                </Alert>
                <Alert variant="danger" show={slopeAndFlowpathNotZeroInvalid}>
                    Please specify slop/length of flowpath for uncontrolled areas (cannot be 0) or
                    remove uncontrolled areas (set to 0)
                </Alert>
                {/* Coefficient */}
                <Alert variant="danger" show={coefficientInvalid}>
                    Coefficient values - max:1, min: 0.001.
                </Alert>
                {/* Tank Height */}
                <Alert variant="danger" show={tankHeight !== '' && +tankHeight === 0}>
                    Please enter a non-zero value for storage height above orifice, or leave the
                    field empty
                </Alert>
                {/* Uncontrolled Area */}
                <Alert variant="danger" show={uncontrolledAreaInvalid !== -1}>
                    The #{+uncontrolledAreaInvalid + 1} uncontrolled Pervious Area cannot be greater
                    than Post-development Pervious Area.
                </Alert>
                {/* Storage Type */}
                <Alert variant="danger" show={!storageType || !osdCompany}>
                    Please select the storage type and OSD device.
                </Alert>
            </div>

            <div className="my-2">
                <PaymentButton
                    assessorEmail={userInfo.assessorEmail}
                    serviceID={9}
                    userID={userInfo.userID}
                    buttonTitle={'Calculate OSD'}
                    onPaymentComplete={async () => {
                        return computeOSDResults();
                    }}
                    subscriptionType="osd"
                    disabled={
                        (coeffs === null && AEPS === null) ||
                        (!hasPsd && +sumPreDevAreas - +sumPostDevAreas !== 0) ||
                        (tankHeight !== '' && +tankHeight === 0) ||
                        coefficientInvalid ||
                        slopeFlowPathInvalid ||
                        allFileldsFilledInvalid ||
                        slopeAndFlowpathNotZeroInvalid ||
                        osdCompany === '' ||
                        !(
                            isTCValueWithinBounds(tcPre) &&
                            isTCValueWithinBounds(tcPost) &&
                            isTCValueWithinBounds(tcU)
                        )
                    }
                />
            </div>
        </>
    );
}
