// modules
import React from 'react';
// css
import './calculator.css';
// utils
import useFavicon from '../../utils/usdFavicon';
// components
import UserInfo from '../UserInfo';
import StateUploadDownloadButtons from './StateUploadDownloadButtons';
import { CompanyIconPreviewer } from '../CompanyIconComponents';
import WSUDContainer from './WSUD/WSUDContainer';
import OsdContainer from '../OsdContainer';
import RwtContainer from '../RwtContainer';
import { useSelector } from 'react-redux';
import { selectProjectInfo } from '../../utils/redux/projectInfoSlice';
import AuditorContainer from '../auditor/AuditorContainer';

const Calculator = ({ tool, loadedModel }) => {
    const projectInfo = useSelector(selectProjectInfo);
    const favicon =
        tool === 'osds4vips'
            ? '/osds4vips.ico'
            : tool === 'auditor-converter'
              ? '/auditor.ico'
              : tool === 'rwt-demand-tool'
                ? '/rwt-demand-tool.ico'
                : '/wsud.ico';
    useFavicon(favicon);

    return (
        <div className="panInnerCalc flex-1 w-100">
            <div className="d-flex flex-wrap w-100" style={{ gap: 10 }}>
                <div className="flex-2">
                    <UserInfo projectInfo={projectInfo} tool={tool} />
                </div>
                {/* Company Icon */}
                <div className="flex-1 d-flex">
                    <CompanyIconPreviewer />
                </div>
                {/* Sus Buttons Panel */}
                <div className="flex-1 d-flex state-upload-download-button-container">
                    <StateUploadDownloadButtons tool={tool} />
                </div>
            </div>
            <br />

            {projectInfo.developmentType && projectInfo.planningPermitNo && (
                <div>
                    {tool === 'wsud' && <WSUDContainer loadedModel={loadedModel} />}
                    {tool === 'osds4vips' && <OsdContainer loadedModel={loadedModel} />}
                    {tool === 'rwt-demand-tool' && <RwtContainer />}
                    {tool === 'auditor-converter' && <AuditorContainer />}
                </div>
            )}
        </div>
    );
};

export default Calculator;
