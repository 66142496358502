import { BiX } from 'react-icons/bi';
// import {
//     TooltipButton,
//     TooltipContainer,
//     TooltipContent,
// } from '../../styled-components/TooltipComponents';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import Tooltip from '../../common/Tooltip/Tooltip';

export default function FormRow({
    handleInputChange,
    formRow,
    columns,
    onDelete = undefined,
    showTooltipError,
}) {
    return (
        <div className="osd-form-section-container">
            {formRow.map((row, index) => (
                <div key={row.id + index} className="osd-form-section">
                    {columns.map((column, columnIndex) => {
                        // Don't add any elements
                        if (column === undefined || column === false) return null;

                        // Add empty space element
                        if (column === null)
                            return <div key={`row-${index}-column-${columnIndex}`} />;

                        // Add element based on type
                        let inputEl;
                        switch (column.inputType) {
                            case 'dropdown': {
                                inputEl = (
                                    <DropdownElement
                                        index={index}
                                        columnIndex={columnIndex}
                                        column={column}
                                        row={row}
                                        handleInputChange={handleInputChange}
                                    />
                                );
                                break;
                            }
                            case 'button': {
                                inputEl = <ButtonElement column={column} />;
                                break;
                            }
                            default: {
                                inputEl = (
                                    <TextFieldElement
                                        index={index}
                                        columnIndex={columnIndex}
                                        column={column}
                                        row={row}
                                        handleInputChange={handleInputChange}
                                        showTooltipError={showTooltipError}
                                    />
                                );
                            }
                        }

                        // Render cell
                        return (
                            <div
                                className={`${column?.className ?? ''}`}
                                key={`row-${index}-column-${columnIndex}`}
                            >
                                {index === 0 && column.label && <ColumnLabel column={column} />}
                                {inputEl}
                            </div>
                        );
                    })}

                    {/* Delete Button */}
                    {onDelete && (
                        <div className="text-left mb-2">
                            <BiX
                                className="delete-icon"
                                onClick={() => onDelete(index)}
                                disabled={formRow.length === 1}
                            />
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

const ColumnLabel = ({ column }) => {
    return (
        <label>
            {column.label}
            {column.labelToolTip && (
                <Tooltip link={column.labelToolTip.link} style={column.labelToolTip.style}>
                    {column.labelToolTip.content}
                </Tooltip>
            )}
            {/* {column.labelToolTip && ( */}
            {/*     <TooltipContainer> */}
            {/*         <TooltipButton> */}
            {/*             <a href={column.labelToolTip.link} target="_blank" rel="noreferrer"> */}
            {/*                 ❓ */}
            {/*             </a> */}
            {/*         </TooltipButton> */}
            {/*         <TooltipContent {...column.labelToolTip.contentProps}> */}
            {/*             {column.labelToolTip.content} */}
            {/*         </TooltipContent> */}
            {/*     </TooltipContainer> */}
            {/* )} */}
        </label>
    );
};

const DropdownElement = ({ index, columnIndex, column, row, handleInputChange }) => {
    return (
        <select
            name={column.id}
            value={row[column.id] ?? ''}
            onChange={(e) => handleInputChange(e, index, column.numberCheck)}
            disabled={column.disabled}
        >
            {column.options.map((option, optionIndex) => (
                <option
                    key={`row-${index}-column-${columnIndex}-option-${optionIndex}`}
                    value={option.value}
                >
                    {option.label}
                </option>
            ))}
        </select>
    );
};

const ButtonElement = ({ column }) => {
    return (
        <Button className={column.className} onClick={column.onClick} title={column.title}>
            {column.icon}
        </Button>
    );
};

const TextFieldElement = (props) => {
    const { index, column, row, handleInputChange, showTooltipError } = props;

    const disabled =
        typeof column.disabled === 'boolean'
            ? column.disabled
            : typeof column.disabled === 'function'
              ? column.disabled(row)
              : null;

    const inputEl = (
        <input
            type="text"
            name={column.id}
            value={row[column.id] ?? ''}
            onChange={(e) => handleInputChange(e, index, column.numberCheck)}
            disabled={disabled}
            placeholder={column.placeholder ?? ''}
        />
    );

    const renderTooltip = ({ children, ...props }) => (
        <Popover id="popover-basic" {...props}>
            <Popover.Content>{children}</Popover.Content>
        </Popover>
    );

    if (column.errorToolTip)
        return (
            <OverlayTrigger
                placement="top"
                overlay={(props) =>
                    renderTooltip({
                        ...props,
                        children: column.errorToolTip.content,
                    })
                }
                show={showTooltipError[`${column?.id}-${index}`] ?? false} // Control visibility with state
            >
                {inputEl}
            </OverlayTrigger>
        );

    return inputEl;
};
