import React, { useEffect } from 'react';
import {
    Form,
    Row,
    Col,
    FormGroup,
    FormLabel,
    FormControl,
    FormCheck,
    Button,
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';

export default function PorjectDescription({
    projectDescriptionSelection,
    setProjectDescriptionSelection,
    projectType,
    setProjectType,
    address,
    setAddress,
    council,
    setCouncil,
    requirements,
    setRequirements,
    guidelines,
    setGuidelines,
    newTemplateData,
    setNewTemplateData,
    projectDescriptionUploadedImage,
    setProjectDescriptionUploadedImage,
    setConfirmSaveProjDesc,
}) {
    const dispatch = useDispatch();

    useEffect(() => {
        setConfirmSaveProjDesc(false);
    }, [
        projectDescriptionSelection,
        projectType,
        address,
        council,
        requirements,
        guidelines,
        newTemplateData,
        projectDescriptionUploadedImage,
        setConfirmSaveProjDesc,
    ]);

    return (
        <Form>
            <FormGroup>
                <Row>
                    <Col>
                        <FormCheck
                            type="radio"
                            label="No Description Section needed"
                            name="radioOptions"
                            id="radio1"
                            checked={projectDescriptionSelection === 'no-description'}
                            onChange={() =>
                                dispatch(setProjectDescriptionSelection('no-description'))
                            }
                        />
                    </Col>
                    <Col>
                        <FormCheck
                            type="radio"
                            label="Preset Template"
                            name="radioOptions"
                            id="radio2"
                            checked={projectDescriptionSelection === 'preset-template'}
                            onChange={() =>
                                dispatch(setProjectDescriptionSelection('preset-template'))
                            }
                        />
                    </Col>
                    <Col>
                        <FormCheck
                            type="radio"
                            label="New Template"
                            name="radioOptions"
                            id="radio3"
                            checked={projectDescriptionSelection === 'new-template'}
                            onChange={() =>
                                dispatch(setProjectDescriptionSelection('new-template'))
                            }
                        />
                    </Col>
                </Row>
            </FormGroup>

            {projectDescriptionSelection === 'preset-template' && (
                <PreSetTemplate
                    projectType={projectType}
                    setProjectType={setProjectType}
                    address={address}
                    setAddress={setAddress}
                    council={council}
                    setCouncil={setCouncil}
                    requirements={requirements}
                    setRequirements={setRequirements}
                    guidelines={guidelines}
                    setGuidelines={setGuidelines}
                />
            )}
            {projectDescriptionSelection === 'new-template' && (
                <NewTemplate
                    newTemplateData={newTemplateData}
                    projectDescriptionUploadedImage={projectDescriptionUploadedImage}
                    setNewTemplateData={setNewTemplateData}
                />
            )}
            {/* Image uploader */}
            <div className="mt-4">
                <FormLabel className="h5">Upload Image</FormLabel>
                <Form.Control
                    type="file"
                    onChange={(e) => {
                        new Promise((resolve) => {
                            const fileReader = new FileReader();
                            fileReader.onload = () => {
                                const imageDataUrl = fileReader.result;
                                dispatch(setProjectDescriptionUploadedImage(imageDataUrl));
                                resolve();
                            };
                            fileReader.readAsDataURL(e.target.files[0]);
                        });
                    }}
                />
            </div>
            <br />
            <Button variant="success" onClick={() => setConfirmSaveProjDesc(true)}>
                Save
            </Button>
        </Form>
    );
}
const PreSetTemplate = ({
    projectType,
    setProjectType,
    address,
    setAddress,
    council,
    setCouncil,
    requirements,
    setRequirements,
    guidelines,
    setGuidelines,
}) => {
    const dispatch = useDispatch();

    return (
        <>
            <div>
                The project is{' '}
                <FormControl
                    size="sm"
                    style={{ width: '180px' }}
                    value={projectType}
                    onChange={(e) => dispatch(setProjectType(e.target.value))}
                />{' '}
                located at{' '}
                <FormControl
                    size="sm"
                    style={{ width: '180px' }}
                    value={address}
                    onChange={(e) => dispatch(setAddress(e.target.value))}
                />{' '}
                in{' '}
                <FormControl
                    size="sm"
                    style={{ width: '180px' }}
                    value={council}
                    onChange={(e) => dispatch(setCouncil(e.target.value))}
                />{' '}
                This report outlines the WSUD modelling done to demonstrate compliance with
                Council&apos;s requirements for{' '}
                <FormControl
                    size="sm"
                    style={{ width: '180px' }}
                    value={requirements}
                    onChange={(e) => dispatch(setRequirements(e.target.value))}
                />
                The model was created based on{' '}
                <FormControl
                    size="sm"
                    style={{ width: '180px' }}
                    value={guidelines}
                    onChange={(e) => dispatch(setGuidelines(e.target.value))}
                />{' '}
                guidelines. These are the latest modelling guidelines referenced by Council. For
                more information on modelling, refer to the following sections.
            </div>
        </>
    );
};

const NewTemplate = ({ newTemplateData, setNewTemplateData }) => {
    const dispatch = useDispatch();

    return (
        <>
            <FormControl
                as="textarea"
                rows={3}
                placeholder="Enter your project summary here"
                autoSize={{ minRows: 3, maxRows: 8 }}
                value={newTemplateData}
                onChange={(e) => dispatch(setNewTemplateData(e.target.value))}
            />
        </>
    );
};
