import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { FiX } from 'react-icons/fi';

import TCContainer from './TCContainer';
import tcCalculatorAPI from './TCContainer/tcCalculatorAPI';

import './TCCalculatorModal.css';

const titles = { pre: 'Pre', post: 'Post', uncontrolled: 'Uncontrolled' };

const TCCalculatorModal = ({ type, tcCalculatorData, onClose, onDone }) => {
    const handleDone = (calculatorData, finalResult) => {
        onDone(calculatorData, finalResult);
        onClose();
    };
    const [calculators, setCalculators] = useState(null);

    useEffect(() => {
        tcCalculatorAPI.getCalculators().then((data) => {
            setCalculators(data.tcCalculator);
        });
    }, []);

    return (
        <Modal show={type !== ''} centered dialogClassName="tc-calculator-modal">
            <Modal.Header>
                <div className="modal-header-content">
                    <strong>Time of Concentration Calculator ({titles[type]}-Dev)</strong>
                    <div onClick={onClose} className="text-danger cursor-pointer mr-1 mb-1">
                        <FiX />
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="tc-calculator-forms">
                    <TCContainer
                        calculators={calculators}
                        tcCalculatorData={tcCalculatorData}
                        onDone={handleDone}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default TCCalculatorModal;
