// modules
import React, { useState, useEffect } from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import PaymentButton from '../../PaymentButton/PaymentButton';

const CalculateOsdResultButton = ({
    TC,
    preDevAreas = [],
    postDevAreas = [],
    tankHeight,
    storageType,
    osdCompany,
    computeOSDResults,
    osdResultLoading,
    disabled,
    userInfo,
}) => {
    const [uncontrolledAreaInvalid, setUncontrolledAreaInvalid] = useState(-1);
    const [allFileldsFilledInvalid, setAllFileldsFilledInvalid] = useState(false);

    const TCInvalid =
        (!TC.psd && (!TC.pre ||
        +TC.pre < 5)) ||
        !TC.post ||
        +TC.post < 5 ||
        (TC.uncontrolled && +TC.uncontrolled < 5);

    useEffect(() => {
        setAllFileldsFilledInvalid(false);
        setUncontrolledAreaInvalid(-1);

        /* Pre Dev */
        // Council Provided PSD
        if (TC.psd && !TC.psd) setAllFileldsFilledInvalid(true);
        // Area, Coefficient
        for (const index in preDevAreas) {
            if (!TC.psd) {
                if (!preDevAreas[index].surfaceArea) setAllFileldsFilledInvalid(true);
            }
        }

        /* Post Dev */
        for (const index in postDevAreas) {
            if (!postDevAreas[index].surfaceArea) setAllFileldsFilledInvalid(true);
            if (+postDevAreas[index].uncontrolled > +postDevAreas[index].surfaceArea)
                setUncontrolledAreaInvalid(index);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preDevAreas, postDevAreas, TC]);

    return (
        <>
            {/* Validation */}
            <div>
                <Alert variant="danger" show={allFileldsFilledInvalid}>
                    Please fill in all the fields
                </Alert>
                <Alert variant="danger" show={TCInvalid}>
                    Please fill in the Time of Concentration. Tc Min: 5 minutes.
                </Alert>
                {/* Tank Height */}
                <Alert variant="danger" show={tankHeight !== '' && +tankHeight === 0}>
                    Please enter a non-zero value for storage height above orifice, or leave the
                    field empty
                </Alert>
                {/* Uncontrolled Area */}
                <Alert variant="danger" show={uncontrolledAreaInvalid !== -1}>
                    The #{+uncontrolledAreaInvalid + 1} uncontrolled Pervious Area cannot be greater
                    than Post-Development Pervious Area.
                </Alert>
                {/* Storage Type */}
                <Alert variant="danger" show={storageType === '' || osdCompany === ''}>
                    Please select the storage type and OSD device.
                </Alert>
            </div>

            {/* Calculate OSD Button */}
            {osdResultLoading ? (
                <>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="output"
                        aria-hidden="true"
                    />
                    <br />
                </>
            ) : (
                <div>
                    <PaymentButton
                        assessorEmail={userInfo.assessorEmail}
                        serviceID={9}
                        userID={userInfo.userID}
                        buttonTitle={'Calculate OSD'}
                        onPaymentComplete={async () => {
                            return computeOSDResults();
                        }}
                        subscriptionType="osd"
                        disabled={
                            disabled || allFileldsFilledInvalid || TCInvalid || osdCompany === ''
                        }
                    />
                </div>
            )}
        </>
    );
};

export default CalculateOsdResultButton;
