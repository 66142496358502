import React from 'react';
import { StyleSheet, View, Image, Link } from '@react-pdf/renderer';

export default function OsdSpecificationAndTypicalDrawing({ imageLink, manufactureDocuments }) {
    const styles = StyleSheet.create({
        mainTitle: {
            fontSize: 20,
            fontweight: 700,
            textAlign: 'center',
            marginTop: 5,
            marginBottom: 5,
        },
        image: {
            objectFit: 'scale-down',
        },
        hyperLinkSection: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 10,
            marginTop: 8,
        },
    });

    return (
        <>
            <View style={{ height: '40vh', marginBottom: 8 }}>
                <Image style={styles.image} src={imageLink} />
                {manufactureDocuments?.map((document, index) => {
                    return (
                        <View key={document.name + index} style={styles.hyperLinkSection}>
                            <Link src={document.link}>{document.name}</Link>
                        </View>
                    );
                })}
            </View>
        </>
    );
}
