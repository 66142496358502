import React from 'react';
import { StyleSheet, Page, View, Text } from '@react-pdf/renderer';
// components
import Table from '../Table';
import ReportFooter from '../ReportFooter';
import ReportHeader from '../ReportHeader';

const styles = StyleSheet.create({
    page: {
        paddingTop: 30,
        paddingBottom: 10,
        paddingHorizontal: 35,
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    image: {
        paddingTop: 30,
        height: 350,
        width: 400,
    },
    mainTitle: {
        fontSize: 18,
        fontweight: 700,
        textAlign: 'center',
        marginTop: 5,
        marginBottom: 20,
    },
});

const defaultStyles = {
    borderStyle: '1px solid #658cbb',
    textAlignment: 'center',
    headerBorder: true,
    fontSize: 9,
    headerFontSize: 9,
    headerTextAlign: 'center',
    tableWidth: '100%',
};

const createStyles = (newStyles = defaultStyles) => {
    return {
        section: { marginTop: 15 },
        table: {
            display: 'table',
            width: newStyles.tableWidth,
            border: newStyles.borderStyle,
            borderBottom: 0,
            borderRight: 0,
            borderLeft: 0,
            borderTop: newStyles.headerBorder ? newStyles.borderStyle : 0,
        },

        tableRow: {
            flexDirection: 'row',
            borderBottom: newStyles.borderStyle,
        },

        headerCell: {
            fontSize: newStyles.headerFontSize,
            textAlign: newStyles.headerTextAlign,
            borderRight: newStyles.headerBorder ? newStyles.borderStyle : 0,
            borderLeft: newStyles.headerBorder ? newStyles.borderStyle : 0,
            height: '100%',
            padding: 5,
        },

        tableCell: {
            fontSize: newStyles.fontSize,
            textAlign: newStyles.textAlignment,
            borderRight: newStyles.borderStyle,
            borderLeft: newStyles.borderStyle,
            height: '100%',
            padding: 5,
        },
    };
};

const RangeOfDurationsCalculatedPages = ({ vreq_n_data, reportHeaderIconUrl }) => {
    // Seperate the Storm Durations into a few pages
    const filteredVreqNData = vreq_n_data.filter(function (item) {
        return +item.t_dur > 0;
    });
    const stormDurations = [];
    const pageSize = 20;
    for (let i = 0; i < filteredVreqNData.length; i += pageSize)
        stormDurations.push(filteredVreqNData.slice(i, i + pageSize));

    return (
        <>
            {stormDurations.map((arrayItem) => {
                return (
                    <Page key={arrayItem[0].t_dur} style={styles.page} size="A4" wrap={false}>
                        <View>
                            <ReportHeader reportHeaderUrl={reportHeaderIconUrl} />
                        </View>

                        <View>
                            <Text style={styles.mainTitle}>
                                Range of Durations Calculated {'\n'}to Determine Critical Duration
                            </Text>
                        </View>
                        <Table
                            columnNames={[
                                'Td, min',
                                'I, mm/hr',
                                'Qpre, L/s', // new
                                'Quncontrolled, L/s', // new
                                'Qpeak, I/s',
                                'Volume Required, (m3)',
                            ]}
                            tableData={arrayItem.map((item) => {
                                const intenSityTDur = +item.intensity_tdur;
                                const qPreTN = +item.q_pre_adjusted;
                                const qPostTN = +item.q_post_tn;
                                const qUncontrolled = +item.q_uncontrolled_tn;
                                const vReqN = +item.Vreq_n;
                                if (+item.t_dur > 0)
                                    return [
                                        item.t_dur,
                                        intenSityTDur.toFixed(4),
                                        qPreTN.toFixed(4),
                                        qUncontrolled.toFixed(4),
                                        qPostTN.toFixed(4),
                                        vReqN.toFixed(4),
                                    ];
                                else return null;
                            })}
                            cellWidths={[
                                '16.66%',
                                '16.66%',
                                '16.66%',
                                '16.66%',
                                '16.66%',
                                '16.66%',
                            ]}
                            tableStyles={createStyles()}
                        />
                        {/* Footer */}
                        <ReportFooter />
                    </Page>
                );
            })}
        </>
    );
};

export default RangeOfDurationsCalculatedPages;
