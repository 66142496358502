import axios from "axios";

export const convertMxProjToMsf = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
        const response = await axios.post('https://testing.stormupdated.com.au/mxproj2msf', formData);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

const textToFile = (txt) => {
    return new Blob([txt], { type: 'text/plain' })
}

// post to /AuditMSF to audit the MSF
export const auditMSF = async (loadingData) => {
    let payload = {
        mlb: loadingData.authority,
        msf: loadingData.msf,
    };
    try {
        const res = await axios.post('https://a32nb3dwl2.execute-api.ap-southeast-2.amazonaws.com/auditor', payload, { timeout: 100000 });

        const extractCompliantNodes = (auditResults, templatesByNodeType) => {
            const compliantNodes = auditResults.reduce((acc, result) => {
                if (result.compliantTemplate.length > 0) {
                    acc.push({
                        nodeName: result.nodeName,
                        compliantTemplates: result.compliantTemplate.map(
                            (templateIndex) => {
                                return templatesByNodeType[result.nodeType][
                                    templateIndex
                                ];
                            },
                        ),
                        rows: result.allRows,
                        nodeType: result.nodeType,
                    });
                }
                return acc;
            }, []);

            return compliantNodes;
        };
        const extractNodesNotExistingInDB = (auditResults) => {
            const nodesNotExistingInDB = auditResults.reduce((acc, result) => {
                if (
                    result.compliantTemplate.length === 0 &&
                    result.nonCompliantTemplate.length === 0
                ) {
                    const notExistingInDBNode = {
                        nodeName: result.nodeName,
                        nonExistingInDBTemplates: result.nonCompliantTemplate,
                        nonExistingInDBRows: {},
                        rows: result.allRows,
                    };

                    // Ensure nonExistingInDBRows is defined
                    result.nonCompliantRows = result.nonCompliantRows || {};

                    acc.push(notExistingInDBNode);
                }
                return acc;
            }, []);

            return nodesNotExistingInDB;
        };
        const extractNonCompliantNodes = (auditResults, templatesByNodeType) => {
            const nonCompliantNodes = auditResults.reduce((acc, result) => {
                if (
                    result.compliantTemplate.length < 1 &&
                    result.nonCompliantTemplate.length > 0
                ) {
                    const nonCompliantNode = {
                        nodeName: result.nodeName,
                        nonCompliantTemplates: result.nonCompliantTemplate.map(
                            (templateIndex) => {
                                return templatesByNodeType[result.nodeType][
                                    templateIndex
                                ];
                            },
                        ),
                        nonCompliantRows: {},
                        rows: result.allRows,
                    };

                    // Ensure nonCompliantRows is defined
                    result.nonCompliantRows = result.nonCompliantRows || {};

                    // Iterate through each non-compliant template
                    if (result.nonCompliantTemplate.length > 0) {
                        result.nonCompliantTemplate.forEach((template) => {
                            if (template && template.match_name) {
                                // Find the matching rows for this template's match_name
                                const matchingRows =
                                    result.nonCompliantRows[template.match_name];
                                // Add the rows to nonCompliantRows under the corresponding match_name
                                if (matchingRows) {
                                    nonCompliantNode.nonCompliantRows[
                                        template.match_name
                                    ] = matchingRows;
                                }
                            }
                        });
                    }

                    acc.push(nonCompliantNode);
                }
                return acc;
            }, []);

            return nonCompliantNodes;
        };

        const { auditResults, templatesByNodeType } = res.data;

        const compliantNodes = await extractCompliantNodes(
            auditResults,
            templatesByNodeType,
        );
        const nonCompliantNodes = await extractNonCompliantNodes(
            auditResults,
            templatesByNodeType,
        );
        const nodesNotExistingInDB = await extractNodesNotExistingInDB(
            res.data.auditResults,
        );

        return { compliantNodes, nonCompliantNodes, nodesNotExistingInDB };
    } catch (e) {
        console.log(e);
        alert(
            'The server could not respond. Please try again, or contact Mircea (0433030044) or Greg (0452518727) if the error persists.',
            e,
        );
    }
};

export const getMelodyResult = async (msf, mlb) => {
    const msfFile = textToFile(msf);
    const msfName = `model.msf`;
    const formData = new FormData();

    formData.append('msf', msfFile, msfName);
    formData.append('mlb', `${mlb}`);
    try {
        const res = await axios.post(
            `https://sam-richman.stormupdated.com.au/jco-service`,
            formData,
            {
                timeout: 600000,
            },
        );
        return res.data;

    } catch (error) {
        console.error(error);
        return null;
    }
}