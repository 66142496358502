import { useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { FaRegQuestionCircle } from 'react-icons/fa';

import s from './Tooltip.module.css';

export default function Tooltip({ link = '', linkColor = '#6786ff', style = {}, children }) {
    const [isHovered, setIsHovered] = useState(false);

    const hasLink = typeof link === 'string' && link.length > 0;

    const infoSymbol = (
        <FaRegQuestionCircle
            className={`${s['tooltip-icon']} ${hasLink ? s.link : ''}`}
            color={isHovered && link.length > 0 ? linkColor : '#7b7d81'}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        />
    );

    const infoButton = hasLink ? (
        <a target="_blank" rel="noreferrer" href={link}>
            {infoSymbol}
        </a>
    ) : null;

    return (
        <OverlayTrigger
            placement="top"
            overlay={(props) => (
                <Popover
                    {...props}
                    className={s['tooltip']}
                    style={{ ...props.style, maxWidth: '32rem', ...style }}
                >
                    <Popover.Content className={s['tooltip-content']}>{children}</Popover.Content>
                </Popover>
            )}
        >
            {infoButton ?? <span>{infoSymbol}</span>}
        </OverlayTrigger>
    );
}
