import React from 'react';

// components
import {
    TooltipButton,
    TooltipContainer,
    TooltipContent,
} from '../../styled-components/TooltipComponents';
import FormRow from './FormRow';

// utils
import { isFloat } from '../../../utils/common';
import Tooltip from '../../common/Tooltip/Tooltip';

const designStormColumns = ['preDevDesignStorm', 'postDevDesignStorm'];
const StormDurations = ({ TDuration, setTDuriation, designStorm = {}, setDesignStorm, AEPS }) => {
    const handleTDurationInputChange = (e, _, numberCheck = false) => {
        const { name, value } = e.target;

        if (numberCheck && !isFloat(e.target.value)) {
            return;
        }
        const bufArray = { ...TDuration };
        bufArray[name] = value;
        bufArray.tDurationEnd =
            +bufArray.tDurationStart + +bufArray.tDurationStepSize * +bufArray.tDurationNumOfSteps;

        setTDuriation(bufArray);
    };

    const handleDesignStormInputChange = (e, _, numberCheck = false) => {
        const { name, value } = e.target;

        if (numberCheck && !isFloat(e.target.value)) {
            return;
        }
        const bufArray = { ...designStorm };
        bufArray[name] = value;
        setDesignStorm(bufArray);
    };

    const handleInputChange = (e, _, numberCheck = false) => {
        if (designStormColumns.includes(e.target.name)) {
            handleDesignStormInputChange(e, _, numberCheck);
        } else {
            handleTDurationInputChange(e, _, numberCheck);
        }
    };

    return (
        <div className="osd-form-section-container osd-blue-border">
            <div className="osd-form-section">
                <span className="title-2 span-3">Design Storm</span>

                <div className="title-2 span-4">
                    Storm Durations
                    <Tooltip link="https://youtu.be/GBWe2MWBK9g">
                        <div>
                            <span>
                                The Modified rational method produces a simple hydrograph over
                                multiple storm durations to identify maximum required detention
                                volume.
                            </span>
                        </div>
                        <br />
                        <div>
                            <span>
                                We generally iterate for storm durations up to the 3 hour storm or
                                as required by Council/project.
                            </span>
                        </div>
                        <br />
                        <div>
                            <span>
                                Duration end = Duration start + (Step Size * Number of Steps).
                            </span>
                        </div>
                    </Tooltip>
                    {/* <TooltipContainer> */}
                    {/*     <TooltipButton>❓</TooltipButton> */}
                    {/*     <TooltipContent> */}
                    {/*         <div> */}
                    {/*             <span> */}
                    {/*                 The Modified rational method produces a simple hydrograph over */}
                    {/*                 multiple storm durations to identify maximum required detention */}
                    {/*                 volume. */}
                    {/*             </span> */}
                    {/*         </div> */}
                    {/*         <br /> */}
                    {/*         <div> */}
                    {/*             <span> */}
                    {/*                 We generally iterate for storm durations up to the 3 hour storm */}
                    {/*                 or as required by Council/project. */}
                    {/*             </span> */}
                    {/*         </div> */}
                    {/*         <br /> */}
                    {/*         <div> */}
                    {/*             <span> */}
                    {/*                 Duration end = Duration start + (Step Size * Number of Steps). */}
                    {/*             </span> */}
                    {/*         </div> */}
                    {/*     </TooltipContent> */}
                    {/* </TooltipContainer> */}
                </div>
            </div>
            <FormRow
                formRow={[{ ...TDuration, ...designStorm }]}
                columns={[
                    designStorm && {
                        id: 'preDevDesignStorm',
                        label: 'Pre Dev (AEP %):',
                        inputType: 'dropdown',
                        options: AEPS.map((aep) => ({ label: aep, value: aep })),
                        labelToolTip: {
                            content:
                                'Pre Development Annual Exceedence Probability (AEP) is used to detemine existing/pre development flows and establish a permissible site discharge (PSD).',
                            link: 'http://www.bom.gov.au/water/awid/id-703.shtml',
                        },
                    },
                    designStorm && {
                        id: 'postDevDesignStorm',
                        label: 'Post Dev (AEP %):',
                        inputType: 'dropdown',
                        options: AEPS.map((aep) => ({ label: aep, value: aep })),
                        labelToolTip: {
                            content:
                                'Post Development Annual Exceedence Probability (AEP) is used to determine the storm event for sizing the On Site Detention volume.',
                            link: 'http://www.bom.gov.au/water/awid/id-703.shtml',
                        },
                    },
                    designStorm && null,
                    { id: 'tDurationStepSize', label: 'Step Size:', numberCheck: true },
                    { id: 'tDurationNumOfSteps', label: 'Number of Steps:', numberCheck: true },
                    { id: 'tDurationStart', label: 'Duration Start (mins):', numberCheck: true },
                    { id: 'tDurationEnd', label: 'Duration End (mins):', disabled: true },
                ]}
                handleInputChange={handleInputChange}
            />
        </div>
    );
};

export default StormDurations;
