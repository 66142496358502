// module
import React from 'react';
// css
import './thefooter.css';

function TheFooter() {
    return (
        <>
            <footer style={{ padding: 10 }}>
                <div>
                    <p className="text-center footer-statement">
                        Please feel free to submit our Reports as part of your Development
                        Application.
                    </p>
                </div>
                <div>
                    <h5 className="text-center">
                        <a
                            href="https://www.cleanstormwater.com.au"
                            target="_blank"
                            style={{ color: 'white' }}
                            rel="noreferrer"
                        >
                            ❤️ Cleanstormwater. 2024
                        </a>
                    </h5>
                </div>
            </footer>
        </>
    );
}

export default TheFooter;
