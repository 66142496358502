/**
 * @param {string} value
 * @param {number} precision - number of decimal points
 */
export const isValidRealNumber = (value, precision) => {
    const pattern = `^(\\d+(\\.\\d{0,${precision}})?|\\.\\d{1,${precision}})$`;
    const regex = new RegExp(pattern);
    return regex.test(value);
};

/**
 * Check if the value is nullish (null or undefined)
 *
 * @param {any} value
 *
 * @return {boolean} true if the value is null or undefined, false otherwise
 */
export const isNullish = (value) => value === null || value === undefined;
