import { mapListToDic } from './common';
import { getMethodsAPI } from './api/lambda';

const defaultValue = { treatmentTypes: [], mappedTreatmentTypes: [] };

export const getTreatmentTypes = (
    areaType,
    rainfallStation,
    catchmentType,
    treatmentDevice,
    isPervious
) => {
    if (areaType === '') return defaultValue;
    const endpoint = isPervious ? 'getPerviousTreatmentTypes' : 'getTreatmentTypes';
    return getMethodsAPI({
        endpoint,
        rainfallStation,
        areaType,
        catchmentType,
        treatmentDevice,
    })
        .then((response) => {
            const treatmentTypes = response.data;
            const mappedTreatmentTypes = mapListToDic(
                treatmentTypes.map((node) => {
                    return node.tnodename;
                })
            );
            return { treatmentTypes, mappedTreatmentTypes };
        })
        .catch((error) => {
            console.error(error);
            return defaultValue;
        });
};

export const getTreatmentSizePlaceholder = (apiBaseRoute) => {
    const placeholderDictionary = {
        getBioretentionResults: "Size (m²)",
        getRainwatertankResults: "Size (L)",
        LinearReduction: "Hi-flow bypass (L/s)",
        getHydrochannelResults: "Hi-flow bypass (L/s)",
        getNoTreatmentResults: "",
    };

    return placeholderDictionary[apiBaseRoute];
};
