import React from 'react';
import { StyleSheet, View, Page, Text, Image } from '@react-pdf/renderer';
// components
import ReportHeader from '../ReportHeader';
import Table from '../Table';
import ReportFooter from '../ReportFooter';
import SpecImagePages from '../SpecImagePages';
import { TCCalculatorTemplate } from '../tc-calculation/TCCalculatorTemplate';
import { createStyles, defaultStyles } from '../common';
import {
    catchmentDetailsTable,
    stormDetailsTable,
    cValueTable,
    flowAndVolumeTable,
    tankHeightTable,
} from './../ReportBlocks';

const generateAllTableData = (data) => {
    return [
        catchmentDetailsTable(
            data.osdResult.report.site_calcs,
            data.osdResult.report.runoff_coefficient,
            { isPsdProvided: data.osd.psd },
        ),

        stormDetailsTable(data.osdResult.report.storm_details),

        cValueTable(
            'C-Value (Pre Dev)',
            data.osdResult.report.c_value_pre,
            data.osdResult.intermediate_results,
            {
                isPsdProvided: data.osd.psd,
                isPreDev: true,
                isControlled: true,
                showAppendix: false,
            },
        ),

        cValueTable(
            'C-Value (Post Dev Controlled)',
            data.osdResult.report.c_value_post,
            data.osdResult.intermediate_results,
            {
                isPreDev: false,
                isControlled: true,
                showAppendix: false,
            },
        ),

        cValueTable(
            'C-Value (Post Dev Uncontrolled)',
            data.osdResult.report.c_value_post,
            data.osdResult.intermediate_results,
            {
                isPreDev: false,
                isControlled: false,
                showAppendix: true,
            },
        ),

        flowAndVolumeTable(
            data.osdResult.report.flow_and_vol,
            data.osdResult.report.vreq_n_max,
            data.osd.climateAdjustment,
        ),

        tankHeightTable(data.osdResult.report.orifice),
    ];
};

export default function ModifiedRationalReportTemplate({
    data,
    imageSource,
    manufactureImageLinks,
    manufactureDocuments,
}) {
    const styles = StyleSheet.create({
        body: {
            paddingTop: 30,
            paddingBottom: 30,
            paddingHorizontal: 35,
            marginBottom: 10,
        },
        section: {
            marginBottom: 10,
            padding: 15,
            display: 'flex',
            flexDirection: 'row',
        },
        mainTitle: {
            fontSize: 18,
            fontweight: 1000,
            textAlign: 'center',
            marginTop: 5,
            marginBottom: 5,
        },
        catchmentTimes: {
            marginTop: 10,
            fontSize: 15,
            width: '100%',
            justifyContent: 'end',
            display: 'flex',
        },
        disclaimer: {
            fontSize: 9,
            marginTop: 15,
            lineHeight: 1.3,
        },
    });

    return (
        <>
            <Page style={styles.body} wrap>
                <ReportHeader reportHeaderUrl={data.reportHeaderIconUrl} />
                <View style={{ pageBreakAfter: 'always' }}>
                    <Text style={styles.mainTitle}>OSD Design Summary Report</Text>
                    <Text style={{ fontSize: 12, marginTop: 10 }}>
                        Method for OSD calculations: MODIFIED RATIONAL METHOD
                    </Text>
                </View>
                <View>
                    <Text style={{ fontSize: 12, marginTop: 10 }}>
                        OSD Device Type:{data.OSDMaterial} {data.storageType}
                    </Text>
                </View>
                <View>
                    <Text style={{ fontSize: 12, marginTop: 10 }}>
                        OSD Device Name: {data.OSDCompany}
                    </Text>
                </View>
                {/* TC Calculator */}
                <TCCalculatorTemplate
                    tcData={data.osd.tcData}
                    createStyles={createStyles}
                    defaultStyles={defaultStyles}
                />
                {/* Site Details */}
                <View style={{ marginBottom: 50 }}>
                    {generateAllTableData(data).map((table, index) => {
                        if (table.styles) {
                            return (
                                <>
                                    <Table
                                        columnNames={table.columns}
                                        tableData={table.items}
                                        headerWidths={table.headerWidths}
                                        cellWidths={table.cellWidths}
                                        customCellWidths={table.customCellWidths}
                                        tableStyles={table.styles}
                                        title={table.title}
                                    />
                                    {table.appendix ?? <></>}
                                </>
                            );
                        }
                    })}

                    <Text style={styles.disclaimer}>
                        NOTE:{'\n'} * Uncontrolled areas are assumed to be accounted for in the
                        calculation of PSD when PSD is provided by Council.
                    </Text>
                </View>
                <ReportFooter />
            </Page>

            <SpecImagePages
                data={data}
                osdCompany={data.OSDCompany}
                manufactureImageLinks={manufactureImageLinks}
                manufactureDocuments={manufactureDocuments}
                reportHeaderUrl={data.reportHeaderIconUrl}
            />

            <Page style={styles.body} wrap>
                <ReportHeader reportHeaderUrl={data.reportHeaderIconUrl} />
                <Text style={styles.mainTitle}>Required Storage Volume (Vreq) Calculation</Text>
                <View style={{ marginTop: 30 }} fixed>
                    <Image src={imageSource} />
                </View>
                <ReportFooter />
            </Page>
        </>
    );
}
