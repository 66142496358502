import React from 'react';
import { View, Page, StyleSheet, Text } from '@react-pdf/renderer';
import ReportFooter from './ReportFooter';
import ReportHeader from './ReportHeader';
import OsdSpecificationAndTypicalDrawing from './OsdSpecificationAndTypicalDrawing';

const styles = StyleSheet.create({
    body: {
        paddingTop: 30,
        paddingBottom: 10,
        paddingHorizontal: 35,
        marginBottom: 10,
    },
    mainTitle: {
        fontSize: 18,
        fontweight: 700,
        textAlign: 'center',
        marginTop: 5,
        marginBottom: 20,
    },
    title1: {
        fontSize: 15,
        paddingInline: 50,
    },
});

const SpecImagePages = ({ osdCompany, manufactureImageLinks, manufactureDocuments, reportHeaderUrl }) => {
    return (
        <>
            {manufactureImageLinks && manufactureImageLinks.length && (
                <Page style={styles.body} size="A4">
                    {manufactureImageLinks?.map((imageLink, index) => (
                        <View style={{ height: '100%' }} key={'imagePage' + index}>
                            <View>
                                <ReportHeader reportHeaderUrl={reportHeaderUrl} />
                            </View>
                            <View>
                                {index === 0 && (
                                    <View>
                                        <Text style={styles.mainTitle}>
                                            OSD Specifications and Typical Drawings - {osdCompany}
                                        </Text>
                                    </View>
                                )}
                                <OsdSpecificationAndTypicalDrawing
                                    imageLink={imageLink}
                                    manufactureDocuments={manufactureDocuments}
                                />
                            </View>
                        </View>
                    ))}
                    <ReportFooter />
                </Page>
            )}
        </>
    );
};

export default SpecImagePages;
