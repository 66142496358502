import {
    getManufactureDeviceDocumentLinks,
    getReportTemplateByManufactureDevice,
} from '../../rdsCmsUtils';
import { getFileFromS3Bucket } from '../../s3Utils';

export const rainwaterTankCols = ['Surface Area', 'Height', 'Daily Demand', 'Irrigation Demand'];

export function sourceNodeValueExtract(rows) {
    let baseFlowTssMean,
        baseFlowTssStdDev,
        baseFlowTssMethod,
        baseFlowTpMean,
        baseFlowTpStdDev,
        baseFlowTpMethod,
        baseFlowTnMean,
        baseFlowTnStdDev,
        baseFlowTnMethod,
        stormFlowTssMean,
        stormFlowTssStdDev,
        stormFlowTssMethod,
        stormFlowTpMean,
        stormFlowTpStdDev,
        stormFlowTpMethod,
        stormFlowTnMean,
        stormFlowTnStdDev,
        stormFlowTnMethod,
        soilStorageCap,
        fieldCap,
        baseFlowTssSerialCorrelation,
        stormFlowTssSerialCorrelation,
        baseFlowTpSerialCorrelation,
        stormFlowTpSerialCorrelation,
        baseFlowTnSerialCorrelation,
        stormFlowTnSerialCorrelation,
        rainfallThreshold,
        initialStorage,
        infiltCapCoeff,
        infiltCapExpo,
        initialDepth,
        dailyRechargeRate,
        dailyBaseFlowRate,
        dailyDeepSeepageRate,
        imperviousness;

    rows.forEach((obj) => {
        if (obj.rowName.includes('Total Suspended Solids - Base Flow Concentration - Mean')) {
            baseFlowTssMean = obj.rowValue;
        } else if (
            obj.rowName.includes('Total Suspended Solids - Base Flow Concentration - Std Dev')
        ) {
            baseFlowTssStdDev = obj.rowValue;
        } else if (
            obj.rowName.includes('Total Suspended Solids - Base Flow Concentration - Estimation')
        ) {
            baseFlowTssMethod = obj.rowValue;
        } else if (obj.rowName.includes('Total Phosphorus - Base Flow Concentration - Mean')) {
            baseFlowTpMean = obj.rowValue;
        } else if (obj.rowName.includes('Total Phosphorus - Base Flow Concentration - Std Dev')) {
            baseFlowTpStdDev = obj.rowValue;
        } else if (
            obj.rowName.includes('Total Phosphorus - Base Flow Concentration - Estimation')
        ) {
            baseFlowTpMethod = obj.rowValue;
        } else if (obj.rowName.includes('Total Nitrogen - Base Flow Concentration - Mean')) {
            baseFlowTnMean = obj.rowValue;
        } else if (obj.rowName.includes('Total Nitrogen - Base Flow Concentration - Std Dev')) {
            baseFlowTnStdDev = obj.rowValue;
        } else if (obj.rowName.includes('Total Nitrogen - Base Flow Concentration - Estimation')) {
            baseFlowTnMethod = obj.rowValue;
        } else if (
            obj.rowName.includes('Total Suspended Solids - Storm Flow Concentration - Mean')
        ) {
            stormFlowTssMean = obj.rowValue;
        } else if (
            obj.rowName.includes('Total Suspended Solids - Storm Flow Concentration - Std Dev')
        ) {
            stormFlowTssStdDev = obj.rowValue;
        } else if (
            obj.rowName.includes('Total Suspended Solids - Storm Flow Concentration - Estimation')
        ) {
            stormFlowTssMethod = obj.rowValue;
        } else if (obj.rowName.includes('Total Phosphorus - Storm Flow Concentration - Mean')) {
            stormFlowTpMean = obj.rowValue;
        } else if (obj.rowName.includes('Total Phosphorus - Storm Flow Concentration - Std Dev')) {
            stormFlowTpStdDev = obj.rowValue;
        } else if (
            obj.rowName.includes('Total Phosphorus - Storm Flow Concentration - Estimation')
        ) {
            stormFlowTpMethod = obj.rowValue;
        } else if (obj.rowName.includes('Total Nitrogen - Storm Flow Concentration - Mean')) {
            stormFlowTnMean = obj.rowValue;
        } else if (obj.rowName.includes('Total Nitrogen - Storm Flow Concentration - Std Dev')) {
            stormFlowTnStdDev = obj.rowValue;
        } else if (obj.rowName.includes('Total Nitrogen - Storm Flow Concentration - Estimation')) {
            stormFlowTnMethod = obj.rowValue;
        } else if (
            obj.rowName.includes('Rainfall-Runoff - Pervious Area - Soil Storage Capacity')
        ) {
            soilStorageCap = obj.rowValue;
        } else if (obj.rowName.includes('Rainfall-Runoff - Pervious Area - Field Capacity (mm)')) {
            fieldCap = obj.rowValue;
        } else if (
            obj.rowName.includes('Total Suspended Solids - Base Flow Concentration - Serial Corre')
        ) {
            baseFlowTssSerialCorrelation = obj.rowValue;
        } else if (
            obj.rowName.includes('Total Suspended Solids - Storm Flow Concentration - Serial Corr')
        ) {
            stormFlowTssSerialCorrelation = obj.rowValue;
        } else if (
            obj.rowName.includes('Total Phosphorus - Base Flow Concentration - Serial Correlation')
        ) {
            baseFlowTpSerialCorrelation = obj.rowValue;
        } else if (
            obj.rowName.includes('Total Phosphorus - Storm Flow Concentration - Serial Correlatio')
        ) {
            stormFlowTpSerialCorrelation = obj.rowValue;
        } else if (
            obj.rowName.includes('Total Nitrogen - Base Flow Concentration - Serial Correlation')
        ) {
            baseFlowTnSerialCorrelation = obj.rowValue;
        } else if (
            obj.rowName.includes('Total Nitrogen - Storm Flow Concentration - Serial Correlation')
        ) {
            stormFlowTnSerialCorrelation = obj.rowValue;
        } else if (obj.rowName.includes('Rainfall-Runoff - Impervious Area - Rainfall Threshold')) {
            rainfallThreshold = obj.rowValue;
        } else if (obj.rowName.includes('Rainfall-Runoff - Pervious Area - Initial Storage')) {
            initialStorage = obj.rowValue;
        } else if (
            obj.rowName.includes('Rainfall-Runoff - Pervious Area - Infiltration Capacity Coeffic')
        ) {
            infiltCapCoeff = obj.rowValue;
        } else if (
            obj.rowName.includes('Rainfall-Runoff - Pervious Area - Infiltration Capacity Exponen')
        ) {
            infiltCapExpo = obj.rowValue;
        } else if (
            obj.rowName.includes('Rainfall-Runoff - Groundwater Properties - Initial Depth')
        ) {
            initialDepth = obj.rowValue;
        } else if (
            obj.rowName.includes('Rainfall-Runoff - Groundwater Properties - Daily Recharge Rate')
        ) {
            dailyRechargeRate = obj.rowValue;
        } else if (
            obj.rowName.includes('Rainfall-Runoff - Groundwater Properties - Daily Baseflow Rate')
        ) {
            dailyBaseFlowRate = obj.rowValue;
        } else if (
            obj.rowName.includes('Rainfall-Runoff - Groundwater Properties - Daily Deep Seepage')
        ) {
            dailyDeepSeepageRate = obj.rowValue;
        } else if (obj.rowName.includes('Areas - Impervious (%)')) {
            imperviousness = obj.rowValue;
        }
    });

    return {
        baseFlowTssMean,
        baseFlowTssStdDev,
        baseFlowTssMethod,
        baseFlowTpMean,
        baseFlowTpStdDev,
        baseFlowTpMethod,
        baseFlowTnMean,
        baseFlowTnStdDev,
        baseFlowTnMethod,
        stormFlowTssMean,
        stormFlowTssStdDev,
        stormFlowTssMethod,
        stormFlowTpMean,
        stormFlowTpStdDev,
        stormFlowTpMethod,
        stormFlowTnMean,
        stormFlowTnStdDev,
        stormFlowTnMethod,
        soilStorageCap,
        fieldCap,
        baseFlowTssSerialCorrelation,
        stormFlowTssSerialCorrelation,
        baseFlowTpSerialCorrelation,
        stormFlowTpSerialCorrelation,
        baseFlowTnSerialCorrelation,
        stormFlowTnSerialCorrelation,
        rainfallThreshold,
        initialStorage,
        infiltCapCoeff,
        infiltCapExpo,
        initialDepth,
        dailyRechargeRate,
        dailyBaseFlowRate,
        dailyDeepSeepageRate,
        imperviousness,
    };
}
export function gptGenericNodeValueExtract(rows) {
    const result = {};

    Object.keys(rows).forEach((key) => {
        if (!rows[key].rowName.includes('Enabled')) {
            const rowNameParts = rows[key].rowName.split(' ');
            const objectName = rowNameParts[0];
            if (!result[objectName]) {
                result[objectName] = [];
            }
            if (rows[key].rowName.includes('Input')) {
                result[objectName].push({ input: rows[key].rowName, value: rows[key].rowValue });
            } else if (rows[key].rowName.includes('Output')) {
                result[objectName].push({ output: rows[key].rowName, value: rows[key].rowValue });
            }
        }
    });

    return result;
}

export const groupByNodeTypeAndMatchName = (nodes) => {
    return nodes.reduce((acc, node) => {
        const { nodeType, compliantTemplates } = node;
        if (compliantTemplates.length === 1) {
            const matchName = compliantTemplates[0].match_name;
            acc[matchName] = acc[matchName] || [];
            acc[matchName].push(node);
        } else {
            acc[nodeType] = acc[nodeType] || [];
            acc[nodeType].push(node);
        }

        return acc;
    }, {});
};

export const getManufactureDeviceImages = async (data) => {
    const uniqueData = [...new Set(data)];
    let imagesBuf = {};
    const promises = uniqueData.map((deviceType) => {
        if (deviceType !== 'none') {
            return getFileFromS3Bucket(
                'manufacture-device-images',
                `${deviceType.toLowerCase()}.png`,
            )
                .then((res) => {
                    imagesBuf = { ...imagesBuf, [deviceType]: res };
                })
                .catch((err) => {
                    console.log(err);
                });
        } else return null;
    });

    await Promise.all(promises);

    const fetchData = async (imageUrl) => {
        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            const reader = new FileReader();
            return new Promise((resolve, reject) => {
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.error('Error loading image:', error);
        }
    };

    let fetchImages = {};
    for (const key in imagesBuf) {
        fetchImages[key] = await fetchData(imagesBuf[key]);
    }

    return imagesBuf;
};
export const getDocumentLinks = async (data) => {
    let fetchedLinks = {};
    const promises = data.map((node) => {
        if (
            node.compliantTemplates.length === 1 &&
            node.compliantTemplates[0].match_name.toLowerCase() !== 'none'
        ) {
            return getManufactureDeviceDocumentLinks(
                node.compliantTemplates[0].match_name.toLowerCase(),
            )
                .then((res) => {
                    fetchedLinks = {
                        ...fetchedLinks,
                        [node.compliantTemplates[0].match_name]: res,
                    };
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            return Promise.resolve();
        }
    });

    await Promise.all(promises);
    return fetchedLinks;
};

export const getReportTemplates = async (data) => {
    let fetchedReportTemplates = {};
    const promises = [];

    Object.keys(data).forEach((key) => {
        data[key].forEach((node) => {
            if (node.nodeType !== 'none') {
                if (node.compliantTemplates.length === 1) {
                    let templateKey = 'not applicable';
                    if (node.nodeType) {
                        templateKey = matchNames(node.nodeType).toLowerCase();
                    }
                    promises.push(
                        getReportTemplateByManufactureDevice(templateKey)
                            .then((res) => {
                                if (res && res.length > 0 && res[0]['template_name']) {
                                    fetchedReportTemplates = {
                                        ...fetchedReportTemplates,
                                        [node.compliantTemplates[0].match_name]:
                                            res[0]['template_name'],
                                    };
                                } else {
                                    fetchedReportTemplates = {
                                        ...fetchedReportTemplates,
                                        [node.compliantTemplates[0].match_name]: node.nodeName,
                                    };
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                                fetchedReportTemplates = {
                                    ...fetchedReportTemplates,
                                    [node.nodeName]: node.nodeName,
                                };
                            }),
                    );
                } else {
                    const templateKey = matchNames(node.nodeType).toLowerCase();
                    promises.push(
                        getReportTemplateByManufactureDevice(templateKey)
                            .then((res) => {
                                if (res && res.length > 0 && res[0]['template_name']) {
                                    fetchedReportTemplates = {
                                        ...fetchedReportTemplates,
                                        [matchNames(node.nodeType)]: res[0]['template_name'],
                                    };
                                } else {
                                    fetchedReportTemplates = {
                                        ...fetchedReportTemplates,
                                        [matchNames(node.nodeType)]: node.nodeName,
                                    };
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                                fetchedReportTemplates = {
                                    ...fetchedReportTemplates,
                                    [node.nodeName]: node.nodeName,
                                };
                            }),
                    );
                }
            }
        });
    });

    await Promise.all(promises);

    return fetchedReportTemplates;
};

export const parseMSF = (msfContent) => {
    const nodes = [];
    const lines = msfContent.split('\n');
    let currentNode = null;
    let foundFirstNodeType = false;

    for (let line of lines) {
        line = line.trim();
        if (line === '') {
            continue;
        }
        if (!foundFirstNodeType && line.startsWith('Node Type,')) {
            foundFirstNodeType = true;
        }

        if (foundFirstNodeType) {
            if (line.startsWith('Node Type,')) {
                const parts = line.split(',');
                const nodeType = parts.length > 1 ? parts[1].trim() : '';

                if (currentNode !== null && currentNode.name !== null) {
                    nodes.push(currentNode);
                }

                if (nodeType !== 'UrbanSourceNode') {
                    currentNode = {
                        name: null,
                        data: {},
                    };
                } else {
                    currentNode = null; // Skip creating a new node for UrbanSourceNode
                }
            } else if (currentNode !== null) {
                const parts = line.split(',');
                if (parts.length > 1) {
                    const key = parts[0].trim();
                    const value = parts[1].trim();
                    if (key === 'Node Name') {
                        currentNode.name = value;
                    }
                    currentNode.data[key] = value;
                }
            }
        }
    }

    if (currentNode !== null && currentNode.name !== null) {
        nodes.push(currentNode); // Ensure the last node is added if it wasn't already
    }

    return nodes;
};

export const extractMSFData = (nodes) => {
    const extractedData = {};
    nodes.forEach((node) => {
        const nodeName = node.data['Node Name'];
        extractedData[nodeName] = {
            name: nodeName,
        };
    });
    return extractedData;
};
const nodeTypeToTemplateMap = {
    WetlandNode: 'wetland',
    PondNode: 'pond',
    SedimentationBasinNode: 'sedbasin',
    DetentionBasinNode: 'detention basin',
    InfiltrationSystemNodeV4: 'not in auditor yet',
    BioRetentionNodeV4: 'bioretention',
    MediaFiltrationNode: 'permeable pavement',
    BufferNode: 'buffer',
    SwaleNode: 'swale',
    RainWaterTankNode: 'rainwater tank typical',
    GPTNode: 'Not applicable',
    GenericNode: '',
    GPTNodeNew: '',
};
export const matchNames = (nodeType) => {
    return nodeTypeToTemplateMap[nodeType] || nodeType;
};
export const transformKeys = (nodes) => {
    const transformedNodes = {};

    for (const key in nodes) {
        if (nodes.hasOwnProperty(key)) {
            const newKey = matchNames(key);
            transformedNodes[newKey] = nodes[key];
        }
    }

    return transformedNodes;
};
export const addTreatmentData = (treatmentNodes, treatments) => {
    return treatmentNodes.map((node) => {
        const matchingTreatment = treatments.find((treatment) => treatment.name === node.nodeName);
        if (matchingTreatment) {
            return { ...node, treatmentData: matchingTreatment.data };
        }
        return node;
    });
};
export function extractTteResultsForReceivingNodes(data, receivingNodes) {
    const receivingNodeIds = receivingNodes.map((node) => node.data['Node ID']);
    let tteResults = {};
    receivingNodeIds.forEach((nodeId) => {
        if (data.TteResults[nodeId]) {
            tteResults[nodeId] = data.TteResults[nodeId];
            tteResults = tteResults[nodeId].results;
        }
    });

    return tteResults;
}

// TODO: Might need to consider NORBE
export function extractReceivingNodes(data) {
    return data.filter(
        (treatment) =>
            treatment.data['General - Location'] === 'Receiving Node' ||
            treatment.data['General - Location'] === 'LPOD',
    );
}

export const stringOrNumber = (value, decimalPlace = 3) => {
    if (isNaN(+value)) return value;
    else return (+value).toFixed(decimalPlace);
};

export const stringOrNumberWithSignificantVals = (value, significantFigures = 3) => {
    if (isNaN(+value)) return value;

    const numericValue = +value;
    const absoluteValue = Math.abs(numericValue);

    if (absoluteValue === 0) {
        return '0';
    }

    const decimalPartLength = value.toString().split('.')[1]?.length || 0;

    const multiplier = Math.pow(10, decimalPartLength + significantFigures);

    const roundedValue = Math.round(numericValue * multiplier) / multiplier;

    return roundedValue.toString();
};
