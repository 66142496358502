import React, { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getTreatmentTypes } from '../../../../utils/treatmentTypes';
import { selectSiteDetails } from '../../../../utils/redux/WSUD/siteDetailsSlice';
import ContextProvider from '../../../../utils/contextProvider';

const TreatmentTypeDropdown = ({ index, currValue, areaType, handleInputChange }) => {
    const { type, rainfallStation, catchmentType, state } = useContext(ContextProvider);
    const siteDetails = useSelector(selectSiteDetails);

    const { data, isLoading, isError } = useQuery(
        [
            'treatmentTypes',
            areaType,
            rainfallStation,
            catchmentType,
            state,
            siteDetails.treatmentDevice,
            type,
        ],
        () =>
            getTreatmentTypes(
                areaType,
                rainfallStation,
                catchmentType,
                siteDetails.treatmentDevice,
                type === 'pervious',
            ),
        { staleTime: 60000 }, // keeps caches version for 60 seconds
    );

    useEffect(() => {
        // if current treatmentType is not in the new list, set it to None
        // this also handles the case where updating Treatment Device
        // updates the treatmentTypes
        if (
            data &&
            currValue !== 'None' &&
            !data?.treatmentTypes?.find((treatment) => treatment.tnodename === currValue)
        ) {
            handleInputChange('treatmentType', 'None', {
                updateResult: true,
                newApiBaseRoute: 'getNoTreatmentResults',
            });
        }
    }, [data?.treatmentTypes]);
    return (
        <select
            id={`treatmentType-${index}`}
            value={currValue}
            onChange={(e) =>
                handleInputChange('treatmentType', e.target.value, {
                    updateResult: true,
                    newApiBaseRoute: data.treatmentTypes.find(
                        (item) => item.tnodename === e.target.value,
                    )?.endpoint,
                    updateBounds: true,
                })
            }
            key={'treatmentType' + index}
            disabled={!areaType || areaType.trim() === ''}
        >
            {!isLoading &&
                !isError &&
                Object.keys(data.mappedTreatmentTypes).map((type, i) => {
                    if (data.mappedTreatmentTypes[type].length > 0) {
                        return (
                            <optgroup label={type} key={`treatmentType-optgroup-${i}`}>
                                {data.mappedTreatmentTypes[type].map((subType, j) => (
                                    <option
                                        key={`treatmentType-suboption-${j}`}
                                        value={type + '' + subType}
                                    >
                                        {type + '' + subType}
                                    </option>
                                ))}
                            </optgroup>
                        );
                    }
                    return (
                        <option key={`treatmentType-option-${i}`} value={type}>
                            {type}
                        </option>
                    );
                })}
        </select>
    );
};

export default TreatmentTypeDropdown;
