// utils
import { flask_api } from "./globalConstant";
import { getFileFromS3Bucket } from "./s3Utils";

export const getTreatmentTrainsImage = async (msf) => {
    let msfDataPassable = msf.replace(/&/gi, "and");
    let uploadSuccess = false;
    const formData = new FormData();
    formData.append('msf', msfDataPassable);
    await flask_api
        .post("/getTreatmentTrainsImage", formData)
        .then(() => {
            uploadSuccess = true;
        })
        .catch((err) => {
            console.log(
                "Error happended when getting treatment trains image.",
                err
            );
        });

    let url = "";
    if (uploadSuccess) {
        url = await getFileFromS3Bucket("treatment-trains-image", "image.png");
    }
    return url;
};
