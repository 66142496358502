import React from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';
import DefaultTemplate from './default-template/DefaultTemplate';
import OsdDeviceTemplate from './osd-device-template/OsdDeviceTemplate';
import WetlandTemplate from './wetland-template/WetlandTemplate';
import BioretentionTemplate from './bioretention-template/BioretentionTemplate';
import SedbasinTemplate from './sedbasin-template/SedbasinTemplate';
import RainwaterTankTemplate from './rainwater-tank-template/RainwaterTankTemplate';
import PermeablePavementTemplate from './permeable-pavement/PermeablePavementTemplate';
import RooftopRainGardenTemplate from './rooftop-rain-garden-template/RooftopRainGardenTemplate';
import TreePitTemplate from './tree-pit-template/TreePitTemplate';
import SwaleTemplate from './swale-template/SwaleTemplate';
import ManufacturedRainwaterTankTemplate from './manufactured-rainwater-tank-template/ManufacturedRainwaterTankTemplate';
import KerbspaceTemplate from './kerbspace-template/KerbspaceTemplate';
import { matchNames } from '../auditorUtils';
function SpecificationsAndTypicalDrawings({
    nodeList,
    manufactureDeviceImages,
    manufactureDeviceDocumentLinks,
    reportTemplate,
}) {
    const styles = StyleSheet.create({
        section: {
            marginBottom: 100,
            padding: 10,
        },
    });
    const transformNodes = (nodes) => {
        const transformedNodes = {};

        for (const key in nodes) {
            if (nodes.hasOwnProperty(key)) {
                const newKey = matchNames(key);

                if (!transformedNodes[newKey]) {
                    transformedNodes[newKey] = [];
                }

                nodes[key].forEach((node) => {
                    transformedNodes[newKey].push(node.treatmentData);
                });
            }
        }

        return transformedNodes;
    };

    nodeList = transformNodes(nodeList);
    return (
        <>
            {Object.keys(nodeList).map((item) => {
                if (
                    item !== 'receivingnode' &&
                    item !== 'urbansourcenode' &&
                    item !== 'junction' &&
                    item !== 'development node' &&
                    item !== 'Not applicable'
                ) {
                    if (reportTemplate[item]) {
                        if (reportTemplate[item].includes('bioretention template')) {
                            return (
                                <React.Fragment key={item}>
                                    <View style={styles.section} break>
                                        <BioretentionTemplate
                                            nodeInfo={nodeList[item]}
                                            image={manufactureDeviceImages[item]}
                                            title={item}
                                            documentLinks={manufactureDeviceDocumentLinks[item]}
                                        />
                                    </View>
                                </React.Fragment>
                            );
                        } else if (reportTemplate[item].includes('wetland template')) {
                            return (
                                <React.Fragment key={item}>
                                    <View style={styles.section} break>
                                        <WetlandTemplate
                                            nodeInfo={nodeList[item]}
                                            image={manufactureDeviceImages[item]}
                                            title={item}
                                            documentLinks={
                                                manufactureDeviceDocumentLinks[item.toLowerCase()]
                                            }
                                        />
                                    </View>
                                </React.Fragment>
                            );
                        } else if (reportTemplate[item].includes('osd device template')) {
                            return (
                                <React.Fragment key={item}>
                                    <View style={styles.section} break>
                                        <OsdDeviceTemplate
                                            nodeInfo={nodeList[item]}
                                            image={manufactureDeviceImages[item]}
                                            title={item}
                                            documentLinks={manufactureDeviceDocumentLinks[item]}
                                        />
                                    </View>
                                </React.Fragment>
                            );
                        } else if (reportTemplate[item].includes('sedbasin template')) {
                            return (
                                <React.Fragment key={item}>
                                    <View style={styles.section} break>
                                        <SedbasinTemplate
                                            nodeInfo={nodeList[item]}
                                            image={manufactureDeviceImages[item]}
                                            title={item}
                                            documentLinks={manufactureDeviceDocumentLinks[item]}
                                        />
                                    </View>
                                </React.Fragment>
                            );
                        } else if (
                            reportTemplate[item].includes('manufactured rainwater tank template')
                        ) {
                            return (
                                <React.Fragment key={item}>
                                    <View style={styles.section} break>
                                        <ManufacturedRainwaterTankTemplate
                                            nodeInfo={nodeList[item]}
                                            image={manufactureDeviceImages[item]}
                                            title={item}
                                            documentLinks={manufactureDeviceDocumentLinks[item]}
                                        />
                                    </View>
                                </React.Fragment>
                            );
                        } else if (reportTemplate[item].includes('rainwater tank template')) {
                            return (
                                <React.Fragment key={item}>
                                    <View style={styles.section} break>
                                        <RainwaterTankTemplate
                                            nodeInfo={nodeList[item]}
                                            image={manufactureDeviceImages[item]}
                                            title={item}
                                            documentLinks={manufactureDeviceDocumentLinks[item]}
                                        />
                                    </View>
                                </React.Fragment>
                            );
                        } else if (reportTemplate[item].includes('kerbspace template')) {
                            return (
                                <React.Fragment key={item}>
                                    <View style={styles.section} break>
                                        <KerbspaceTemplate
                                            nodeInfo={nodeList[item]}
                                            image={manufactureDeviceImages[item]}
                                            title={item}
                                            documentLinks={manufactureDeviceDocumentLinks[item]}
                                        />
                                    </View>
                                </React.Fragment>
                            );
                        } else if (reportTemplate[item].includes('permeable pavement template')) {
                            return (
                                <React.Fragment key={item}>
                                    <View style={styles.section} break>
                                        <PermeablePavementTemplate
                                            nodeInfo={nodeList[item]}
                                            image={manufactureDeviceImages[item]}
                                            title={item}
                                            documentLinks={manufactureDeviceDocumentLinks[item]}
                                        />
                                    </View>
                                </React.Fragment>
                            );
                        } else if (reportTemplate[item].includes('rooftop rain garden template')) {
                            return (
                                <React.Fragment key={item}>
                                    <View style={styles.section} break>
                                        <RooftopRainGardenTemplate
                                            nodeInfo={nodeList[item]}
                                            image={manufactureDeviceImages[item]}
                                            title={item}
                                            documentLinks={manufactureDeviceDocumentLinks[item]}
                                        />
                                    </View>
                                </React.Fragment>
                            );
                        } else if (reportTemplate[item].includes('tree pit template')) {
                            return (
                                <React.Fragment key={item}>
                                    <View style={styles.section} break>
                                        <TreePitTemplate
                                            nodeInfo={nodeList[item]}
                                            image={manufactureDeviceImages[item]}
                                            title={item}
                                            documentLinks={manufactureDeviceDocumentLinks[item]}
                                        />
                                    </View>
                                </React.Fragment>
                            );
                        } else if (reportTemplate[item].includes('swale template')) {
                            return (
                                <React.Fragment key={item}>
                                    <View style={styles.section} break>
                                        <SwaleTemplate
                                            nodeInfo={nodeList[item]}
                                            image={manufactureDeviceImages[item]}
                                            title={item}
                                            documentLinks={manufactureDeviceDocumentLinks[item]}
                                        />
                                    </View>
                                </React.Fragment>
                            );
                        } else if (reportTemplate[item].includes('default template')) {
                            return (
                                <View style={styles.section} break key={item}>
                                    <DefaultTemplate
                                        nodeInfo={nodeList[item]}
                                        image={
                                            manufactureDeviceImages[item]
                                                ? manufactureDeviceImages[item]
                                                : ''
                                        }
                                        title={item}
                                        documentLinks={manufactureDeviceDocumentLinks[item]}
                                    />
                                </View>
                            );
                        }
                    }
                }
                return null;
            })}
        </>
    );
}

export default SpecificationsAndTypicalDrawings;
