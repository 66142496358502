import React, { useState } from 'react';
import { FaCalculator } from 'react-icons/fa';
import validator from 'validator';

// components
import FormRow from './FormRow';
import DevAreasFooter from './DevAreasFooter';
import runoffCoeffTooltipImage from '../../../assets/tooltip-images/IDM pop up runoff coefficient.png';

// utils
import { isFloat } from '../../../utils/common';
let timeoutId = null;

// options:
//  - alwaysShowTC: dont hide TC when PSD is entered
const PreDevAreas = ({
    preDevAreas,
    setPreDevAreas,
    TC,
    setTC,
    sumPostDevAreas,
    sumPreDevAreas,
    setShowTCCalculator,
    calculatedCy,
    options = { alwaysShowTC: false, hideImperviousness: false, hideCy: false },
}) => {
    const [showTooltipError, setShowTooltipError] = useState({});

    const handleTCInputChange = (e, _, numberCheck = false) => {
        let { name, value } = e.target;

        // Clear any existing timeout
        if (timeoutId !== null) {
            clearTimeout(timeoutId);
            setShowTooltipError({});
        }

        if (numberCheck && !isFloat(e.target.value)) return;

        const newObject = { ...TC };
        newObject[name] = value;

        setTC(newObject);
    };

    const handleInputChange = async (e, index, numberCheck = false) => {
        let { name, value } = e.target;

        // Clear any existing timeout
        if (timeoutId !== null) {
            clearTimeout(timeoutId);
            setShowTooltipError({});
        }

        if (numberCheck && !isFloat(e.target.value)) return;

        // Clone
        const bufArray = [...preDevAreas];

        // Modify
        if (name === 'impervious' && value === 'custom') {
            bufArray[index][name] = null;
            bufArray[index].cy = 0;
        } else if (name === 'impervious' && value && calculatedCy) {
            bufArray[index][name] = JSON.parse(value);
            bufArray[index].cy =
                calculatedCy.cyPre[bufArray[index][name] ? 'impervious' : 'pervious'];
        } else if (
            // Accepts values 0 <= Cy <= 1
            name.startsWith('cy') &&
            value.length > 0 &&
            !validator.isFloat(value, { max: 1 })
        ) {
            setShowTooltipError({ ...showTooltipError, [`${name}-${index}`]: true });
            timeoutId = setTimeout(() => {
                setShowTooltipError({ ...showTooltipError, [`${name}-${index}`]: false });
            }, 4000);
            return;
        } else {
            bufArray[index][name] = value;
        }

        // Update
        setPreDevAreas(bufArray);
    };

    return (
        <div className="osd-form-section-container osd-blue-border">
            <div className="osd-form-section">
                <span className="title-2 span-7">Pre-Development Areas</span>
            </div>
            <FormRow
                formRow={[TC]}
                columns={[
                    {
                        id: 'psd',
                        label: 'Input PSD (L/s)',
                        numberCheck: true,
                        placeholder: 'Optional',
                        labelToolTip: {
                            content:
                                'Permissible Site Discharge (PSD) refers to the maximum flow rate which the site can discharge to receiving waters or council drain. Leave empty unless Authority provided this value or you calculated it in a different way.',
                        },
                    },
                    null,
                    TC.pre !== undefined && !(TC.psd && !options.alwaysShowTC)
                        ? {
                              id: 'pre',
                              label: 'TC Pre (m²)',
                              numberCheck: true,
                              labelToolTip: {
                                  content:
                                      'Pre-development Time of Concentration (TC Pre) is determined based on longest flow path from top to bottom of catchment. TC Pre is calculated using QUDM methods (blue button on the right) or directly specified in this field. Time of Concentration has a minimum of 5 minutes as recommended by QUDM. Refer to QUDM version 4 page 66, click ? below.',
                                  link: 'https://ipweaq.intersearch.com.au/ipweaqjspui/bitstream/1/4983/1/2042%20QUDM%20FINAL%2018%20August%202017%20%282%29.pdf',
                              },
                          }
                        : null,
                    TC.pre !== undefined && !(TC.psd && !options.alwaysShowTC)
                        ? {
                              id: 'tcPreCalculator',
                              inputType: 'button',
                              icon: <FaCalculator />,
                              numberCheck: true,
                              onClick: () => setShowTCCalculator('pre'),
                              title: 'Calculate TC',
                          }
                        : null,
                ]}
                handleInputChange={handleTCInputChange}
            />
            {!TC.psd && (
                <>
                    <FormRow
                        formRow={preDevAreas}
                        columns={[
                            { id: 'areaName', label: 'Catchment Name', className: 'span-2' },
                            { id: 'surfaceArea', label: 'Area (m²)', numberCheck: true },
                            !options.hideImperviousness
                                ? {
                                      id: 'impervious',
                                      label: 'Imperviousness',
                                      inputType: 'dropdown',
                                      options: [
                                          { label: 'User Define', value: 'custom' },
                                          { label: 'Impervious', value: true },
                                          { label: 'Pervious', value: false },
                                      ],
                                      labelToolTip: {
                                          content: (
                                              <div>
                                                  <img
                                                      alt=""
                                                      src="https://csw-public.s3.ap-southeast-2.amazonaws.com/osd/boyds/C+-+Value+Pop+Up+Image.png"
                                                  />
                                                  <p>
                                                      Refers to the C-Value in Rational Method
                                                      (Q=CIA). Image shows some options to determine
                                                      C- Values based on established guidelines.
                                                  </p>
                                              </div>
                                          ),
                                          link: 'https://drive.google.com/file/d/12ytsxESvJYlP6d5xj-YZlO1gFnaNDRhl/view?usp=sharing',
                                          style: { maxWidth: '55rem' },
                                      },
                                  }
                                : undefined,
                            !options.hideImperviousness && calculatedCy
                                ? {
                                      id: 'cy',
                                      label: 'C-Value Pre',
                                      numberCheck: true,
                                      disabled: (row) => typeof row?.impervious === 'boolean',
                                      errorToolTip: {
                                          content: 'Value must be between 0 < Cy <= 1',
                                      },
                                  }
                                : undefined,
                            options.showRunoffCoefficient && {
                                id: 'runoffCoefficient',
                                label: 'Runoff Coeff',
                                numberCheck: true,
                                labelToolTip: {
                                    content: (
                                        <div>
                                            <img
                                                src={runoffCoeffTooltipImage}
                                                alt="Runoff Coefficient"
                                            />
                                        </div>
                                    ),
                                    link: 'https://www.designmanual.com.au/assets/files/documents/IDM/IDM_Version_5.4_.pdf',
                                    style: { width: '100%' },
                                },
                            },
                        ]}
                        showTooltipError={showTooltipError}
                        handleInputChange={handleInputChange}
                        onDelete={(index) => {
                            const bufArray = preDevAreas.filter((_, i) => i !== index);
                            setPreDevAreas(bufArray);
                        }}
                    />
                    <DevAreasFooter
                        onAdd={() => {
                            const newItem = {
                                id: preDevAreas[preDevAreas.length - 1].id + 1,
                                areaName: '',
                                surfaceArea: '',
                                impervious: true,
                            };
                            if (options.showRunoffCoefficient) {
                                newItem['runoffCoefficient'] = '';
                            }
                            const bufArray = [...preDevAreas, newItem];
                            setPreDevAreas(bufArray);
                        }}
                        type="Pre"
                        sum1={sumPreDevAreas}
                        sum2={sumPostDevAreas}
                    />
                </>
            )}
        </div>
    );
};

export default PreDevAreas;
