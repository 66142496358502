import axios from 'axios';
// utils
import { JCO_ONLINE_DEV_CMS_LAMBDA_API } from './globalConstant';

const SU_CALCULATOR_BACKEND = 'https://hsrj73fhkj.execute-api.ap-southeast-2.amazonaws.com';

export const fetchAllFilesFromBucket = async (bucketName) => {
    let itemsFromS3 = [];

    const formData = new FormData();
    const params = { bucket: bucketName };
    formData.append('params', JSON.stringify(params));
    formData.append('endpoint', 'getAllFilesFromS3');

    await axios.post(`${JCO_ONLINE_DEV_CMS_LAMBDA_API}/s3`, formData).then((res) => {
        itemsFromS3.push(res.data.results);
    });

    return itemsFromS3;
};

export const uploadFileToS3Bucket = async (fileName, bucket, uploadedFile) => {
    const formData = new FormData();
    const params = { bucket: bucket, key: fileName };
    formData.append('file', uploadedFile);
    formData.append('params', JSON.stringify(params));
    formData.append('endpoint', 'uploadToS3');

    return axios
        .post(`${JCO_ONLINE_DEV_CMS_LAMBDA_API}/s3`, formData)

        .catch((err) => {
            console.error('Error uploading file:', err);
        });
};

/**
 *
 * Getting file from AWS S3 by bucket name and file name.
 *
 * @param {string} bucket
 * @param {string} fileName
 * @returns
 */
export const getFileFromS3Bucket = async (bucket, fileName) => {
    const formData = new FormData();
    const params = { bucket: bucket, filename: fileName };
    formData.append('params', JSON.stringify(params));
    formData.append('endpoint', 'getFileFromS3');

    let url = '';
    await axios
        .post(`${JCO_ONLINE_DEV_CMS_LAMBDA_API}/s3`, formData)
        .then((res) => {
            url = res.data.results;
        })
        .catch((err) => {
            console.error('Error fetching the file:', err);
        });

    return url;
};
export const countClick = async (email, button_id) => {
    const USER_RELATED = 'https://xgr5xf78jh.execute-api.ap-southeast-2.amazonaws.com/user';
    const payload = {
        endpoint: 'countClick',
        params: { email, button_id },
    };

    let result;
    await axios
        .post(`${USER_RELATED}`, payload)
        .then((res) => {
            result = res.data.results;
        })
        .catch((err) => {
            console.error('Error fetching the file:', err);
        });

    return result;
};

export const recordUserLogs = async (project_id, tool, event, file_path) => {
    const payload = {
        endpoint: 'add_user_log',
        project_id,
        tool,
        event,
        file_path,
    };

    try {
        return fetch(`${SU_CALCULATOR_BACKEND}/record`, {
            method: 'POST',
            body: JSON.stringify(payload),
        });
    } catch (error) {
        console.log(error);
    }
};

export const recordCalculateOSD = async (data) => {
    const options = {
        method: 'POST',
        url: `${SU_CALCULATOR_BACKEND}/record`,
        headers: { 'content-type': 'application/json' },
        data: {
            endpoint: 'add_osd_calculation_record',
            name: data.name,
            email: data.email,
            coordinates: data.coordinates,
            catchment_area: data.catchment_area,
            council: data.council,
            address: data.address,
            storage_type: data.storage_type,
            tank_specified: data.tank_specified,
            psd: data.psd,
            volume: data.volume,
            height_above_orifice: data.height_above_orifice,
            orifice_diameter: data.orifice_diameter,
            website: 'SU-Calculator',
        },
    };

    console.log(options.data);
    try {
        await fetch(options.url, {
            method: 'POST',
            body: JSON.stringify(options.data),
        });
    } catch (error) {
        console.log(error);
    }
};
