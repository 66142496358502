import React from 'react';
import { Text, View, Image, StyleSheet, Link } from '@react-pdf/renderer';

/**
 * Renders EditorJS blocks using react-pdf components
 * @see {@link https://editorjs.io/saving-data/#output-data-format} EditorJS output
 * @see {@link https://github.com/editor-js/paragraph?tab=readme-ov-file#output-data} Paragraph output format
 * @see {@link https://github.com/editor-js/nested-list?tab=readme-ov-file#output-data} Nested list output format
 * @see {@link https://github.com/editor-js/image?tab=readme-ov-file#output-data} Image output format
 *
 * @example Input
 * const blocks = [
 *    {
 *        type: "paragraph",
 *        data: { text: "Hello world!" }
 *    },
 *    {
 *        type: "list",
 *        data: {
 *            style: "unordered"
 *            items: [ { content: "point 1", items: [] } ]
 *        }
 *    },
 *    {
 *        type: "image",
 *        data: {
 *            file: { url: "https://image-link.example" }
 *        }
 *    },
 * ]
 *
 * There are only 3 types of blocks:
 *  - Paragraph
 *  - List (ordered or unordered / can be nested)
 *  - Image
 *
 * @param {Object}   params - Component parameters.
 * @param {Object[]} params.blocks - EditorJS blocks.
 *
 * @returns Returns a Document component
 */
const PDFRenderDoc = ({ blocks }) => {
    const elements = blocks.map((block, id) => {
        switch (block.type) {
            case 'paragraph': {
                // Convert HTML entity spaces to spaces
                block.data.text = block.data.text.replace('&nbsp;', ' ');
                return (
                    <View key={id} style={styles.paragraph}>
                        {renderTextWithLinks(block.data.text.replace('&amp;', '&'))}
                    </View>
                );
            }
            case 'list': {
                return <NestedList key={id} style={block.data.style} items={block.data.items} />;
            }
            case 'image': {
                return (
                    <Image
                        key={id}
                        src={{
                            uri: block.data.file.url,
                            method: 'GET',
                            headers: { 'Cache-Control': 'no-cache' },
                            body: '',
                        }}
                        style={styles.image}
                    />
                );
            }
            default:
                return null;
        }
    });

    return <View style={styles.container}>{elements}</View>;
};

/**
 * Renders (ordered/unordered) list blocks using react-pdf components
 *
 * @param {Object}   params - Component parameters.
 * @param {string}   params.style - Style of the list (ordered/unordered).
 * @param {Object[]} params.items - List of items.
 * @param {string}   params.items[].content - Text to be displayed.
 * @param {Object[]} params.items[].items - List of nested items (if any)
 *
 * @returns Returns a View component with nested list items
 */
const NestedList = ({ style, items }) => {
    return (
        <View>
            {items.map((item, index) =>
                style === 'ordered' ? (
                    <View key={index} style={styles.orderedList}>
                        <Text>{item.content}</Text>
                        {item.items.length > 0 && <NestedList items={item.items} style={style} />}
                    </View>
                ) : (
                    <View key={index} style={styles.unorderedList}>
                        <Text>{item.content}</Text>
                        {item.items.length > 0 && <NestedList items={item.items} style={style} />}
                    </View>
                ),
            )}
        </View>
    );
};

/**
 * Renders text with links for react-pdf
 *
 * @param {string} text - Text that may contain <a> tags
 *
 * @returns {React.Element[]} Returns an array of Text and Link components
 */
const renderTextWithLinks = (text) => {
    const parts = text.split(/(<a href="[^"]+">[^<]+<\/a>)/g);

    return (
        <Text>
            {parts.map((part, index) => {
                const match = part.match(/<a href="([^"]+)">([^<]+)<\/a>/);

                if (match) {
                    return (
                        <Link key={index} src={match[1]} style={styles.link}>
                            {match[2]}
                        </Link>
                    );
                } else if (part.trim().length > 0) {
                    return (
                        <Text key={index} style={{ display: 'inline' }}>
                            {part}
                        </Text>
                    );
                } else {
                    return null;
                }
            })}
        </Text>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    paragraph: {
        marginBottom: 10,
        fontSize: 10,
    },
    orderedList: {
        marginLeft: 20,
        fontSize: 10,
    },
    unorderedList: {
        marginLeft: 20,
        fontSize: 10,
    },
    image: {
        width: '100%',
        marginBottom: 10,
    },
    link: {
        display: 'flex',
    },
});

export default PDFRenderDoc;
