import { useEffect } from 'react';

export default function useFavicon(href) {
    useEffect(() => {
        // Find existing favicon element
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');

        // Update link tag attributes
        link.type = 'image/x-icon';
        link.rel = 'icon';
        link.href = href;

        // Add link to head if it doesn't exist
        if (!document.querySelector("link[rel*='icon']")) {
            document.getElementsByTagName('head')[0].appendChild(link);
        }
    }, [href]);
}
