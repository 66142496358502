import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Page, Image, Text } from '@react-pdf/renderer';
// components
import Table from '../Table';
import ReportFooter from '../ReportFooter';
import ReportHeader from '../ReportHeader';
import TreatmentTrainEffectivenessResult from './results/treatment-trains-effectiveness.js';
import { sourceNodeValueExtract, gptGenericNodeValueExtract } from './auditorUtils.js';
import SourceFlowNodeTable from './sourceNodeParametersTable.jsx';
import GPTCaptureTable from './GPTCapture.jsx';
import TreatmentTrainsImage from '../TreatmentTrainsImage.jsx';
import SpecificationsAndTypicalDrawings from './specifications-and-typical-drawings/SpecificationsAndTypicalDrawings.jsx';
//import AppendixTable from './Appendix.jsx';
import AppendixComponent from './AppendixComponent.jsx';
/**
 * Auditor Report
 *
 * @param {string} reportHeaderUrl
 * @param {Array} compliantNodes
 * @param {Array} nonCompliantNodes
 * @param {string} treatmentTrainsImageUrl
 * @returns
 */
export default function AuditorReportTemplate({
    reportHeaderUrl,
    compliantNodes,
    nonCompliantNodes,
    treatmentTrainsImageUrl,
    manufactureDeviceImages,
    treatmentData,
    treatmentTemplates,
    manufacturerDocumentLinks,
    nodesNotExistingInDB,
    tteResultsForReceivingNodes,
}) {
    let extractedValues = [];
    let gptValues = [];
    let CSources = [];
    let CTreatments = [];
    let NCSources = [];
    let NCTreatments = [];

    compliantNodes.forEach((node) => {
        node.compliantTemplates.forEach((temp) => {
            if (temp.table === 'UrbanSourceNode') {
                const values = sourceNodeValueExtract(node.rows);
                let compliantNodes = [];
                compliantNodes.push(temp.match_name);
                extractedValues.push({
                    match_name: compliantNodes,
                    values: values,
                    name: node.nodeName,
                });
            }
        });
    });
    compliantNodes.forEach((node) => {
        let firstGptNodeProcessed = false;

        node.compliantTemplates.forEach((temp) => {
            if (firstGptNodeProcessed) return;

            if (temp.table === 'GPTNode' || temp.table === 'GenericTreatmentNode') {
                const values = gptGenericNodeValueExtract(node.rows);
                const nodeName = node.nodeName;
                const matchName = temp.match_name;

                gptValues.push({
                    match_name: matchName,
                    values: values,
                    name: nodeName,
                });

                firstGptNodeProcessed = true;
            }
        });
    });

    const generateTableData = (nodes, type) => {
        const defaultStyles = {
            borderStyle: '1px solid #658cbb',
            textAlignment: 'center',
            headerBorder: true,
            fontSize: 9,
            headerFontSize: 9,
            headerTextAlign: 'center',
            marginBottom: 0,
        };

        const createStyles = (newStyles = defaultStyles) => {
            return {
                section: {
                    marginTop: 10,
                    flexGrow: 1,
                },
                table: {
                    display: 'table',
                    width: '100%',
                    border: newStyles.borderStyle,
                    borderBottom: 0,
                    borderRight: 0,
                    borderLeft: 0,
                    borderTop: newStyles.headerBorder ? newStyles.borderStyle : 0,
                    marginBottom: newStyles.marginBottom,
                },

                tableRow: {
                    width: '100%',
                    flexDirection: 'row',
                    borderBottom: newStyles.borderStyle,
                },

                headerCell: {
                    fontSize: newStyles.headerFontSize,
                    textAlign: newStyles.headerTextAlign,
                    borderRight: newStyles.headerBorder ? newStyles.borderStyle : 0,
                    borderLeft: newStyles.headerBorder ? newStyles.borderStyle : 0,
                    height: '100%',
                    padding: 5,
                },

                tableCell: {
                    fontSize: newStyles.fontSize,
                    textAlign: newStyles.textAlignment,
                    borderRight: newStyles.borderStyle,
                    borderLeft: newStyles.borderStyle,
                    height: '100%',
                    padding: 5,
                },
            };
        };

        const sourceNodes = nodes.filter((node) =>
            node.compliantTemplates.some((template) => template.table === 'UrbanSourceNode'),
        );
        CSources = sourceNodes;

        const nonSourceNodes = nodes.filter((node) =>
            node.compliantTemplates.every((template) => template.table !== 'UrbanSourceNode'),
        );
        CTreatments = nonSourceNodes;

        const formatNodeDataSource = (nodes) =>
            nodes.map((node) => [
                node.nodeName,
                node.rows
                    .filter((row) => row.rowName === 'Zoning Surface Type')
                    .map((row) => row.rowValue)
                    .join('\n'),
                node.rows
                    .filter((row) => row.rowName === 'Areas - Total Area (ha)')
                    .map((row) => (row.rowValue * 10000).toFixed(3))
                    .join('\n'),
                node.rows
                    .filter((row) => row.rowName === 'Areas - Impervious (%)')
                    .map((row) => parseFloat(row.rowValue).toFixed(3))
                    .join('\n'),
                node.compliantTemplates.map((template) => template.match_name).join(', '),
            ]);
        //const lpodId
        const formatNodeData = (nodes) =>
            nodes
                .filter((node) => node.nodeName !== 'LPOD')
                .map((node) => [
                    node.nodeName,
                    node.compliantTemplates[0].table,
                    node.compliantTemplates.map((template) => template.match_name).join(', '),
                ]);
        return {
            sourceNodesTable: {
                columns: [
                    'Node Name',
                    'Node Type',
                    'Area (m2)',
                    '%Impervious',
                    'Compliant Templates',
                ],
                items: formatNodeDataSource(sourceNodes),
                styles: createStyles(),
                cellWidths: ['30%', '15%', '20%', '15%', '20%'],
            },
            nonSourceNodesTable: {
                columns: ['Node Name', 'Node Type', 'Compliant Templates'],
                items: formatNodeData(nonSourceNodes),
                styles: createStyles(),
                cellWidths: ['40%', '30%', '30%'],
            },
        };
    };

    const generateNonCompliantNodesTableData = (nodes) => {
        const defaultStyles = {
            borderStyle: '1px solid #658cbb',
            textAlignment: 'center',
            headerBorder: true,
            fontSize: 9,
            headerFontSize: 9,
            headerTextAlign: 'center',
            marginBottom: 0,
        };

        const createStyles = (newStyles = defaultStyles) => {
            return {
                section: {
                    marginTop: 10,
                    flexGrow: 1,
                },
                table: {
                    display: 'table',
                    width: '100%',
                    border: newStyles.borderStyle,
                    borderBottom: 0,
                    borderRight: 0,
                    borderLeft: 0,
                    borderTop: newStyles.headerBorder ? newStyles.borderStyle : 0,
                    marginBottom: newStyles.marginBottom,
                },

                tableRow: {
                    width: '100%',
                    flexDirection: 'row',
                    borderBottom: newStyles.borderStyle,
                },

                headerCell: {
                    fontSize: newStyles.headerFontSize,
                    textAlign: newStyles.headerTextAlign,
                    borderRight: newStyles.headerBorder ? newStyles.borderStyle : 0,
                    borderLeft: newStyles.headerBorder ? newStyles.borderStyle : 0,
                    height: '100%',
                    padding: 5,
                },

                tableCell: {
                    fontSize: newStyles.fontSize,
                    textAlign: newStyles.textAlignment,
                    borderRight: newStyles.borderStyle,
                    borderLeft: newStyles.borderStyle,
                    height: '100%',
                    padding: 5,
                },
            };
        };

        const sourceNodes = nodes.filter((node) =>
            node.nonCompliantTemplates.some((template) => template.table === 'UrbanSourceNode'),
        );

        const nonSourceNodes = nodes.filter((node) =>
            node.nonCompliantTemplates.every((template) => template.table !== 'UrbanSourceNode'),
        );
        NCSources = sourceNodes;
        NCTreatments = nonSourceNodes;

        const formatNodeDataSource = (nodes) =>
            nodes.map((node, index) => [
                node.nodeName,
                node.rows
                    .filter((row) => row.rowName === 'Zoning Surface Type')
                    .map((row) => row.rowValue)
                    .join('\n'),
                node.rows
                    .filter((row) => row.rowName === 'Areas - Total Area (ha)')
                    .map((row) => (row.rowValue * 10000).toFixed(3))
                    .join('\n'),
                node.rows
                    .filter((row) => row.rowName === 'Areas - Impervious (%)')
                    .map((row) => parseFloat(row.rowValue).toFixed(3))
                    .join('\n'),
                node.nonCompliantTemplates.map((template) => template.match_name).join(', '),
            ]);

        const formatNodeData = (nodes) =>
            nodes
                .filter((node) => node.nodeName !== 'LPOD') // Exclude nodes with nodeName 'LPOD'
                .map((node) => {
                    const templates = node.nonCompliantTemplates.map(
                        (template) => template.match_name,
                    );
                    const displayedTemplates = templates.slice(0, 2);
                    const remainingCount = templates.length - displayedTemplates.length;

                    return [
                        node.nodeName,
                        node.nonCompliantTemplates[0].table,
                        remainingCount > 0
                            ? `Not compliant with any templates in our db`
                            : displayedTemplates.join(', '),
                    ];
                });

        return {
            sourceNodesTable: {
                columns: [
                    'Node Name',
                    'Node Type',
                    'Area (m2)',
                    '%Impervious',
                    'Non Compliant Templates',
                ],
                items: formatNodeDataSource(sourceNodes),
                styles: createStyles(),
                cellWidths: ['30%', '15%', '20%', '15%', '20%'],
            },
            nonSourceNodesTable: {
                columns: ['Node Name', 'Node Type', 'Non Compliant Templates'],
                items: formatNodeData(nonSourceNodes),
                styles: createStyles(),
                cellWidths: ['40%', '20%', '40%'],
            },
        };
    };

    const generateNotExistingInDBNodesTableData = (nodes) => {
        const defaultStyles = {
            borderStyle: '1px solid #658cbb',
            textAlignment: 'center',
            headerBorder: true,
            fontSize: 9,
            headerFontSize: 9,
            headerTextAlign: 'center',
            marginBottom: 0,
        };

        const createStyles = (newStyles = defaultStyles) => {
            return {
                section: {
                    marginTop: 10,
                    flexGrow: 1,
                },
                table: {
                    display: 'table',
                    width: '100%',
                    border: newStyles.borderStyle,
                    borderBottom: 0,
                    borderRight: 0,
                    borderLeft: 0,
                    borderTop: newStyles.headerBorder ? newStyles.borderStyle : 0,
                    marginBottom: newStyles.marginBottom,
                },

                tableRow: {
                    width: '100%',
                    flexDirection: 'row',
                    borderBottom: newStyles.borderStyle,
                },

                headerCell: {
                    fontSize: newStyles.headerFontSize,
                    textAlign: newStyles.headerTextAlign,
                    borderRight: newStyles.headerBorder ? newStyles.borderStyle : 0,
                    borderLeft: newStyles.headerBorder ? newStyles.borderStyle : 0,
                    height: '100%',
                    padding: 5,
                },

                tableCell: {
                    fontSize: newStyles.fontSize,
                    textAlign: newStyles.textAlignment,
                    borderRight: newStyles.borderStyle,
                    borderLeft: newStyles.borderStyle,
                    height: '100%',
                    padding: 5,
                },
            };
        };

        const formatNodeData = (nodes) =>
            nodes.map((node) => [
                node.nodeName,
                node.rows.length > 0 ? node.rows[0].rowValue : 'N/A',
            ]);

        return {
            notExistingInDBNodesTable: {
                columns: ['Node Name', 'Node Type'],
                items: formatNodeData(nodes),
                styles: createStyles(),
                cellWidths: ['50%', '50%'],
            },
        };
    };

    const styles = StyleSheet.create({
        body: {
            paddingHorizontal: '5vw',
            paddingVertical: '3vh',
            fontSize: 10,
        },
        title: {
            fontSize: 15,
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: '2vh',
        },
    });

    const compliantTableData = generateTableData(compliantNodes);
    const nonCompliantTableData = generateNonCompliantNodesTableData(nonCompliantNodes);
    const notExistingInDBTableData = generateNotExistingInDBNodesTableData(nodesNotExistingInDB);
    const hasGPTValues = gptValues && gptValues.length > 0;
    const hasExtractedValues = extractedValues && extractedValues.length > 0;
    return (
        <>
            <Page style={styles.body} size="A4">
                <ReportHeader reportHeaderUrl={reportHeaderUrl} />
                <Text style={styles.title}>Compliant Source Nodes</Text>
                <View style={{ marginBottom: '3vh' }}>
                    <Table
                        columnNames={compliantTableData.sourceNodesTable.columns}
                        tableData={compliantTableData.sourceNodesTable.items}
                        headerWidths={compliantTableData.sourceNodesTable.headerWidths}
                        cellWidths={compliantTableData.sourceNodesTable.cellWidths}
                        tableStyles={compliantTableData.sourceNodesTable.styles}
                    />
                </View>
                <Text style={styles.title}>Non-Compliant Source Nodes</Text>
                <View style={{ marginBottom: '3vh' }}>
                    <Table
                        columnNames={nonCompliantTableData.sourceNodesTable.columns}
                        tableData={nonCompliantTableData.sourceNodesTable.items}
                        headerWidths={nonCompliantTableData.sourceNodesTable.headerWidths}
                        cellWidths={nonCompliantTableData.sourceNodesTable.cellWidths}
                        tableStyles={nonCompliantTableData.sourceNodesTable.styles}
                    />
                </View>

                <ReportFooter />
            </Page>
            <Page style={styles.body} size="A4">
                <ReportHeader reportHeaderUrl={reportHeaderUrl} />
                <Text style={styles.title}>Compliant Treatment Nodes</Text>
                <View style={{ marginBottom: '3vh' }}>
                    <Table
                        columnNames={compliantTableData.nonSourceNodesTable.columns}
                        tableData={compliantTableData.nonSourceNodesTable.items}
                        headerWidths={compliantTableData.nonSourceNodesTable.headerWidths}
                        cellWidths={compliantTableData.nonSourceNodesTable.cellWidths}
                        tableStyles={compliantTableData.nonSourceNodesTable.styles}
                    />
                </View>
                <Text style={styles.title}>Non-Compliant Treatment Nodes</Text>
                <View style={{ marginBottom: '3vh' }}>
                    <Table
                        columnNames={nonCompliantTableData.nonSourceNodesTable.columns}
                        tableData={nonCompliantTableData.nonSourceNodesTable.items}
                        headerWidths={nonCompliantTableData.nonSourceNodesTable.headerWidths}
                        cellWidths={nonCompliantTableData.nonSourceNodesTable.cellWidths}
                        tableStyles={nonCompliantTableData.nonSourceNodesTable.styles}
                    />
                </View>
                <ReportFooter />
            </Page>
            {notExistingInDBTableData.notExistingInDBNodesTable.items.length > 0 && (
                <Page style={styles.body} size="A4">
                    <ReportHeader reportHeaderUrl={reportHeaderUrl} />
                    <Text style={styles.title}>Nodes this Council doesn't accept</Text>
                    <View style={{ marginBottom: '3vh' }}>
                        <Table
                            columnNames={notExistingInDBTableData.notExistingInDBNodesTable.columns}
                            tableData={notExistingInDBTableData.notExistingInDBNodesTable.items}
                            headerWidths={
                                notExistingInDBTableData.notExistingInDBNodesTable.headerWidths
                            }
                            cellWidths={
                                notExistingInDBTableData.notExistingInDBNodesTable.cellWidths
                            }
                            tableStyles={notExistingInDBTableData.notExistingInDBNodesTable.styles}
                        />
                    </View>
                    <ReportFooter />
                </Page>
            )}
            {tteResultsForReceivingNodes !== undefined  && (
                <Page style={styles.body} size="A4">
                    <ReportHeader reportHeaderUrl={reportHeaderUrl} />
                    <TreatmentTrainEffectivenessResult
                        saraResult={tteResultsForReceivingNodes}
                        hydrocarbonReductionRate={[]}
                    />
                    <ReportFooter />
                </Page>
            )}
            <Page>
                <ReportHeader reportHeaderUrl={reportHeaderUrl} />
                <TreatmentTrainsImage treatmentTrainsImageUrl={treatmentTrainsImageUrl} />
                <SpecificationsAndTypicalDrawings
                    nodeList={treatmentData}
                    manufactureDeviceImages={manufactureDeviceImages}
                    manufactureDeviceDocumentLinks={manufacturerDocumentLinks}
                    reportTemplate={treatmentTemplates}
                />
                <ReportFooter />
            </Page>
            {hasGPTValues && (
                <GPTCaptureTable extractedValues={gptValues} companyIcon={reportHeaderUrl} />
            )}
            {hasExtractedValues && (
                <SourceFlowNodeTable
                    extractedValues={extractedValues}
                    companyIcon={reportHeaderUrl}
                />
            )}
            {/* Compliant Source Nodes */}
            <AppendixComponent
                sourceNodes={CSources}
                companyIcon={reportHeaderUrl}
                title={'Compliant Source Node Checklists'}
            />
            {/* Compliant Treatment Nodes */}
            <AppendixComponent
                sourceNodes={CTreatments}
                companyIcon={reportHeaderUrl}
                title={'Compliant Treatment Node Checklists'}
            />
            {/* Non Compliant Source Nodes */}
            <AppendixComponent
                sourceNodes={NCSources}
                companyIcon={reportHeaderUrl}
                title={'Non Compliant Source Node Checklists'}
            />
            {/* Non Compliant Treatment Nodes */}
            <AppendixComponent
                sourceNodes={NCTreatments}
                companyIcon={reportHeaderUrl}
                title={'Non Compliant Treatment NodeChecklists'}
            />
        </>
    );
}
