import React from 'react';
import { StyleSheet, View, Page, Text } from '@react-pdf/renderer';

import ReportFooter from '../ReportFooter';
import ReportHeader from '../ReportHeader';
import Table from '../Table';

// using https://lingojam.com/SuperscriptGenerator to generate superscripts (<sup>2</sup> is not accept by React PDF)

function round(num) {
    return check(
        parseFloat(num)
            .toFixed(2)
            .replace(/\.?0+$/, ''),
    );
}

function check(num) {
    if (num === undefined || num === null || isNaN(num) | (num === '')) return 'N/A';
    return num;
}

const getClimatePercentage = (num) => {
    if (num === 1) {
        return 'No climate change adjustment';
    } else if (num < 1) {
        return '-' + round((1 - num) * 100) + '%';
    } else {
        return '+' + round((num - 1) * 100) + '%';
    }
};

function getAdjustedStorageVolume(num, adjustment) {
    return round(num * adjustment);
}

const generateAllTableData = (data) => {
    const defaultStyles = {
        borderStyle: '1px solid #658cbb',
        textAlignment: 'center',
        headerBorder: true,
        fontSize: 9,
        headerFontSize: 9,
        headerTextAlign: 'center',
    };
    const createStyles = (newStyles = defaultStyles) => {
        return {
            section: {
                marginTop: 10,
                flexGrow: 1,
            },
            table: {
                display: 'table',
                width: '100%',
                border: newStyles.borderStyle,
                borderBottom: 0,
                borderRight: 0,
                borderLeft: 0,
                borderTop: newStyles.headerBorder ? newStyles.borderStyle : 0,
            },

            tableRow: {
                width: '100%',
                flexDirection: 'row',
                borderBottom: newStyles.borderStyle,
            },

            headerCell: {
                fontSize: newStyles.headerFontSize,
                textAlign: newStyles.headerTextAlign,
                borderRight: newStyles.headerBorder ? newStyles.borderStyle : 0,
                borderLeft: newStyles.headerBorder ? newStyles.borderStyle : 0,
                height: '100%',
                padding: 5,
            },

            tableCell: {
                fontSize: newStyles.fontSize,
                textAlign: newStyles.textAlignment,
                borderRight: newStyles.borderStyle,
                borderLeft: newStyles.borderStyle,
                height: '100%',
                padding: 5,
            },
        };
    };
    return [
        !data.osd.psd
            ? {
                  columns: [
                      'Pre-Development Site Details',
                      'Area (m²)',
                      'Runoff Coefficient',
                      'Uncontrolled Area (m²)',
                  ],
                  items: data.osd.preDevAreas.areas.map((obj) => {
                      return [obj.areaName, obj.surfaceArea, obj.runoffCoefficient, 'N/A'];
                  }),
                  styles: createStyles(),
              }
            : {},

        {
            columns: [
                'Post-Development Site Details',
                'Area (m²)',
                'Runoff Coefficient',
                'Uncontrolled Area (m²)',
            ],
            items: data.osd.postDevAreas.areas.map((obj) => {
                return [obj.areaName, obj.surfaceArea, obj.runoffCoefficient, obj.uncontrolled];
            }),
            styles: createStyles(),
        },

        {
            columns: ['', 'Pre-Development', 'Post-Development', 'Uncontrolled'],
            items: [
                [
                    'Total Site Area (m²)',
                    round(data.osd.sumPreDevAreas),
                    round(data.osd.sumPostDevAreas),
                    round(data.osd.sumUncontrolledAreas),
                ],

                [
                    'Weighted Runoff Coefficient',
                    round(data.osdResult.Cpre),
                    round(data.osdResult.Cpost),
                    round(data.osdResult.Cu),
                ],
            ],
            cellWidths: ['33%', '22%', '23%', '22%'],
            styles: createStyles(),
        },

        {
            columns: ['Catchment Times (minutes)'],
            items: [
                ['Time of Concentration of catchment (Tc):', data.osd.catchmentTimeTc + ' mins'],

                [
                    'Time of Concentration of catchment to site outlet (Tcs):',
                    data.osd.catchmentTimeTcs + ' mins',
                ],
                [
                    'Time of Concentration of site outlet to catchment outlet (Tso):',
                    check(data.osd.catchmentTimeTc - data.osd.catchmentTimeTcs) + ' mins',
                ],
            ],
            styles: createStyles({
                ...defaultStyles,
                borderStyle: 'none',
                textAlignment: 'left',
                headerFontSize: 13,
                headerTextAlign: 'left',
            }),
            cellWidths: ['70%', '22%'],
            headerWidths: ['100%'],
        },
        {
            columns: ['Storage Design:'],
            items: [
                ['Storage Type: ', data.storageType],

                ['Rainfall Zone: ', 'Latitude: ' + data.latitude, 'Longitude: ' + data.longitude],
                ['AED for PSD (%):', data.osd.aepPSD, 'AEP for OSD (%):      ' + data.osd.aepOSD],
            ],
            cellWidths: ['20%', '40%', '40%'],
            headerWidths: ['100%'],
            styles: createStyles({
                ...defaultStyles,
                borderStyle: 'none',
                textAlignment: 'left',
                headerFontSize: 13,
                headerTextAlign: 'left',
            }),
        },

        {
            columns: ['Storm Duration and Intensity'],
            items: [
                [
                    'Flow',
                    'Tc (mins): ' + data.osd.catchmentTimeTc,
                    'I (mmm/hr): ' + round(data.osdResult.i_PSD),
                    'Storage',
                    'Td (mins): ' + round(data.osdResult.td),
                    'I (mm/hr): ' + round(data.osdResult.i_OSD),
                ],
            ],
            headerWidths: ['100%'],
            cellWidths: ['9%', '16%', '24%', '13%', '19%', '19%'],
            styles: createStyles({
                ...defaultStyles,
                headerBorder: false,
                headerFontSize: 13,
                headerTextAlign: 'left',
            }),
        },
        {
            columns: ['Flow Calculations and Storage Details'],
            items: [
                ['Pre-development peak site inflow (L/s):', round(data.osdResult.Qp)],
                ['Uncontrolled peak site inflow (L/s):', round(data.osdResult.Qu)],
                ['Post-development peak site inflow for PSD (L/s):', round(data.osdResult.Qa)],
                ['Post-development peak site inflow for OSD (L/s):', round(data.osdResult.Qa_dash)],
                [
                    'Calculated PSD (L/s):',
                    data.osd.psd === false ? round(data.osdResult.PSD) : 'N/A',
                ],
                ['Nominated PSD (L/s):', round(data.osdResult.PSD)],
                ['Required Storage Volume (m³)', round(data.osdResult.onSiteDetentionVolume)],
                data.osd.climateAdjustment !== '1'
                    ? [
                          'Climate Change (% increase of required storage volume):',
                          getClimatePercentage(parseFloat(data.osd.climateAdjustment)),
                      ]
                    : [],
                data.osd.climateAdjustment !== '1'
                    ? [
                          'Adjusted Required Storage Volume (m³):',
                          getAdjustedStorageVolume(
                              data.osdResult.onSiteDetentionVolume,
                              data.osd.climateAdjustment,
                          ),
                      ]
                    : [],
                data.osdResult.orificeDiameter !== 'N/A'
                    ? ['Height of storage above orifice (m):', round(data.osd.tankHeight)]
                    : [],

                data.osdResult.orificeDiameter !== 'N/A'
                    ? ['Required Orifice Diameter (mm):', round(data.osdResult.orificeDiameter)]
                    : [],
            ],
            cellWidths: ['70%', '30%'],
            headerWidths: ['100%'],
            styles: createStyles({
                ...defaultStyles,
                borderStyle: 'none',
                textAlignment: 'left',
                headerBorder: false,
                headerFontSize: 13,
                headerTextAlign: 'left',
            }),
        },
    ];
};

const SwinburneReportTemplate = ({ data }) => {
    const styles = StyleSheet.create({
        body: {
            paddingTop: 30,
            paddingBottom: 30,
            paddingHorizontal: 35,
            marginBottom: 10,
        },
        section: {
            marginBottom: 10,
            padding: 15,
            display: 'flex',
            flexDirection: 'row',
        },
        mainTitle: {
            fontSize: 18,
            fontweight: 1000,
            textAlign: 'center',
            marginTop: 5,
            marginBottom: 5,
        },
        catchmentTimes: {
            marginTop: 10,
            fontSize: 15,
            width: '100%',
            justifyContent: 'end',
            display: 'flex',
        },
    });
    return (
        <Page style={styles.body} wrap>
            <ReportHeader reportHeaderUrl={data.reportHeaderIconUrl} />
            <View style={{ pageBreakAfter: 'always' }}>
                <Text style={styles.mainTitle}>OSD Design Summary Report</Text>
                <Text style={{ fontSize: 12, marginTop: 10 }}>
                    Method for OSD calculations: {data.osd.method.toUpperCase()}
                    {data.osd.qaMultiplicationFactor === 1 ? ' Standard Swinburne' : ' OSD4W'}
                </Text>
            </View>

            {/* Notes*/}
            {data.osd.qaMultiplicationFactor === 1 ? (
                <Text style={{ fontSize: 8, color: 'red', marginTop: 5 }}>
                    *Notes:{'\n'}
                    These calculations are performed using the Swinburne Method.
                    {'\n'}
                    They do not include the safety factor in OSD4W.{'\n'}
                    The results from this calculation may differ significantly compared to results
                    from OSD4W
                </Text>
            ) : (
                <Text></Text>
            )}

            {/* OSD System Specified */}
            <View>
                <Text style={{ fontSize: 10, marginTop: 10 }}>Device Name: {data.OSDCompany}</Text>
                <Text style={{ fontSize: 10, marginTop: 10 }}>
                    Device Type: {data.OSDMaterial} - {data.storageType}
                </Text>
            </View>

            {/* Site Details */}
            <View style={{ marginBottom: 50 }}>
                {generateAllTableData(data).map((table, index) => (
                    <>
                        {table.styles ? (
                            <Table
                                tableKey={index}
                                columnNames={table.columns}
                                tableData={table.items}
                                headerWidths={table.headerWidths}
                                cellWidths={table.cellWidths}
                                customCellWidths={table.customCellWidths}
                                tableStyles={table.styles}
                            />
                        ) : (
                            <></>
                        )}
                    </>
                ))}
            </View>

            <ReportFooter />
        </Page>
    );
};

export default SwinburneReportTemplate;
