import { createSlice } from "@reduxjs/toolkit";

// used in Default Template (Pre development Areas section)
const predevInfoSlice = createSlice({
    name: "predevInfo",
    initialState: {
        psd: false,
        councilPSD: "",
        preDevSlope: "1",
        preDevFlowLength: "100",
        preDevAreas: [
            {
                id: 0,
                areaName: "",
                surfaceArea: "",
                runoffCoefficient: "",
                surfaceType: "Paved",
                impervious: true,
            },
        ],
        sumPreDevAreas: 0,
        averagePreROC: 0,
    },
    reducers: {
        handlePsd: (state, action) => {
            state.psd = action.payload;
        },
        handleCouncilPSD: (state, action) => {
            state.councilPSD = action.payload;
        },
        handlePreDevSlope: (state, action) => {
            state.preDevSlope = action.payload;
        },
        handlePreDevFlowLength: (state, action) => {
            state.preDevFlowLength = action.payload;
        },
        handlePreDevAreas: (state, action) => {
            state.preDevAreas = action.payload;
        },
        handleSumPreDevAreas: (state, action) => {
            state.sumPreDevAreas = action.payload;
        },
        handleAveragePreROC: (state, action) => {
            state.averagePreROC = action.payload;
        },
        handlePreDevInfo: (_, action) => {
            return action.payload;
        },
    },
});

export const {
    handlePsd,
    handleCouncilPSD,
    handlePreDevSlope,
    handlePreDevFlowLength,
    handlePreDevAreas,
    handleSumPreDevAreas,
    handleAveragePreROC,
    handlePreDevInfo,
} = predevInfoSlice.actions;

export const predevInfo = (state) => state.predevInfo;
export default predevInfoSlice.reducer;
