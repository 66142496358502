import React from 'react';
import { Document, pdf } from '@react-pdf/renderer';
// components
import BasicInformation from '../BasicInformation';

import SwinburneReportTemplate from './SwinburneReportTemplate';
import SpecImagePages from '../SpecImagePages';

const getSwinburneReport = (data, manufactureImageLinks, manufactureDocuments) => {
    return (
        <Document>
            <BasicInformation
                reportHeaderUrl={data.reportHeaderIconUrl}
                address={{
                    suburb: data.suburb,
                    state: data.state,
                    postcode: data.postcode,
                    council: data.council,
                    address: data.address,
                    coordinate: data.coordinate,
                }}
                basicInfo={{
                    developmentType: data.developmentType,
                    permit: data.planningPermitNo,
                    projectName: 'N/A',
                    assessor: data.assessorName,
                    email: data.assessorEmail,
                }}
            />

            {/* OSD Summary*/}
            <SwinburneReportTemplate data={data} />

            {/* Images */}
            {manufactureImageLinks.length > 0 && (
                <SpecImagePages
                    osdCompany={data.OSDCompany}
                    manufactureDocuments={manufactureDocuments}
                    manufactureImageLinks={manufactureImageLinks}
                    reportHeaderUrl={data.reportHeaderIconUrl}
                />
            )}
        </Document>
    );
};

// main function
export const generateSwinburneReport = async (
    data,
    manufactureImageLinks,
    manufactureDocuments,
) => {
    try {
        const reportBlob = await pdf(
            getSwinburneReport(data, manufactureImageLinks, manufactureDocuments),
        ).toBlob();
        return reportBlob;
    } catch (err) {
        console.error(err);
    }
};
