import React from 'react';

// components
import FormRow from './FormRow';

// utils
import { isFloat } from '../../../utils/common';

const DesignStorm = ({ designStorm, setDesignStorm, AEPS }) => {
    const handleInputChange = (e, _, numberCheck = false) => {
        let { name, value } = e.target;

        if (numberCheck && !isFloat(e.target.value)) {
            return;
        }
        const bufArray = { ...designStorm };
        bufArray[name] = value;
        bufArray.postDevDesignStorm = value;
        setDesignStorm(bufArray);
    };
    return (
        <div className="osd-form-section-container osd-blue-border">
            <div className="osd-form-section">
                <span className="title-2 span-3">Design Storm</span>
            </div>
            <FormRow
                formRow={[designStorm]}
                columns={[
                    {
                        id: 'preDevDesignStorm',
                        label: 'Pre Dev (AEP %):',
                        inputType: 'dropdown',
                        options: AEPS.map((aep) => ({ label: aep, value: aep })),
                        labelToolTip: {
                            content:
                                'Pre Development Annual Exceedence Probability (AEP) is used to detemine existing/pre development flows and establish a permissible site discharge (PSD).',
                            link: 'http://www.bom.gov.au/water/awid/id-703.shtml',
                        },
                    },
                    {
                        id: 'postDevDesignStorm',
                        label: 'Post Dev (AEP %):',
                        disabled: true,
                        inputType: 'dropdown',
                        options: AEPS.map((aep) => ({ label: aep, value: aep })),
                        labelToolTip: {
                            content:
                                'Post Development Annual Exceedence Probability (AEP) is used to determine the storm event for sizing the On Site Detention volume.',
                            link: 'http://www.bom.gov.au/water/awid/id-703.shtml',
                        },
                    },
                    null,
                ]}
                handleInputChange={handleInputChange}
            />
        </div>
    );
};

export default DesignStorm;
