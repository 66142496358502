import { createSlice } from "@reduxjs/toolkit";

const wsudResultsSlice = createSlice({
    name: "wsudResults",
    initialState: null,
    reducers: {
        setWSUDResults: (_, action) => {
            return action.payload;
        },
    },
});

export const { setWSUDResults } = wsudResultsSlice.actions;
export const selectWSUDResults = (state) => state.wsudResults;
export default wsudResultsSlice.reducer;
