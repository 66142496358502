// images
import boydsRationalTooltip from '../../../assets/tooltip-images/boyds-rational-coefficient-tooltip.png';
import {
    TooltipButton,
    TooltipContainer,
    TooltipContent,
} from '../../styled-components/TooltipComponents';

export default function PostDevAreas({ postDevAreas, setPostDevAreas }) {
    return (
        <div style={{ whiteSpace: 'pre-line' }}>
            {/* Header */}
            <div className="d-flex" style={{ gap: '10px' }}>
                <span className="flex-2">{'\n'}Catchment Name</span>
                <span className="flex-1">
                    {'\n'} Area (m<sup>2</sup>)
                </span>
                <span className="flex-1">{'\n'}Imperviousness</span>
                <span className="flex-1">
                    Of Which Uncontrolled (m<sup>2</sup>)
                </span>
                <span className="flex-1">Runoff Coefficient</span>
                <span className="flex-1" />
            </div>
            {/* Areas */}
            <div className="d-flex flex-column" style={{ gap: '10px' }}>
                {postDevAreas.map((area, index) => {
                    return (
                        <div
                            key={area.id}
                            className="flex-1 d-flex flex-row"
                            style={{ gap: '10px' }}
                        >
                            {/* Area Name */}
                            <div className="flex-2">
                                <input
                                    type="text"
                                    className="form-control"
                                    stlye={{ marginInline: 'auto' }}
                                    value={area.areaName}
                                    onChange={(e) => {
                                        const bufArray = [...postDevAreas];
                                        bufArray[index].areaName = e.target.value;
                                        setPostDevAreas(bufArray);
                                    }}
                                />
                            </div>
                            {/* Surface Area */}
                            <div className="flex-1">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={area.surfaceArea}
                                    onChange={(e) => {
                                        const bufArray = [...postDevAreas];
                                        const regex = /^\d+(?:\.\d{0,2})?$/i;
                                        if (regex.test(e.target.value)) {
                                            bufArray[index].surfaceArea = e.target.value;
                                        } else if (e.target.value === '') {
                                            bufArray[index].surfaceArea = '';
                                        }
                                        setPostDevAreas(bufArray);
                                    }}
                                />
                            </div>
                            {/* Imperviousness */}
                            <div className="flex-1">
                                <select
                                    type="text"
                                    className="flex-1 form-control"
                                    value={area.impervious}
                                    onChange={(e) => {
                                        const bufArray = [...postDevAreas];
                                        if (e.target.value === 'true')
                                            bufArray[index].impervious = true;
                                        else bufArray[index].impervious = false;
                                        setPostDevAreas(bufArray);
                                    }}
                                >
                                    <option value={true}>Impervious</option>
                                    <option value={false}>Pervious</option>
                                </select>
                            </div>
                            <div className="flex-1">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={area.uncontrolled}
                                    onChange={(e) => {
                                        const bufArray = [...postDevAreas];
                                        const regex = /^\d+(?:\.\d{0,2})?$/i;
                                        if (regex.test(e.target.value)) {
                                            bufArray[index].uncontrolled = e.target.value;
                                        } else if (e.target.value === '') {
                                            bufArray[index].uncontrolled = '';
                                        }
                                        setPostDevAreas(bufArray);
                                    }}
                                />
                            </div>

                            {/* Run-off Coefficient */}
                            <div className="flex-1 d-flex">
                                <input
                                    type="text"
                                    className="flex-5 form-control"
                                    value={area.runoffCoefficient}
                                    onChange={(e) => {
                                        const bufArray = [...postDevAreas];
                                        if (!isNaN(e.target.value)) {
                                            bufArray[index].runoffCoefficient = e.target.value;
                                        } else if (e.target.value === '') {
                                            bufArray[index].runoffCoefficient = '';
                                        }
                                        setPostDevAreas(bufArray);
                                    }}
                                />

                                <TooltipContainer>
                                    <TooltipButton>❓</TooltipButton>
                                    <TooltipContent width="10vw">
                                        <div style={{ textAlign: 'center' }}>min 0.1, max 1</div>
                                    </TooltipContent>
                                </TooltipContainer>
                            </div>
                            {/* Of Which Uncontrolled */}

                            {/* Delete Button */}
                            <div className="flex-1 d-flex align-items-start">
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={() => {
                                        const bufArray = postDevAreas.filter((_, i) => i !== index);
                                        setPostDevAreas(bufArray);
                                    }}
                                    disabled={postDevAreas.length === 1}
                                >
                                    ❌
                                </button>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
