// modules
import React, { useState, useEffect } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
// utils
import { uploadFileToS3Bucket, getFileFromS3Bucket } from '../utils/s3Utils';
import { useDispatch, useSelector } from 'react-redux';
import { setReportHeaderUrl } from '../utils/redux/companyIconSlice';
import { selectUserInfo } from '../utils/redux/userInfoSlice';

export function CompanyIconUploader({
    showCompanyIconUploader,
    setShowCompanyIconUploader,
    assessorEmail,
    setReportHeaderIconUrl,
    onComplete,
}) {
    const dispatch = useDispatch();
    const bucket = 'company-icon';
    /* Image Uploader */
    const [companyIcon, setCompanyIcon] = useState(null);

    const handleImageSubmit = async () => {
        const fileName = `user-uploaded/${assessorEmail}.png`;
        await uploadFileToS3Bucket(fileName, bucket, companyIcon, 'image/png');
        const url = await getFileFromS3Bucket(bucket, fileName);
        setReportHeaderIconUrl(url);
        setShowCompanyIconUploader(false);
        dispatch(setReportHeaderUrl(url));
        onComplete();
    };
    /* When the user choose to leave the icon empty */
    const handleBlankImage = async () => {
        setCompanyIcon(null);
        const url = await getFileFromS3Bucket(bucket, 'nologo.png');
        setReportHeaderIconUrl(url);
        dispatch(setReportHeaderUrl(url));
        setShowCompanyIconUploader(false);
        onComplete();
    };

    return (
        <>
            <Modal show={showCompanyIconUploader}>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formFile">
                            <Form.Label>Upload an image</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={(e) => setCompanyIcon(e.target.files[0])}
                            />
                        </Form.Group>
                        {companyIcon && (
                            <div>
                                <img
                                    className="w-100"
                                    src={URL.createObjectURL(companyIcon)}
                                    alt=""
                                />
                            </div>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer className="w-100 h-100">
                    <Button
                        className="flex-1"
                        variant="secondary"
                        onClick={() => {
                            setCompanyIcon(null);
                            setShowCompanyIconUploader(false);
                            onComplete();
                        }}
                    >
                        I don't want a logo
                    </Button>
                    <Button className="flex-1" variant="info" onClick={handleBlankImage}>
                        I want to leave it blank
                    </Button>
                    <Button
                        className="flex-1 h-100 flex"
                        variant="primary"
                        onClick={handleImageSubmit}
                        disabled={!companyIcon}
                    >
                        Submit Logo
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export function CompanyIconPreviewer() {
    const userInfo = useSelector(selectUserInfo);

    return (
        <div
            className="w-100 h-100 d-flex justify-content-center align-items-center"
            style={{
                border: '3px solid #7290cf',
                borderRadius: 5,
                backgroundColor: '#cedcf8',
                paddingInline: 10,
            }}
        >
            {userInfo.reportHeaderIcon ? (
                <img alt="" src={userInfo.reportHeaderIcon} className="w-100" />
            ) : (
                <div>No Icon Found!</div>
            )}
        </div>
    );
}
