import { createSlice } from "@reduxjs/toolkit";

const projectInfoSlice = createSlice({
  name: "projectInfo",
  initialState: {
    developmentType: "",
    planningPermitNo: "",
  },
  reducers: {
    setDevelopmentType: (state, action) => {
      state.developmentType = action.payload;
    },
    setPlanningPermitNo: (state, action) => {
      state.planningPermitNo = action.payload;
    },
    setProjectInfo: (state, action) => {
      return action.payload; // Assuming action.payload is the new user info object
    },
  },
});

export const { setDevelopmentType, setPlanningPermitNo, setProjectInfo } =
  projectInfoSlice.actions;
export const selectProjectInfo = (state) => state.projectInfo;
export default projectInfoSlice.reducer;
