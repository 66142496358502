// modules
import axios from "axios";
// utils
import { LAMBDA_API } from "./globalConstant";

export const getCouncilRulesByAuthorityName = async (authorityName) => {
  let result;

  const payload = {
    endpoint: "getCouncilRulesByAuthorityName",
    params: { authority_name: authorityName },
  };
  await axios.post(`${LAMBDA_API}/cms`, payload).then((res) => {
    result = res.data.results;
  });

  return result;
};
