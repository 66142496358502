import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const PaymentConfirmation = ({
    showConfirmation,
    handlePaymentConfirmed,
    handlePaymentCancelled,
}) => {
    return (
        <Modal show={showConfirmation} onHide={handlePaymentCancelled}>
            <Modal.Header closeButton>
                <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>This is a paid action. Do you wish to proceed?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handlePaymentCancelled}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handlePaymentConfirmed}>
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PaymentConfirmation;
