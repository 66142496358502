import React, { useContext } from 'react';
import styles from './AreaForm.module.css';

import { useDispatch } from 'react-redux';
import { addNewArea } from '../../../../utils/redux/WSUD/areaSlice';
import { sails_api } from '../../../../utils/globalConstant';

import ContextProvider from '../../../../utils/contextProvider';
import AreaFormRow from './AreaFormRow';
import { useQuery } from 'react-query';

const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || '';

const AreaForm = ({ areas }) => {
    const dispatch = useDispatch();
    const { type, rainfallStation, catchmentType } = useContext(ContextProvider);
    const { data: areaTypes } = useQuery(
        [type, rainfallStation, catchmentType],
        async () => {
            if (catchmentType === '') return [];
            const res = await sails_api.get(`${type}AreaTypes/${rainfallStation}/${catchmentType}`);
            return res.data.map((item) =>
                type === 'pervious' && item.snodetype === 'Mixed'
                    ? 'MixedPervious'
                    : item.snodetype,
            );
        },
        {
            initialData: [],
        },
    );

    const handleAddArea = () => {
        dispatch(addNewArea(type));
    };

    return (
        <div className={styles['area-form']}>
            <p style={{ fontSize: 'larger' }}>{capitalize(type)} Areas</p>
            <div style={{ marginBottom: '3vh' }}>
                {areas.map((area, index) => (
                    <AreaFormRow key={index} area={area} index={index} areaTypes={areaTypes} />
                ))}
            </div>

            <button type="button" onClick={handleAddArea}>
                Add {capitalize(type)} Area
            </button>
        </div>
    );
};

export default AreaForm;
