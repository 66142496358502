import React from 'react';
import { StyleSheet, View, Text } from '@react-pdf/renderer';
import { stringOrNumber } from '../auditorUtils';

export default function TreatmentTrainEffectivenessResult({
    saraResult,
    hydrocarbonReductionRate,
}) {
    const styles = StyleSheet.create({
        body: {
            paddingHorizontal: 15,
        },
        title: {
            fontSize: 14,
            textAlign: 'center',
            marginBottom: '3%',
        },
        table: {
            marginBottom: 10,
        },
        tableTitle: {
            width: '100%',
            textAlign: 'center',
            fontSize: 12,
            marginBottom: 5,
        },
        tableLabel: {
            flex: 2,
            textAlign: 'center',
            fontSize: 9,
            border: 1,
        },
        tableRow: {
            display: 'flex',
            flexDirection: 'row',
        },
        tableCol: {
            flex: 1,
            textAlign: 'center',
            fontSize: 9,
            border: 1,
        },
        rowVertical: {
            flex: 2,
            display: 'flex',
            flexDirection: 'column',
        },
        topRow: {
            border: 1,
            textAlign: 'left',
            fontSize: 9,
        },
        bottomRow: {
            display: 'flex',
            flexDirection: 'row',
        },
        bottomCol: {
            flex: 1,
            border: 1,
            backgroundColor: '#D3D3D3',
            fontSize: 9,
            textAlign: 'center',
        },
    });

    return (
        <>
            <View style={styles.body}       >
                <Text style={styles.title}>Treatment Train Effectiveness Result</Text>
                <View style={styles.table}>
                    <Text style={styles.tableTitle}>LPOD</Text>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableLabel}></Text>
                        <Text style={styles.tableCol}>Sources</Text>
                        <Text style={styles.tableCol}>Residual Load</Text>
                        <Text style={styles.tableCol}>Percentage Reduction {'\n'} (%)</Text>
                        <Text style={styles.tableCol}>
                            Authority {'\n'} Target {'\n'}Reduction (%)
                        </Text>
                        <View style={styles.rowVertical}>
                            <Text style={styles.topRow}>
                                Green Star Buildings Target Reductions
                            </Text>
                            <View style={styles.bottomRow}>
                                <Text style={styles.bottomCol}>Credit Achievement</Text>
                                <Text style={styles.bottomCol}>Exceptional Performance</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableLabel}>Flow (ML/yr)</Text>
                        {saraResult['Flow (ML/yr)'].map((value) => {
                            let content;
                            if (value.toString() === 'N/A') {
                                content = 'N/A';
                            } else {
                                content = stringOrNumber(Math.round(value * 100) / 100);
                            }
                            return (
                                <Text style={styles.tableCol} key={content}>
                                    {content}
                                </Text>
                            );
                        })}
                        {saraResult['Flow (ML/yr)'].length < 5 && (
                            <>
                                <Text style={styles.tableCol}>40</Text>
                                <Text style={styles.tableCol}>80</Text>
                            </>
                        )}
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableLabel}>Total Gross Pollutant (kg/yr)</Text>
                        {saraResult['Total Gross Polutent (kg/yr)'].map((value) => {
                            return (
                                <Text style={styles.tableCol} key={value}>
                                    {stringOrNumber(Math.round(value * 100) / 100)}
                                </Text>
                            );
                        })}
                        {saraResult['Total Gross Polutent (kg/yr)'].length < 5 && (
                            <>
                                <Text style={styles.tableCol}>90</Text>
                                <Text style={styles.tableCol}>95</Text>
                            </>
                        )}
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableLabel}>Total Nitrogen (kg/yr)</Text>
                        {saraResult['Total Nitrogen (kg/yr)'].map((value) => {
                            return (
                                <Text style={styles.tableCol} key={value}>
                                    {stringOrNumber(Math.round(value * 100) / 100)}
                                </Text>
                            );
                        })}
                        {saraResult['Total Nitrogen (kg/yr)'].length < 5 && (
                            <>
                                <Text style={styles.tableCol}>45</Text>
                                <Text style={styles.tableCol}>60</Text>
                            </>
                        )}
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableLabel}>Total Phosphorus (kg/yr)</Text>
                        {saraResult['Total Phosphorus (kg/yr)'].map((value) => {
                            return (
                                <Text style={styles.tableCol} key={value}>
                                    {stringOrNumber(Math.round(value * 100) / 100)}
                                </Text>
                            );
                        })}
                        {saraResult['Total Phosphorus (kg/yr)'].length < 5 && (
                            <>
                                <Text style={styles.tableCol}>65</Text>
                                <Text style={styles.tableCol}>70</Text>
                            </>
                        )}
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableLabel}>Total Suspended Solid (kg/yr)</Text>
                        {saraResult['Total Suspended Solid (kg/yr)'].map((value) => {
                            return (
                                <Text style={styles.tableCol} key={value}>
                                    {stringOrNumber(Math.round(value * 100) / 100)}
                                </Text>
                            );
                        })}
                        {saraResult['Total Suspended Solid (kg/yr)'].length < 5 && (
                            <>
                                <Text style={styles.tableCol}>85</Text>
                                <Text style={styles.tableCol}>90</Text>
                            </>
                        )}
                    </View>
                </View>
                {hydrocarbonReductionRate.length > 0 && (
                    <View style={styles.table}>
                        <Text style={styles.tableTitle}>
                            Hydrocarbon Reductions performed by GPTs specifically designed to handle
                            hydrocarbon
                        </Text>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableLabel}>
                                Hydrocarbon reduction {'\n'} by Device
                            </Text>
                            <Text style={styles.tableCol}>
                                Device is rated for removal of {'\n'}hydrocarbon {'('}%{')'}
                            </Text>
                            <Text style={styles.tableCol}>
                                How much flow device treats {'\n'} {'('}%{')'}
                            </Text>
                            <Text style={styles.tableCol}>
                                Hydrocarbon Reduction of Installed Device {'\n'} {'('}%{')'}
                            </Text>
                            <View style={styles.rowVertical}>
                                <Text style={styles.topRow}>
                                    Green Star Buildings Target Reductions
                                </Text>
                                <View style={styles.bottomRow}>
                                    <Text style={styles.bottomCol}>Credit Achievement</Text>
                                    <Text style={styles.bottomCol}>Exceptional Performance</Text>
                                </View>
                            </View>
                        </View>
                        {hydrocarbonReductionRate.map((item) => {
                            return (
                                <View style={styles.tableRow} key={item.nodeName}>
                                    <Text style={styles.tableLabel}>
                                        {item.nodeName ? item.nodeName : 'N/A'}
                                    </Text>
                                    <Text style={styles.tableCol}>
                                        {item.defaultReductionRate
                                            ? stringOrNumber(+item.defaultReductionRate)
                                            : 'N/A'}
                                    </Text>
                                    <Text style={styles.tableCol}>
                                        {item.flowTreated
                                            ? stringOrNumber(+item.flowTreated)
                                            : 'N/A'}
                                    </Text>
                                    <Text style={styles.tableCol}>
                                        {item.finalReductionRate
                                            ? stringOrNumber(+item.finalReductionRate)
                                            : 'N/A'}
                                    </Text>
                                    <Text style={styles.tableCol}>
                                        {item.gs1 ? stringOrNumber(+item.gs1) : 'N/A'}
                                    </Text>
                                    <Text style={styles.tableCol}>
                                        {item.gs2 ? stringOrNumber(+item.gs2) : 'N/A'}
                                    </Text>
                                </View>
                            );
                        })}
                    </View>
                )}
            </View>
        </>
    );
}
