import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// css
import './assets/css/osd.css';
// utils
import { sails_api } from '../utils/globalConstant';
import { selectUserInfo } from '../utils/redux/userInfoSlice';
import { selectProjectInfo } from '../utils/redux/projectInfoSlice';
import { selectMapInfo } from '../utils/redux/mapInfoSlice';
import { openInNewTab } from '../utils/common';
// components
import Osd from './Osd';

const OsdContainer = ({ loadedModel }) => {
    const userInfo = useSelector(selectUserInfo);
    const projectInfo = useSelector(selectProjectInfo);
    const mapInfo = useSelector(selectMapInfo);

    const locationId = `${mapInfo.coordinate.lat}-${mapInfo.coordinate.lng}`; // Unique identifier for current location

    const [coeffs, setCoeffs] = useState(null);
    const [AEPS, setAEPS] = useState(() => {
        const savedAEPS = JSON.parse(sessionStorage.getItem('AEPS'));
        const savedLocationId = sessionStorage.getItem('locationId');
        return savedLocationId === locationId ? savedAEPS : (loadedModel?.aeps.AEPS ?? null);
    });
    const [OSDMethod, setOSDMethod] = useState(() => {
        const savedOSDMethod = JSON.parse(sessionStorage.getItem('OSDMethod'));
        const savedLocationId = sessionStorage.getItem('locationId');
        return savedLocationId === locationId
            ? savedOSDMethod
            : (loadedModel?.preferred_osd_method ?? null);
    });

    useEffect(() => {
        const fetchData = async () => {
            if (mapInfo.address.endsWith('New Zealand')) {
                setOSDMethod('Rational(AUNZ)');
                sessionStorage.setItem('OSDMethod', JSON.stringify('Rational(AUNZ)'));
                sessionStorage.setItem('locationId', locationId); // Save location ID to sessionStorage
            } else {
                if (AEPS === null) {
                    try {
                        const res = await sails_api.get(
                            `osd/aep?latitude=${mapInfo.coordinate.lat}&longitude=${mapInfo.coordinate.lng}`,
                        );
                        setAEPS(res.data);
                        sessionStorage.setItem('AEPS', JSON.stringify(res.data));
                        sessionStorage.setItem('locationId', locationId); // Save location ID to sessionStorage
                    } catch (error) {
                        console.error(error);
                    }
                }
                if (OSDMethod === null) {
                    try {
                        const res = await sails_api.get(
                            `OSD/osdAndWQMethods/${mapInfo.state}/${mapInfo.council}`,
                        );
                        setOSDMethod(res.data.osd_method);
                        sessionStorage.setItem('OSDMethod', JSON.stringify(res.data.osd_method));
                        sessionStorage.setItem('locationId', locationId); // Save location ID to sessionStorage
                    } catch (error) {
                        console.error(error);
                        setOSDMethod('Swinburne');
                        sessionStorage.setItem('OSDMethod', JSON.stringify('Swinburne'));
                        sessionStorage.setItem('locationId', locationId); // Save location ID to sessionStorage
                    }
                }
            }
        };
        fetchData();
    }, [AEPS, OSDMethod, mapInfo, locationId]);

    const [uploadedFileName, setUploadedFileName] = useState('');

    function extractDataFromCSVContent(csvContent) {
        return new Promise((resolve, reject) => {
            try {
                const lines = csvContent.trim().split('\n');
                let parameterRow = 0;
                while (!lines[parameterRow].split(',')[0].startsWith('DDF Model')) {
                    ++parameterRow;
                }

                const parameters = lines[parameterRow++].split(',').slice(1);
                const values = lines[parameterRow].split(',').slice(1);
                const coefss = {};
                for (let i = 1; i < parameters.length; i++) {
                    coefss[parameters[i].trim()] = parseFloat(values[i].trim());
                }
                // hardcoded aep values for now
                coefss.std_aeps = [
                    '63.2',
                    '50',
                    '20',
                    '10',
                    '5',
                    '3.3',
                    '2',
                    '1.7',
                    '1.3',
                    '1',
                    '0.4',
                ];
                resolve(coefss);
            } catch (error) {
                alert('Error parsing file!');
                reject(null);
            }
        });
    }

    const handleUploadCSV = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.addEventListener('change', (event) => {
            const file = event.target.files[0];

            if (file) {
                setUploadedFileName(file.name);

                const reader = new FileReader();
                reader.onload = (event) => {
                    const csvData = event.target.result;
                    extractDataFromCSVContent(csvData)
                        .then((data) => {
                            console.log('Extracted data:', data);
                            setCoeffs(data);
                        })
                        .catch((error) => {
                            console.error('Error extracting data:', error);
                        });
                };
                reader.readAsText(file);
            }
        });
        input.click();
    };

    return (
        <>
            {AEPS !== null && OSDMethod !== null && (
                <div>
                    <section>
                        <span>
                            This here On-Site Detention calculator allows you to perform OSD
                            calculation with the method specific to the Council your project is in.
                            If Council wants OSD4w Swinburne, covered. Standard Swinburne, covered.
                            Rational or Boyd's, covered. Even Victoria IDM. Why? because we're
                            awesome. Let us know if you want other methods!
                        </span>
                    </section>

                    {mapInfo.address.endsWith('New Zealand') ? (
                        <div className="uploadContainer">
                            <p>
                                Coordinates: LAT {': '}
                                <strong>{mapInfo.coordinate.lat}</strong>, LNG{': '}
                                <strong>{mapInfo.coordinate.lng}</strong>
                            </p>
                            <p className="text-left w-100">
                                You have selected{' '}
                                {mapInfo.address.slice(0, -' ,New Zealand'.length)} in New Zealand.
                                Please generate the intensity report and download the spreadsheet
                                from the following link:
                                <br />
                            </p>
                            <div className="d-flex">
                                <a
                                    className="link"
                                    href="https://hirds.niwa.co.nz/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Download the spreadsheet here
                                </a>
                                <span
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                        openInNewTab(
                                            'https://drive.google.com/file/d/1498Iuchf0IXm2bNx31rYc7ajev7whpa8/view?usp=sharing',
                                        )
                                    }
                                >
                                    ❓
                                </span>
                            </div>
                            <br />
                            <p className="text-left w-100">After downloading it, upload it here.</p>
                            <button className="button" onClick={handleUploadCSV}>
                                Upload CSV
                            </button>
                            {uploadedFileName && (
                                <p className="uploadedFile">Uploaded File: {uploadedFileName}</p>
                            )}
                        </div>
                    ) : (
                        <>
                            <div className="preferred_method_note mt-3">
                                NOTE:
                                {OSDMethod ? (
                                    <>
                                        The council's preferred OSD method is{' '}
                                        <strong>{OSDMethod}</strong>
                                    </>
                                ) : (
                                    <>
                                        We are unaware of the Council's preferred OSD method. You
                                        can choose one of the below methods.
                                    </>
                                )}
                            </div>
                            <div className="mt-3">
                                Project Address{': '}
                                <strong>{mapInfo.address}</strong>
                            </div>
                            <div className="mt-1">
                                Council{': '}
                                <strong>{mapInfo.council}</strong>
                            </div>
                            <div className="mt-1 mb-3">
                                Coordinates: LAT {': '}
                                <strong>{mapInfo.coordinate.lat}</strong>, LNG{': '}
                                <strong>{mapInfo.coordinate.lng}</strong>
                            </div>
                        </>
                    )}
                    <Osd
                        AEPS={AEPS}
                        preferredOSDMethod={OSDMethod}
                        userInfo={userInfo}
                        projectInfo={projectInfo}
                        mapInfo={mapInfo}
                        Coefficients={coeffs}
                        loadedModel={loadedModel}
                    />
                </div>
            )}
        </>
    );
};

export default OsdContainer;
