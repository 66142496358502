/**
 * Rendres EditorJS blocks
 * @see {@link https://editorjs.io/saving-data/#output-data-format} EditorJS output
 * @see {@link https://github.com/editor-js/paragraph?tab=readme-ov-file#output-data} Paragraph output format
 * @see {@link https://github.com/editor-js/nested-list?tab=readme-ov-file#output-data} Nested list output format
 * @see {@link https://github.com/editor-js/image?tab=readme-ov-file#output-data} Image output format
 *
 * @example Input
 * const blocks = [
 *    {
 *        type: "paragraph",
 *        data: { text: "Hello world!" }
 *    },
 *    {
 *        type: "list",
 *        data: {
 *            style: "unordered"
 *            items: [ { content: "point 1", items: [] } ]
 *        }
 *    },
 *    {
 *        type: "image",
 *        data: {
 *            file: { url: "https://image-link.example" }
 *        }
 *    },
 * ]
 *
 * There are only 3 types of blocks:
 *  - Paragraph
 *  - List (order or unordered / can be nested)
 *  - Image
 *
 * @param {Object}   params - Component parameters.
 * @param {Object[]} params.blocks - EditorJS blocks.
 *
 * @returns Returns ordered or unordered component
 */
const RendreDoc = ({ blocks }) => {
    const elements = blocks.map((block, id) => {
        switch (block.type) {
            case 'paragraph': {
                return (
                    <p
                        key={id}
                        className="doc-paragraph"
                        dangerouslySetInnerHTML={{ __html: blankTargetAllLinks(block.data.text) }}
                    />
                );
            }
            case 'list': {
                return <NestedList style={block.data.style} items={block.data.items} />;
            }
            case 'image': {
                const imageSrc = block.data.file.url;
                return (
                    <img key={id} className="doc-image" src={imageSrc} style={{ width: '100%' }} />
                );
            }
        }
    });

    return <div className="doc">{elements}</div>;
};

/**
 * Rendres (ordered/unordered) list blocks
 *
 * @param {Object}   params - Component parameters.
 * @param {string}   params.style - Style of the list (ordered/unordered).
 * @param {Object[]} params.items - List of items.
 * @param {string}   params.items[].content - Text to be displayed.
 * @param {Object[]} params.items[].items - List of nested items (if any)
 *
 * @returns Returns ordered or unordered component
 */
const NestedList = ({ style, items }) => {
    return (
        <>
            {items.map((item, index) =>
                style === 'ordered' ? (
                    <ol key={index} className="doc-ordered-list" style={{ marginLeft: '2rem' }}>
                        <li>{blankTargetAllLinks(item.content)}</li>
                        {item.items.length > 0 && <NestedList items={item.items} style={style} />}
                    </ol>
                ) : (
                    <ul key={index} className="doc-ordered-list" style={{ marginLeft: '2rem' }}>
                        <li>{blankTargetAllLinks(item.content)}</li>
                        {item.items.length > 0 && <NestedList items={item.items} style={style} />}
                    </ul>
                ),
            )}
        </>
    );
};

/**
 * Add `taget=_blank` to <a> tags in the input text to make all links open in new tab.
 * This is need because Editor.JS does not provide a way to control this. So this is a
 * workaround to handle this.
 *
 * @example
 * // return "Google website: <a target='_blank' rel='noopener noreferrer' href='https://google.com'>link</a>"
 * blankTargetAllLinks("Google website: <a href='https://google.com'>link</a>")
 *
 * @param {string} body - Text that may contain <a> tags
 *
 * @returns {string} Returns the same body with the links that now can be opened in new tab
 */
const blankTargetAllLinks = (body) => {
    const html = document.createElement('div');
    html.innerHTML = body;
    // Get all links and add `target="_blank"` attribute
    const links = html.getElementsByTagName('a');
    for (let i = 0, len = links.length; i < len; i += 1) {
        links[i].target = '_blank';
        links[i].rel = 'noopener noreferrer';
    }
    return html.innerHTML;
};

export default RendreDoc;
