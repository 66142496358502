// module
import React from "react";
import { trackGAEvent } from "../../utils/globalConstant";

function AdsPanel({ whichSide, ads }) {
  return (
    <>
      {ads.length > 1 && (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ gap: "5px" }}
        >
          {ads.map((ad, index) => {
            if (whichSide === "Left") {
              if (index % 2 === 0 && ad.name.includes("side-bar-1.6")) {
                return (
                  <div
                    key={ad.id}
                    className="flex-1 d-flex justify-content-center align-items-center"
                    style={{
                      overflow: "hidden",
                      maxWidth: 200,
                    }}
                    onMouseDown={(e) => {
                      if (e.button === 1 || e.button === 2) {
                        trackGAEvent(
                          "Click on the left side advertisement",
                          ad.advertisement_name,
                          ad.advertisement_link,
                          0
                        );
                      }
                    }}
                    onClick={() => {
                      trackGAEvent(
                        "Click on the left side advertisement",
                        ad.advertisement_name,
                        ad.advertisement_link,
                        0
                      );
                    }}
                  >
                    <a
                      href={ad.advertisement_link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="w-100"
                        style={{ maxWidth: "300px" }}
                        src={ad.url}
                        alt={ad.advertisement_name}
                      />
                    </a>
                  </div>
                );
              } else return null;
            } else if (whichSide === "Right") {
              if (index % 2 !== 0 && ad.name.includes("side-bar-1.6")) {
                return (
                  <div
                    key={ad.id}
                    className="flex-1 d-flex justify-content-center align-items-center"
                    style={{
                      overflow: "hidden",
                      maxWidth: 200,
                    }}
                    onMouseDown={(e) => {
                      if (e.button === 1 || e.button === 2) {
                        trackGAEvent(
                          "Click on the right side advertisement",
                          ad.advertisement_name,
                          ad.advertisement_link,
                          0
                        );
                      }
                    }}
                    onClick={() => {
                      trackGAEvent(
                        "Click on the right side advertisement",
                        ad.advertisement_name,
                        ad.advertisement_link,
                        0
                      );
                    }}
                  >
                    <a
                      href={ad.advertisement_link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="w-100"
                        style={{ maxWidth: "300px" }}
                        src={ad.url}
                        alt={ad.advertisement_name}
                      />
                    </a>
                  </div>
                );
              } else return null;
            } else return null;
          })}
        </div>
      )}
    </>
  );
}

export default AdsPanel;
