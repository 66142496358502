// modules
import React from 'react';
import { StyleSheet, View, Text, Image, Link } from '@react-pdf/renderer';

function BioretentionTemplate({ nodeInfo, image, title, documentLinks }) {
    console.log(
        'nodeInfo: ',
        nodeInfo,
        'image: ',
        image,
        'title: ',
        title,
        'document link: ',
        documentLinks,
    );
    const styles = StyleSheet.create({
        title: {
            fontSize: 14,
            textAlign: 'center',
            marginBottom: '3%',
        },
        image: {
            width: '100%',
        },
        link: {
            textAlign: 'center',
            fontSize: 9,
            marginBottom: 5,
        },
        // TABLE
        table: {
            padding: 5,
        },
        tableTitle: {
            width: '100%',
            textAlign: 'center',
            fontSize: 14,
            marginBottom: 5,
        },
        tableRow: {
            flexDirection: 'row',
            height: '60px',
        },

        tableRow_result: {
            flexDirection: 'row',
            height: '30px',
        },

        tableCol: {
            flex: 1,
            textAlign: 'center',
            fontSize: 9,
            border: 1,
            padding: 5,
        },
    });

    return (
        <>
            <Text style={styles.tableTitle}>Specifications and Typical Drawings - {title}</Text>
            {image && <Image style={styles.image} src={image} />}
            {documentLinks
                ? documentLinks.map((link, index) => {
                      return (
                          <Link
                              key={link['manufacture_device_document_link']}
                              src={link['manufacture_device_document_link']}
                              style={styles.link}
                          >
                              {link['document_name'] ? link['document_name'] : `document ${index}`}
                          </Link>
                      );
                  })
                : null}
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <Text
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            fontSize: '9',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            color: 'red',
                            paddingTop: '20px',
                        }}
                    >
                        Catchment Name
                    </Text>
                    <View
                        style={{
                            flex: 2,
                            textAlign: 'center',
                            fontSize: '9',
                            borderWidth: '1px 0 0 1px',
                            borderStyle: 'solid',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Text
                            style={{
                                flex: 1,
                                textAlign: 'center',
                                fontSize: '9',
                                color: 'red',
                                paddingTop: '6px',
                            }}
                        >
                            Area
                        </Text>
                        <View style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                            <Text
                                style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    fontSize: '9',
                                    borderTop: '1px solid black',
                                    paddingTop: '5px',
                                    borderRight: '1px solid black',
                                }}
                            >
                                Surface(m²)
                            </Text>
                            <Text
                                style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    fontSize: '9',
                                    borderLeft: '1px solid black',
                                    borderTop: '1px solid black',
                                    paddingTop: '5px',
                                }}
                            >
                                Filter(m²)
                            </Text>
                        </View>
                    </View>

                    <Text
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            fontSize: '9',
                            borderWidth: '1px 0 0 1px',
                            borderStyle: 'solid',
                            color: 'red',
                            paddingTop: '20px',
                            paddingBottom: '20px',
                        }}
                    >
                        EDD height (mm)
                    </Text>

                    <View
                        style={{
                            flex: 2,
                            textAlign: 'center',
                            fontSize: '9',
                            borderWidth: '1px 0 0 1px',
                            borderStyle: 'solid',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Text
                            style={{
                                flex: 1,
                                textAlign: 'center',
                                fontSize: '9',
                                color: 'red',
                                paddingTop: '6px',
                            }}
                        >
                            Filter Layer
                        </Text>
                        <View style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                            <Text
                                style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    fontSize: '9',
                                    borderTop: '1px solid black',
                                    paddingTop: '3px',
                                    borderRight: '1px solid black',
                                }}
                            >
                                Height (mm)
                            </Text>
                            <Text
                                style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    fontSize: '9',
                                    borderLeft: '1px solid black',
                                    borderTop: '1px solid black',
                                    paddingTop: '3px',
                                }}
                            >
                                Permeability (mm/hr)
                            </Text>
                        </View>
                    </View>
                    <Text
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            fontSize: '9',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            color: 'red',
                            paddingTop: '20px',
                        }}
                    >
                        Bio height (mm)
                    </Text>
                </View>
                {nodeInfo.map((node) => {
                    return (
                        <View style={styles.tableRow_result} key={node['impAreaName']}>
                            <Text style={styles.tableCol}>
                                {node['impAreaName'] && node['impAreaName']}
                                {node['pAreaName'] && node['pAreaName']}
                                {!node['impAreaName'] && !node['pAreaName'] && 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node['surfaceArea'] ? node['surfaceArea'] : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node['filterArea'] ? node['filterArea'] : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node['eddHeight'] ? +node['eddHeight'] : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node['filterLayerHeight'] ? +node['filterLayerHeight'] : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node['filterPermeability'] ? +node['filterPermeability'] : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node['eddHeight'] && node['filterLayerHeight']
                                    ? (
                                          +node['eddHeight'] +
                                          +node['filterLayerHeight'] +
                                          350
                                      ).toFixed(2)
                                    : 'N/A'}
                            </Text>
                        </View>
                    );
                })}
            </View>
        </>
    );
}
export default BioretentionTemplate;
