import React from "react";
import { StyleSheet, View, Text } from "@react-pdf/renderer";

const imperviousColumnNames = [
  "impAreaName",
  "impAreaType",
  "impAreaSize",
  "impAreaTreatType",
  "impAreaTreatSize",
];

const perviousTableHeaders = [
  "Pervious Area Name",
  "Pervious \n Area Type",
  "Pervious \n Area (m²)",
  "Treatment Type",
  "Treatment \n Area (m²) / Volume (L)",
];
const perviousColumnNames = [
  "pAreaName",
  "pAreaType",
  "pAreaSize",
  "pAreaTreatType",
  "pAreaTreatSize",
];

export function ExistingModel({
  wqMethod,
  imperviousNodes,
  perviousNodes,
  eolTreatment,
  eolTreatmentSize,
}) {
  const styles = StyleSheet.create({
    body: {
      paddingTop: 30,
      paddingBottom: 10,
      paddingHorizontal: 35,
    },
    container: {
      height: "70%",
    },
    title: {
      textAlign: "center",
      fontSize: 20,
      margin: 10,
    },
    text1: {
      textAlign: "center",
      fontSize: 15,
      fontWeight: 700,
      margin: 10,
    },
    table: {
      display: "table",
      width: "100%",
    },
    tableRow: {
      width: "100%",
      flexDirection: "row",
      textAlign: "center",
    },
    tableCell: {
      height: "100%",
      width: "100%",
      padding: 5,
      border: "1px solid #658cbb",
      fontSize: 10,
    },
  });

  const imperviousTableHeaders = [
    "Impervious Area Name",
    "Impervious \n Area Type",
    "Impervious \n Area (m²)",
    wqMethod.includes("EOLStandard")
      ? "Primary & Secondary* Treatment Name"
      : "Treatment Type",
    wqMethod.includes("EOLStandard")
      ? "Primary & Secondary* Treatment Size(m²) f/L/items"
      : "Treatment \n Area (m²) f/L/items",
    wqMethod.includes("EOLStandard")
      ? "No. of Bedrooms / Occupants"
      : "End-of-line** Device",
  ];

  return (
    <View style={styles.container}>
      {/* Impervious Nodes */}
      <Text style={styles.title}>
        {wqMethod.includes("NorBE")
          ? "Treatment Trains"
          : "Treatment Trains - Existing Model"}
      </Text>
      <Text style={styles.title}>Impervious Nodes</Text>
      <View style={styles.tableRow}>
        {imperviousTableHeaders.map((header, index) => (
          <Text key={index} style={styles.tableCell}>
            {header}
          </Text>
        ))}
      </View>
      {imperviousNodes.map((node) => {
        return (
          <View key={node.id ? node.id : -1} style={styles.tableRow}>
            {imperviousColumnNames.map((columnName) => {
              return (
                <Text key={columnName} style={styles.tableCell}>
                  {node[columnName] ? node[columnName] : "N/A"}
                </Text>
              );
            })}
            <Text style={styles.tableCell}>
              {node["impAreaBrooms"] ? node["impAreaBrooms"] : "N/A"}
            </Text>
            {wqMethod.includes("EOLStandard") && (
              <Text style={styles.tableCell}>
                {eolTreatmentSize} L/s {eolTreatment}
              </Text>
            )}
          </View>
        );
      })}
      {wqMethod.includes("EOLStandard") && (
        <>
          <Text>
            * Primary and Secondary means this device will treat Gros Pollutants
            and Coarse Sediments.
          </Text>
          <Text>
            ** End of Line devices are Secondary and Tertiary devices. This
            means they will treat Fine Sediments and Dissolved Pollutants.
          </Text>
        </>
      )}
      {/* Pervious Nodes */}
      <Text style={styles.title}>Pervious Nodes</Text>
      <View style={styles.tableRow}>
        {perviousTableHeaders.map((header, index) => (
          <Text key={index} style={styles.tableCell}>
            {header}
          </Text>
        ))}
      </View>
      {perviousNodes.map((node) => {
        return (
          <View key={node.id ? node.id : -1} style={styles.tableRow}>
            {perviousColumnNames.map((columnName) => {
              return (
                <Text key={columnName} style={styles.tableCell}>
                  {node[columnName] ? node[columnName] : "N/A"}
                </Text>
              );
            })}
          </View>
        );
      })}
    </View>
  );
}

export function DevelopedModel({ imperviousNodes, perviousNodes }) {
  const styles = StyleSheet.create({
    body: {
      paddingTop: 30,
      paddingBottom: 10,
      paddingHorizontal: 35,
    },
    container: {
      height: "70%",
    },
    title: {
      textAlign: "center",
      fontSize: 20,
      margin: 10,
    },
    text1: {
      textAlign: "center",
      fontSize: 15,
      fontWeight: 700,
      margin: 10,
    },
    table: {
      display: "table",
      width: "100%",
    },
    tableRow: {
      width: "100%",
      flexDirection: "row",
      textAlign: "center",
    },
    tableCell: {
      height: "100%",
      width: "100%",
      padding: 5,
      border: "1px solid #658cbb",
      fontSize: 10,
    },
  });

  const imperviousTableHeaders = [
    "Impervious Area Name",
    "Impervious \n Area Type",
    "Impervious \n Area (m²)",
    "Treatment Name",
    "Treatment \n Area (m²/L/items)",
    "No. of Bedrooms / Occupants",
  ];

  return (
    <View style={styles.container}>
      {/* Impervious Nodes */}
      <Text style={styles.title}>Treatment Trains</Text>
      <Text style={styles.title}>Impervious Nodes</Text>
      <View style={styles.tableRow}>
        {imperviousTableHeaders.map((header, index) => (
          <Text key={index} style={styles.tableCell}>
            {header}
          </Text>
        ))}
      </View>
      {imperviousNodes.map((node) => {
        return (
          <View key={node.id ? node.id : -1} style={styles.tableRow}>
            {imperviousColumnNames.map((columnName) => {
              return (
                <Text key={columnName} style={styles.tableCell}>
                  {node[columnName] ? node[columnName] : "N/A"}
                </Text>
              );
            })}
            <Text style={styles.tableCell}>
              {node["impAreaBrooms"] ? node["impAreaBrooms"] : "N/A"}
            </Text>
          </View>
        );
      })}
      {/* Pervious Nodes */}
      <Text style={styles.title}>Pervious Nodes</Text>
      <View style={styles.tableRow}>
        {perviousTableHeaders.map((header, index) => (
          <Text key={index} style={styles.tableCell}>
            {header}
          </Text>
        ))}
      </View>
      {perviousNodes.map((node) => {
        return (
          <View key={node.id ? node.id : -1} style={styles.tableRow}>
            {perviousColumnNames.map((columnName) => {
              return (
                <Text key={columnName} style={styles.tableCell}>
                  {node[columnName] ? node[columnName] : "N/A"}
                </Text>
              );
            })}
          </View>
        );
      })}
    </View>
  );
}
