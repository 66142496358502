import axios from 'axios';

const baseUrl = 'https://xgr5xf78jh.execute-api.ap-southeast-2.amazonaws.com/rwt-demand-tool';

const endpoint = {
    occupancyCalculator: 'occupancyCalculator',
    indoorDemandCalculator: 'indoorDemandCalculator',
    irrigationDemandCalculator: 'irrigationDemandCalculator',
    reportGenerationLog: 'report-generation-log',
};

const obj = {};

/**
 * Calculate occupancy demand
 *
 * @param {Object}   data
 * @param {string}   data.method
 * @param {Object}   data.params
 *
 * @returns {Promise<number>}
 * @throws {Error} Problems during sending the HTTP request
 */
obj.calculateOccupancy = async (data) => {
    const payload = data;
    return await axios
        .post(`${baseUrl}?endpoint=${endpoint.occupancyCalculator}`, payload)
        .then((res) => {
            return res.data.result;
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code out of the rnage of 2xx
                console.log(error.response.data);
                throw Error('Something wrong happend!');
            } else if (error.request) {
                // The request was made but no response was received
                throw Error('Server is unreachable! Please contanct support.');
            } else {
                throw Error("Calculation couldn't be perfomred! Please contact support.");
            }
        });
};
/**
 * Fetch rainwater tank data
 *
 * @param {Object} mapInfo
 * @returns {Promise<Object>}
 * @throws {Error}
 */
obj.fetchRainwaterTankData = async (mapInfo) => {
    if (!mapInfo || !mapInfo.rainfallStation) {
        throw new Error('Invalid mapInfo or rainfallStation.');
    }

    const query = `SELECT * FROM rainwatertank.${mapInfo.rainfallStation.replace(/\s+/g, '_')} LIMIT 1;`;
    const API = `https://xgr5xf78jh.execute-api.ap-southeast-2.amazonaws.com/dbquery`;
    const payload = { query };

    try {
        const response = await axios.post(API, payload);
        return response.data.results;
    } catch (error) {
        if (error.response) {
            console.log(error.response.data);
            throw new Error('Something went wrong with the server response.');
        } else if (error.request) {
            throw new Error('Server is unreachable. Please contact support.');
        } else {
            throw new Error(
                'An error occurred during the request setup. Please contact support.',
                error,
            );
        }
    }
};
/**
 * Calculate indoor demand
 *
 * @param {Object}   data
 * @param {string}   data.method
 * @param {Object}   data.params
 *
 * @returns {Promise<number>}
 * @throws {Error} Problems during sending the HTTP request
 */
obj.calculateIndoorDemand = async (data) => {
    const payload = data;
    return await axios
        .post(`${baseUrl}?endpoint=${endpoint.indoorDemandCalculator}`, payload)
        .then((res) => {
            return res.data.result;
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code out of the rnage of 2xx
                console.log(error.response.data);
                throw Error('Something wrong happend!');
            } else if (error.request) {
                // The request was made but no response was received
                throw Error('Server is unreachable! Please contanct support.');
            } else {
                throw Error("Calculation couldn't be perfomred! Please contact support.");
            }
        });
};

/**
 * Calculate outdoor demand
 *
 * @param {Object}   data
 * @param {string}   data.method
 * @param {Object}   data.params
 *
 * @returns {Promise<number>}
 * @throws {Error} Problems during sending the HTTP request
 */
obj.calculateIrrigationDemand = async (data) => {
    const payload = data;
    return await axios
        .post(`${baseUrl}?endpoint=${endpoint.irrigationDemandCalculator}`, payload)
        .then((res) => {
            return res.data.result;
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code out of the rnage of 2xx
                console.log(error.response.data);
                throw Error('Something wrong happend!');
            } else if (error.request) {
                // The request was made but no response was received
                throw Error('Server is unreachable! Please contanct support.');
            } else {
                throw Error("Calculation couldn't be perfomred! Please contact support.");
            }
        });
};

/**
 * Get methods, fields, documentation for each tool from the server
 *
 * @returns {Promise<Object>}
 * @throws {Error} Problems during sending the HTTP request
 */
obj.getCalculators = async () => {
    return await axios
        .get(`${baseUrl}`)
        .then((res) => {
            const calculators = {};
            res.data.result.forEach((calculator) => {
                calculators[calculator.id] = calculator;
            });
            return calculators;
        })
        .catch((error) => {
            console.log('[RWT_API_ERROR_REQ]', error.request);
            console.log('[RWT_API_ERROR_RES]', error.response);
            if (error.response) {
                // The request was made and the server responded with a status code out of the rnage of 2xx
                console.log(error.response);
                switch (error.response.status) {
                    case 500: // Server error
                        throw Error('Something wrong happend!');
                    case 503: // Unreachable service
                        throw Error(
                            'Service unreachable. Please refersh the page or try again later!',
                        );
                    case 404: // not found
                        throw Error('Service doese not exists. Please contact support!');
                    default: // other
                        throw Error('Something wrong happend!');
                }
            } else if (error.request) {
                // The request was made but no response was received
                throw Error(
                    'There is a problem communicating with the server. Please refersh the page or try again later!',
                );
            } else {
                throw Error('Something wrong happend!');
            }
        });
};

/**
 * Log "report generation" clicks
 *
 * @returns {Promise<Object>}
 * @throws {Error} Problems during sending the HTTP request
 */
obj.logReportGeneration = async (data) => {
    const payload = data;
    return await axios
        .post(`${baseUrl}?endpoint=${endpoint.reportGenerationLog}`, payload)
        .then((res) => {
            return res.data.result;
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code out of the rnage of 2xx
                console.log(error.response.data);
                throw Error('Something wrong happend!');
            } else if (error.request) {
                // The request was made but no response was received
                throw Error('Server is unreachable! Please contanct support.');
            } else {
                throw Error("Calculation couldn't be perfomred! Please contact support.");
            }
        });
};

obj.getAnnualET = async (lat, lon) => {
    return await axios
        .get(`https://cleanstormwater.ddns.net:7749/sails/getMonthlyET?lat=${lat}&lon=${lon}`)
        .then((res) => {
            return res.data.monthlyET;
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code out of the rnage of 2xx
                console.log(error.response.data);
                throw Error('Something wrong happend!');
            } else if (error.request) {
                // The request was made but no response was received
                throw Error('Server is unreachable! Please contanct support.');
            } else {
                throw Error(
                    'Something wrong happend while retrieving the value of Annual Evapotranspiration!',
                );
            }
        });
};

export default obj;
