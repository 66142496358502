import React from 'react';
import {
    TooltipButton,
    TooltipContainer,
    TooltipContent,
} from '../../styled-components/TooltipComponents';
import { isFloat } from '../../../utils/common';
import Tooltip from '../../common/Tooltip/Tooltip';

const Optional = ({ climateChangeRate, setClimateChangeRateChange, tankHeight, setTankHeight }) => {
    return (
        <div className="osd-form-section-container osd-blue-border">
            <div className="osd-form-section">
                <span className="title-2">Optional</span>
            </div>
            <div className="osd-form-section">
                <div className="span-7">
                    - Increase OSD storage volume for Climate Change variation by:{' '}
                    <input
                        style={{ maxWidth: '100px' }}
                        value={climateChangeRate}
                        placeholder="Optional"
                        onChange={(e) => {
                            if (isFloat(e.target.value)) setClimateChangeRateChange(e.target.value);
                        }}
                        className="mx-2"
                    />
                    % from calculated results.
                    <Tooltip>
                        <span>
                            Default value as per Council rules. Please consult Council Engineer if a
                            lower value is desired.
                            <br /> 0% indicates no climate change adjustment.
                        </span>
                    </Tooltip>
                    {/* <TooltipContainer> */}
                    {/*     <TooltipButton>❓</TooltipButton> */}
                    {/*     <TooltipContent> */}
                    {/*         <span> */}
                    {/*             Default value as per Council rules. Please consult Council Engineer */}
                    {/*             if a lower value is desired. */}
                    {/*             <br /> 0% indicates no climate change adjustment. */}
                    {/*         </span> */}
                    {/*     </TooltipContent> */}
                    {/* </TooltipContainer> */}
                </div>
                <div className="span-7">
                    <span>- Height of Storage Above Orifice:</span>
                    <input
                        style={{ maxWidth: '100px' }}
                        value={tankHeight}
                        min={0}
                        placeholder="Optional"
                        onChange={(e) => {
                            if (isFloat(e.target.value)) setTankHeight(e.target.value);
                        }}
                        className="mx-2"
                    />
                    (m)
                    <Tooltip
                        link="https://ipweaq.intersearch.com.au/ipweaqjspui/bitstream/1/4983/1/2042%20QUDM%20FINAL%2018%20August%202017%20%282%29.pdf"
                        style={{ maxWidth: '40rem' }}
                    >
                        <img
                            alt=""
                            src="https://csw-public.s3.ap-southeast-2.amazonaws.com/osd/boyds/Orifice+Equation.png"
                        />
                        <p>Used to calculate orifice size based on the standard orifice equation</p>
                    </Tooltip>
                    {/* <TooltipContainer> */}
                    {/*     <TooltipButton> */}
                    {/*         <a */}
                    {/*             href="https://ipweaq.intersearch.com.au/ipweaqjspui/bitstream/1/4983/1/2042%20QUDM%20FINAL%2018%20August%202017%20%282%29.pdf" */}
                    {/*             rel="noreferrer" */}
                    {/*             target="_blank" */}
                    {/*         > */}
                    {/*             ❓ */}
                    {/*         </a> */}
                    {/*     </TooltipButton> */}
                    {/*     <TooltipContent> */}
                    {/*         <img */}
                    {/*             alt="" */}
                    {/*             src="https://csw-public.s3.ap-southeast-2.amazonaws.com/osd/boyds/Orifice+Equation.png" */}
                    {/*             style={{ maxWidth: '40rem' }} */}
                    {/*         /> */}
                    {/*         <p> */}
                    {/*             Used to calculate orifice size based on the standard orifice */}
                    {/*             equation */}
                    {/*         </p> */}
                    {/*     </TooltipContent> */}
                    {/* </TooltipContainer> */}
                </div>
            </div>
        </div>
    );
};

export default Optional;
