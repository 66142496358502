// OverallResults.jsx

import React, { useEffect, useState } from 'react';
import ResultsTable from './ResultsTable';
import styles from './OverallResults.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { selectWSUDResults } from '../../../../utils/redux/WSUD/wsudResultsSlice';
import { selectAreas } from '../../../../utils/redux/WSUD/areaSlice';
import { selectSiteDetails } from '../../../../utils/redux/WSUD/siteDetailsSlice';
import {
    updateSourceFlow,
    updateResidualFlow,
    updateTotalVolumeReused,
    updateTotalReduction,
} from '../../../../utils/redux/WSUD/overallFlowReductionSlice';

const OverallResults = ({ targetReductions, cityCouncil, rwtData }) => {
    const dispatch = useDispatch();
    const overallResults = useSelector(selectWSUDResults);
    const areaResults = useSelector(selectAreas);
    const siteDetails = useSelector(selectSiteDetails);
    const [sourceFlow, setSourceFlow] = useState(0);
    const [totalResidual, setTotalResidual] = useState(0);
    const [totalReduction, setTotalReduction] = useState(0);
    const [totalVolumeReused, setTotalVolumeReused] = useState(0);
    const [demandValue, setDemandValue] = useState(62);
    useEffect(() => {
        if (siteDetails.rainwaterDemand === 'Toilet + Laundry') {
            setDemandValue(62);
        } else {
            setDemandValue(20);
        }
    }, [siteDetails]);
    useEffect(() => {
        if (areaResults) {
            let totalSourceFlow = 0;
            let totalResidualFlow = 0;
            let totalReduction = 0;
            let totalVolumeReused = 0;
            const processAreas = (areas) => {
                areas.forEach((area) => {
                    const result = area.result;
                    if (result) {
                        const sourceFlow = parseFloat(result.sourceFlow) || 0;
                        const residualFlow = parseFloat(result.residualFlow) || 0;
                        const reductionPercFlow = parseFloat(result.reductionPercFlow) || 0;
                        const numberOfOccupants = parseFloat(area.numberOfOccupants) || 0;
                        const waterSupplyReliability =
                            parseFloat(result.waterSupplyReliability) || 0;

                        totalSourceFlow += sourceFlow;
                        totalResidualFlow += residualFlow;
                        totalReduction += (sourceFlow * reductionPercFlow) / 100;
                        const volumeReused =
                            ((numberOfOccupants * demandValue * 365) / 1000) *
                            (waterSupplyReliability / 100);
                        totalVolumeReused += volumeReused;
                    }
                });
            };
            if (areaResults.pervious) {
                processAreas(areaResults.pervious);
            }
            if (areaResults.impervious) {
                processAreas(areaResults.impervious);
            }
            dispatch(updateSourceFlow((totalSourceFlow * 1000).toFixed(3)));
            dispatch(updateResidualFlow((totalResidualFlow * 1000).toFixed(3)));
            dispatch(updateTotalVolumeReused(totalVolumeReused.toFixed(3)));
            dispatch(updateTotalReduction((totalReduction * 1000).toFixed(3)));
            setSourceFlow(totalSourceFlow * 1000);
            setTotalResidual(totalResidualFlow * 1000);
            setTotalReduction(totalReduction * 1000);
            setTotalVolumeReused(totalVolumeReused);
        }
    }, [areaResults]);

    if (overallResults !== null) {
        return (
            <div className={styles.overallResults}>
                <p style={{ fontSize: 'larger' }}>Live Results</p>
                <div className={styles.resultsContainer}>
                    <div style={{ width: '40%' }}>
                        <ResultsTable
                            title="Overall STORM Ratings for LGA"
                            header={['TN (%)', 'TP (%)', 'TSS (%)', 'GP (%)']}
                            results={[
                                [
                                    overallResults.overallRatings?.tnRate,
                                    overallResults.overallRatings?.tpRate,
                                    overallResults.overallRatings?.tssRate,
                                    overallResults.overallRatings?.gpRate,
                                ],
                            ]}
                            greenOrRed={{
                                '00': parseFloat(overallResults.overallRatings?.tnRate) > 100,
                                '01': parseFloat(overallResults.overallRatings?.tpRate) > 100,
                                '02': parseFloat(overallResults.overallRatings?.tssRate) > 100,
                                '03': parseFloat(overallResults.overallRatings?.gpRate) > 100,
                            }}
                        />
                        {overallResults.overallRatings?.tpRate === 'NaN' && (
                            <h5 className={styles['alert']} style={{ color: 'red' }}>
                                Please enter all inputs
                            </h5>
                        )}
                        <h5>
                            Reaching a 100% STORM rating indicates that you have successfully met
                            the prescribed target reductions for this Authority. For instance,
                            achieving a STORM TN of 100% implies that you have fulfilled the target
                            pollutant reductions specified on the right.
                        </h5>
                        <ResultsTable
                            title="Overall Flow Reductions (abs value)"
                            header={[
                                'Source (KL/yr)',
                                'Residual (KL/yr)',
                                'Total Reductions (KL/yr)',
                                'Volume Reused (KL/yr)',
                            ]}
                            results={[
                                [
                                    sourceFlow.toFixed(2),
                                    totalResidual.toFixed(2),
                                    totalReduction.toFixed(2),
                                    totalVolumeReused.toFixed(2),
                                ],
                            ]}
                            greenOrRed={{
                                '00': undefined,
                                '01': undefined,
                                '02': undefined,
                                '03': undefined,
                            }}
                        />
                    </div>

                    <div name="reductionTable">
                        <ResultsTable
                            title="Overall Reductions"
                            header={['', 'TN (%)', 'TP (%)', 'TSS (%)', 'GP (%)', 'Flow (%)']}
                            results={[
                                [
                                    'Achieved Reductions',
                                    overallResults.overallReductions?.tnReduction,
                                    overallResults.overallReductions?.tpReduction,
                                    overallResults.overallReductions?.tssReduction,
                                    overallResults.overallReductions?.gpReduction,
                                    overallResults.overallReductions?.flowReduction,
                                ],
                                [
                                    'LGA Target Reductions',
                                    targetReductions?.tn,
                                    targetReductions?.tp,
                                    targetReductions?.tss,
                                    targetReductions?.gp,
                                    'N/A',
                                ],
                                ['Greenstar Credit Achievement', 45, 65, 85, 90, 'N/A'],
                                ['Greenstar Exeptional Performance', 60, 70, 90, 95, 'N/A'],
                            ]}
                            greenOrRed={{
                                '01':
                                    parseFloat(overallResults.overallReductions?.tnReduction) >
                                    targetReductions.tn,
                                '02':
                                    parseFloat(overallResults.overallReductions?.tpReduction) >
                                    targetReductions.tp,
                                '03':
                                    parseFloat(overallResults.overallReductions?.tssReduction) >
                                    targetReductions.tss,
                                '04':
                                    parseFloat(overallResults.overallReductions?.gpReduction) >
                                    targetReductions.gp,
                            }}
                        />
                        <label htmlFor="reductionTable">
                            Required Target Reductions For {cityCouncil}
                        </label>
                    </div>
                </div>
            </div>
        );
    } else return <></>;
};

export default OverallResults;
