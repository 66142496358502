import React from 'react';
import { StyleSheet, View, Text, Image, Link } from '@react-pdf/renderer';
import { stringOrNumber } from '../../auditorUtils';

function SedbasinTemplate({ nodeInfo, image, title, documentLinks }) {
    const styles = StyleSheet.create({
        title: {
            fontSize: 14,
            textAlign: 'center',
            marginBottom: '3%',
        },
        image: {
            width: '100%',
        },
        link: {
            textAlign: 'center',
            fontSize: 9,
            marginBottom: '2vh',
            marginTop: '2vh',
        },
        superscript: {
            fontSize: 5,
            lineHeight: 5,
        },
        // TABLE
        table: {
            padding: 3,
        },
        tableTitle: {
            width: '100%',
            textAlign: 'center',
            fontSize: 14,
            marginBottom: '4vh',
        },
        tableRow: {
            flexDirection: 'row',
            height: '70px',
        },
        tableRow_result: {
            flexDirection: 'row',
        },
        tableCol: {
            flex: 1,
            textAlign: 'center',
            fontSize: 8,
            border: 1,
            padding: 5,
        },
        columnTitle: {
            flex: 1,
            textAlign: 'center',
            fontSize: 9,
            borderWidth: 1,
            borderStyle: 'solid',
            color: 'red',
            paddingTop: 20,
        },
    });
    const calcExfiltrationArea = (node) => {
        const er =
            node['Exfiltration Rate (mm/hr)'] ||
            node['Storage and Infiltration Properties - Exfiltration Rate (mm/hr)'];
        const sArea =
            node['Surface Area (m2)'] ||
            node['Storage and Infiltration Properties - Surface Area (square metres)'];
        const depth =
            node['Permanent Pool Average Depth (m)'] ||
            node['Storage and Infiltration Properties - Permanent Pool Volume (cubic metres)'];

        if (sArea && depth) {
            const circumference = 2 * Math.sqrt(Math.PI * +sArea);
            const tea = +sArea + circumference * +depth;
            return +tea.toFixed(1);
        }
        return 0;
    };

    const calcExfiltrationOutflow = (node) => {
        const tea = calcExfiltrationArea(node);
        const er =
            node['Exfiltration Rate (mm/hr)'] ||
            node['Storage and Infiltration Properties - Exfiltration Rate (mm/hr)'];
        if (tea && er) {
            const filterRate = (tea * er) / 3600;
            return +filterRate.toFixed(4);
        }
        return 0;
    };

    return (
        <>
            <Text style={styles.tableTitle}>Specifications and Typical Drawings - {title}</Text>
            {image && <Image style={styles.image} src={image} />}
            {documentLinks
                ? documentLinks.map((link, index) => {
                      return (
                          <Link
                              key={link['manufacture_device_document_link']}
                              src={link['manufacture_device_document_link']}
                              style={styles.link}
                          >
                              {link['document_name'] ? link['document_name'] : `document ${index}`}
                          </Link>
                      );
                  })
                : null}
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <Text
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            fontSize: '9',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            color: 'red',
                            paddingTop: '20px',
                        }}
                    >
                        {'  '}Device Name
                    </Text>

                    <Text
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            fontSize: '9',
                            borderWidth: '1px 0 0 1px',
                            borderStyle: 'solid',
                            color: 'red',
                            paddingTop: '20px',
                            paddingBottom: '20px',
                        }}
                    >
                        Surface {'  '} Area (m2)
                    </Text>

                    <Text
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            fontSize: '9',
                            borderWidth: '1px 0 0 1px',
                            borderStyle: 'solid',
                            color: 'red',
                            paddingTop: '20px',
                            paddingBottom: '20px',
                        }}
                    >
                        EDD height (mm)
                    </Text>

                    <View
                        style={{
                            flex: 2,
                            textAlign: 'center',
                            fontSize: '9',
                            borderWidth: '1px 0 0 1px',
                            borderStyle: 'solid',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Text
                            style={{
                                flex: 1,
                                textAlign: 'center',
                                fontSize: '9',
                                color: 'red',
                                paddingTop: '6px',
                            }}
                        >
                            Permanent Pool
                        </Text>
                        <View style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                            <Text
                                style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    fontSize: '9',
                                    borderTop: '1px solid black',
                                    paddingTop: '5px',
                                    borderRight: '1px solid black',
                                }}
                            >
                                Average Depth(m)
                            </Text>
                            <Text
                                style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    fontSize: '9',
                                    borderLeft: '1px solid black',
                                    borderTop: '1px solid black',
                                    paddingTop: '5px',
                                }}
                            >
                                Volume (m<Text style={styles.superscript}>3</Text>)
                            </Text>
                        </View>
                    </View>

                    <View
                        style={{
                            flex: 2,
                            textAlign: 'center',
                            fontSize: '9',
                            borderWidth: '1px 0 0 1px',
                            borderStyle: 'solid',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Text
                            style={{
                                flex: 1,
                                textAlign: 'center',
                                fontSize: '9',
                                color: 'red',
                                paddingTop: '6px',
                            }}
                        >
                            Exfiltration
                        </Text>
                        <View style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                            <Text
                                style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    fontSize: '9',
                                    borderTop: '1px solid black',
                                    paddingTop: '5px',
                                    borderRight: '1px solid black',
                                }}
                            >
                                Rate(mm/hr)
                            </Text>
                            <Text
                                style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    fontSize: '9',
                                    borderLeft: '1px solid black',
                                    borderTop: '1px solid black',
                                    paddingTop: '5px',
                                }}
                            >
                                Outflow(L/s)
                            </Text>
                        </View>
                    </View>

                    <Text
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            fontSize: '9',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            color: 'red',
                            paddingTop: '20px',
                            flexWrap: 'nowrap',
                        }}
                    >
                        {' '}
                        Notional Detention Time (hrs)
                    </Text>

                    <Text
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            fontSize: '9',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            color: 'red',
                            paddingTop: '20px',
                        }}
                    >
                        Equivalent Pipe Diameter (mm)
                    </Text>
                </View>

                {nodeInfo.map((node) => {
                    return (
                        <View
                            style={styles.tableRow_result}
                            key={node['Node Name'] || node['Custom Name']}
                        >
                            <Text style={styles.tableCol}>
                                {node['Node Name'] || node['Custom Name'] || 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node[
                                    'Storage and Infiltration Properties - Surface Area (square metres)'
                                ]
                                    ? stringOrNumber(
                                          +node[
                                              'Storage and Infiltration Properties - Surface Area (square metres)'
                                          ],
                                      )
                                    : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node['Extended Detention Depth (m)'] !== undefined
                                    ? stringOrNumber(+node['Extended Detention Depth (m)'])
                                    : node[
                                            'Storage and Infiltration Properties - Extended Detention Depth (metres)'
                                        ] !== undefined
                                      ? stringOrNumber(
                                            +node[
                                                'Storage and Infiltration Properties - Extended Detention Depth (metres)'
                                            ],
                                        )
                                      : 'N/A'}
                            </Text>

                            <Text style={styles.tableCol}>
                                {node['Permanent Pool Average Depth (m)'] !== undefined
                                    ? stringOrNumber(+node['Permanent Pool Average Depth (m)'])
                                    : node[
                                            'Storage and Infiltration Properties - Permanent Pool Volume (cubic metres)'
                                        ] !== undefined
                                      ? stringOrNumber(
                                            +node[
                                                'Storage and Infiltration Properties - Permanent Pool Volume (cubic metres)'
                                            ],
                                        )
                                      : 'N/A'}
                            </Text>

                            <Text style={styles.tableCol}>
                                {node[
                                    'Storage and Infiltration Properties - Permanent Pool Volume (cubic metres)'
                                ]
                                    ? stringOrNumber(
                                          +node[
                                              'Storage and Infiltration Properties - Permanent Pool Volume (cubic metres)'
                                          ],
                                      )
                                    : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node[
                                    'Storage and Infiltration Properties - Exfiltration Rate (mm/hr)'
                                ] !== undefined &&
                                node[
                                    'Storage and Infiltration Properties - Exfiltration Rate (mm/hr)'
                                ] !== null
                                    ? stringOrNumber(
                                          +node[
                                              'Storage and Infiltration Properties - Exfiltration Rate (mm/hr)'
                                          ],
                                      )
                                    : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {calcExfiltrationOutflow(node) !== 0
                                    ? stringOrNumber(calcExfiltrationOutflow(node))
                                    : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node['Outlet Properties - Notional Detention Time (hrs)']
                                    ? stringOrNumber(
                                          +node[
                                              'Outlet Properties - Notional Detention Time (hrs)'
                                          ],
                                      )
                                    : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node['Outlet Properties - Equivalent Pipe Diameter (mm)']
                                    ? stringOrNumber(
                                          node['Outlet Properties - Equivalent Pipe Diameter (mm)'],
                                      )
                                    : 'N/A'}
                            </Text>
                        </View>
                    );
                })}
            </View>
        </>
    );
}

export default SedbasinTemplate;
