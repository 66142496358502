import { createSlice } from "@reduxjs/toolkit";

const companyIconSlice = createSlice({
  name: "companyIcon",
  initialState: {
    reportHeaderUrl: "",
  },
  reducers: {
    setReportHeaderUrl: (state, action) => {
      state.reportHeaderUrl = action.payload;
    },
  },
});

export const { setReportHeaderUrl } = companyIconSlice.actions;
export const selectCompanyIcon = (state) => state.companyIcon;
export default companyIconSlice.reducer;
