import React from 'react';
import { StyleSheet, View, Text } from '@react-pdf/renderer';

export default function StormRatingAndOverallReduction({
    wqMethod,
    overallResults,
    targetReductions,
    overallFlowReduction,
}) {
    console.log({ overallFlowReduction });
    const styles = StyleSheet.create({
        container: {
            width: '100%',
            height: '70%',
            marginBottom: 10,
            paddingRight: 10,
        },
        text1: {
            textAlign: 'center',
            fontSize: 15,
            fontWeight: 700,
            margin: 10,
        },
        text2: {
            textAlign: 'center',
            fontSize: 12,
            fontWeight: 700,
            margin: 10,
        },
        table: {
            display: 'table',
            width: '100%',
        },
        tableRow: {
            width: '100%',
            flexDirection: 'row',
            textAlign: 'center',
        },
        tableCell: {
            height: '100%',
            width: '100%',
            padding: 5,
            border: '1px solid #658cbb',
            fontSize: 10,
        },
    });
    const overallFlowReductionsTableHeaders = [
        'Source (KL/yr)',
        'Residual (KL/yr)',
        'Total Reductions (KL/yr)',
        'Volume Reused (KL/yr)',
    ];
    const overallFlowReductionsTableColumns = [
        'sourceFlow',
        'residualFlow',
        'totalVolumeReused',
        'totalReduction',
    ];

    const stormRatingTableHeaders = [
        'TN (%)',
        'TP (%)',
        'TSS (%)',
        'GP (%)',
        'Abs. Flow Reductions (%)',
    ];
    const stormRatingColumns = ['tnRate', 'tpRate', 'tssRate', 'gpRate', 'flowRate'];

    const overallReductionTableHeders = wqMethod.includes('Standard')
        ? [
              '',
              'TN Reduction (%)',
              'TP Reduction (%)',
              'TSS Reduction (%)',
              'GP Reduction (%)',
              'Flow Reduction (%)',
          ]
        : ['', 'TN Reduction', 'TP Reduction', 'TSS Reduction', 'GP Reduction', 'Flow Reduction'];
    const achievedReductionColumns = [
        'tnReduction',
        'tpReduction',
        'tssReduction',
        'gpReduction',
        'flowReduction',
    ];
    const targetReductionColumns = ['tn', 'tp', 'tss', 'gp'];

    return (
        <View style={styles.container}>
            {wqMethod.includes('Standard') && (
                <>
                    {/* Storm Rating */}
                    <Text style={styles.text1}>Storm Rating</Text>
                    <Text style={styles.text2}>100% means target achieved.</Text>
                    <View style={styles.tableRow}>
                        {stormRatingTableHeaders.map((header) => (
                            <Text key={header} style={styles.tableCell}>
                                {header}
                            </Text>
                        ))}
                    </View>
                    <View style={styles.tableRow}>
                        {stormRatingColumns.map((columnName) => {
                            return (
                                <Text key={columnName} style={styles.tableCell}>
                                    {overallResults.overallRatings[columnName]}
                                </Text>
                            );
                        })}
                    </View>
                </>
            )}
            {/* Overall Reductions */}
            <Text style={styles.text1}>Overall Reductions</Text>
            <View style={styles.tableRow}>
                {overallReductionTableHeders.map((header) => (
                    <Text key={header} style={styles.tableCell}>
                        {header}
                    </Text>
                ))}
            </View>
            <View style={styles.tableRow}>
                <Text style={styles.tableCell}>
                    {wqMethod.includes('Standard')
                        ? 'Achieved Reductions'
                        : 'Achieved Reductions (% from existing)'}
                </Text>
                {achievedReductionColumns.map((columnName) => {
                    return (
                        <Text key={columnName} style={styles.tableCell}>
                            {overallResults.overallReductions[columnName]}
                        </Text>
                    );
                })}
            </View>
            <View style={styles.tableRow}>
                <Text style={styles.tableCell}>
                    {wqMethod.includes('Standard')
                        ? 'Target Reductions'
                        : 'Target Reductions (% from existing)'}
                </Text>
                {targetReductionColumns.map((columnName) => {
                    return (
                        <Text key={columnName} style={styles.tableCell}>
                            {targetReductions[columnName]}
                        </Text>
                    );
                })}
                <Text style={styles.tableCell}>N/A</Text>
            </View>
            <Text style={styles.text1}>Overall Flow Reductions (abs value)</Text>
            <View style={styles.tableRow}>
                {overallFlowReductionsTableHeaders.map((header) => (
                    <Text key={header} style={styles.tableCell}>
                        {header}
                    </Text>
                ))}
            </View>
            <View style={styles.tableRow}>
                {overallFlowReductionsTableColumns.map((columnName) => {
                    return (
                        <Text key={columnName} style={styles.tableCell}>
                            {overallFlowReduction[columnName]}
                        </Text>
                    );
                })}
            </View>
        </View>
    );
}
