import React from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { Button } from 'react-bootstrap';

const ReliabilityTankSizeChart = ({
    chartRef,
    data,
    downloadChartAsImage,
    xAxisLabel = 'Tank Size (L)',
    yAxisLabel = 'Value',
    yAxisLabel2 = undefined,
}) => {
    // Check if data is available
    if (!data || data.length === 0) {
        console.error('Data is missing or empty for the chart.');
        return null; // Prevent rendering of the chart
    }

    const lineColorMap = {
        reliability: '#82ca9d',
        volumeReduction: '#8884d8',
        dischargeReduction: '#ff7300',
    };

    const lineColor =
        yAxisLabel === 'Reliability (%)'
            ? lineColorMap.reliability
            : yAxisLabel === 'Volume Reduction (m³)'
              ? lineColorMap.volumeReduction
              : lineColorMap.dischargeReduction;

    return (
        <div className="reliability-chart-container">
            <div ref={chartRef} className="chart-container">
                <LineChart
                    width={375}
                    height={300}
                    data={data}
                    margin={{ top: 50, left: 50, bottom: 50, right: 30 }}
                >
                    <Line type="monotone" dataKey={Object.keys(data[0])[1]} stroke={lineColor} />
                    <CartesianGrid stroke="#ccc" />
                    <XAxis
                        dataKey="tankSize"
                        label={{
                            value: xAxisLabel,
                            position: 'insideBottom',
                            textAnchor: 'middle',
                            offset: -15,
                            fontSize: 14,
                        }}
                    />
                    <YAxis
                        label={{
                            value: yAxisLabel,
                            angle: -90,
                            position: 'middle',
                            textAnchor: 'middle',
                            dx: -40,
                            dy: 0,
                            fontSize: 15,
                        }}
                    />
                    <Tooltip
                        content={
                            <CustomTooltip yAxisLabel={yAxisLabel} yAxisLabel2={yAxisLabel2} />
                        }
                    />
                </LineChart>
            </div>
            {/* Uncomment to enable download button */}
            {/* <Button variant="info" onClick={downloadChartAsImage} className="btn btn-primary">
                Download Chart
            </Button> */}
        </div>
    );
};

const CustomTooltip = ({ active, payload, label, yAxisLabel, yAxisLabel2 }) => {
    const tooltipStyles = {
        border: '1px solid #ccc',
        borderRadius: '5px',
        padding: '10px',
        backgroundColor: '#fff',
    };

    const tooltipLabelStyles = {
        margin: 0,
        padding: 0,
    };

    if (active && payload && payload.length) {
        return (
            <div style={tooltipStyles}>
                <p style={{ ...tooltipLabelStyles, color: '#82ca9d' }}>{`Tank Size: ${label} L`}</p>
                <p style={{ ...tooltipLabelStyles, color: '#8884d8' }}>
                    {`${yAxisLabel}: ${payload[0].value}`}
                </p>
                {yAxisLabel2 && yAxisLabel2 !== 'n/a' && payload[0] && (
                    <p style={{ ...tooltipLabelStyles, color: '#ff7300' }}>
                        {`${yAxisLabel2}: ${payload[0].payload.volumeReduction}`}
                    </p>
                )}
            </div>
        );
    }

    return null;
};

export default ReliabilityTankSizeChart;
