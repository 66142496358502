import React from 'react';
import { StyleSheet, View, Text, Image, Link } from '@react-pdf/renderer';
import { stringOrNumber } from '../../auditorUtils';

function BioretentionTemplate({ nodeInfo, image, documentLinks, title }) {
    const styles = StyleSheet.create({
        title: {
            fontSize: 14,
            textAlign: 'center',
            marginBottom: '3%',
        },
        image: {
            width: '100%',
        },
        link: {
            textAlign: 'center',
            fontSize: 9,
            marginBottom: '2vh',
            marginTop: '2vh',
        },
        superscript: {
            fontSize: '5px',
            lineHeight: '4px',
        },
        table: {
            padding: 5,
        },
        tableTitle: {
            width: '100%',
            textAlign: 'center',
            fontSize: 14,
            marginBottom: '3vh',
        },
        tableRow: {
            flexDirection: 'row',
            height: '60px',
        },
        tableRow_result: {
            flexDirection: 'row',
        },
        tableCol: {
            flex: 1,
            textAlign: 'center',
            fontSize: 8,
            border: 1,
            padding: 5,
        },
    });

    return (
        <>
            <Text style={styles.tableTitle}>Specifications and Typical Drawings - {title}</Text>
            {image && <Image style={styles.image} src={image} />}
            {documentLinks
                ? documentLinks.map((link, index) => {
                      return (
                          <Link
                              key={link['manufacture_device_document_link']}
                              src={link['manufacture_device_document_link']}
                              style={styles.link}
                          >
                              {link['document_name'] ? link['document_name'] : `document ${index}`}
                          </Link>
                      );
                  })
                : null}
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <Text
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            fontSize: 9,
                            borderWidth: 1,
                            borderStyle: 'solid',
                            color: 'red',
                            paddingTop: 20,
                        }}
                    >
                        {'  '}Device Name
                    </Text>
                    <View
                        style={{
                            flex: 2,
                            textAlign: 'center',
                            fontSize: 9,
                            borderWidth: '1px 0 0 1px',
                            borderStyle: 'solid',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Text
                            style={{
                                flex: 1,
                                textAlign: 'center',
                                fontSize: 9,
                                color: 'red',
                                paddingTop: 6,
                            }}
                        >
                            Area
                        </Text>
                        <View style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                            <Text
                                style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    fontSize: 9,
                                    borderTop: '1px solid black',
                                    paddingTop: 5,
                                    borderRight: '1px solid black',
                                }}
                            >
                                Surface(m<Text style={styles.superscript}>2</Text>)
                            </Text>
                            <Text
                                style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    fontSize: 9,
                                    borderLeft: '1px solid black',
                                    borderTop: '1px solid black',
                                    paddingTop: 5,
                                }}
                            >
                                Filter(m<Text style={styles.superscript}>2</Text>)
                            </Text>
                        </View>
                    </View>

                    <Text
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            fontSize: 9,
                            borderWidth: '1px 0 0 1px',
                            borderStyle: 'solid',
                            color: 'red',
                            paddingTop: 20,
                            paddingBottom: 20,
                        }}
                    >
                        EDD height (mm)
                    </Text>

                    <View
                        style={{
                            flex: 2,
                            textAlign: 'center',
                            fontSize: 9,
                            borderWidth: '1px 0 0 1px',
                            borderStyle: 'solid',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Text
                            style={{
                                flex: 1,
                                textAlign: 'center',
                                fontSize: 9,
                                color: 'red',
                                paddingTop: 6,
                            }}
                        >
                            Filter Layer
                        </Text>
                        <View style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                            <Text
                                style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    fontSize: 9,
                                    borderTop: '1px solid black',
                                    paddingTop: 3,
                                    borderRight: '1px solid black',
                                }}
                            >
                                Height (mm)
                            </Text>
                            <Text
                                style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    fontSize: 9,
                                    borderLeft: '1px solid black',
                                    borderTop: '1px solid black',
                                    paddingTop: 3,
                                }}
                            >
                                Permeability (mm/hr)
                            </Text>
                        </View>
                    </View>

                    <Text
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            fontSize: 9,
                            borderWidth: '1px 0 0 1px',
                            borderStyle: 'solid',
                            color: 'red',
                            paddingTop: 20,
                            paddingBottom: 20,
                        }}
                    >
                        Exfiltration Rate(mm/hr)
                    </Text>
                    <Text
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            fontSize: 9,
                            borderWidth: 1,
                            borderStyle: 'solid',
                            color: 'red',
                            paddingTop: 20,
                        }}
                    >
                        Device height (mm)
                    </Text>
                </View>
                {nodeInfo.map((node) => {
                    return (
                        <View style={styles.tableRow_result} key={node['Node Name']}>
                            <Text style={styles.tableCol}>
                                {node['Node Name'] ? node['Node Name'] : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node['Storage Properties - Surface Area (square metres)']
                                    ? stringOrNumber(
                                          +node[
                                              'Storage Properties - Surface Area (square metres)'
                                          ],
                                      )
                                    : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node['Filter and Media Properties - Filter Area (square metres)']
                                    ? stringOrNumber(
                                          +node[
                                              'Filter and Media Properties - Filter Area (square metres)'
                                          ],
                                      )
                                    : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node['Storage Properties - Extended Detention Depth (metres)']
                                    ? stringOrNumber(
                                          +node[
                                              'Storage Properties - Extended Detention Depth (metres)'
                                          ] * 1000,
                                      )
                                    : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node['Filter and Media Properties - Filter Depth (metres)']
                                    ? stringOrNumber(
                                          +node[
                                              'Filter and Media Properties - Filter Depth (metres)'
                                          ] * 1000,
                                      )
                                    : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node[
                                    'Filter and Media Properties - Saturated Hydraulic Conductivity (mm/hr)'
                                ]
                                    ? stringOrNumber(
                                          +node[
                                              'Filter and Media Properties - Saturated Hydraulic Conductivity (mm/hr)'
                                          ],
                                      )
                                    : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node['Infiltration Properties - Exfiltration Rate (mm/hr)']
                                    ? stringOrNumber(
                                          +node[
                                              'Infiltration Properties - Exfiltration Rate (mm/hr)'
                                          ],
                                      )
                                    : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node['Storage Properties - Extended Detention Depth (metres)'] &&
                                node['Filter and Media Properties - Filter Depth (metres)']
                                    ? stringOrNumber(
                                          (+node[
                                              'Storage Properties - Extended Detention Depth (metres)'
                                          ] +
                                              +node[
                                                  'Filter and Media Properties - Filter Depth (metres)'
                                              ] +
                                              0.35) *
                                              1000,
                                      )
                                    : 'N/A'}
                            </Text>
                        </View>
                    );
                })}
            </View>
        </>
    );
}

export default BioretentionTemplate;
