import { createSlice } from "@reduxjs/toolkit";

const calculatorValidationSlice = createSlice({
  name: "calculatorValidation",
  initialState: {
    wsudAllInputsFilled: false,
  },
  reducers: {
    setWsudAllInputsFilled: (state, action) => {
      state.wsudAllInputsFilled = action.payload;
    },
  },
});

export const { setWsudAllInputsFilled } = calculatorValidationSlice.actions;
export const selectCalculatorValidation = (state) => state.calculatorValidation;
export default calculatorValidationSlice.reducer;
