import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectMapInfo } from '../../../../utils/redux/mapInfoSlice';
import { selectProjectInfo } from '../../../../utils/redux/projectInfoSlice';

const fetchRwtData = async (state, council, rainfallStation, catchmentArea, tankSize, demand) => {
    const url = `
https://backend.stormupdated.com.au/sails/getRainwatertankResults/${state}/${council}/${rainfallStation}/Split/Roof/${catchmentArea}/Rainwater%20Tank%20-%20Above%20Slab/${tankSize}/${demand}/Toilet`;

    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-wrap: wrap; // Allow wrapping to handle smaller fields for TP, TN, etc.
`;

const TextValue = styled.span`
    font-size: ${({ isPollutant }) =>
        isPollutant ? '1.4vh' : '1.5vh'}; // Smaller font for pollutants
    color: #333;
    margin-top: 0.8vh;
    flex: ${({ flexBasis }) => flexBasis || '1'};

    &:last-child {
        margin-right: 0;
    }
`;
const RwtLiveResults = ({
    demandDay,
    demandYear,
    catchmentArea,
    reliability,
    rwtData,
    targetReductions,
    tankSize,
}) => {
    const projectInfo = useSelector(selectProjectInfo);
    const mapInfo = useSelector(selectMapInfo);
    const [volumeReused, setVolumeReused] = useState('N/A');
    const [volumeDischarged, setVolumeDischarged] = useState('N/A');
    const [flowGenerated, setFlowGenerated] = useState('N/A');
    const [rwtApiResponse, setRwtApiResponse] = useState('N/A');
    const { tss, tp, tn, gp } = targetReductions;
    const sflow = rwtData.length > 0 ? (rwtData[0].sflow / rwtData[0].snodearea) * 1000 : 0;

    useEffect(() => {
        if (demandDay && reliability) {
            setVolumeReused(((reliability * demandDay * 365) / 100).toFixed(2));
        } else if (demandYear && reliability) {
            setVolumeReused(((reliability * demandYear) / 100).toFixed(2));
        } else {
            setVolumeReused('N/A');
        }
    }, [demandDay, demandYear, reliability]);

    useEffect(() => {
        if (catchmentArea) {
            const calculatedFlow = sflow * catchmentArea;
            setFlowGenerated(parseFloat(calculatedFlow.toFixed(2)));
        } else {
            setFlowGenerated('N/A');
        }
    }, [catchmentArea, sflow]);

    useEffect(() => {
        if (flowGenerated !== 'N/A' && volumeReused !== 'N/A') {
            const calculatedVolumeDischarged = flowGenerated - parseFloat(volumeReused);
            setVolumeDischarged(parseFloat(calculatedVolumeDischarged.toFixed(2)));
        } else {
            setVolumeDischarged('N/A');
        }
    }, [flowGenerated, volumeReused]);
    // Fetch RWT data when demandDay, catchmentArea, tankSize, or demandYear changes
    useEffect(() => {
        const { state, council, rainfallStation } = mapInfo;
        let demand = (demandDay * 1000) / 20;

        if (state && council && rainfallStation && catchmentArea && tankSize && demand) {
            fetchRwtData(state, council, rainfallStation, catchmentArea, tankSize, demand).then(
                (data) => {
                    setRwtApiResponse(data);
                },
            );
        }
    }, [demandDay, catchmentArea, tankSize, demandYear]);

    // Function to determine color based on target comparison
    const getColor = (value, target) => {
        if (value == null) return '#333'; // Default color when N/A
        return value >= target ? 'green' : 'red';
    };

    return (
        <Container>
            <TextValue flexBasis="18%">Flow Generated (av. KL/yr): {flowGenerated}</TextValue>
            <TextValue flexBasis="19%">Volume Reused (av. KL/yr): {volumeReused}</TextValue>
            <TextValue flexBasis="20%">Volume Discharged (av. KL/yr): {volumeDischarged}</TextValue>

            <TextValue
                flexBasis="5%"
                isPollutant
                style={{
                    color: getColor(rwtApiResponse.reductionPercTSS, tss),
                    marginLeft: '2.3vw',
                }}
            >
                TSS (%):{' '}
                {rwtApiResponse.reductionPercTSS != null
                    ? rwtApiResponse.reductionPercTSS.toFixed(2)
                    : 'N/A'}
            </TextValue>
            <TextValue
                flexBasis="5%"
                isPollutant
                style={{ color: getColor(rwtApiResponse.reductionPercTP, tp) }}
            >
                TP (%):{' '}
                {rwtApiResponse.reductionPercTP != null
                    ? rwtApiResponse.reductionPercTP.toFixed(2)
                    : 'N/A'}
            </TextValue>
            <TextValue
                flexBasis="5%"
                isPollutant
                style={{ color: getColor(rwtApiResponse.reductionPercTN, tn) }}
            >
                TN (%):{' '}
                {rwtApiResponse.reductionPercTN != null
                    ? rwtApiResponse.reductionPercTN.toFixed(2)
                    : 'N/A'}
            </TextValue>
        </Container>
    );
};

export default RwtLiveResults;
