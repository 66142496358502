import ReactGA from 'react-ga4';
import axios from 'axios';

export const GOOGLE_MAP_API_KEY = 'AIzaSyBXWqbpWjUJpXbknlMwWDhwN0IEBzRWxCE';
export const onLocalTest = window.location.href.includes('localhost');

export const trackGAEvent = (category, action, label, value) => {
    if (!onLocalTest) {
        ReactGA.event({
            category: category,
            action: action,
            label: label, // optional
            value: value, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: 'xhr', // optional, beacon/xhr/image
        });
    }
};

export const trackPageView = (url, title) => {
    if (!onLocalTest) {
        ReactGA.send({
            hitType: 'pageview',
            page: url,
            title: title,
        });
    }
};

/* APIs */
export const JCO_ONLINE_DEV_CMS_LAMBDA_API =
    'https://xgr5xf78jh.execute-api.ap-southeast-2.amazonaws.com';
export const totoevApi = axios.create({
    baseURL: 'https://backend.stormupdated.com.au/',
});
export const sails_api = axios.create({
    baseURL: 'https://backend.stormupdated.com.au/sails',
});
export const LAMBDA_API = 'https://xgr5xf78jh.execute-api.ap-southeast-2.amazonaws.com';
export const flask_api = axios.create({
    baseURL: 'https://backend.stormupdated.com.au/flask',
});
export const local_api = axios.create({
    baseURL: 'http://localhost:8000',
});
export const LAMBDA_API_NEW = axios.create({
    baseURL: 'https://a32nb3dwl2.execute-api.ap-southeast-2.amazonaws.com',
});

export const fixedFloat2 = (value) => {
    const regex = /^\d+(\.\d{0,2})?$/;
    if (regex.test(value)) {
        return value;
    }
};

export const states = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];
export const stateCoordinates = {
    ACT: {
        center: { lat: -35.42995093240622, lng: 149.0282702905103 },
        zoom: 6,
    },
    NSW: {
        center: { lat: -32.186360053101396, lng: 148.77494312266262 },
        zoom: 6,
    },
    NT: {
        center: { lat: -18.398986846262332, lng: 133.72803718845648 },
        zoom: 6,
    },
    QLD: {
        center: { lat: -19.617333739656416, lng: 145.8990016598991 },
        zoom: 6,
    },
    SA: {
        center: { lat: -30.265556751610323, lng: 136.05253052267014 },
        zoom: 6,
    },
    TAS: {
        center: { lat: -41.93069837065246, lng: 146.77890262469748 },
        zoom: 6,
    },
    VIC: {
        center: { lat: -36.89938739551902, lng: 144.85531304687504 },
        zoom: 6,
    },
    WA: {
        center: { lat: -24.91318170386305, lng: 119.52909302267012 },
        zoom: 6,
    },
};

/* Dictionaries: from Su Calculator to JCO */
export const nodeTypeDict = {
    'Bioretention 100mm': 'BioRetentionNodeV4',
    'Bioretention 200mm': 'BioRetentionNodeV4',
    'Bioretention 300mm': 'BioRetentionNodeV4',
    'Roof Garden 100mm': 'BioRetentionNodeV4',
    'Roof Garden 300mm': 'BioRetentionNodeV4',
    'Tree Pit 100mm': 'BioRetentionNodeV4',
    'Tree Pit Large - 12sqm': 'BioRetentionNodeV4',
    'Tree Pit Medium - 8sqm': 'BioRetentionNodeV4',
    'Tree Pit Small - 5sqm': 'BioRetentionNodeV4',
    'Rainwater Tank - Above Slab': 'RainWaterTankNode',
    'Rainwater Tank - Buried': 'RainWaterTankNode',
    'Waterline Rainwater Tank': 'RainWaterTankNode',
    'Permeable Pavement 10mm': 'MediaFiltrationNode',
    // All other SU nodes not listed here: "GPTNode"
};
export const treatmentNameDict = {
    'Rainwater Tank - Above Slab': 'Rainwater Tank Typical',
    'Rainwater Tank - Buried': 'Rainwater Tank Typical',
    'SQIDEP Hydrochannel': 'Hydrochannel',
    Vortceptor: 'Vortceptor',
    Stormceptor: 'Stormceptor',
    'SQIDEP SPELBasin': 'SPELBasin',
    Ecoceptor: 'Ecoceptor',
    HumeGard: 'HumeGard',
    'First Defense HC': 'First Defense HC',
    Stormsacks: 'Stormsacks',
    CDS: 'CDS',
    'Ecosol Litter Basket 1500': 'Ecosol Litter Basket 1500',
    'Ecosol Storm Pit Class 2': 'Ecosol Storm Pit Class 2',
    'Enviss Pit': 'Enviss Pit',
    Humeceptor: 'Humeceptor',
    'Ecosol GPT': 'Ecosol GPT',
    'Waterline Rainwater Tank': 'Waterline Tanks',
    'Bioretention 100mm': 'Bioretention',
    'Bioretention 200mm': 'Bioretention',
    'Bioretention 300mm': 'Bioretention',
    'Permeable Pavement 10mm': 'Permeable Pavement',
    'Roof Garden 300mm': 'Rooftop rain garden',
    'Roof Garden 100mm': 'Rooftop rain garden',
    'Tree Pit 100mm': 'Tree pit',
    'Tree Pit Large - 12sqm': 'Tree pit',
    'Tree Pit Medium - 8sqm': 'Tree pit',
    'Tree Pit Small - 5sqm': 'Tree pit',
    'Rocla UpFlo Filter': 'UpFlo Filter',
    SPELFilter: 'SPELFilter',
    'SPEL Hydrosystem': 'Hydrosystem',
    EcoProtector: 'EcoProtector',
    EnviroFilter: 'EnviroFilter',
    HumerFilter: 'HumeFilter',
};

export const SecondaryLinkPropForNodeType = {
    'Rainwater Tanks': [
        'Low Flow Bypass',
        'High Flow Bypass',
        'Outflow to Drain',
        'Overflow',
        'Reuse',
    ],
    'Sediment Basin': [
        'Low Flow Bypass',
        'High Flow Bypass',
        'Pipe Flow',
        'Weir Overflow',
        // 'Infiltration',
        'Reuse',
    ],
    Swale: [
        'Low Flow Bypass',
        'Channel Flow',
        'Overcapacity flow',
        // 'Infiltration'
    ],
    Bioretention: [
        'Low Flow Bypass',
        'High Flow Bypass',
        'Pipe Flow',
        'Weir Overflow',
        // 'Infiltration',
    ],
    'Media Filtration': [
        'Low Flow Bypass',
        'High Flow Bypass',
        'Pipe Flow',
        'Weir Overflow',
        // 'Infiltration',
    ],
    GPT: ['Low Flow Bypass', 'High Flow Bypass', 'Transfer Function'],
    Catchment: ['Base Flow', 'Pervious Storm Flow', 'Impervious Storm Flow'],
};

export const getSecondaryLinkProps = async (input) => {
    let linkFlow;
    if (Object.values(treatmentNameDict).includes(input)) {
        switch (input) {
            case 'Tree pit':
            case 'Rooftop rain garden':
            case 'Bioretention':
            case 'Bioretention Wyndham City':
                linkFlow = SecondaryLinkPropForNodeType['Bioretention'];
                break;
            case 'SedBasin':
                linkFlow = SecondaryLinkPropForNodeType['Sediment Basin'];
                break;
            case 'Swale':
            case 'Unkept Snake Pit Swale':
                linkFlow = SecondaryLinkPropForNodeType['Swale'];
                break;
            case 'Detention Basin':
            case 'OSD short chamber':
            case 'OSD tall chamber':
            case 'SPELFilter Vault short':
            case 'SPELFilter Vault tall':
                linkFlow = SecondaryLinkPropForNodeType['Sediment Basin'];
                break;
            case 'Wetland':
                linkFlow = SecondaryLinkPropForNodeType['Sediment Basin'];
                break;
            case 'Rainwater Tank Typical':
            case 'Waterline Tanks':
            case 'RWT short':
            case 'RWT tall':
                linkFlow = SecondaryLinkPropForNodeType['Rainwater Tanks'];
                break;
            case 'Permeable Pavement':
                linkFlow = SecondaryLinkPropForNodeType['Media Filtration'];
                break;
            default:
                linkFlow = SecondaryLinkPropForNodeType['GPT'];
                break;
        }
    } else {
        linkFlow = SecondaryLinkPropForNodeType['Catchment'];
    }
    return linkFlow;
};

// the number of lines for each node
export const lineNumber = {
    UrbanSourceNode: 55,
    AgriculturalSourceNode: 54,
    ForestSourceNode: 54,
    WetlandNode: 45,
    PondNode: 44,
    SedimentationBasinNode: 44,
    DetentionBasinNode: 43,
    InfiltrationSystemNodeV4: 33,
    BioRetentionNodeV4: 38,
    MediaFiltrationNode: 34,
    BufferNode: 11,
    SwaleNode: 27,
    RainWaterTankNode: 40,
    GPTNode: 122,
    GenericNode: 122,
    ReceivingNode: 6,
    PreDevelopmentNode: 7,
    PostDevelopmentNode: 7,
    JunctionNode: 6,
};
