import { Form, Button } from 'react-bootstrap';
import RwtCalculatorContainer from '../RwtCalculatorContainer/RwtCalculatorContainer';
import RendreDoc from '../../RenderDoc/RenderDoc';
import FormLabel from '../../FormLabel/FormLabel';

import { isValidRealNumber } from '../../../validations/common';
import rwtApi from '../rwtApi';

import s from './OccupancyCalculator.module.css';

const isValidValue = (value, fieldProperties) => {
    if (fieldProperties?.type === 'string') return true;
    else if (fieldProperties?.type === 'float') {
        if (isValidRealNumber(value, 2)) return true;
    }
    return false;
};

const parseValue = (value, fieldProperties) => {
    if (fieldProperties?.type === 'string') return value;
    else if (fieldProperties?.type === 'float') return parseFloat(value);
    else if (fieldProperties?.type === 'integer') return parseInt(value);
    return value;
};

export default function OccupancyCalculator({
    isReady,
    methods,
    onMethodChange,
    calculatorState,
    setCalculatorState,
}) {
    const calculateOccupancy = async () => {
        setCalculatorState((prev) => {
            return { ...prev, errors: {} };
        });

        // Parse fields
        const parsedData = {};
        const errors = {};
        for (const fieldId in calculatorState.fields) {
            const field = calculatorState.fields[fieldId];
            if (field.disabled || field.properties?.readonly) continue;
            else if (field?.subfields && Object.keys(field.subfields).length > 0) {
                // Parse subfields
                for (const subfieldId in field.subfields) {
                    const subfield = field.subfields[subfieldId];
                    parsedData[subfield.id] = subfield.value.map((val, idx) => {
                        if (isValidValue(val, subfield.properties)) {
                            return parseValue(val, subfield.properties);
                        } else {
                            errors[subfield.id + idx] =
                                val.length === 0 ? 'Input field cannot be empty' : 'Invalid value';
                            return null;
                        }
                    });
                }
                continue;
            }
            if (isValidValue(field.value, field.properties))
                parsedData[fieldId] = parseValue(field.value, field.properties);
            else
                errors[field.id] =
                    field.value.length === 0 ? 'Input field cannot be empty' : 'Invalid value';
        }

        // console.log('[PARSED_DATA]', parsedData);
        console.log('[ERRORS]', errors);

        if (Object.keys(errors).length > 0) {
            setCalculatorState((prev) => {
                return { ...prev, errors, status: 'initial' };
            });
            return;
        } else {
            setCalculatorState((prev) => {
                return { ...prev, errors, status: 'inProgress' };
            });
        }

        const body = { method: calculatorState.selectedMethod.id, params: {} };
        if (calculatorState?.selectedMethod?.id === 'SouthAustraliaMUSICModellingGuidelines2021') {
            // Restructure params to the expected structure in backend
            const areas = [];
            for (let i = 0; i < parsedData.floorArea.length; i++) {
                areas.push({
                    areaName: '...',
                    floorArea: parsedData.floorArea[i],
                    occupancyRate: parsedData.occupancyRate[i],
                });
            }
            body.params = {
                occupancyHoursProfile: parsedData.occupancyHoursProfile,
                areas: areas,
            };
        } else {
            body.params = parsedData;
        }

        console.log('[BODY]', body);

        // Send request to backend to perform calculations
        rwtApi
            .calculateOccupancy(body)
            .then((result) => {
                console.log('[OCCUPANCY_RESULT]', result);
                // Set result
                if (
                    calculatorState?.selectedMethod?.id ===
                    'SouthAustraliaMUSICModellingGuidelines2021'
                ) {
                    setCalculatorState((prev) => {
                        const updatedState = { ...prev };
                        updatedState.fields.weightedMaxPeakOccupancy.value =
                            result.weightedMaxPeakOccupancy;
                        updatedState.fields.totalBuildingArea.value = result.totalBuildingArea;
                        updatedState.result = result.totalOccupants;
                        updatedState.status = 'initial';
                        return updatedState;
                    });
                } else {
                    setCalculatorState((prev) => {
                        return {
                            ...prev,
                            errors: {},
                            result,
                            status: 'initial',
                        };
                    });
                }
            })
            .catch((error) => {
                // TODO: what should happen in an error
                alert(error.message);
                setCalculatorState((prev) => {
                    return { ...prev, status: 'initial' };
                });
            });
    };

    const onAddAreaButtonClicked = () => {
        const updatedState = { ...calculatorState };
        updatedState.fields.areas.subfields.areaUseType.value.push('');
        updatedState.fields.areas.subfields.occupancyRate.value.push('');
        updatedState.fields.areas.subfields.floorArea.value.push('');
        setCalculatorState(updatedState);
    };

    const onDeleteButtonClicked = (idx) => {
        if (calculatorState.fields.areas.subfields.occupancyRate?.value?.length < 2) return;
        const updatedState = { ...calculatorState };
        updatedState.fields.areas.subfields.areaUseType.value.splice(idx, 1);
        updatedState.fields.areas.subfields.occupancyRate.value.splice(idx, 1);
        updatedState.fields.areas.subfields.floorArea.value.splice(idx, 1);
        setCalculatorState(updatedState);
    };

    const onInputFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        const updatedState = { ...calculatorState };
        // Modify state
        if (value.length === 0 || (value.length > 0 && isValidRealNumber(value, 2)))
            updatedState.fields[name].value = value;
        // Clear the results
        if (updatedState?.selectedMethod?.id === 'SouthAustraliaMUSICModellingGuidelines2021') {
            updatedState.fields.weightedMaxPeakOccupancy.value = '';
            updatedState.fields.totalBuildingArea.value = '';
        }
        updatedState.result = null;
        // Update state
        setCalculatorState(updatedState);
    };

    const onInputSubfieldChange = (e, parentFieldId, idx) => {
        const name = e.target.name;
        const value = e.target.value;

        const updatedState = { ...calculatorState };
        // Modify state
        if (
            value.length === 0 ||
            updatedState.fields[parentFieldId].subfields[name]?.properties?.type === 'string' ||
            (value.length > 0 && isValidRealNumber(value, 2))
        )
            updatedState.fields[parentFieldId].subfields[name].value[idx] = value;
        // Clear the results
        if (updatedState?.selectedMethod?.id === 'SouthAustraliaMUSICModellingGuidelines2021') {
            updatedState.fields.weightedMaxPeakOccupancy.value = '';
            updatedState.fields.totalBuildingArea.value = '';
        }
        updatedState.result = null;
        // Update state
        setCalculatorState(updatedState);
    };
    //-------------------------------------------
    // --> Set Method Doc Section
    //-------------------------------------------
    let methodDoc = null;
    if (calculatorState.selectedMethod?.info?.blocks)
        methodDoc = (
            <div className="doc-container">
                <RendreDoc blocks={calculatorState.selectedMethod?.info?.blocks} />
            </div>
        );

    //-------------------------------------------
    // --> Set Form
    //-------------------------------------------
    const form = (fields) => {
        const calculatorType = 'occupancy';
        if (calculatorState?.selectedMethod?.id === 'SouthAustraliaMUSICModellingGuidelines2021') {
            // Custom layout for this method

            // occupancyHoursProfileField
            // [..........]
            //
            // areas
            //    subfield1     subfield2
            // ( [.........]  [.........] [DELETE] )
            // ( [.........]  [.........] [DELETE] )
            // ( [.........]  [.........] [DELETE] )

            const occupancyHoursProfileField = fields.occupancyHoursProfile;
            const areasField = fields.areas;
            const areaUseTypeField = fields.areas.subfields.areaUseType;
            const occupancyRateField = fields.areas.subfields.occupancyRate;
            const floorAreaField = fields.areas.subfields.floorArea;

            const areaRows = [];

            const areasLength = occupancyRateField?.value?.length ?? 0;
            for (let i = 0; i < areasLength; i++) {
                areaRows.push(
                    <div className={s['form-row']} style={{ marginBottom: '0.6rem' }} key={i}>
                        <div>
                            <Form.Control
                                name={`${areaUseTypeField.id}`}
                                disabled={areaUseTypeField?.disabled}
                                value={areaUseTypeField.value[i]}
                                placeholder={`Area ${i + 1}`}
                                onChange={(e) => onInputSubfieldChange(e, areasField.id, i)}
                            />
                            <span className={s['input-control-error']}>
                                {calculatorState.errors[areaUseTypeField.id + i]}
                            </span>
                        </div>
                        <div>
                            <Form.Control
                                name={`${occupancyRateField.id}`}
                                disabled={occupancyRateField?.disabled}
                                value={occupancyRateField.value[i]}
                                onChange={(e) => onInputSubfieldChange(e, areasField.id, i)}
                            />
                            <span className={s['input-control-error']}>
                                {calculatorState.errors[occupancyRateField.id + i]}
                            </span>
                        </div>
                        <div>
                            <Form.Control
                                name={`${floorAreaField.id}`}
                                disabled={floorAreaField?.disabled}
                                value={floorAreaField.value[i]}
                                onChange={(e) => onInputSubfieldChange(e, areasField.id, i)}
                            />
                            <span className={s['input-control-error']}>
                                {calculatorState.errors[floorAreaField.id + i]}
                            </span>
                        </div>
                        <div>
                            <Button
                                variant="outline-danger"
                                disabled={areasLength < 2}
                                onClick={() => onDeleteButtonClicked(i)}
                            >
                                Delete
                            </Button>
                        </div>
                    </div>,
                );
            }

            return (
                <form className={s['container']}>
                    <div className="rwt-calculator-form" style={{ marginBottom: '1rem' }}>
                        <div
                            key={occupancyHoursProfileField.id}
                            className="input-control-container"
                        >
                            <FormLabel
                                htmlFor={`${calculatorType}-${occupancyHoursProfileField.id}`}
                                info={occupancyHoursProfileField.info}
                            >
                                {occupancyHoursProfileField.label}
                            </FormLabel>
                            <div>
                                <Form.Control
                                    id={`${calculatorType}-${occupancyHoursProfileField.id}`}
                                    name={`${occupancyHoursProfileField.id}`}
                                    disabled={occupancyHoursProfileField?.disabled}
                                    value={occupancyHoursProfileField.value}
                                    onChange={(e) => onInputFieldChange(e)}
                                />
                                <span className="input-control-error">
                                    {calculatorState.errors[occupancyHoursProfileField.id]}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={`${s['areas-field']}`}>
                        <FormLabel info={areasField.info} bold={true}>
                            {areasField.label}
                        </FormLabel>
                        <div className={s['form-row']}>
                            <FormLabel info={areaUseTypeField.info}>
                                {areaUseTypeField.label}
                            </FormLabel>
                            <FormLabel info={occupancyRateField.info}>
                                {occupancyRateField.label}
                            </FormLabel>
                            <FormLabel info={floorAreaField.info}>{floorAreaField.label}</FormLabel>
                            <div>{/* Empty cell */}</div>
                        </div>
                        {areaRows}
                        <div style={{ margin: '1rem 0' }}>
                            <Button onClick={onAddAreaButtonClicked}>Add Area</Button>
                        </div>
                    </div>
                </form>
            );
        } else {
            // Use standard layout
            return (
                <form className="rwt-calculator-form">
                    {Object.keys(fields).map((fieldId) => {
                        const field = fields[fieldId];
                        return (
                            <div key={field.id} className="input-control-container">
                                <FormLabel
                                    htmlFor={`${calculatorType}-${field.id}`}
                                    info={field.info}
                                >
                                    {field.label}
                                </FormLabel>
                                <div>
                                    <Form.Control
                                        id={`${calculatorType}-${field.id}`}
                                        name={`${field.id}`}
                                        disabled={field?.disabled}
                                        value={field.value}
                                        onChange={(e) => onInputFieldChange(e)}
                                    />
                                    <span className="input-control-error">
                                        {calculatorState.errors[field.id]}
                                    </span>
                                </div>
                            </div>
                        );
                    })}
                </form>
            );
        }
    };

    let subfields = null;
    if (calculatorState?.selectedMethod?.id === 'SouthAustraliaMUSICModellingGuidelines2021') {
        const weightedMaxPeakOccupancyField = calculatorState.fields.weightedMaxPeakOccupancy;
        const totalBuildingAreaField = calculatorState.fields.totalBuildingArea;
        subfields = (
            <div>
                <div className={s['form-row']}>
                    <FormLabel info={weightedMaxPeakOccupancyField.info}>
                        {weightedMaxPeakOccupancyField.label}
                    </FormLabel>
                    <FormLabel info={totalBuildingAreaField.info}>
                        {totalBuildingAreaField.label}
                    </FormLabel>
                </div>
                <div className={s['form-row']}>
                    <Form.Control disabled={true} value={weightedMaxPeakOccupancyField.value} />
                    <Form.Control disabled={true} value={totalBuildingAreaField.value} />
                </div>
            </div>
        );
    }

    //-------------------------------------------
    // --> Return Component
    //-------------------------------------------
    return (
        <RwtCalculatorContainer
            isReady={isReady}
            backgroundColor="#6ab0f028"
            title="Indoor Occupancy Calculator"
            footer={subfields}
            methods={methods}
            selectedMethod={calculatorState.selectedMethod?.id}
            onMethodChange={(method) => onMethodChange(method, calculatorState, setCalculatorState)}
            result={{ label: 'Total Occupants', value: calculatorState.result }}
            inProgress={calculatorState.status === 'inProgress'}
            onSubmit={calculateOccupancy}
        >
            {methodDoc}
            {form(calculatorState.fields)}
        </RwtCalculatorContainer>
    );
}
