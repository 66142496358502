import { createSlice } from "@reduxjs/toolkit";

const osdStateSlice = createSlice({
    name: "osdState",
    initialState: {
        qaMultiplicationFactor: 2,
        aepPSD: 20,
        aepOSD: 10,
        climateDisplay: 0,
        catchmentTimeTc: "20",
        catchmentTimeTso: "10",
        catchmentTimeTcs: "10",
        designStorm: 1,
    },
    reducers: {
        handleQaMultiplicationFactor: (state, action) => {
            state.qaMultiplicationFactor = action.payload;
        },
        handleAepPSD: (state, action) => {
            state.aepPSD = action.payload;
        },
        handleAepOSD: (state, action) => {
            state.aepOSD = action.payload;
        },
        handleClimateDisplay: (state, action) => {
            state.climateDisplay = action.payload;
        },
        handleCatchmentTimeTc: (state, action) => {
            state.catchmentTimeTc = action.payload;
        },
        handleCatchmentTimeTso: (state, action) => {
            state.catchmentTimeTso = action.payload;
        },
        handleCatchmentTimeTcs: (state, action) => {
            state.catchmentTimeTcs = action.payload;
        },
        handleDesignStorm: (state, action) => {
            state.designStorm = action.payload;
        },
        handleOSDState: (_, action) => {
            return action.payload;
        },
    },
});

export const {
    handleQaMultiplicationFactor,
    handleAepPSD,
    handleAepOSD,
    handleClimateDisplay,
    handleCatchmentTimeTc,
    handleCatchmentTimeTso,
    handleCatchmentTimeTcs,
    handleDesignStorm,
    handleOSDState,
} = osdStateSlice.actions;
export const osdState = (state) => state.osdState;
export default osdStateSlice.reducer;
