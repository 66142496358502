import React from 'react';
import { StyleSheet, View, Text, Image, Link } from '@react-pdf/renderer';
import { stringOrNumber } from '../../auditorUtils';

function WetlandTemplate({ nodeInfo, image, documentLinks, title }) {
    const styles = StyleSheet.create({
        title: {
            fontSize: 14,
            textAlign: 'center',
            marginBottom: '3%',
        },
        image: {
            width: '100%',
        },
        link: {
            textAlign: 'center',
            fontSize: 9,
            marginBottom: '2vh',
            marginTop: '2vh',
        },
        superscript: {
            fontSize: 5,
            lineHeight: 5,
        },
        // TABLE
        columnTitle: {
            flex: 1,
            textAlign: 'center',
            fontSize: 9,
            borderWidth: 1,
            borderStyle: 'solid',
            color: 'red',
            paddingTop: 20,
        },
        table: {
            padding: 5,
        },
        tableTitle: {
            width: '100%',
            textAlign: 'center',
            fontSize: 14,
            marginBottom: '3vh',
        },
        tableRow: {
            flexDirection: 'row',
        },
        tableCol: {
            flex: 1,
            textAlign: 'center',
            fontSize: 8,
            border: 1,
            padding: 5,
        },
    });
    return (
        <>
            <Text style={styles.tableTitle}>Specifications and Typical Drawings - {title}</Text>
            {image && <Image style={styles.image} src={image} />}
            {documentLinks
                ? documentLinks.map((link, index) => (
                      <Link
                          key={link['manufacture_device_document_link']}
                          src={link['manufacture_device_document_link']}
                          style={styles.link}
                      >
                          {link['document_name'] ? link['document_name'] : `document ${index}`}
                      </Link>
                  ))
                : null}
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <Text style={styles.columnTitle}>Treatment Device Name</Text>
                    <Text style={styles.columnTitle}>
                        Surface Area(m<Text style={styles.superscript}>2</Text>)
                    </Text>
                    <Text style={styles.columnTitle}>EDD height (mm)</Text>
                    <Text style={styles.columnTitle}>Permanent Pool Average Depth( m)</Text>
                    <Text style={styles.columnTitle}>
                        Permanent Pool Volume(m<Text style={styles.superscript}>3</Text>)
                    </Text>
                    <Text style={styles.columnTitle}>Daily Demand (kL/day)</Text>
                    <Text style={styles.columnTitle}>Annual Demand (kL/yr)</Text>
                    <Text style={styles.columnTitle}>Notion Detention Time (hrs)</Text>
                    <Text style={styles.columnTitle}>Equivalent Pipe Diameter (mm)</Text>
                </View>
                {nodeInfo.map((node, index) => (
                    <View style={styles.tableRow} key={index}>
                        <Text style={styles.tableCol}>{node['General - Location'] || 'N/A'}</Text>
                        <Text style={styles.tableCol}>
                            {node['Storage Properties - Surface Area (square metres)']
                                ? stringOrNumber(
                                      +node['Storage Properties - Surface Area (square metres)'],
                                  )
                                : 'N/A'}
                        </Text>
                        <Text style={styles.tableCol}>
                            {node['Storage Properties - Extended Detention Depth (metres)']
                                ? stringOrNumber(
                                      +node[
                                          'Storage Properties - Extended Detention Depth (metres)'
                                      ],
                                  )
                                : 'N/A'}
                        </Text>
                        <Text style={styles.tableCol}>
                            {node['Permanent Pool Average Depth (m)'] !== undefined
                                ? stringOrNumber(+node['Permanent Pool Average Depth (m)'])
                                : node[
                                        'Storage and Infiltration Properties - Permanent Pool Volume (cubic metres)'
                                    ] !== undefined
                                  ? stringOrNumber(
                                        +node[
                                            'Storage and Infiltration Properties - Permanent Pool Volume (cubic metres)'
                                        ],
                                    )
                                  : node[
                                          'Storage Properties - Permanent Pool Volume (cubic metres)'
                                      ] !== undefined &&
                                      node['Storage Properties - Surface Area (square metres)'] !==
                                          undefined
                                    ? stringOrNumber(
                                          +node[
                                              'Storage Properties - Permanent Pool Volume (cubic metres)'
                                          ] /
                                              +node[
                                                  'Storage Properties - Surface Area (square metres)'
                                              ],
                                      )
                                    : 'N/A'}
                        </Text>

                        <Text style={styles.tableCol}>
                            {node['Permanent Pool Storage Properties - Initial Volume']
                                ? stringOrNumber(
                                      +node['Permanent Pool Storage Properties - Initial Volume'],
                                  )
                                : node[
                                        'Storage Properties - Permanent Pool Volume (cubic metres)'
                                    ] !== undefined
                                  ? stringOrNumber(
                                        +node[
                                            'Storage Properties - Permanent Pool Volume (cubic metres)'
                                        ],
                                    )
                                  : 'N/A'}
                        </Text>

                        <Text style={styles.tableCol}>
                            {node['Reuse Properties - Daily Demand Value (ML/day)']
                                ? stringOrNumber(
                                      +node['Reuse Properties - Daily Demand Value (ML/day)'] *
                                          1000,
                                  )
                                : 'N/A'}
                        </Text>
                        <Text style={styles.tableCol}>
                            {node['Reuse Properties - Annual Demand Value (ML/year)']
                                ? stringOrNumber(
                                      +node['Reuse Properties - Annual Demand Value (ML/year)'] *
                                          1000,
                                  )
                                : 'N/A'}
                        </Text>
                        <Text style={styles.tableCol}>
                            {node['Outlet Properties - Notional Detention Time (hrs)']
                                ? stringOrNumber(
                                      +node['Outlet Properties - Notional Detention Time (hrs)'],
                                  )
                                : 'N/A'}
                        </Text>
                        <Text style={styles.tableCol}>
                            {node['Outlet Properties - Equivalent Pipe Diameter (mm)']
                                ? stringOrNumber(
                                      +node['Outlet Properties - Equivalent Pipe Diameter (mm)'],
                                  )
                                : 'N/A'}
                        </Text>
                    </View>
                ))}
            </View>
        </>
    );
}

export default WetlandTemplate;
