import React from "react";
import { StyleSheet, View, Image, Text } from "@react-pdf/renderer";

export default function TreatmentTrainsImage({ treatmentTrainsImageUrl }) {
  const styles = StyleSheet.create({
    container: {
      height: "85%",
    },
    title: {
      textAlign: "center",
      fontSize: 20,
      margin: 10,
    },
    image: {
      padding: 10,
      width: "85%",
    },
  });

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Treatment Trains</Text>
      <Image style={styles.image} src={treatmentTrainsImageUrl} />
    </View>
  );
}
