import React from 'react';
import { Document, Page, View, StyleSheet, Text } from '@react-pdf/renderer';
import ReportHeader from '../ReportHeader';
import ReportFooter from '../ReportFooter';

const wrapText = (text, maxLength) => {
    if (text.length <= maxLength) {
        return text;
    }

    const regex = new RegExp(`.{1,${maxLength}}`, 'g');
    const wrappedText = text.match(regex).join('\n');
    return wrappedText;
};

const maxLength = 15;

const AppendixTable = ({
    template,
    additionalColumns,
    companyIcon,
    title,
    showTitle,
    pageIndex,
    tableIndex,
}) => {
    const styles = StyleSheet.create({
        container: {
            padding: 5,
        },
        title: {
            fontSize: 16,
            textAlign: 'center',
            marginBottom: '4%',
        },
        table: {
            display: 'table',
            width: 'auto',
            marginBottom: '3%',
        },
        tableRow: {
            flexDirection: 'row',
            borderBottom: 1,
        },
        tablePropertyheader: {
            fontSize: 9,
            fontWeight: 'bold',
            borderRight: 1,
            borderTop: 1,
            borderLeft: 1,
            paddingTop: 5,
            paddingLeft: 5,
            paddingRight: 5,
            textAlign: 'center',
            wordWrap: 'break-word',
            justifyContent: 'center',
            alignItems: 'center',
            width: '30%',
        },
        tableColHeader: {
            fontSize: 8,
            fontWeight: 'bold',
            borderRight: 1,
            borderTop: 1,
            borderLeft: 1,
            paddingBottom: 5,
            paddingTop: 5,
            paddingLeft: 8,
            paddingRight: 8,
            textAlign: 'center',
            wordWrap: 'break-word',
        },
        tableCol: {
            fontSize: 8,
            borderRight: 1,
            borderLeft: 1,
            padding: 5,
            textAlign: 'center',
            flexDirection: 'column',
            display: 'flex',
        },
        firstColumn: {
            width: '30%',
        },
        minMaxCol: {
            width: '7%',
        },
        remainingColumn: {
            width: `${56 / additionalColumns.length}%`,
        },
        page: {
            padding: 18,
        },
        redText: {
            color: 'red',
        },
    });

    const ITEMS_PER_PAGE = 21;

    const chunkArray = (array, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    };

    const shouldDisplayRow = (item) => {
        if (['GPTNode', 'GenericNode'].includes(template.checkList[0].node_type)) {
            return additionalColumns.some((col) => {
                return col.values.find((row) => row.rowName === item.property_name)?.rowValue;
            });
        }
        return true;
    };

    const filteredCheckList = template.checkList.filter(shouldDisplayRow);
    const chunkedCheckList = chunkArray(filteredCheckList, ITEMS_PER_PAGE);

    return (
        <>
            {chunkedCheckList.map((checkListChunk, chunkIndex) => (
                <Page key={`${pageIndex}-${tableIndex}-${chunkIndex}`} style={styles.page}>
                    <View>
                        <ReportHeader reportHeaderUrl={companyIcon} />
                        {pageIndex === 0 && chunkIndex === 0 && tableIndex === 0 && (
                            <>
                                {showTitle && (
                                    <>
                                        <Text style={styles.title}>{title}</Text>
                                    </>
                                )}
                            </>
                        )}
                        <View style={styles.tableRow}>
                            {chunkIndex !== 0 ? (
                                <Text style={[styles.tablePropertyheader, styles.firstColumn]}>
                                    (Continuation...) {'\n'}
                                    All nodes mentioned here {title.includes('Non')
                                        ? "don't"
                                        : ''}{' '}
                                    comply with the guideline parameters for {template.match_name}
                                </Text>
                            ) : (
                                <Text style={[styles.tablePropertyheader, styles.firstColumn]}>
                                    All nodes mentioned here {title.includes('Non') ? "don't" : ''}{' '}
                                    comply with the guideline parameters for {template.match_name}
                                </Text>
                            )}
                            <Text style={[styles.tableColHeader, styles.minMaxCol]}>
                                Min {'\n'} Value
                            </Text>
                            <Text style={[styles.tableColHeader, styles.minMaxCol]}>
                                Max {'\n'} Value
                            </Text>
                            {additionalColumns.map((col, index) => (
                                <Text
                                    style={[styles.tableColHeader, styles.remainingColumn]}
                                    key={index}
                                >
                                    {wrapText(col.header, maxLength)}
                                </Text>
                            ))}
                        </View>
                        <View style={styles.table}>
                            {checkListChunk.map((item, rowIndex) => (
                                <View style={styles.tableRow} key={rowIndex}>
                                    <Text style={[styles.tableCol, styles.firstColumn]}>
                                        {item.property_name}
                                    </Text>
                                    <Text style={[styles.tableCol, styles.minMaxCol]}>
                                        {item.min_value}
                                    </Text>
                                    <Text style={[styles.tableCol, styles.minMaxCol]}>
                                        {item.max_value}
                                    </Text>
                                    {additionalColumns.map((col, colIndex) => {
                                        let rowValue = col.values.find(
                                            (row) => row.rowName === item.property_name,
                                        )?.rowValue;
                                        const rowName = col.values.find(
                                            (row) => row.rowName === item.property_name,
                                        )?.rowName;

                                        const imperviousColumn = col.values.find((row) =>
                                            row.rowName.includes('Areas - Impervious'),
                                        );
                                        const isImpervious = imperviousColumn
                                            ? imperviousColumn.rowValue === '100'
                                            : false;

                                        if (
                                            isImpervious &&
                                            (rowName.includes('Base Flow') ||
                                                rowName.includes('Pervious'))
                                        ) {
                                            rowValue = 'irrelevant';
                                        }

                                        const isRowValueRelevant =
                                            rowValue !== 'irrelevant' && rowValue !== undefined;

                                        const isOutOfMinMaxRange =
                                            item.max_value !== undefined &&
                                            item.max_value !== null &&
                                            (Number(rowValue) < Number(item.min_value) ||
                                                Number(rowValue) > Number(item.max_value));

                                        const isOutOfMinRange =
                                            item.max_value === null &&
                                            Number(rowValue) < Number(item.min_value);

                                        const isValueOutOfRange =
                                            isRowValueRelevant &&
                                            (isOutOfMinMaxRange || isOutOfMinRange);

                                        return (
                                            <Text
                                                style={[
                                                    styles.tableCol,
                                                    styles.remainingColumn,
                                                    isValueOutOfRange && styles.redText,
                                                ]}
                                                key={colIndex}
                                            >
                                                {rowValue}
                                            </Text>
                                        );
                                    })}
                                </View>
                            ))}
                        </View>
                    </View>
                    <ReportFooter />
                </Page>
            ))}
        </>
    );
};

const groupNodesByTemplateName = (nodes) => {
    const groupedNodes = {};

    nodes.forEach((node) => {
        if (node.compliantTemplates) {
            node.compliantTemplates.forEach((template) => {
                if (!groupedNodes[template.match_name]) {
                    groupedNodes[template.match_name] = {
                        checkList: template.checkList,
                        nodes: [],
                    };
                }
                groupedNodes[template.match_name].nodes.push(node);
            });
        } else {
            node.nonCompliantTemplates.forEach((template) => {
                if (!groupedNodes[template.match_name]) {
                    groupedNodes[template.match_name] = {
                        checkList: template.checkList,
                        nodes: [],
                    };
                }
                groupedNodes[template.match_name].nodes.push(node);
            });
        }
    });

    return groupedNodes;
};

const formatAdditionalColumns = (nodes) => {
    const additionalColumns = [];

    nodes.forEach((node) => {
        additionalColumns.push({
            header: node.nodeName,
            values: node.rows.map((row) => ({
                rowName: row.rowName,
                rowValue: row.rowValue,
            })),
        });
    });

    return additionalColumns;
};

const AppendixComponent = ({ sourceNodes, companyIcon, title }) => {
    const groupedNodes = groupNodesByTemplateName(sourceNodes);

    const chunkArray = (array, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    };

    return (
        <>
            {Object.keys(groupedNodes).map((templateName, index) => {
                const group = groupedNodes[templateName];
                const additionalColumns = formatAdditionalColumns(group.nodes);
                const chunkedAdditionalColumns = chunkArray(additionalColumns, 5);

                return (
                    <>
                        {chunkedAdditionalColumns.map((additionalColumnsChunk, tableIndex) => (
                            <AppendixTable
                                key={`${index}-${tableIndex}`}
                                template={{ match_name: templateName, checkList: group.checkList }}
                                additionalColumns={additionalColumnsChunk}
                                companyIcon={companyIcon}
                                title={title}
                                showTitle={index === 0 && tableIndex === 0}
                                pageIndex={index}
                                tableIndex={tableIndex}
                            />
                        ))}
                    </>
                );
            })}
        </>
    );
};

export default AppendixComponent;
