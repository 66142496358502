// modules
import React from 'react';
// images
import adv from '../../../assets/manufacture-adv.png';
// css
import '../../../styles/OsdResultsTable.css';

const OsdResultsTable = ({ preDevDesignStorm, postDevDesignStorm, tableData }) => {
    let { aboveGroundStorage, belowGroundPipe, belowGroundTank } = tableData;
    if (!belowGroundPipe && !belowGroundTank) {
        belowGroundPipe = aboveGroundStorage;
        belowGroundTank = aboveGroundStorage;
    }

    return (
        <div className="osd-results-table ">
            <div className="osd-results-cell-a">
                <span>Check out stormwater treatment devices by</span>
                <img
                    alt="Protector OSD"
                    className="w-100"
                    src={adv}
                    onClick={() => window.open('https://protector.com.au/products/', '_blank')}
                />
            </div>
            <div className="osd-results-cell-d">Above Ground Storage</div>
            <div className="osd-results-cell-e">Below Ground Pipe</div>
            <div className="osd-results-cell-f">Below Ground Tank</div>
            <div className="osd-results-cell-b">OSD Results</div>
            {/* Headers */}
            {/* Above Ground Storage */}
            <div className="osd-results-cell-g">Permissible Discharge</div>
            <div className="osd-results-cell-h">On site detention volume</div>
            <div className="osd-results-cell-i">Orifice Diameter</div>
            {/* Below Ground Pipe */}
            <div className="osd-results-cell-j">Permissible Discharge</div>
            <div className="osd-results-cell-k">On site detention volume</div>
            <div className="osd-results-cell-l">Orifice Diameter</div>
            {/* Below Ground Tank */}
            <div className="osd-results-cell-m">Permissible Discharge</div>
            <div className="osd-results-cell-n">On site detention volume</div>
            <div className="osd-results-cell-o">Orifice Diameter</div>
            <div className="osd-results-cell-p" style={{ opacity: preDevDesignStorm ? 1 : 0 }}>
                PSD based on: {preDevDesignStorm}% AEP; OSD volume based on: {postDevDesignStorm}%
                AEP
            </div>
            {/* Table Data */}
            {/* Above Ground Storage */}
            <div className="osd-results-cell-q">{aboveGroundStorage.permissibleDischarge} L/s</div>
            <div className="osd-results-cell-r">
                {aboveGroundStorage.onSiteDetentionVolume} m<sup>3</sup>
            </div>
            <div className="osd-results-cell-s">{aboveGroundStorage.orificeDiameter}</div>
            {/* Below Ground Pipe */}
            <div className="osd-results-cell-t">{belowGroundPipe.permissibleDischarge} L/s</div>
            <div className="osd-results-cell-u">
                {belowGroundPipe.onSiteDetentionVolume}m<sup>3</sup>
            </div>
            <div className="osd-results-cell-v">{belowGroundPipe.orificeDiameter}</div>
            {/* Below Ground Tank */}
            <div className="osd-results-cell-w">{belowGroundTank.permissibleDischarge} L/s</div>
            <div className="osd-results-cell-x">
                {belowGroundTank.onSiteDetentionVolume} m<sup>3</sup>
            </div>
            <div className="osd-results-cell-y">{belowGroundTank.orificeDiameter}</div>
        </div>
    );
};

export default OsdResultsTable;
