import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

const rainwaterTankToolSlice = createSlice({
    name: 'rainwaterTankInfo',
    initialState: {
        hasSizeRainwaterTank: true,
        hasRainwatertankTable: true,
        sizeRainwaterTanks: [
            {
                id: uuidv4(),
                catchmentArea: '',
                demandKlDay: '',
                demandKlYear: '',
                reliability: '',
                tankSize: '',
            },
        ],
    },
    reducers: {
        setHasSizeRainwaterTank: (state, action) => {
            state.hasSizeRainwaterTank = action.payload;
        },
        setHasRainwatertankTable: (state, action) => {
            state.hasRainwatertankTable = action.payload;
        },
        setSizeRainwaterTanks: (state, action) => {
            state.sizeRainwaterTanks = action.payload;
        },
        setRainwaterTankInfo: (state, action) => {
            return action.payload; // Assuming action.payload is the new user info object
        },
    },
});

export const {
    setHasSizeRainwaterTank,
    setHasRainwatertankTable,
    setSizeRainwaterTanks,
    setRainwaterTankInfo,
} = rainwaterTankToolSlice.actions;
export const rainwaterTankTool = (state) => state.rainwaterTankInfo;
export default rainwaterTankToolSlice.reducer;
