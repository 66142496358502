import { configureStore } from '@reduxjs/toolkit';
// slices
import userInfoSlice from './userInfoSlice';
import projectInfoSlice from './projectInfoSlice';
import mapInfoSlice from './mapInfoSlice';
import userPermissionSlice from './userPermissionSlice';
import calculatorValidationSlice from './calculatorValidationSlice';
import sizeRainwaterTankToolSlice from './sizeRainwaterTankToolSlice';
import defaultTemplateSlice from './defaultTemplateSlice';
import idmTemplateSlice from './idmTemplateSlice';
import predevDTSlice from './preDevDTSlice';
import postdevDTSlice from './postDevDTSlice';
import osdStateSlice from './osdStateDTSlice';
import siteDetailsSlice from './WSUD/siteDetailsSlice';
import areaSlice from './WSUD/areaSlice';
import wsudResultsSlice from './WSUD/wsudResultsSlice';
import reportNoteSectionSlice from './reportNoteSectionSlice';
import overallFlowReductionSlice from './WSUD/overallFlowReductionSlice.js';

export const rootStore = configureStore({
    reducer: {
        userInfo: userInfoSlice,
        projectInfo: projectInfoSlice,
        mapInfo: mapInfoSlice,
        userPermission: userPermissionSlice,
        calculatorValidation: calculatorValidationSlice,
        rainwaterTankInfo: sizeRainwaterTankToolSlice,
        defaultTemplateInfo: defaultTemplateSlice,
        idmTemplateInfo: idmTemplateSlice,
        predevInfo: predevDTSlice,
        postdevInfo: postdevDTSlice,
        osdState: osdStateSlice,
        siteDetails: siteDetailsSlice,
        area: areaSlice,
        wsudResults: wsudResultsSlice,
        reportNoteSection: reportNoteSectionSlice,
        overallFlowReduction: overallFlowReductionSlice,
    },
});
