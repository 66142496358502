import React from 'react';
import { Button } from 'react-bootstrap';

const DevAreasFooter = ({ onAdd, type, sum1, sum2 }) => {
    return (
        <>
            <div className="osd-form-section">
                <Button className="btn btn-sm btn-primary span-2" onClick={onAdd}>
                    ADD NEW AREA
                </Button>
                <div className="span-3" />

                <div className="span-2 text-right">
                    Total {type}-Development Areas:&nbsp; {sum1}
                    &nbsp;{' '}
                </div>
            </div>
            <div className="osd-form-section">
                {sum2 - sum1 > 0 && (
                    <span className="text-danger span-7 text-center">
                        <b>
                            Please add {sum2 - sum1} m<sup>2</sup> of catchment area!{' '}
                        </b>
                    </span>
                )}
            </div>
        </>
    );
};

export default DevAreasFooter;
