// modules
import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
// utils
import './OsdsAds.css';

const extractManufactureData = (companyData) => {
    if (!companyData) return {};
    const manufactureData = {};
    for (var i = 0; i < companyData.length; i++) {
        if (manufactureData[companyData[i].material] === undefined) {
            manufactureData[companyData[i].material] = [];
        }

        manufactureData[companyData[i].material].push({
            name: companyData[i]['osd_device_name'],
            url: companyData[i].url,
        });
    }
    return manufactureData;
};

function OsdsAds({ storageType, osdCompany, companyDetails, setOSDMaterial, setOSDCompany }) {
    const [isLoading, setIsLoading] = useState(true);
    const [manufactureData, setManufactureData] = useState({});

    const setMaterialAndCompany = (material, companyName) => {
        setOSDMaterial(material);
        setOSDCompany(companyName);
    };

    useEffect(() => {
        if (!companyDetails || !companyDetails.length) return;
        setIsLoading(true);
        const newManufactureData = extractManufactureData(companyDetails);
        setManufactureData(newManufactureData);
        setIsLoading(false);
    }, [companyDetails]);

    if (isLoading) {
        return (
            <>
                <Spinner as="span" animation="border" size="sm" role="output" aria-hidden="true" />
                <br />
            </>
        );
    } else {
        return (
            <div
                className="flex relative p-4 osd-blue-border"
                style={{
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: '15px',
                    // backgroundColor: '#cedcf8',
                }}
            >
                {Object.keys(manufactureData).length > 0 ? (
                    Object.keys(manufactureData).map((material, index) => {
                        return (
                            <div key={index} className="image-row">
                                <div style={{ width: '8vw' }}>
                                    <label className="text-xl-left font-weight-bold">
                                        {material.toUpperCase()}
                                    </label>
                                </div>
                                <div className="image-grid">
                                    {manufactureData[material].map((company, companyIndex) => (
                                        // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                        <img
                                            key={companyIndex}
                                            src={company.url}
                                            alt={`Image ${index}-${companyIndex}`}
                                            style={{
                                                cursor: 'pointer',
                                                border:
                                                    osdCompany === company.name
                                                        ? '2px solid green'
                                                        : 'none',
                                            }}
                                            onClick={() => {
                                                setMaterialAndCompany(material, company.name);
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className="image-row">
                        <label className="text-xl">No Relevant OSD Devices Found!</label>
                    </div>
                )}
                {/* Conditional rendering based on the presence of OSD companies */}
                {osdCompany && (
                    <div className="alert alert-success" role="alert">
                        <strong>
                            {`You selected ${storageType}${
                                osdCompany === 'N/A'
                                    ? ', no preferred OSD Device specified'
                                    : ' & ' + osdCompany + ' device.'
                            }`}
                        </strong>
                    </div>
                )}
            </div>
        );
    }
}
export default OsdsAds;
