export default function MapInfo({ mapInfo }) {
    return (
        <div>
            <div className="mt-3">
                Project Address{': '}
                <strong>{mapInfo.address}</strong>
            </div>
            <div className="mt-1">
                Council{': '}
                <strong>{mapInfo.council}</strong>
            </div>
            <div className="mt-1 mb-3 ">
                Coordinates: LAT {': '}
                <strong>{mapInfo.coordinate.lat}</strong>, LNG{': '}
                <strong>{mapInfo.coordinate.lng}</strong>
            </div>
        </div>
    );
}
