import React, { useState, useEffect } from "react";
// react-bootstrap
import Accordion from "react-bootstrap/Accordion";
import { Card } from "react-bootstrap";
// css
import "bootstrap/dist/css/bootstrap.min.css";
import "./CouncilRule.css";
import { getCouncilRulesByAuthorityName } from "../../utils/councilRuleUtils";
import {
  getAuthorityIdByMlbName,
  getAuthorityNameByAuthorityId,
} from "../../utils/authorityUtils";

/*
 * This component is used to render council rules in Accordion
 */
function CouncilRule({ mlbName }) {
  const [councilRules, setCounilRules] = useState([]);
  const [treatmentDevices, setTreatmentDevices] = useState([]);

  useEffect(() => {
    getAuthorityIdByMlbName(mlbName).then((authorityId) => {
      getAuthorityNameByAuthorityId(authorityId).then((authorityName) => {
        getCouncilRulesByAuthorityName(authorityName).then(
          (councilRuleResults) => {
            setCounilRules(councilRuleResults);
            let buf = [];
            for (const item of councilRuleResults) {
              if (!buf.includes(item.treatment_device)) {
                buf.push(item.treatment_device);
              }
            }
            setTreatmentDevices(buf);
          }
        );
      });
    });
  }, [mlbName]);

  return (
    <>
      {councilRules.length !== 0 && (
        <>
          <div>
            <Accordion defaultActiveKey="0">
              <p className="council-rule-note glowing-text">
                {" "}
                &darr;&nbsp;Click here for council rules
              </p>
              <Accordion.Toggle
                variant="link"
                eventKey="1"
                type="button"
                className="btn btn-primary mt-1 mb-2"
              >
                Council&nbsp;Rules
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card>
                  <Card.Body>
                    {treatmentDevices.length > 0 &&
                      treatmentDevices.map((device, index) => {
                        return (
                          <div key={index} className="mb-2">
                            <div>
                              <span style={{ fontSize: 24 }}>
                                {device !== "-1" ? device : "General Rules"}
                              </span>
                            </div>
                            <ul className="list-group">
                              {councilRules.map((councilRule, index) => {
                                if (councilRule.treatment_device === device) {
                                  return (
                                    <li
                                      className="list-group-item"
                                      key={index}
                                    >
                                      <span>
                                        {councilRule.priority + ", "}
                                        {councilRule.link !== "null" ? (
                                          <a
                                            href={councilRule.link}
                                            style={{ fontSize: 12 }}
                                          >
                                            {councilRule.council_rule}
                                          </a>
                                        ) : (
                                          <>{councilRule.council_rule}</>
                                        )}
                                      </span>
                                    </li>
                                  );
                                } else return null;
                              })}
                            </ul>
                          </div>
                        );
                      })}
                  </Card.Body>
                </Card>
              </Accordion.Collapse>
            </Accordion>
          </div>
          <hr />
        </>
      )}
    </>
  );
}

export default CouncilRule;
