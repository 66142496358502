import React from "react";
import { StyleSheet, View, Text } from "@react-pdf/renderer";

export function IndividualTreatmentReductions({ imperviousNodes }) {
    const styles = StyleSheet.create({
        title: {
            textAlign: "center",
            fontSize: 20,
            margin: 10,
        },
        text1: {
            textAlign: "center",
            fontSize: 15,
            fontWeight: 700,
            margin: 10,
        },
        table: {
            display: "table",
            width: "100%",
        },
        tableRow: {
            width: "100%",
            flexDirection: "row",
            textAlign: "center",
        },
        tableCell: {
            height: "100%",
            width: "100%",
            padding: 5,
            border: "1px solid #658cbb",
            fontSize: 10,
        },
    });

    const imperviousTableHeaders = [
        "Impervious Area Name",
        "Treatment Name",
        "TN Reduction (%)",
        "TP Reduction (%)",
        "TSS Reduction (%)",
        "GP Reduction (%)",
        "Flow Reduction (%)",
        "Water Supply Reliability(%)",
    ];
    const imperviousColumnNames = [
        "reductionPercTN",
        "reductionPercTP",
        "reductionPercTSS",
        "reductionPercGP",
        "reductionPercFlow",
        "waterSupplyReliability",
    ];

    return (
        <>
            <Text style={styles.title}>Impervious Nodes</Text>
            <View style={styles.tableRow}>
                {imperviousTableHeaders.map((header, index) => (
                    <Text key={index} style={styles.tableCell}>
                        {header}
                    </Text>
                ))}
            </View>
            {imperviousNodes.map((node) => {
                return (
                    <View key={node.id ? node.id : -1} style={styles.tableRow}>
                        <Text style={styles.tableCell}>
                            {node["impAreaName"]}
                        </Text>
                        <Text style={styles.tableCell}>
                            {node["impAreaTreatType"]}
                        </Text>
                        {imperviousColumnNames.map((columnName) => {
                            const value = +node.results[columnName];
                            return (
                                <Text key={columnName} style={styles.tableCell}>
                                    {value ? value.toFixed(2) : "N/A"}
                                </Text>
                            );
                        })}
                    </View>
                );
            })}
        </>
    );
}

export function EndOfLineReductions({ eolTreatment, overallResults }) {
    const styles = StyleSheet.create({
        title: {
            textAlign: "center",
            fontSize: 20,
            margin: 10,
        },
        text1: {
            textAlign: "center",
            fontSize: 15,
            fontWeight: 700,
            margin: 10,
        },
        table: {
            display: "table",
            width: "100%",
        },
        tableRow: {
            width: "100%",
            flexDirection: "row",
            textAlign: "center",
        },
        tableCell: {
            height: "100%",
            width: "100%",
            padding: 5,
            border: "1px solid #658cbb",
            fontSize: 10,
        },
    });

    const eolTableHeaders = [
        "End-of-Line Treatment Name",
        "TN Reduction (%)",
        "TP Reduction (%)",
        "TSS Reduction (%)",
        "GP Reduction (%)",
    ];
    const eolColumnNames = ["tn", "tp", "tss", "gp"];

    return (
        <>
            {/* Impervious Nodes */}
            <Text style={styles.title}>End-of-Line Reductions</Text>
            <View style={styles.tableRow}>
                {eolTableHeaders.map((header, index) => (
                    <Text key={index} style={styles.tableCell}>
                        {header}
                    </Text>
                ))}
            </View>
            <View style={styles.tableRow}>
                <Text style={styles.tableCell}>{eolTreatment}</Text>
                {eolColumnNames.map((columnName) => {
                    const value = +overallResults.eolResults[columnName];
                    return (
                        <Text key={columnName} style={styles.tableCell}>
                            {value ? value.toFixed(2) : "N/A"}
                        </Text>
                    );
                })}
            </View>
        </>
    );
}

export function PerviousReductions({ perviousNodes }) {
    const styles = StyleSheet.create({
        title: {
            textAlign: "center",
            fontSize: 20,
            margin: 10,
        },
        text1: {
            textAlign: "center",
            fontSize: 15,
            fontWeight: 700,
            margin: 10,
        },
        table: {
            display: "table",
            width: "100%",
        },
        tableRow: {
            width: "100%",
            flexDirection: "row",
            textAlign: "center",
        },
        tableCell: {
            height: "100%",
            width: "100%",
            padding: 5,
            border: "1px solid #658cbb",
            fontSize: 10,
        },
    });

    const perviousTableHeaders = [
        "Pervious Area Name",
        "Treatment Type",
        "TN Reduction (%)",
        "TP Reduction (%)",
        "TSS Reduction (%)",
        "GP Reduction (%)",
        "Flow Reduction (%)",
    ];
    const perviousColumnNames = [
        "reductionPercTN",
        "reductionPercTP",
        "reductionPercTSS",
        "reductionPercGP",
        "reductionPercFlow",
    ];

    return (
        <>
            <Text style={styles.title}>Pervious Nodes</Text>
            <View style={styles.tableRow}>
                {perviousTableHeaders.map((header, index) => (
                    <Text key={index} style={styles.tableCell}>
                        {header}
                    </Text>
                ))}
            </View>
            {perviousNodes.map((node) => {
                return (
                    <View key={node.id ? node.id : -1} style={styles.tableRow}>
                        <Text style={styles.tableCell}>
                            {node["pAreaName"]}
                        </Text>
                        <Text style={styles.tableCell}>
                            {node["pAreaTreatType"]}
                        </Text>
                        {perviousColumnNames.map((columnName) => {
                            const value = +node.results[columnName];
                            return (
                                <Text key={columnName} style={styles.tableCell}>
                                    {value ? value.toFixed(2) : "N/A"}
                                </Text>
                            );
                        })}
                    </View>
                );
            })}
        </>
    );
}
