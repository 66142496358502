import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
// utils
import {
    checkSubscriptionStatus,
    isInCompanySubscription,
    deductSDCredit,
    verifyUserAccess,
} from '../../utils/api/lambda';
import RechargePopUp from './RechargePopup';
import PaymentConfirmation from './PaymentConfirmation';

/**
 *
 * @param {*} serviceID corresponds to the id of the report in our database
 * if service ID is -1, the button click is free
 * @returns
 */
export default function PaymentButton({
    assessorEmail,
    serviceID,
    userID,
    buttonTitle,
    onPaymentComplete,
    buttonColor = '#087cfc',
    subscriptionType,
    disabled = false,
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showRechargePopup, setShowRechargePopup] = useState(false);

    const isSubscribed = async (user_id, email) => {
        const companySubscription = await isInCompanySubscription(email, subscriptionType);
        const result = await checkSubscriptionStatus(user_id, subscriptionType);
        const isSubscribed = result.length > 0 || companySubscription;
        return isSubscribed;
    };

    const handleButtonClick = () => {
        processPayment();
    };

    const processPayment = async () => {
        const isSubscriber = await isSubscribed(userID, assessorEmail);
        if (serviceID > 0 && !isSubscriber) {
            const credit_amount = await verifyUserAccess(userID, serviceID);
            if (credit_amount) {
                setShowConfirmation(true);
            } else {
                setShowRechargePopup(true);
            }
        } else {
            handlePaymentCompleted(false);
        }
    };

    const handlePaymentCompleted = async (deductCredit) => {
        setIsLoading(true);

        await onPaymentComplete()
            .then(() => {
                if (deductCredit) deductSDCredit(userID, serviceID);
            })
            .catch((error) => {
                alert('An unexpected error occurred! Your SD credits have been refunded');
                console.error(error);
            });
        setIsLoading(false);
    };

    const handlePaymentConfirmed = () => {
        setShowConfirmation(false);
        handlePaymentCompleted(true);
    };

    const handlePaymentCancelled = () => {
        setShowConfirmation(false);
    };

    return (
        <>
            <Button
                variant="primary"
                className="btn btn-sm btn-result spacer"
                style={{
                    width: '250px',
                    height: '50px',
                    backgroundColor: buttonColor,
                }}
                onClick={handleButtonClick}
                disabled={disabled || isLoading}
            >
                {isLoading ? <Spinner animation="border" size="sm" /> : buttonTitle}
            </Button>
            <PaymentConfirmation
                showConfirmation={showConfirmation}
                handlePaymentConfirmed={handlePaymentConfirmed}
                handlePaymentCancelled={handlePaymentCancelled}
            />
            <RechargePopUp
                showRechargePopup={showRechargePopup}
                setShowRechargePopup={setShowRechargePopup}
            />
        </>
    );
}
