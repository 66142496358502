import React from 'react';
import { Page, StyleSheet, View, Text, Image } from '@react-pdf/renderer';

function ProjectDescription({ reportNoteData }) {
    const styles = StyleSheet.create({
        body: {
            paddingTop: 30,
            paddingBottom: 10,
            paddingHorizontal: 35,
        },
        title: {
            fontSize: 14,
            textAlign: 'left',
            marginBottom: '5%',
        },
        content: {
            fontSize: 10,
            textAlign: 'left',
            marginBottom: '5%',
            lineHeight: '2',
        },
        image: {
            marginVertical: 30,
            marginLeft: 5,
            maxWidth: 600,
        },
    });

    const description =
        reportNoteData.projectDescriptionSelection === 'preset-template'
            ? `The project is ${reportNoteData.projectType} located at ${reportNoteData.address} in ${reportNoteData.council} This report outlines the WSUD modelling done to demonstrate compliance with Council's requirements for${reportNoteData.requirements} The model was created based on${reportNoteData.guidelines} guidelines. These are the latest modelling guidelines referenced by Council. For more information on modelling, refer to the following sections.`
            : reportNoteData.newTemplateData;

    return (
        <Page style={styles.body}>
            <View>
                <Text style={styles.title}>Project Summary</Text>
                <View style={styles.content}>
                    <Text>{description}</Text>
                </View>

                {reportNoteData.projectDescriptionUploadedImage !== '' && (
                    <View>
                        <Image src={reportNoteData.projectDescriptionUploadedImage} />
                    </View>
                )}
            </View>
        </Page>
    );
}

export default ProjectDescription;
