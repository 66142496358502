// module
import React, { useState, useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
// css
import './theheader.css';
// image
import su_logo from '../../assets/su_logo.png';

function TheHeader() {
    const [pageTitle, setPageTitle] = useState('');
    useEffect(() => {
        const currentUrl = window.location.href;
        if (currentUrl.includes('auditor')) setPageTitle('Auditor of MUSIC (BETA) & Converter ');
        else if (currentUrl.includes('osds4vips')) setPageTitle('OSDs For VIPs');
        else if (currentUrl.includes('rwt')) setPageTitle('RWT Tools');
        else setPageTitle('SU Calculator');
    }, []);

    return (
        <>
            <Navbar
                variant="dark"
                className="w-100 d-flex flex-wrap justify-content-center align-items-center"
            >
                <Navbar.Brand
                    href="https://www.cleanstormwater.com.au/"
                    target="_blank"
                    className="flex-1"
                >
                    <img alt="" src={su_logo} className="h-100" style={{ maxHeight: 100 }} />
                </Navbar.Brand>
                <div className="flex-1 text-center" style={{ color: 'white' }}>
                    <h1>{pageTitle}</h1>
                </div>
                <Nav className="flex-1" style={{ fontSize: 16 }}>
                    <Nav.Link href="https://www.cleanstormwater.com.au/" target="_blank">
                        Cleanstormwater
                    </Nav.Link>
                    <Nav.Link
                        href="https://www.cleanstormwater.com.au/reportexample"
                        target="_blank"
                    >
                        Report Example
                    </Nav.Link>
                    <Nav.Link href="https://www.cleanstormwater.com.au/tutorial" target="_blank">
                        Tutorials
                    </Nav.Link>
                    <Nav.Link href="https://www.cleanstormwater.com.au/aboutus" target="_blank">
                        About Us
                    </Nav.Link>
                    <Nav.Link href="https://www.cleanstormwater.com.au/awards" target="_blank">
                        Awards
                    </Nav.Link>
                    <Nav.Link href="https://www.cleanstormwater.com.au/help" target="_blank">
                        Help
                    </Nav.Link>
                    <Nav.Link
                        href="https://www.cleanstormwater.com.au/help/support"
                        target="_blank"
                    >
                        Contact Us
                    </Nav.Link>
                </Nav>
            </Navbar>
        </>
    );
}

export default TheHeader;
