import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// redux
import { Provider } from 'react-redux';
import { rootStore } from './utils/redux/reducer'; // Import your Redux store

window.process = {
    env: {
        NODE_ENV: 'development',
    },
};

const RedirectToExternalUrl = ({ url }) => {
    alert('Invalid Path, Redirecting to our User Portal');
    window.location.href = url;
    return null;
};

ReactDOM.render(
    <BrowserRouter>
        <Provider store={rootStore}>
            <Routes>
                <Route path="/wsud" element={<App />} />
                <Route path="/osds4vips" element={<App />} />
                <Route path="/rwt-demand-tool" element={<App />} />
                <Route path="/auditor-converter" element={<App />} />
                <Route
                    path="*"
                    element={
                        <RedirectToExternalUrl url="https://www.user-portal.stormupdated.com.au" />
                    }
                />
            </Routes>
        </Provider>
    </BrowserRouter>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
