// modules
import React from 'react';
import { StyleSheet, View, Page } from '@react-pdf/renderer';
// components
import ReportFooter from '../ReportFooter';
import ReportHeader from '../ReportHeader';
import DefaultTemplate from './default-template/DefaultTemplate';
import OsdDeviceTemplate from './osd-device-template/OsdDeviceTemplate';
import WetlandTemplate from './wetland-template/WetlandTemplate';
import BioretentionTemplate from './bioretention-template/BioretentionTemplate';
import SedbasinTemplate from './sedbasin-template/SedbasinTemplate';
import RainwaterTankTemplate from './rainwater-tank-template/RainwaterTankTemplate';
import PermeablePavementTemplate from './permeable-pavement/PermeablePavementTemplate';
import RooftopRainGardenTemplate from './rooftop-rain-garden-template/RooftopRainGardenTemplate';
import TreePitTemplate from './tree-pit-template/TreePitTemplate';
import SwaleTemplate from './swale-template/SwaleTemplate';
import ManufacturedRainwaterTankTemplate from './manufactured-rainwater-tank-template/ManufacturedRainwaterTankTemplate';

function SpecificationsAndTypicalDrawings({
    treatmentNodesObject,
    reportTemplates,
    manufactureDeviceImages,
    manufactureDeviceDocumentLinks,
    reportHeaderIconUrl,
}) {
    const styles = StyleSheet.create({
        section: {
            marginBottom: 100,
            padding: 10,
        },
        footerSection: {
            position: 'absolute',
            width: '100vw',
            bottom: 10,
            textAlign: 'center',
            marginHorizontal: 'auto',
        },
    });

    return (
        <>
            {Object.keys(treatmentNodesObject).map((item) => {
                if (item !== 'undefined') {
                    return (
                        <Page style={styles.body} size="A4" key={item}>
                            <View>
                                <ReportHeader reportHeaderUrl={reportHeaderIconUrl} />
                            </View>
                            {reportTemplates[item] &&
                                reportTemplates[item].includes('bioretention template') && (
                                    <View style={styles.section}>
                                        <BioretentionTemplate
                                            nodeInfo={treatmentNodesObject[item]}
                                            image={manufactureDeviceImages[item]}
                                            title={item}
                                            documentLinks={manufactureDeviceDocumentLinks[item]}
                                        />
                                    </View>
                                )}
                            {reportTemplates[item] &&
                                reportTemplates[item].includes('wetland template') && (
                                    <View style={styles.section}>
                                        <WetlandTemplate
                                            nodeInfo={treatmentNodesObject[item]}
                                            image={manufactureDeviceImages[item]}
                                            title={item}
                                            documentLinks={manufactureDeviceDocumentLinks[item]}
                                        />
                                    </View>
                                )}
                            {reportTemplates[item] &&
                                reportTemplates[item].includes('osd device template') && (
                                    <View style={styles.section}>
                                        <OsdDeviceTemplate
                                            nodeInfo={treatmentNodesObject[item]}
                                            image={manufactureDeviceImages[item]}
                                            title={item}
                                            documentLinks={manufactureDeviceDocumentLinks[item]}
                                        />
                                    </View>
                                )}
                            {reportTemplates[item] &&
                                reportTemplates[item].includes('sedbasin template') && (
                                    <View style={styles.section}>
                                        <SedbasinTemplate
                                            nodeInfo={treatmentNodesObject[item]}
                                            image={manufactureDeviceImages[item]}
                                            title={item}
                                            documentLinks={manufactureDeviceDocumentLinks[item]}
                                        />
                                    </View>
                                )}
                            {reportTemplates[item] &&
                                reportTemplates[item].includes(
                                    'manufactured rainwater tank template',
                                ) && (
                                    <View style={styles.section}>
                                        <ManufacturedRainwaterTankTemplate
                                            nodeInfo={treatmentNodesObject[item]}
                                            image={manufactureDeviceImages[item]}
                                            title={item}
                                            documentLinks={manufactureDeviceDocumentLinks[item]}
                                        />
                                    </View>
                                )}
                            {reportTemplates[item] &&
                                reportTemplates[item].includes('rainwater tank template') && (
                                    <View style={styles.section}>
                                        <RainwaterTankTemplate
                                            nodeInfo={treatmentNodesObject[item]}
                                            image={manufactureDeviceImages[item]}
                                            title={item}
                                            documentLinks={manufactureDeviceDocumentLinks[item]}
                                        />
                                    </View>
                                )}
                            {reportTemplates[item] &&
                                reportTemplates[item].includes('permeable pavement template') && (
                                    <View style={styles.section}>
                                        <PermeablePavementTemplate
                                            nodeInfo={treatmentNodesObject[item]}
                                            image={manufactureDeviceImages[item]}
                                            title={item}
                                            documentLinks={manufactureDeviceDocumentLinks[item]}
                                        />
                                    </View>
                                )}
                            {reportTemplates[item] &&
                                reportTemplates[item].includes('rooftop rain garden template') && (
                                    <View style={styles.section}>
                                        <RooftopRainGardenTemplate
                                            nodeInfo={treatmentNodesObject[item]}
                                            image={manufactureDeviceImages[item]}
                                            title={item}
                                            documentLinks={manufactureDeviceDocumentLinks[item]}
                                        />
                                    </View>
                                )}
                            {reportTemplates[item] &&
                                reportTemplates[item].includes('tree pit template') && (
                                    <View style={styles.section}>
                                        <TreePitTemplate
                                            nodeInfo={treatmentNodesObject[item]}
                                            image={manufactureDeviceImages[item]}
                                            title={item}
                                            documentLinks={manufactureDeviceDocumentLinks[item]}
                                        />
                                    </View>
                                )}
                            {reportTemplates[item] &&
                                reportTemplates[item].includes('swale template') && (
                                    <View style={styles.section}>
                                        <SwaleTemplate
                                            nodeInfo={treatmentNodesObject[item]}
                                            image={manufactureDeviceImages[item]}
                                            title={item}
                                            documentLinks={manufactureDeviceDocumentLinks[item]}
                                        />
                                    </View>
                                )}
                            {reportTemplates[item] &&
                                reportTemplates[item].includes('default template') && (
                                    <View style={styles.section} key={item}>
                                        <DefaultTemplate
                                            nodeInfo={treatmentNodesObject[item]}
                                            image={
                                                manufactureDeviceImages[item]
                                                    ? manufactureDeviceImages[item]
                                                    : ''
                                            }
                                            title={item}
                                            documentLinks={manufactureDeviceDocumentLinks[item]}
                                        />
                                    </View>
                                )}
                            <View style={styles.footerSection}>
                                <ReportFooter />
                            </View>
                        </Page>
                    );
                }
                return <></>;
            })}
        </>
    );
}
export default SpecificationsAndTypicalDrawings;
