// modules
import React from 'react';
// images
import adv from '../../../assets/manufacture-adv.png';
// css
import '../../../styles/OsdResultsTable.css';

export function OsdResultsTable({
    osdResultData,
    hasPsd,
    councilPSD,
    designStorm,
    climateChangeRate,
}) {
    return (
        <div className="osd-results-table my-2">
            <div className="osd-results-cell-a">
                <span>Check out stormwater treatment devices by</span>
                <img
                    alt="Protector OSD"
                    className="w-100"
                    src={adv}
                    onClick={() => window.open('https://protector.com.au/products/', '_blank')}
                />
            </div>
            <div className="osd-results-cell-d">Above Ground Storage</div>
            <div className="osd-results-cell-e">Below Ground Pipe</div>
            <div className="osd-results-cell-f">Below Ground Tank</div>
            <div className="osd-results-cell-b">OSD Results</div>
            {/* Headers */}
            {/* Above Ground Storage */}
            <div className="osd-results-cell-g">Permissible Discharge</div>
            <div className="osd-results-cell-h">On site detention volume</div>
            <div className="osd-results-cell-i">Orifice Diameter</div>
            {/* Below Ground Pipe */}
            <div className="osd-results-cell-j">Permissible Discharge</div>
            <div className="osd-results-cell-k">On site detention volume</div>
            <div className="osd-results-cell-l">Orifice Diameter</div>
            {/* Below Ground Tank */}
            <div className="osd-results-cell-m">Permissible Discharge</div>
            <div className="osd-results-cell-n">On site detention volume</div>
            <div className="osd-results-cell-o">Orifice Diameter</div>
            <div className="osd-results-cell-p">
                PSD based on: {designStorm}% AEP; OSD volume based on: {designStorm}% AEP
            </div>
            {/* Table Data */}
            {/* Above Ground Storage */}
            <div className="osd-results-cell-q">
                {hasPsd ? councilPSD : parseFloat(osdResultData.q_pre).toFixed(2)}
                L/s
            </div>
            <div className="osd-results-cell-r">
                {(osdResultData.max_volume * (1 + climateChangeRate / 100)).toFixed(2)}m<sup>3</sup>
            </div>
            <div className="osd-results-cell-s">
                {osdResultData.report.orifice.orificeDiameter === 'N/A' ||
                osdResultData.report.orifice.orificeDiameter === null ||
                parseFloat(+osdResultData.report.orifice.orificeDiameter).toFixed(2) === '0.00'
                    ? 'N/A'
                    : parseFloat(+osdResultData.report.orifice.orificeDiameter).toFixed(2) + 'mm'}
            </div>
            {/* Below Ground Pipe */}
            <div className="osd-results-cell-t">
                {hasPsd ? councilPSD : parseFloat(osdResultData.q_pre).toFixed(2)}
                L/s
            </div>
            <div className="osd-results-cell-u">
                {(osdResultData.max_volume * (1 + climateChangeRate / 100)).toFixed(2)}m<sup>3</sup>
            </div>
            <div className="osd-results-cell-v">
                {osdResultData.report.orifice.orificeDiameter === 'N/A' ||
                osdResultData.report.orifice.orificeDiameter === null ||
                parseFloat(+osdResultData.report.orifice.orificeDiameter).toFixed(2) === '0.00'
                    ? 'N/A'
                    : parseFloat(+osdResultData.report.orifice.orificeDiameter).toFixed(2) + 'mm'}
            </div>
            {/* Below Ground Tank */}
            <div className="osd-results-cell-w">
                {hasPsd ? councilPSD : parseFloat(osdResultData.q_pre).toFixed(2)}
            </div>
            <div className="osd-results-cell-x">
                {(osdResultData.max_volume * (1 + climateChangeRate / 100)).toFixed(2)}m<sup>3</sup>
            </div>
            <div className="osd-results-cell-y">
                {osdResultData.report.orifice.orificeDiameter === 'N/A' ||
                osdResultData.report.orifice.orificeDiameter === null ||
                parseFloat(+osdResultData.report.orifice.orificeDiameter).toFixed(2) === '0.00'
                    ? 'N/A'
                    : parseFloat(+osdResultData.report.orifice.orificeDiameter).toFixed(2) + 'mm'}
            </div>
        </div>
    );
}
