import { getImageUrlFromS3 } from "./api/s3";
import { getManufactureDeviceDocumentLinks } from "./rdsCmsUtils";

/* Please refactor this code to save the data in the DB */
export const getOsdImageLinkAndDocument = async (osdCompany) => {
    const imageLinks = await getManufactureDeviceImages(osdCompany);
    const documents = await getDocumentLinks(osdCompany);

    return {
        manufactureImageLinks: imageLinks,
        manufactureDocuments: documents,
    };
};

const getManufactureDeviceImages = async (osdCompany) => {
    let imagesBuf = await getImageUrlFromS3(
        "manufacture-device-images",
        `${osdCompany.toLowerCase()}.png`
    )
        .then((res) => {
            return res;
        })
        .catch((err) => {
            console.log(err);
        });

    return [imagesBuf];
};

const getDocumentLinks = async (osdCompany) => {
    return await getManufactureDeviceDocumentLinks(osdCompany.toLowerCase())
        .then((res) => {
            return res.map((device) => {
                return {
                    name: device.document_name,
                    link: device.manufacture_device_document_link,
                };
            });
        })
        .catch((err) => {
            console.log(err);
        });
};
