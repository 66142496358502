import styled from 'styled-components';

export const TooltipContainer = styled.div`
    position: relative;
    display: inline-block;
`;

export const TooltipContent = styled.span`
    display: none;
    position: absolute;
    background-color: #718096;
    color: #fff;
    border-radius: 4px;
    padding: 8px;
    z-index: 1;
    text-align: left;
    ${(props) => {
        switch (props.$position) {
            case 'top':
                return 'top: calc(100% + 10px); left: 50%; transform: translateX(-50%);';
            case 'bottom':
                return 'bottom: calc(100% + 10px); left: 50%; transform: translateX(-50%);';
            case 'left':
                return 'top: 50%; right: calc(100% + 10px); transform: translateY(-50%);';
            case 'right':
                return 'top: 50%; left: calc(100% + 10px); transform: translateY(-50%);';
            case 'top-left':
                return 'top: calc(100% + 10px); right: calc(100% + 10px);';
            case 'top-right':
                return 'top: calc(100% + 10px); left: calc(100% + 10px);';
            default:
                return 'bottom: calc(100% + 10px);  transform: translateX(-50%);'; // Default to bottom
        }
    }}
    ${(props) => {
        if (props.$width !== undefined) {
            return `width: ${props.$width};`;
        } else {
            return 'min-width: 30vw;';
        }
    }}
    ${(props) => {
        if (props.$whitespace) {
            return `white-space: ${props.$whitespace};`;
        }
    }}
    
        ${TooltipContainer}:hover & {
        display: block;
    }
`;

export const TooltipButton = styled.span`
    display: inline-block;
`;
