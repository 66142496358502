// modules
import axios from "axios";
// utils
import { LAMBDA_API } from "./globalConstant";

export const getAuthorityIdByMlbName = async (mlbName) => {
  let result;

  const payload = {
    endpoint: "getAuthorityIdByMlbName",
    params: { mlb_name: mlbName },
  };
  await axios.post(`${LAMBDA_API}/cms`, payload).then((res) => {
    result =
      res.data.results.length > 0 ? res.data.results[0].authority_id : "";
  });

  return result;
};
export const getAuthorityNameByAuthorityId = async (authorityId) => {
  let result;

  const payload = {
    endpoint: "getAuthorityNameByAuthorityId",
    params: { authority_id: authorityId },
  };
  await axios.post(`${LAMBDA_API}/cms`, payload).then((res) => {
    result =
      res.data.results.length > 0 ? res.data.results[0].authority_name : "";
  });
  return result;
};
