import React from 'react';
import { Document, pdf } from '@react-pdf/renderer';
// components
import BasicInformation from '../BasicInformation';
import RWTDemandReportTemplate from './RWTDemandReportTemplate';

const getRWTDemandReport = (data, userInfo, mapInfo, projectInfo) => {
    return (
        <Document>
            <BasicInformation
                reportHeaderUrl={userInfo.reportHeaderIconUrl}
                address={{
                    suburb: mapInfo.suburb,
                    state: mapInfo.state,
                    postcode: mapInfo.postcode,
                    council: mapInfo.council,
                    address: mapInfo.address,
                    coordinate: mapInfo.coordinate,
                }}
                basicInfo={{
                    developmentType: projectInfo.developmentType,
                    permit: projectInfo.planningPermitNo,
                    projectName: 'N/A',
                    assessor: userInfo.assessorName,
                    email: userInfo.assessorEmail,
                }}
            />
            <RWTDemandReportTemplate data={data} reportHeaderIcon={userInfo.reportHeaderIcon} />
        </Document>
    );
};

// main function
export const generateRWTDemandReport = async (data, userInfo, mapInfo, projectInfo) => {
    try {
        const reportBlob = await pdf(
            getRWTDemandReport(data, userInfo, mapInfo, projectInfo),
        ).toBlob();
        return reportBlob;
    } catch (err) {
        console.error(err);
    }
};
