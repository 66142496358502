import { createSlice } from '@reduxjs/toolkit';

const reportNoteSectionSlice = createSlice({
    name: 'reportNoteSection',
    initialState: {
        useReportNote: false,
        projectDescriptionSelection: 'preset-template',
        projectType: '',
        address: '',
        council: '',
        requirements: '',
        guidelines: '',
        newTemplateData: '',
        projectDescriptionUploadedImage: '',
        reportNoteAppendixItems: [],
    },
    reducers: {
        setUseReportNote: (state, action) => {
            state.useReportNote = action.payload;
        },
        setProjectDescriptionSelection: (state, action) => {
            state.projectDescriptionSelection = action.payload;
        },
        setProjectType: (state, action) => {
            state.projectType = action.payload;
        },
        setAddress: (state, action) => {
            state.address = action.payload;
        },
        setCouncil: (state, action) => {
            state.council = action.payload;
        },
        setRequirements: (state, action) => {
            state.requirements = action.payload;
        },
        setGuidelines: (state, action) => {
            state.guidelines = action.payload;
        },
        setNewTemplateData: (state, action) => {
            state.newTemplateData = action.payload;
        },
        setProjectDescriptionUploadedImage: (state, action) => {
            state.projectDescriptionUploadedImage = action.payload;
        },
        addReportNoteAppendixItem: (state, action) => {
            const maxId = state.reportNoteAppendixItems.reduce(
                (max, item) => Math.max(max, parseInt(item.id, 10)),
                0,
            );
            const newId = (maxId + 1).toString();

            state.reportNoteAppendixItems = [
                ...state.reportNoteAppendixItems,
                {
                    id: newId,
                    title: '',
                    appendixData: '',
                },
            ];
        },
        updateReportNoteAppendixItem: (state, action) => {
            const { itemId, columnName, value } = action.payload;
            const itemIndex = state.reportNoteAppendixItems.findIndex((item) => item.id === itemId);

            if (itemIndex !== -1) {
                state.reportNoteAppendixItems[itemIndex][columnName] = value;
            }
        },
        removeReportNoteAppendixItem: (state, action) => {
            state.reportNoteAppendixItems = state.reportNoteAppendixItems.filter(
                (item) => +item.id !== action.payload,
            );
        },
    },
});

export const {
    setUseReportNote,
    setProjectDescriptionSelection,
    setProjectType,
    setAddress,
    setCouncil,
    setRequirements,
    setGuidelines,
    setNewTemplateData,
    setProjectDescriptionUploadedImage,
    addReportNoteAppendixItem,
    updateReportNoteAppendixItem,
    removeReportNoteAppendixItem,
} = reportNoteSectionSlice.actions;

export const selectReportNoteSection = (state) => state.reportNoteSection;
export default reportNoteSectionSlice.reducer;
