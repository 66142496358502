import axios from "axios";

export const sendReceipt = async (
    receiptFile,
    assessorName,
    assessorEmail,
    service
) => {
    const formData = new FormData();
    formData.append("endpoint", "sendInvoiceReport");
    formData.append(
        "params",
        JSON.stringify({
            assessor: assessorName,
            email: assessorEmail,
            service: service,
        })
    );
    formData.append("Receipt", receiptFile, "Su Receipt.pdf");
    await axios.post(
        "https://xgr5xf78jh.execute-api.ap-southeast-2.amazonaws.com/emailer",
        formData
    );
};

/**
 *
 * @param {Blob} reportFile
 * @param {string} assessorName
 * @param {string} assessorEmail
 * @param {string} address
 * @param {string} reportType - su || osds4vips || auditor
 * @param {object} susFileData - currently, it contains all the states from Su Calculator
 */
export const sendSuReport = async (
    reportFile,
    assessorName,
    assessorEmail,
    address,
    reportType,
    susFileData
) => {
    const formData = new FormData();
    formData.append("endpoint", "sendSuReport");
    formData.append(
        "params",
        JSON.stringify({
            assessorName,
            assessorEmail,
            address,
            reportType,
            susFileData,
        })
    );
    formData.append("Report", reportFile, "Su Receipt.pdf");

    await axios.post(
        "https://xgr5xf78jh.execute-api.ap-southeast-2.amazonaws.com/emailer",
        formData
    );
};
