import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setRainwaterDemand,
    setTreatmentDevice,
    setDeviceInput,
    selectSiteDetails,
    setCatchmentType,
} from '../../../../utils/redux/WSUD/siteDetailsSlice';
import styles from './SiteDetailsForm.module.css';
import {
    TooltipContainer,
    TooltipContent,
    TooltipButton,
} from '../../../styled-components/TooltipComponents';
import eolDeviceTooltip from '../../../../assets/tooltip-images/su-calculator-eol-device.png';
import ControlledInput from '../ControlledInput';
import { JCO_ONLINE_DEV_CMS_LAMBDA_API, sails_api } from '../../../../utils/globalConstant';
import { selectMapInfo } from '../../../../utils/redux/mapInfoSlice';
import { isNumber } from '../../../../utils/common';
import { getMethodsAPI } from '../../../../utils/api/lambda';

const rainwaterDemandOptions = ['Toilet', 'Toilet + Laundry'];
const eolDeviceCustomValues = {
    'No end-of-line treatment': '',
};

export const SiteDetailsForm = () => {
    const dispatch = useDispatch();
    const siteDetails = useSelector(selectSiteDetails);
    const mapInfo = useSelector(selectMapInfo);
    const [catchmentTypes, setCatchmentTypes] = useState([]);
    const [EOLDevices, setEOLDevices] = useState([]);

    const disableTreatmentSizeInput = siteDetails.treatmentDevice === '';

    const handleRainwaterDemandChange = (event) => {
        dispatch(setRainwaterDemand(event.target.value));
    };
    const handleCatchmentTypeChange = (event) => {
        dispatch(setCatchmentType(event.target.value));
    };
    const handleTreatmentDeviceChange = (event) => {
        dispatch(setTreatmentDevice(event.target.value));
    };
    const handleDeviceInputChange = (event) => {
        const input = event.target.value;
        if (!isNumber(input)) return;
        dispatch(setDeviceInput(input));
    };

    useEffect(() => {
        const fetchCatchmentTypes = async () => {
            sails_api
                .get('catchmentTypes/' + mapInfo.rainfallStation)
                .then((res) => {
                    const catchmentTypes = res.data.catchmentTypes.reverse();
                    if (catchmentTypes.length && !siteDetails.catchmentType)
                        dispatch(setCatchmentType(catchmentTypes[0]));
                    setCatchmentTypes(catchmentTypes);
                })
                .catch((error) => {
                    console.error(error);
                });

            getMethodsAPI({
                endpoint: 'getEOLDevices',
                rainfallStation: mapInfo.rainfallStation,
            }).then((response) => {
                setEOLDevices(response.data);
            });
        };
        fetchCatchmentTypes();
    }, []);

    return (
        <div className={styles['site-details-form']}>
            <div className={styles['form-group']}>
                <label htmlFor="catchmentType">Catchment Type</label>
                <select
                    id="catchmentType"
                    name="catchmentType"
                    value={siteDetails.catchmentType}
                    onChange={handleCatchmentTypeChange}
                >
                    {catchmentTypes.map((option, index) => (
                        <option value={option} key={`catchmentType-option-${index}`}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>
            <div className={styles['form-group']}>
                <label htmlFor="rainwaterDemand">
                    Rainwater demand
                    <TooltipContainer>
                        <TooltipButton>❓</TooltipButton>
                        <TooltipContent position="top-right" width="20vw">
                            Toilet water demand is 20 Liters per Day.
                            <br />
                            Toilet + Laundry water demand is 62 Liters per Day.
                        </TooltipContent>
                    </TooltipContainer>
                </label>
                <select
                    id="rainwaterDemand"
                    name="rainwaterDemand"
                    value={siteDetails.rainwaterDemand}
                    onChange={handleRainwaterDemandChange}
                >
                    {rainwaterDemandOptions.map((option, index) => (
                        <option value={option} key={`rainwater-option-${index}`}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>

            <div className={styles['form-group']}>
                <label htmlFor="treatmentDevice">
                    Manufactured End-of-Line Treatment Device
                    <TooltipContainer>
                        <TooltipButton>❗</TooltipButton>
                        <TooltipContent position="top-left" width="20vw">
                            <>
                                <img
                                    className="eol_tooltip"
                                    src={eolDeviceTooltip}
                                    width="95%"
                                    alt=""
                                    style={{ marginLeft: '2.5%' }}
                                />
                                <br />
                                If you wish to simulate a manufactured treatment train, kindly
                                choose an End-of-Line Treatment Device and specify its size.
                            </>
                        </TooltipContent>
                    </TooltipContainer>
                </label>
                <div style={{ display: 'flex', gap: '1vw' }}>
                    <select
                        id="treatmentDevice"
                        name="treatmentDevice"
                        value={siteDetails.treatmentDevice}
                        onChange={handleTreatmentDeviceChange}
                    >
                        {EOLDevices.map((option, index) => (
                            <option
                                value={
                                    eolDeviceCustomValues[option.eol_device] ?? option.eol_device
                                }
                                key={`treatment-option-${index}`}
                            >
                                {option.eol_device}
                            </option>
                        ))}
                    </select>
                    <ControlledInput
                        type="text"
                        id="deviceInput"
                        name="deviceInput"
                        placeholder="Treatment Size (L/s)"
                        value={disableTreatmentSizeInput ? '' : siteDetails.deviceInput}
                        disabled={disableTreatmentSizeInput}
                        onChange={handleDeviceInputChange}
                    />
                </div>
                <div className={styles.liveStormRow}>
                    {!siteDetails.resultUpdateRequired ? (
                        <div
                            className={`${styles.resultsWrapper} ${
                                siteDetails.eolResult === null && styles.hidden
                            } `}
                        >
                            <div className={styles.resultItem}>
                                TN:{' '}
                                {siteDetails.eolResult?.tn ? siteDetails.eolResult.tn + '%' : 'N/A'}
                            </div>
                            <div className={styles.resultItem}>
                                TP:{' '}
                                {siteDetails.eolResult?.tp ? siteDetails.eolResult.tp + '%' : 'N/A'}
                            </div>
                            <div className={styles.resultItem}>
                                TSS:{' '}
                                {siteDetails.eolResult?.tss
                                    ? siteDetails.eolResult.tss + '%'
                                    : 'N/A'}
                            </div>
                            <div className={styles.resultItem}>
                                GP:{' '}
                                {siteDetails.eolResult?.gp ? siteDetails.eolResult.gp + '%' : 'N/A'}
                            </div>
                        </div>
                    ) : (
                        <div
                            className={`${styles.loading} ${
                                (siteDetails.treatmentDevice === '' ||
                                    siteDetails.deviceInput === '') &&
                                styles.hidden
                            } `}
                        >
                            Calculating...
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SiteDetailsForm;
