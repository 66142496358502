import React from 'react';
import { StyleSheet, View, Text, Image, Link } from '@react-pdf/renderer';
import { stringOrNumberWithSignificantVals } from '../../auditorUtils';

function KerbspaceTemplate({ nodeInfo, image, documentLinks, title }) {
    const styles = StyleSheet.create({
        title: {
            fontSize: 14,
            textAlign: 'center',
            marginBottom: '3%',
        },
        image: {
            width: '100%',
        },
        link: {
            textAlign: 'center',
            fontSize: 9,
            marginBottom: '2vh',
            marginTop: '2vh',
        },
        superscript: {
            fontSize: '5px',
            lineHeight: '4px',
        },
        // TABLE
        table: {
            padding: 5,
        },
        tableTitle: {
            width: '100%',
            textAlign: 'center',
            fontSize: 14,
            marginBottom: '3vh',
        },
        tableRow: {
            flexDirection: 'row',
            height: '60px',
        },

        tableRow_result: {
            flexDirection: 'row',
        },

        tableCol: {
            flex: 1,
            textAlign: 'center',
            fontSize: 8,
            border: 1,
            padding: 5,
        },
    });
    return (
        <>
            <Text style={styles.tableTitle}>Specifications and Typical Drawings - {title}</Text>
            {image && <Image style={styles.image} src={image} />}
            {documentLinks
                ? documentLinks.map((link, index) => {
                      return (
                          <Link
                              key={link['manufacture_device_document_link']}
                              src={link['manufacture_device_document_link']}
                              style={styles.link}
                          >
                              {link['document_name'] ? link['document_name'] : `document ${index}`}
                          </Link>
                      );
                  })
                : null}
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <Text
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            fontSize: '9',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            color: 'red',
                            paddingTop: '20px',
                        }}
                    >
                        {'  '}Device Name
                    </Text>
                    <View
                        style={{
                            flex: 2,
                            textAlign: 'center',
                            fontSize: '9',
                            borderWidth: '1px 0 0 1px',
                            borderStyle: 'solid',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Text
                            style={{
                                flex: 1,
                                textAlign: 'center',
                                fontSize: '9',
                                color: 'red',
                                paddingTop: '6px',
                            }}
                        >
                            Area
                        </Text>
                        <View style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                            <Text
                                style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    fontSize: '9',
                                    borderTop: '1px solid black',
                                    paddingTop: '5px',
                                    borderRight: '1px solid black',
                                }}
                            >
                                Surface(m<Text style={styles.superscript}>2</Text>)
                            </Text>
                            <Text
                                style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    fontSize: '9',
                                    borderLeft: '1px solid black',
                                    borderTop: '1px solid black',
                                    paddingTop: '5px',
                                }}
                            >
                                Filter(m<Text style={styles.superscript}>2</Text>)
                            </Text>
                        </View>
                    </View>

                    <Text
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            fontSize: '9',
                            borderWidth: '1px 0 0 1px',
                            borderStyle: 'solid',
                            color: 'red',
                            paddingTop: '20px',
                            paddingBottom: '20px',
                        }}
                    >
                        EDD height (mm)
                    </Text>

                    <View
                        style={{
                            flex: 2,
                            textAlign: 'center',
                            fontSize: '9',
                            borderWidth: '1px 0 0 1px',
                            borderStyle: 'solid',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Text
                            style={{
                                flex: 1,
                                textAlign: 'center',
                                fontSize: '9',
                                color: 'red',
                                paddingTop: '6px',
                            }}
                        >
                            Filter Layer
                        </Text>
                        <View style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                            <Text
                                style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    fontSize: '9',
                                    borderTop: '1px solid black',
                                    paddingTop: '3px',
                                    borderRight: '1px solid black',
                                }}
                            >
                                Height (mm)
                            </Text>
                            <Text
                                style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    fontSize: '9',
                                    borderLeft: '1px solid black',
                                    borderTop: '1px solid black',
                                    paddingTop: '3px',
                                }}
                            >
                                Permeability (mm/hr)
                            </Text>
                        </View>
                    </View>

                    <View
                        style={{
                            flex: 2,
                            textAlign: 'center',
                            fontSize: '9',
                            borderWidth: '1px 0 0 1px',
                            borderStyle: 'solid',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Text
                            style={{
                                flex: 1,
                                textAlign: 'center',
                                fontSize: '9',
                                color: 'red',
                                paddingTop: '6px',
                            }}
                        >
                            Exfiltration
                        </Text>
                        <View style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                            <Text
                                style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    fontSize: '9',
                                    borderTop: '1px solid black',
                                    paddingTop: '5px',
                                    borderRight: '1px solid black',
                                }}
                            >
                                Rate(mm/hr)
                            </Text>
                            <Text
                                style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    fontSize: '9',
                                    borderLeft: '1px solid black',
                                    borderTop: '1px solid black',
                                    paddingTop: '5px',
                                }}
                            >
                                Outflow(L/s)
                            </Text>
                        </View>
                    </View>
                </View>
                {nodeInfo.map((node) => {
                    return (
                        <View style={styles.tableRow_result} key={node['Node Name']}>
                            <Text style={styles.tableCol}>
                                {node['Node Name'] ? node['Node Name'] : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node['Storage Properties - Surface Area (square metres)']
                                    ? stringOrNumberWithSignificantVals(
                                          +node[
                                              'Storage Properties - Surface Area (square metres)'
                                          ],
                                      )
                                    : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node['Filter and Media Properties - Filter Area (square metres)']
                                    ? stringOrNumberWithSignificantVals(
                                          +node[
                                              'Filter and Media Properties - Filter Area (square metres)'
                                          ],
                                      )
                                    : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node['Storage Properties - Extended Detention Depth (metres)']
                                    ? stringOrNumberWithSignificantVals(
                                          +node[
                                              'Storage Properties - Extended Detention Depth (metres)'
                                          ] * 1000,
                                      )
                                    : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node['Filter and Media Properties - Filter Depth (metres)']
                                    ? stringOrNumberWithSignificantVals(
                                          +node[
                                              'Filter and Media Properties - Filter Depth (metres)'
                                          ] * 1000,
                                      )
                                    : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node[
                                    'Filter and Media Properties - Saturated Hydraulic Conductivity (mm/hr)'
                                ]
                                    ? stringOrNumberWithSignificantVals(
                                          +node[
                                              'Filter and Media Properties - Saturated Hydraulic Conductivity (mm/hr)'
                                          ],
                                      )
                                    : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node['Infiltration Properties - Exfiltration Rate (mm/hr)']
                                    ? stringOrNumberWithSignificantVals(
                                          +node[
                                              'Infiltration Properties - Exfiltration Rate (mm/hr)'
                                          ],
                                      )
                                    : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node['Total exfiltration outflow (L/s)']
                                    ? stringOrNumberWithSignificantVals(
                                          +node['Total exfiltration outflow (L/s)'],
                                      )
                                    : 'N/A'}
                            </Text>
                            {/* <Text style={styles.tableCol}>
                {node['Storage Properties - Extended Detention Depth (metres)'] && node['Filter and Media Properties - Filter Depth (metres)']
                  ? stringOrNumberWithSignificantVals(
                      (+node['Storage Properties - Extended Detention Depth (metres)'] + +node['Filter and Media Properties - Filter Depth (metres)'] + 0.35) *
                        1000,
                    )
                  : 'N/A'}
              </Text> */}
                        </View>
                    );
                })}
            </View>
        </>
    );
}

export default KerbspaceTemplate;
