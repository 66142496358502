import React from 'react';
import { StyleSheet, Document, Page, View, pdf, Image, Text } from '@react-pdf/renderer';
// components
import BasicInformation from '../BasicInformation';
import BoydsReportTemplate from './BoydsReportTemplate';
import SpecImagePages from '../SpecImagePages';
import ReportHeader from '../ReportHeader';
import ReportFooter from '../ReportFooter';
import BoydsMethodCalculationTemplate from './BoydsMethodCalculationTemplate';
import OsdRangeOfDurationsCalculatedPage from './../sharedPages/OsdRangeOfDurationsCalculatedPage';

const getBoydsReport = (data, manufactureImageLinks, manufactureDocuments) => {
    const styles = StyleSheet.create({
        page: {
            paddingTop: 30,
            paddingBottom: 10,
            paddingHorizontal: 35,
        },
        imageContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
        },
        image: {
            paddingTop: 30,
            height: 350,
            width: 400,
        },
        mainTitle: {
            fontSize: 18,
            fontweight: 700,
            textAlign: 'center',
            marginTop: 5,
            marginBottom: 20,
        },
    });

    return (
        <Document>
            <BasicInformation
                reportHeaderUrl={data.reportHeaderIconUrl}
                address={{
                    suburb: data.suburb,
                    state: data.state,
                    postcode: data.postcode,
                    council: data.council,
                    address: data.address,
                    coordinate: data.coordinate,
                }}
                basicInfo={{
                    developmentType: data.developmentType,
                    permit: data.planningPermitNo,
                    projectName: 'N/A',
                    assessor: data.assessorName,
                    email: data.assessorEmail,
                }}
            />

            {/* OSD Summary*/}
            <BoydsReportTemplate data={data} />

            {/* Images */}
            <SpecImagePages
                osdCompany={data.OSDCompany}
                manufactureImageLinks={manufactureImageLinks}
                manufactureDocuments={manufactureDocuments}
                reportHeaderUrl={data.reportHeaderIconUrl}
            />

            {data.osd.method?.toLowerCase() === 'boyds method' && (
                <BoydsMethodCalculationTemplate data={data} />
            )}

            {data.osd.tDurChart && data.osd.tDurChart !== '' && (
                <>
                    {/* Storm Duration Chart */}
                    <Page style={styles.page} size="A4">
                        <View>
                            <ReportHeader reportHeaderUrl={data.reportHeaderIconUrl} />
                        </View>
                        <View>
                            <Text style={styles.mainTitle}>
                                Range of Durations used to Determine Max volume Required
                            </Text>
                        </View>
                        <View style={styles.imageContainer}>
                            <Image src={data.osd.tDurChart} style={styles.image} />
                        </View>
                        <ReportFooter />
                    </Page>

                    {/* Storm Duration Table */}
                    <OsdRangeOfDurationsCalculatedPage
                        vreq_n_data={data.osdResult.report.vreq_n_data}
                        reportHeaderIconUrl={data.reportHeaderIconUrl}
                    />
                </>
            )}
        </Document>
    );
};

// main function
export const generateBoydsReport = async (data, manufactureImageLinks, manufactureDocuments) => {
    try {
        const reportBlob = await pdf(
            getBoydsReport(data, manufactureImageLinks, manufactureDocuments),
        ).toBlob();
        return reportBlob;
    } catch (err) {
        console.error(err);
    }
};
