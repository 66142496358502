// modules
import React from 'react';
import { Button } from 'react-bootstrap';
// css
import '../../styles/OsdResultsTable.css';

export function StorageTypeSelection({ setStorageType }) {
    const handleButtonClick = (storageType) => {
        setStorageType(storageType);
    };

    return (
        <div
            style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
                padding: '5vh 5vw',
                // backgroundColor: '#dbc8f7',
            }}
            className="osd-blue-border"
        >
            <Button
                className="osd-results-table-yellow-button"
                onClick={() => {
                    handleButtonClick('Above Ground Storage');
                }}
            >
                Above Ground Storage
            </Button>
            <Button
                className="osd-results-table-green-button"
                onClick={() => {
                    handleButtonClick('Below Ground Pipe');
                }}
            >
                Below Ground Pipe
            </Button>
            <Button
                className="osd-results-table-blue-button"
                onClick={() => {
                    handleButtonClick('Below Ground Tank');
                }}
            >
                Below Ground Tank
            </Button>
        </div>
    );
}
