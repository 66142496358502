import React from 'react';
import { StyleSheet, View, Text, Image, Link } from '@react-pdf/renderer';
import { stringOrNumber } from '../../auditorUtils';

function ManufacturedRainwaterTankTemplate({ nodeInfo, image, title, documentLinks }) {
    const styles = StyleSheet.create({
        title: {
            fontSize: 14,
            textAlign: 'center',
            marginBottom: '3%',
        },
        image: {
            width: '100%',
        },
        link: {
            textAlign: 'center',
            fontSize: 9,
            marginBottom: '2vh',
            marginTop: '2vh',
        },
        superscript: {
            fontSize: 5,
            lineHeight: 5,
        },
        redText: {
            color: 'red',
        },
        // TABLE
        table: {
            padding: 5,
        },
        tableTitle: {
            width: '100%',
            textAlign: 'center',
            fontSize: 12,
            marginBottom: '3vh',
        },
        tableRow: {
            flexDirection: 'row',
        },
        tableCol: {
            flex: 1,
            textAlign: 'center',
            fontSize: 8,
            border: 1,
            padding: 5,
        },
        columnTitle: {
            flex: 1,
            textAlign: 'center',
            fontSize: 9,
            borderWidth: 1,
            borderStyle: 'solid',
            color: 'red',
            paddingTop: 20,
        },
    });

    return (
        <>
            <Text style={styles.tableTitle}>Specifications and Typical Drawings - {title}</Text>
            {image && <Image style={styles.image} src={image} />}
            {documentLinks
                ? documentLinks.map((link, index) => {
                      return (
                          <Link
                              key={link['manufacture_device_document_link']}
                              src={link['manufacture_device_document_link']}
                              style={styles.link}
                          >
                              {link['document_name'] ? link['document_name'] : `document ${index}`}
                          </Link>
                      );
                  })
                : null}
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <Text style={styles.columnTitle}>Tank Name</Text>
                    <Text style={styles.columnTitle}>
                        Storage Properties - Extended Detention Depth (metres)
                    </Text>
                    <Text style={styles.columnTitle}>
                        Surface Area(m<Text style={styles.superscript}>2</Text>)
                    </Text>
                    <Text style={styles.columnTitle}>OSD Volume (kL)</Text>
                    <Text style={styles.columnTitle}>Overflow Pipe Diameter (mm)</Text>
                    <Text style={styles.columnTitle}>High Flow By-pass (cubic metres per sec)</Text>
                </View>
                {nodeInfo.map((node) => {
                    let height;
                    if (node['Storage Properties - Extended Detention Depth (metres)']) {
                        height = stringOrNumber(
                            +node['Storage Properties - Extended Detention Depth (metres)'],
                        );
                    } else {
                        height = 'N/A';
                    }
                    let totalVolRainwaterTank;
                    if (node['OSD Volume (kL)']) {
                        totalVolRainwaterTank = node['OSD Volume (kL)'];
                    } else {
                        totalVolRainwaterTank = 'N/A';
                    }
                    return (
                        <View style={styles.tableRow} key={node['Node Name']}>
                            <Text style={styles.tableCol}>
                                {node['Node Name'] ? node['Node Name'] : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>{height}</Text>
                            <Text style={styles.tableCol}>
                                {node['Storage Properties - Surface Area (square metres)']
                                    ? stringOrNumber(
                                          +node[
                                              'Storage Properties - Surface Area (square metres)'
                                          ],
                                      )
                                    : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>{totalVolRainwaterTank}</Text>
                            <Text style={styles.tableCol}>
                                {node['Outlet Properties - Overflow Pipe Diameter (mm)']
                                    ? node['Outlet Properties - Overflow Pipe Diameter (mm)']
                                    : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node['Inlet Properties - High Flow By-pass (cubic metres per sec)']
                                    ? node[
                                          'Inlet Properties - High Flow By-pass (cubic metres per sec)'
                                      ]
                                    : 'N/A'}
                            </Text>
                        </View>
                    );
                })}
            </View>
        </>
    );
}

export default ManufacturedRainwaterTankTemplate;
