import { createSlice } from '@reduxjs/toolkit';

const mapInfoSlice = createSlice({
    name: 'mapInfo',
    initialState: null,
    reducers: {
        setMapInfo: (state, action) => {
            return action.payload; // Assuming action.payload is the new user info object
        },
    },
});

export const { setMapInfo } = mapInfoSlice.actions;
export const selectMapInfo = (state) => state.mapInfo;
export default mapInfoSlice.reducer;
