import { createSlice } from '@reduxjs/toolkit';

const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState: {
        assessorName: '',
        assessorEmail: '',
        userID: '',
        reportHeaderIcon: '',
    },
    reducers: {
        setAssessorName: (state, action) => {
            state.assessorName = action.payload;
        },
        setAssessorEmail: (state, action) => {
            state.assessorEmail = action.payload;
        },
        setUserInfo: (_, action) => {
            return action.payload;
        },
    },
});

export const { setAssessorName, setAssessorEmail, setUserInfo } = userInfoSlice.actions;
export const selectUserInfo = (state) => state.userInfo;
export default userInfoSlice.reducer;
