import { Form, OverlayTrigger, Popover } from 'react-bootstrap';
import RendreDoc from '../RenderDoc/RenderDoc';

/**
 * This is the form label component. It shows the tooltip if info.blocks
 * array exists. (fromat of EditorJS output)
 * @see {@link RendreDoc}
 */
export default function FormLabel({ children, info, htmlForm, bold = false }) {
    const infoSymbol = '❓';

    // Info button element
    const infoButton = info?.link ? (
        <a target="_blank" rel="noreferrer" href={info.link}>
            {infoSymbol}
        </a>
    ) : null;

    // Tooltip element
    const tooltip = info?.blocks ? (
        <OverlayTrigger
            placement="top"
            overlay={(props) => (
                <Popover {...props}>
                    <Popover.Content>
                        <RendreDoc blocks={info.blocks} />
                    </Popover.Content>
                </Popover>
            )}
        >
            {infoButton ?? <span>{infoSymbol}</span>}
        </OverlayTrigger>
    ) : null;

    return (
        <Form.Label htmlFor={htmlForm} style={bold ? {} : { fontSize: '14px' }}>
            {bold ? <strong>{children}</strong> : children}
            {tooltip ?? infoButton}
        </Form.Label>
    );
}
