import React from "react";
import { StyleSheet, View, Text } from "@react-pdf/renderer";

export function ExistingModelIndividualTreatmentResiduals({
  postDevImperviousNodes,
}) {
  const styles = StyleSheet.create({
    container: {
      height: "70%",
    },
    title: {
      textAlign: "center",
      fontSize: 20,
      margin: 10,
    },
    text1: {
      textAlign: "center",
      fontSize: 15,
      fontWeight: 700,
      margin: 10,
    },
    table: {
      display: "table",
      width: "100%",
    },
    tableRow: {
      width: "100%",
      flexDirection: "row",
      textAlign: "center",
    },
    tableCell: {
      height: "100%",
      width: "100%",
      padding: 5,
      border: "1px solid #658cbb",
      fontSize: 10,
    },
  });

  const imperviousTableHeaders = [
    "Impervious Area Name",
    "Treatment Type",
    "Residual TN (kg/yr)",
    "Residual TP (kg/yr)",
    "Residual TSS (kg/yr)",
    "Residual GP (kg/yr)",
    "Residual Flow (ML/yr)",
    "Water Supply Reliability(%)",
  ];
  const imperviousColumnNames = [
    "residualTN",
    "residualTP",
    "residualTSS",
    "residualGP",
    "residualFlow",
    "waterSupplyReliability",
  ];

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Impervious Nodes</Text>
      <View style={styles.tableRow}>
        {imperviousTableHeaders.map((header, index) => (
          <Text key={index} style={styles.tableCell}>
            {header}
          </Text>
        ))}
      </View>
      {postDevImperviousNodes.map((node) => {
        return (
          <View key={node.id ? node.id : -1} style={styles.tableRow}>
            <Text style={styles.tableCell}>{node["impAreaName"]}</Text>
            <Text style={styles.tableCell}>{node["impAreaTreatType"]}</Text>
            {imperviousColumnNames.map((columnName) => {
              const value = +node.results[columnName];
              return (
                <Text key={columnName} style={styles.tableCell}>
                  {value ? value.toFixed(2) : "N/A"}
                </Text>
              );
            })}
          </View>
        );
      })}
    </View>
  );
}
export function DevelopedModelIndividualTreatmentResiduals({
  wqMethod,
  eolTreatment,
  postDevImperviousNodes,
  overallResults,
}) {
  const styles = StyleSheet.create({
    container: {
      height: "70%",
    },
    title: {
      textAlign: "center",
      fontSize: 20,
      margin: 10,
    },
    text1: {
      textAlign: "center",
      fontSize: 15,
      fontWeight: 700,
      margin: 10,
    },
    table: {
      display: "table",
      width: "100%",
    },
    tableRow: {
      width: "100%",
      flexDirection: "row",
      textAlign: "center",
    },
    tableCell: {
      height: "100%",
      width: "100%",
      padding: 5,
      border: "1px solid #658cbb",
      fontSize: 10,
    },
  });

  const imperviousTableHeaders = [
    "Impervious Area Name",
    "Treatment Type",
    "Residual TN (kg/yr)",
    "Residual TP (kg/yr)",
    "Residual TSS (kg/yr)",
    "Residual GP (kg/yr)",
    "Residual Flow (ML/yr)",
    "Water Supply Reliability(%)",
  ];
  const imperviousColumnNames = [
    "residualTN",
    "residualTP",
    "residualTSS",
    "residualGP",
    "residualFlow",
    "waterSupplyReliability",
  ];

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Impervious Nodes</Text>
      <View style={styles.tableRow}>
        {imperviousTableHeaders.map((header, index) => (
          <Text key={index} style={styles.tableCell}>
            {header}
          </Text>
        ))}
      </View>
      {postDevImperviousNodes.map((node) => {
        return (
          <View key={node.id ? node.id : -1} style={styles.tableRow}>
            <Text style={styles.tableCell}>{node["impAreaName"]}</Text>
            <Text style={styles.tableCell}>{node["impAreaTreatType"]}</Text>
            {imperviousColumnNames.map((columnName) => {
              const value = +node.results[columnName];
              return (
                <Text key={columnName} style={styles.tableCell}>
                  {value ? value.toFixed(2) : "N/A"}
                </Text>
              );
            })}
          </View>
        );
      })}
      {wqMethod.includes("EOL") && (
        <>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>N/A *</Text>
            <Text style={styles.tableCell}>
              {eolTreatment} (End-of-line device)
            </Text>
            {imperviousColumnNames.map((columnName) => {
              const value = +overallResults.eolResults[columnName];
              return (
                <Text style={styles.tableCell}>
                  {value ? value.toFixed(2) : "N/A"}
                </Text>
              );
            })}
          </View>
          {wqMethod.includes("EOLStandard") && (
            <Text style={styles.text1}>
              NOTE: End-of-line Device results shown above.
            </Text>
          )}
        </>
      )}
    </View>
  );
}
export function PerviousResiduals({ postDevPerviousNodes }) {
  const styles = StyleSheet.create({
    container: {
      height: "70%",
    },
    title: {
      textAlign: "center",
      fontSize: 20,
      margin: 10,
    },
    text1: {
      textAlign: "center",
      fontSize: 15,
      fontWeight: 700,
      margin: 10,
    },
    table: {
      display: "table",
      width: "100%",
    },
    tableRow: {
      width: "100%",
      flexDirection: "row",
      textAlign: "center",
    },
    tableCell: {
      height: "100%",
      width: "100%",
      padding: 5,
      border: "1px solid #658cbb",
      fontSize: 10,
    },
  });

  const perviousTableHeaders = [
    "Pervious Area Name",
    "Treatment Type",
    "Residual TN (kg/yr)",
    "Residual TP (kg/yr)",
    "Residual TSS (kg/yr)",
    "Residual GP (kg/yr)",
    "Residual Flow (ML/yr)",
  ];
  const perviousColumnNames = [
    "residualTN",
    "residualTP",
    "residualTSS",
    "residualGP",
    "residualFlow",
  ];

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Pervious Nodes</Text>
      <View style={styles.tableRow}>
        {perviousTableHeaders.map((header, index) => (
          <Text key={index} style={styles.tableCell}>
            {header}
          </Text>
        ))}
      </View>
      {postDevPerviousNodes.map((node) => {
        return (
          <View key={node.id ? node.id : -1} style={styles.tableRow}>
            <Text style={styles.tableCell}>{node["pAreaName"]}</Text>
            <Text style={styles.tableCell}>{node["pAreaTreatType"]}</Text>
            {perviousColumnNames.map((columnName) => {
              const value = +node.results[columnName];
              return (
                <Text key={columnName} style={styles.tableCell}>
                  {value ? value.toFixed(2) : "N/A"}
                </Text>
              );
            })}
          </View>
        );
      })}
    </View>
  );
}
