// modules
import React, { useState, useEffect, useRef } from 'react';
import { Alert } from 'react-bootstrap';
// util
import CouncilRule from './council-rule/CouncilRule';
import PaymentButton from './PaymentButton/PaymentButton';
import { emailOSDReport } from '../utils/DownloadFunctions';
// osd methods
import OsdPanel from '../osds-4-vips/osd-panel/OsdPanel';
import DefaultTemplate from './osd-method-template/default-template/DefaultTemplate';
import IdmTemplate from './osd-method-template/idm-template/IdmTemplate';
import ModifiedRationalTemplate from './osd-method-template/modified-rational-template/ModifiedRationalTemplate';
import RationalAuNzTemplate from './osd-method-template/rationalAuNz-template/RationalAuNzTemplate';
import RationalTemplate from './osd-method-template/rational-template/RationalTemplate';
import BoydsTemplate from './osd-method-template/boyds-template/BoydsTemplate';
import { saveNewOSDModel } from '../utils/api/lambda';
import { uploadFileToS3Bucket } from '../utils/s3Utils';

const tabs = [
    { currentTab: 'Boyds', label: 'BOYDS', bgColor: '#D0BDD9' },
    { currentTab: 'Swinburne', label: 'SWINBURNE', bgColor: '#D4D6B9' },
    { currentTab: 'Rational', label: 'RATIONAL (QUDM)', bgColor: '#B9BBD0' },
    { currentTab: 'Modified Rational', label: 'MODIFIED RATIONAL', bgColor: '#C2C8CD' },
    { currentTab: 'IDM', label: 'IDM Victoria', bgColor: '#E2DEC5' },
    { currentTab: 'Rational(AUNZ)', label: 'RATIONAL (AUNZ)', disabled: true },
    { currentTab: 'UPRCT', label: 'UPRCT (Coming Soon)', disabled: true },
];

const Osd = ({
    AEPS,
    preferredOSDMethod,
    userInfo,
    projectInfo,
    mapInfo,
    Coefficients,
    loadedModel,
}) => {
    const [selectedTab, setSelectedTab] = useState(
        loadedModel?.osd_method ?? preferredOSDMethod ?? 'Swinburne',
    );
    const [bgColor, setBgColor] = useState('');

    /* Storm Duration Chart */
    const [chartGenerated, setChartGenerated] = useState(loadedModel?.result_data ? true : false);
    const chartRef = useRef(null);
    /* Manufacture Data */
    const [osdMaterial, setOSDMaterial] = useState(
        loadedModel?.manufacture_data?.osdMaterial ?? '',
    );
    const [storageType, setStorageType] = useState(
        loadedModel?.manufacture_data?.storageType ?? '',
    );
    const [osdCompany, setOSDCompany] = useState(loadedModel?.manufacture_data?.osdCompany ?? '');

    /* OSD Result from Calculate OSD button */
    const [osdResultData, setOsdResultData] = useState(loadedModel?.result_data ?? null);
    const [templateData, setTemplateData] = useState(loadedModel?.model_details ?? null);

    const [reportSent, setReportSent] = useState(false);

    useEffect(() => {
        if (reportSent) {
            setTimeout(() => setReportSent(false), 10000);
        }
    });

    useEffect(() => {
        const tab = tabs.find((tab) => tab.currentTab === selectedTab);
        setBgColor(tab?.bgColor ?? '');
    }, [selectedTab]);

    const saveModel = (has_report, reportBlob = null) => {
        saveNewOSDModel(
            mapInfo.projectID,
            projectInfo,
            preferredOSDMethod,
            selectedTab,
            AEPS,
            has_report,
            templateData,
            { osdMaterial, storageType, osdCompany },
            osdResultData,
            userInfo.assessorEmail,
        ).then((response) => {
            uploadFileToS3Bucket(`osd/${response.model_id}.pdf`, 'jco-user-reports', reportBlob);
        });
    };

    const handleTabChange = (newTab) => {
        setSelectedTab(newTab);
        setOsdResultData(null);
        setTemplateData(null);
    };

    return (
        <div className="osd-container">
            {/* render council rules */}
            {mapInfo.council.includes('Monash City') && <CouncilRule mlbName="" />}
            <OsdPanel tabs={tabs} selectedTab={selectedTab} handleTabChange={handleTabChange} />
            {/* Append the OSD templates */}
            <div className="panNode_osd" style={{ backgroundColor: `${bgColor}aa` }}>
                <br />
                {selectedTab === 'Boyds' && (
                    <BoydsTemplate
                        AEPS={AEPS}
                        setOSDMaterial={setOSDMaterial}
                        useStorageType={[storageType, setStorageType]}
                        useOSDCompany={[osdCompany, setOSDCompany]}
                        useOSDResultsData={[osdResultData, setOsdResultData]}
                        userInfo={userInfo}
                        mapInfo={mapInfo}
                        osdMethod={selectedTab}
                        templateData={templateData}
                        setTemplateData={setTemplateData}
                        setChartGenerated={setChartGenerated}
                        chartRef={chartRef}
                        savedModel={
                            loadedModel?.osd_method === 'Boyds' ? loadedModel?.model_details : null
                        }
                    />
                )}
                {selectedTab === 'Swinburne' && (
                    <DefaultTemplate
                        AEPS={AEPS}
                        setOSDMaterial={setOSDMaterial}
                        useOSDMaterial={[osdMaterial, setOSDMaterial]}
                        useStorageType={[storageType, setStorageType]}
                        useOSDCompany={[osdCompany, setOSDCompany]}
                        useOSDResultsData={[osdResultData, setOsdResultData]}
                        userInfo={userInfo}
                        mapInfo={mapInfo}
                        osdMethod={selectedTab}
                        setTemplateData={setTemplateData}
                        savedModel={
                            loadedModel?.osd_method === 'Swinburne'
                                ? loadedModel?.model_details
                                : null
                        }
                    />
                )}
                {selectedTab === 'Rational' && (
                    <RationalTemplate
                        AEPS={AEPS}
                        setOSDMaterial={setOSDMaterial}
                        useStorageType={[storageType, setStorageType]}
                        useOSDCompany={[osdCompany, setOSDCompany]}
                        useOSDResultsData={[osdResultData, setOsdResultData]}
                        userInfo={userInfo}
                        mapInfo={mapInfo}
                        osdMethod={selectedTab}
                        setTemplateData={setTemplateData}
                        savedModel={
                            loadedModel?.osd_method === 'Rational'
                                ? loadedModel?.model_details
                                : null
                        }
                    />
                )}
                {selectedTab === 'IDM' && (
                    <IdmTemplate
                        setOSDMaterial={setOSDMaterial}
                        useStorageType={[storageType, setStorageType]}
                        useOSDCompany={[osdCompany, setOSDCompany]}
                        useOSDResultsData={[osdResultData, setOsdResultData]}
                        userInfo={userInfo}
                        mapInfo={mapInfo}
                        setTemplateData={setTemplateData}
                        savedModel={
                            loadedModel?.osd_method === 'IDM' ? loadedModel?.model_details : null
                        }
                    />
                )}
                {selectedTab === 'Modified Rational' && (
                    <ModifiedRationalTemplate
                        AEPS={AEPS}
                        setOSDMaterial={setOSDMaterial}
                        useStorageType={[storageType, setStorageType]}
                        useOSDCompany={[osdCompany, setOSDCompany]}
                        useOSDResultsData={[osdResultData, setOsdResultData]}
                        userInfo={userInfo}
                        mapInfo={mapInfo}
                        templateData={templateData}
                        setTemplateData={setTemplateData}
                        setChartGenerated={setChartGenerated}
                        chartRef={chartRef}
                        savedModel={
                            loadedModel?.osd_method === 'Modified Rational'
                                ? loadedModel?.model_details
                                : null
                        }
                    />
                )}
                {selectedTab === 'Rational(AUNZ)' && (
                    <RationalAuNzTemplate
                        AEPS={AEPS}
                        useOSDMaterial={[osdMaterial, setOSDMaterial]}
                        useStorageType={[storageType, setStorageType]}
                        useOSDCompany={[osdCompany, setOSDCompany]}
                        useOSDResultsData={[osdResultData, setOsdResultData]}
                        userInfo={userInfo}
                        mapInfo={mapInfo}
                        setTemplateData={setTemplateData}
                        coeffs={Coefficients}
                        savedModel={
                            loadedModel?.osd_method === 'Rational(AUNZ)'
                                ? loadedModel?.model_details
                                : null
                        }
                    />
                )}
            </div>
            <br />

            {(selectedTab !== 'Boyds' || (selectedTab === 'Boyds' && chartGenerated)) &&
                (selectedTab !== 'Modified Rational' ||
                    (selectedTab === 'Modified Rational' && chartGenerated)) &&
                templateData !== null &&
                (selectedTab !== 'Rational(AUNZ)' ? (
                    <div className="d-flex justify-content-center">
                        <PaymentButton
                            assessorEmail={userInfo.assessorEmail}
                            serviceID={8}
                            userID={userInfo.userID}
                            buttonTitle={'📃 OSD Report'}
                            onPaymentComplete={() =>
                                emailOSDReport(
                                    userInfo,
                                    mapInfo,
                                    projectInfo,
                                    selectedTab,
                                    osdCompany,
                                    osdMaterial,
                                    storageType,
                                    osdResultData,
                                    templateData,
                                    userInfo.reportHeaderIcon,
                                    (reportBlob) => saveModel(true, reportBlob),
                                ).then(() => {
                                    setReportSent(true);
                                })
                            }
                            subscriptionType="osd"
                        />
                    </div>
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'center',
                        }}
                    >
                        Report Coming Soon!
                    </div>
                ))}
            {/* Alert Messages */}
            <div className="mt-3 d-flex flex-wrap justify-content-center align-items-center w-full">
                <Alert variant="primary" show={reportSent}>
                    Done! Your OSD Report has been sent to:
                    <b>{' ' + userInfo.assessorEmail}</b>.
                </Alert>
            </div>
        </div>
    );
};

export default Osd;
