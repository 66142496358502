import React from "react";
import { Button, Modal } from "react-bootstrap";
import { openInNewTab } from "../../utils/common";

const RechargePopUp = ({ showRechargePopup, setShowRechargePopup }) => {
    const handleRecharge = () => {
        // Redirect the user to the recharge URL
        openInNewTab("http://user-portal.stormupdated.com.au/purchase-credits");
        setShowRechargePopup(false);
    };

    const handleCancel = () => {
        setShowRechargePopup(false);
    };

    return (
        <Modal show={showRechargePopup} onHide={handleCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Insufficient SD Credits</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                You don&apos;t have enough SD Credits to proceed. Please
                recharge your SD Credits or buy a subscription.
            </Modal.Body>

            <Modal.Footer>
                <Button key="recharge" onClick={handleRecharge}>
                    Recharge SD Credits
                </Button>
                <Button
                    key="cancel"
                    variant="light"
                    onClick={handleCancel}
                    style={{ border: "1px solid black" }}
                >
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RechargePopUp;
