import axios from 'axios';

const API = 'https://xgr5xf78jh.execute-api.ap-southeast-2.amazonaws.com/s3';

export const uploadToS3 = async (fileContent, fileName, bucket = 'jco-background-images') => {
    const params = { bucket, key: `${fileName}` };
    const formData = new FormData();

    formData.append('image', fileContent, fileName);
    formData.append('params', JSON.stringify(params));
    formData.append('endpoint', 'uploadToS3');

    return await axios.post(API, formData);
};

export const getImageUrlFromS3 = async (bucket, filename) => {
    const params = { bucket, filename };
    const formData = new FormData();

    formData.append('params', JSON.stringify(params));
    formData.append('endpoint', 'getFileFromS3');

    try {
        const response = (await axios.post(API, formData)).data.results;
        return response;
    } catch (err) {
        console.error('Opps! Something wrong, please try again later!');
    }
    return '';
};
