import React, { useEffect, useState } from 'react';
// util
import { getFileFromS3Bucket } from '../utils/s3Utils';
// component
import { SimpleSlides } from './styled-components/CarouselComponents';
import ReleaseNoteModal from './ReleaseNoteModal';
import { getMethodsAPI } from '../utils/api/lambda';

const linkToTool = { wsud: 'SU', 'rwt-sizing': 'RWT', osds4vips: 'OSDs' };

export default function NewFeaturesModal({ tool }) {
    const [modalContent, setModalContent] = useState(null);

    const getModalContent = async () => {
        const result = await getMethodsAPI({
            endpoint: 'getPopUpAdvertisementByTool',
            tool: linkToTool[tool],
        });

        return await Promise.all(
            result.data.map(async (item) => {
                if (item.image) {
                    const imageUrl = await getFileFromS3Bucket('tool-user-guide', `${item.image}`);
                    return { ...item, imageUrl };
                }
                return item;
            }),
        );
    };

    useEffect(() => {
        const fetchModalContent = async () => {
            const modalContent = await getModalContent();
            if (modalContent.length > 0) setModalContent(modalContent[0]);
        };

        fetchModalContent();
    }, []);

    return (
        <>
            {modalContent && (
                <ReleaseNoteModal
                    modalHeader={modalContent.title}
                    modalContent={
                        <>
                            <SimpleSlides>
                                <img className="w-100" src={modalContent['imageUrl']} alt="" />
                            </SimpleSlides>
                            <div className="p-3">
                                <p style={{ whiteSpace: 'pre-line' }}>{modalContent.description}</p>
                            </div>
                            <div>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => window.open(modalContent.link, '_blank')}
                                >
                                    Link
                                </button>
                            </div>
                        </>
                    }
                />
            )}
        </>
    );
}
