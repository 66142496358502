import { createSlice } from '@reduxjs/toolkit';

const siteDetailsSlice = createSlice({
    name: 'siteDetails',
    initialState: {
        rainwaterDemand: 'Toilet + Laundry',
        catchmentType: '',
        treatmentDevice: '',
        deviceInput: '',
        eolResult: null,
        resultUpdateRequired: true,
    },
    reducers: {
        setRainwaterDemand: (state, action) => {
            state.rainwaterDemand = action.payload;
        },
        setCatchmentType: (state, action) => {
            state.catchmentType = action.payload;
        },
        setTreatmentDevice: (state, action) => {
            state.treatmentDevice = action.payload;
            state.resultUpdateRequired = true;
        },
        setDeviceInput: (state, action) => {
            state.deviceInput = action.payload;
            state.resultUpdateRequired = true;
        },
        setEOLResult: (state, action) => {
            state.eolResult = action.payload;
            state.resultUpdateRequired = false;
        },
        setEOLResultUpdateRequired: (state, action) => {
            state.resultUpdateRequired = action.payload;
        },
        setSiteDetails: (state, action) => {
            return action.payload;
        },
    },
});

export const {
    setRainwaterDemand,
    setCatchmentType,
    setTreatmentDevice,
    setDeviceInput,
    setEOLResult,
    setEOLResultUpdateRequired,
    setSiteDetails,
} = siteDetailsSlice.actions;
export const selectSiteDetails = (state) => state.siteDetails;
export default siteDetailsSlice.reducer;
