import React, { useState, useRef } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from '../Auditor.module.css';

const MxprojConverterContainer = (props) => {
    const [file, setFile] = useState(null);
    const [dragging, setDragging] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [msfContent, setMsfContent] = useState(null);
    const [conversionMessage, setConversionMessage] = useState('');
    const [downloadMessage, setDownloadMessage] = useState('');
    const [uploadMessage, setUploadMessage] = useState('');
    const [isConverted, setIsConverted] = useState(false);

    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile && selectedFile.name.endsWith('.mxproj')) {
            setFile(selectedFile);
            setUploadMessage(`File uploaded: ${selectedFile.name}`);
            setConversionMessage('');
            setDownloadMessage('');
            setMsfContent(null);
            setIsConverted(false);
        } else {
            alert('Please upload a valid .mxproj file');
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = () => {
        setDragging(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setDragging(false);
        const droppedFile = event.dataTransfer.files[0];
        if (droppedFile && droppedFile.name.endsWith('.mxproj')) {
            setFile(droppedFile);
            setUploadMessage(`File uploaded: ${droppedFile.name}`);
            setConversionMessage('');
            setDownloadMessage('');
            setMsfContent(null);
            setIsConverted(false);
        } else {
            alert('Please upload a valid .mxproj file');
        }
    };

    const handleClickUpload = () => {
        fileInputRef.current.click();
    };

    const handleClickConvert = async () => {
        if (!file) {
            alert('Please select a file to upload');
            return;
        }
        setIsLoading(true);
        setConversionMessage('');
        const formData = new FormData();
        formData.append('file', file);
        try {
            const response = await fetch('https://testing.stormupdated.com.au/mxproj2msf', {
                method: 'POST',
                body: formData,
            });
            if (response.ok) {
                const data = await response.json();
                setMsfContent(data.msf);
                setConversionMessage(`Success! File converted to .msf.`);
                setDownloadMessage(
                    `File to be downloaded: ${file.name.replace('.mxproj', '.msf')}`,
                );
                setIsConverted(true);
            } else {
                alert('Error uploading file');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            alert('Error uploading file');
        } finally {
            setIsLoading(false);
        }
    };

    const handleClickDownload = () => {
        if (msfContent) {
            const blob = new Blob([msfContent], { type: 'text/plain' });
            saveAs(blob, file.name.replace('.mxproj', '.msf'));
        }
    };

    return (
        <>
            <div>
                <h3>
                    Upload your MUSIC X file to convert MSF File (can be opened by MUSIC 6 &
                    MUSICX):{' '}
                </h3>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    padding: '10px',
                }}
                className="mt-3"
            >
                <Button
                    className={`${styles['button-light-green']} ${dragging ? styles['dragging'] : ''}`}
                    onClick={handleClickUpload}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    {dragging
                        ? 'Drop file here'
                        : 'Click to upload your MUSIC X file or just drag and drop it here'}
                </Button>
                <Button
                    className={styles['button-medium-green']}
                    style={{ flex: 1, margin: '0 3vw' }}
                    onClick={handleClickConvert}
                    disabled={!file || isLoading || isConverted}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {isLoading ? (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: '10px' }}
                            />
                        ) : (
                            <div
                                className={styles['custom-arrow']}
                                style={{ marginRight: '10px' }}
                            />
                        )}
                        <span>
                            {isLoading
                                ? 'Converting...'
                                : 'Click here to convert it to msf, you can load msf in MUSIC 6 or MUSIC X'}
                        </span>
                    </div>
                </Button>
                <Button
                    className={styles['button-dark-green']}
                    style={{ flex: 1, margin: '0 3vw' }}
                    onClick={handleClickDownload}
                    disabled={!msfContent}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <div className={styles['custom-arrow']} style={{ marginRight: '10px' }} />
                        <span>
                            Click here to download your handy work! :) Also give us a LinkedIn like!
                        </span>
                    </div>
                </Button>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    padding: '1vw',
                }}
            >
                <div style={{ flex: 1, textAlign: 'center' }}>
                    {uploadMessage || <div style={{ visibility: 'hidden' }}>Empty</div>}
                </div>
                <div style={{ flex: 1, textAlign: 'center' }}>
                    {conversionMessage || <div style={{ visibility: 'hidden' }}>Empty</div>}
                </div>
                <div style={{ flex: 1, textAlign: 'center' }}>
                    {downloadMessage || <div style={{ visibility: 'hidden' }}>Empty</div>}
                </div>
            </div>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
                accept=".mxproj"
            />
        </>
    );
};

export default MxprojConverterContainer;
