// images
import boydsRationalTooltip from '../../../assets/tooltip-images/boyds-rational-coefficient-tooltip.png';
import {
    TooltipButton,
    TooltipContainer,
    TooltipContent,
} from '../../styled-components/TooltipComponents';

export default function PreDevAreas({ preDevAreas, setPreDevAreas }) {
    return (
        <div>
            {/* Header */}
            <div className="d-flex" style={{ gap: '10px' }}>
                <span className="flex-2">Catchment Name</span>
                <span className="flex-1">
                    Area (m<sup>2</sup>)
                </span>
                <span className="flex-1">Imperviousness</span>
                <span className="flex-1">Runoff Coefficient</span>
                <span className="flex-1" />
                <span className="flex-1" />
            </div>
            {/* Areas */}
            <div
                className="d-flex flex-column justify-content-center align-items-sta text-left"
                style={{ gap: '10px' }}
            >
                {preDevAreas.map((area, index) => {
                    return (
                        <div key={area.id} className=" d-flex" style={{ gap: '10px' }}>
                            {/* Area Name */}
                            <div className="flex-2 d-flex flex-row">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={area.areaName}
                                    onChange={(e) => {
                                        const bufArray = [...preDevAreas];
                                        bufArray[index].areaName = e.target.value;
                                        setPreDevAreas(bufArray);
                                    }}
                                />
                            </div>
                            {/* Surface Area */}
                            <div className="flex-1">
                                <input
                                    type="text"
                                    className="flex-1 form-control"
                                    value={area.surfaceArea}
                                    onChange={(e) => {
                                        const regex = /^\d+(?:\.\d{0,2})?$/i;
                                        const bufArray = [...preDevAreas];
                                        if (regex.test(e.target.value)) {
                                            bufArray[index].surfaceArea = e.target.value;
                                        } else if (e.target.value === '') {
                                            bufArray[index].surfaceArea = '';
                                        }
                                        setPreDevAreas(bufArray);
                                    }}
                                />
                            </div>
                            {/* Imperviousness */}
                            <div className="flex-1">
                                <select
                                    type="text"
                                    className="flex-1 form-control"
                                    value={area.impervious}
                                    onChange={(e) => {
                                        const bufArray = [...preDevAreas];
                                        if (e.target.value === 'true')
                                            bufArray[index].impervious = true;
                                        else bufArray[index].impervious = false;
                                        setPreDevAreas(bufArray);
                                    }}
                                >
                                    <option value={true}>Impervious</option>
                                    <option value={false}>Pervious</option>
                                </select>
                            </div>

                            {/* Run-off Coefficient */}
                            <div className="flex-1 d-flex">
                                <input
                                    type="text"
                                    className="flex-5 form-control"
                                    value={area.runoffCoefficient}
                                    onChange={(e) => {
                                        const bufArray = [...preDevAreas];
                                        if (!isNaN(e.target.value)) {
                                            bufArray[index].runoffCoefficient = e.target.value;
                                        } else if (e.target.value === '') {
                                            bufArray[index].runoffCoefficient = '';
                                        }
                                        setPreDevAreas(bufArray);
                                    }}
                                />
                                <TooltipContainer>
                                    <TooltipButton>❓</TooltipButton>
                                    <TooltipContent width="10vw">
                                        <div style={{ textAlign: 'center' }}>min 0.1, max 1</div>
                                    </TooltipContent>
                                </TooltipContainer>
                            </div>
                            {/* Delete Button */}
                            <div className="flex-1 d-flex align-items-start">
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={() => {
                                        const bufArray = preDevAreas.filter((_, i) => i !== index);
                                        setPreDevAreas(bufArray);
                                    }}
                                    disabled={preDevAreas.length === 1}
                                >
                                    ❌
                                </button>
                            </div>
                            <span className="flex-1" />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
