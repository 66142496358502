import { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import FormLabel from './../../../FormLabel/FormLabel';
import TCCalculatorContainer from './TCCalculatorContainer/TCCalculatorContainer';
import RenderDoc from './RenderDoc/RenderDoc';
import tcCalculatorAPI from './tcCalculatorAPI';
import { FaRegTrashAlt } from 'react-icons/fa';

import './TCContainer.css';

// TODO: move this into a common file
const isValidPositiveNumber = (str) => {
    // const pattern = /^\d+(\.\d{1,2})?$/;
    const pattern = /^(\d+(\.\d{0,3})?|\.\d{1,3})$/;
    return pattern.test(str);
};

const TCContainer = ({ calculators, tcCalculatorData, onDone }) => {
    const formStatus = {
        initial: 'initial',
        inProgress: 'inProgress',
    };

    const calculatorType = {
        occupancy: 'occupancy',
        indoor: 'indoor',
    };

    const [status, setStatus] = useState({
        [calculatorType.occupancy]: formStatus.initial,
        [calculatorType.indoor]: formStatus.initial,
    });

    //-------------------------------------------
    // --> Occupation
    //-------------------------------------------

    const [tcCalcState, setTCCalcState] = useState(
        tcCalculatorData ?? [
            {
                selectedMethod: undefined,
                fields: {},
                errors: {},
                result: undefined,
            },
        ],
    );

    const overallResult = tcCalcState.reduce((acc, calcState) => {
        return acc + parseFloat(calcState.result);
    }, null);

    const addNewRow = () => {
        const newTCCalcState = [
            ...tcCalcState,
            {
                selectedMethod: undefined,
                fields: {},
                errors: {},
                result: undefined,
            },
        ];
        setTCCalcState(newTCCalcState);
    };

    const deleteRow = (index) => {
        setTCCalcState((prevState) => prevState.filter((_, i) => i !== index));
    };

    const addNewValueRow = (index, fieldName) => {
        setTCCalcState((prev) => {
            const newState = [...prev];
            const newValues = [
                ...newState[index].fields[fieldName].value,
                newState[index].fields[fieldName].fields.reduce((acc, key) => {
                    acc[key.id] = key.defaultValue ?? '';
                    return acc;
                }, {}),
            ];
            newState[index].fields[fieldName].value = newValues;
            return newState;
        });
    };

    const deleteValueRow = (index, fieldName, rowIndex) => {
        setTCCalcState((prev) => {
            const newState = [...prev];
            const fieldValues = newState[index].fields[fieldName].value;

            if (rowIndex >= 0 && rowIndex < fieldValues.length) {
                const newValues = [
                    ...fieldValues.slice(0, rowIndex),
                    ...fieldValues.slice(rowIndex + 1),
                ];
                newState[index].fields[fieldName].value = newValues;
            }

            return newState;
        });
    };

    const onMethodChange = (method, index) => {
        // Create a copy of the state
        const newCalculatorState = [...tcCalcState];
        // Modify state
        newCalculatorState[index].selectedMethod = { ...method, fields: undefined } ?? undefined;
        newCalculatorState[index].fields = {};
        newCalculatorState[index].errors = {};
        newCalculatorState[index].result = undefined;

        method?.rows?.forEach((row) => {
            newCalculatorState[index].fields[row.id] =
                row.fields.length > 1
                    ? {
                          ...row,
                          value: [
                              row.fields.reduce((acc, key) => {
                                  acc[key.id] = key.defaultValue ?? '';
                                  return acc;
                              }, {}),
                          ],
                      }
                    : {
                          ...row.fields[0],
                          value: row.fields[0].defaultValue ?? '',
                      };
        });

        console.log(newCalculatorState);
        // Update state
        setTCCalcState(newCalculatorState);
    };

    const calculateTCForMethod = async () => {
        const newTCCalcState = await Promise.all(
            tcCalcState.map(async (currentCalcState, index) => {
                if (currentCalcState.result !== undefined) {
                    return currentCalcState;
                }
                const body = {
                    method: currentCalcState.selectedMethod?.id,
                    params: {},
                };

                const errors = {};

                for (const fieldKey in currentCalcState.fields) {
                    const field = currentCalcState.fields[fieldKey];

                    if (field.disabled) continue;

                    if (field.fields?.length > 1) {
                        body.params[fieldKey] = field.value.map((row, rowIndex) =>
                            Object.keys(row).reduce((acc, key, subFieldIndex) => {
                                if (
                                    (field.fields[subFieldIndex].info?.isTextField &&
                                        row[key].length > 0) ||
                                    isValidPositiveNumber(row[key])
                                ) {
                                    acc[key] = field.fields[subFieldIndex].info?.isTextField
                                        ? row[key]
                                        : parseFloat(row[key]);
                                } else {
                                    // Set error
                                    errors[key + rowIndex] =
                                        row[key].length === 0
                                            ? 'Input field cannot be empty'
                                            : 'Invalid value';
                                }
                                return acc;
                            }, {}),
                        );
                    } else {
                        // Add value to body if it's valid
                        if (isValidPositiveNumber(field.value)) {
                            body.params[fieldKey] = parseFloat(field.value);
                        } else {
                            // Set error
                            errors[fieldKey] =
                                field.value.length === 0
                                    ? 'Input field cannot be empty'
                                    : 'Invalid value';
                        }
                    }
                }

                // Update state with errors if any
                const updatedCalcState = {
                    ...currentCalcState,
                    errors,
                };

                if (Object.keys(errors).length > 0) {
                    setStatus({ ...status, [calculatorType.occupancy]: formStatus.initial });
                    return updatedCalcState;
                }

                // Send request to backend to perform calculations
                try {
                    const result = await tcCalculatorAPI.calculateTCForMethod(body);
                    console.log(result);
                    updatedCalcState['result'] = result;
                    setStatus({ ...status, [calculatorType.occupancy]: formStatus.initial });
                } catch (error) {
                    // Handle error
                    alert(error.message);
                    setStatus({ ...status, [calculatorType.occupancy]: formStatus.initial });
                }
                return updatedCalcState;
            }),
        );
        setTCCalcState(newTCCalcState);
    };

    const onInputFieldChange = (e, index, ignoreNumberCheck, rowName, rowIndex) => {
        const name = e.target.name;
        const value = e.target.value;

        setTCCalcState((prevState) => {
            const newCalculatorState = [...prevState];
            if (
                value.length === 0 ||
                (value.length > 0 && (ignoreNumberCheck || isValidPositiveNumber(value)))
            ) {
                if (rowName) {
                    const newValueArray = [...newCalculatorState[index].fields[rowName].value];
                    newValueArray[rowIndex][name] = value;
                    newCalculatorState[index].fields[rowName].value = newValueArray;
                } else {
                    newCalculatorState[index].fields[name].value = value;
                }
            }
            newCalculatorState[index]['result'] = undefined;
            return newCalculatorState;
        });
    };

    const InputFields = (calculatorType, fields, errors, index) => {
        return Object.keys(fields).map((fieldId, i) => {
            const rows = fields[fieldId];
            console.log(rows);

            return (
                <div
                    key={i}
                    className={`input-control-container ${rows.fields?.length > 1 ? 'span-two-columns' : ''}`}
                >
                    {rows.fields?.length > 1 ? (
                        <div>
                            {rows.value.map((row, rowIndex) => (
                                <div className="d-flex gap-2 align-items-start">
                                    <Form.Group
                                        key={`${calculatorType}-${index}-${rows.id}-${rowIndex}`}
                                        className="d-flex gap-2 align-items-start flex-1"
                                    >
                                        {Object.keys(row).map((field, fieldIndex) => (
                                            <div key={fieldIndex} className="text-center flex-1">
                                                {rowIndex === 0 ? (
                                                    <FormLabel
                                                        htmlForm={`${calculatorType}-${index}-calc-${rows.id}-${rowIndex}-${rows.fields[fieldIndex].id}`}
                                                        info={rows.fields[fieldIndex].info}
                                                    >
                                                        {rows.fields[fieldIndex].label}
                                                    </FormLabel>
                                                ) : null}
                                                <div>
                                                    <Form.Control
                                                        id={`${calculatorType}-${index}-calc-${rows.id}-${rowIndex}-${rows.fields[fieldIndex].id}`}
                                                        name={`${rows.fields[fieldIndex].id}`}
                                                        disabled={rows.fields[fieldIndex]?.disabled}
                                                        value={row[field]}
                                                        onChange={(e) =>
                                                            onInputFieldChange(
                                                                e,
                                                                index,
                                                                rows.fields[fieldIndex].info
                                                                    ?.isTextField,
                                                                rows.id,
                                                                rowIndex,
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <span className="input-control-error">
                                                    {errors[field + rowIndex]}
                                                </span>
                                            </div>
                                        ))}
                                    </Form.Group>
                                    <div>
                                        {rowIndex === 0 ? (
                                            <Form.Label htmlFor={`delete`} style={{ opacity: 0 }}>
                                                {'Delete'}
                                            </Form.Label>
                                        ) : null}
                                        <Form.Group>
                                            <Button
                                                variant="danger"
                                                // className="h-50"
                                                onClick={() =>
                                                    deleteValueRow(index, fieldId, rowIndex)
                                                }
                                                disabled={!rowIndex && rows.value.length === 1}
                                            >
                                                Delete
                                            </Button>
                                        </Form.Group>
                                    </div>
                                </div>
                            ))}

                            <Button
                                onClick={() => addNewValueRow(index, fieldId)}
                                variant="btn btn-dark"
                            >
                                Add New Area
                            </Button>
                        </div>
                    ) : (
                        <div className="text-center">
                            <FormLabel
                                htmlFor={`${calculatorType}-calc-${rows.id}`}
                                info={rows.info}
                            >
                                {rows.label}
                            </FormLabel>
                            <div>
                                <Form.Control
                                    id={`${calculatorType}-calc-${rows.id}`}
                                    name={`${rows.id}`}
                                    disabled={rows?.disabled}
                                    value={rows.value}
                                    onChange={(e) => onInputFieldChange(e, index)}
                                />
                            </div>
                        </div>
                    )}
                    <span className="input-control-error">{errors[rows.id]}</span>
                </div>
            );
        });
    };

    return (
        <div className="tc-calculator">
            {tcCalcState.map((calcState, index) => (
                <TCCalculatorContainer
                    key={index}
                    methods={calculators?.methods}
                    selectedMethod={calcState.selectedMethod?.id}
                    title={`Flow Segment ${index + 1}`}
                    onMethodChange={(method) => onMethodChange(method, index)}
                    result={{ label: 'Travel Time (mins)', value: calcState.result }}
                    inProgress={status[calculatorType.occupancy] === formStatus.inProgress}
                    onSubmit={() => calculateTCForMethod(index)}
                    deleteIcon={
                        index > 0 || tcCalcState.length > 1 ? (
                            <FaRegTrashAlt
                                onClick={() => deleteRow(index)}
                                className="text-danger mx-1 cursor-pointer"
                            />
                        ) : null
                    }
                    methodToolTip={calcState.selectedMethod?.tooltip}
                >
                    {calcState.selectedMethod?.info?.blocks ? (
                        <div className="doc-container">
                            <RenderDoc blocks={calcState.selectedMethod?.info?.blocks} />
                        </div>
                    ) : null}
                    <form className="tc-calculator-form">
                        {InputFields(
                            calculatorType.occupancy,
                            calcState.fields,
                            calcState.errors,
                            index,
                        )}
                    </form>
                </TCCalculatorContainer>
            ))}
            {overallResult !== null && (
                <div className="text-right">
                    Total Travel Time (mins):{' '}
                    {overallResult ? `${overallResult.toFixed(2)}` : 'N/A'}
                    <strong>
                        {overallResult < 5 && (
                            <div className="text-right text-warning">
                                The calculated time of concentration (TC) is less than 5 minutes.
                                <br />
                                Minimum TC allowed is 5 minutes based on section 4.6.2 Queensland
                                Urban Drainage Manual. <br />
                                TC will reflect this once you click "Done" button.
                            </div>
                        )}
                    </strong>
                </div>
            )}

            <div className="text-right">
                <Button onClick={addNewRow} className="mr-2">
                    Add Method
                </Button>
                <Button
                    variant="info"
                    onClick={calculateTCForMethod}
                    // disabled={selectedMethod === undefined || inProgress}
                    className="mr-2"
                >
                    Calculate
                </Button>
                <Button
                    onClick={() => {
                        const result = overallResult < 5 ? 5 : overallResult;
                        onDone(tcCalcState, result);
                    }}
                    disabled={!overallResult}
                    variant="btn btn-success"
                >
                    Done
                </Button>
            </div>
        </div>
    );
};

export default TCContainer;
