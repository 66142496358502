import axios from 'axios';
import { LAMBDA_API } from './globalConstant';

const dbquery = async (query, API) => {
    return (await axios.post(API, query)).data.results;
};

export const getReportTemplateByManufactureDevice = async (manufacture_device_name) => {
    const payload = {
        endpoint: 'getReportTemplateByManufactureDevice',
        params: { manufacture_device_name: manufacture_device_name },
    };
    const result = await dbquery(payload, `${LAMBDA_API}/cms`);
    if (result.length > 0) {
        return result;
    } else {
        return [
            {
                id: -1,
                manufacture_device_name: manufacture_device_name,
                template_name: 'default template',
            },
        ];
    }
};
export const getManufactureDeviceDocumentLinks = async (manufacture_device_name) => {
    const payload = {
        endpoint: 'getManufactureDeviceDocumentLinks',
        params: { manufacture_device_name: manufacture_device_name },
    };
    return await dbquery(payload, `${LAMBDA_API}/jco-backend`);
};
