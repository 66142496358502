import React from 'react';
import { Document, pdf } from '@react-pdf/renderer';
// components
import BasicInformation from '../BasicInformation';
import IdmReportTemplate from './IdmReportTemplate';
import SpecImagePages from '../SpecImagePages';

// methods
const getIdmReport = (
    userInfo,
    projectInfo,
    mapInfo,
    osdResult,
    templateData,
    storageType,
    osdCompany,
    osdMaterial,
    manufactureImageLinks,
    manufactureDocuments,
    reportHeaderUrl,
) => {
    return (
        <Document>
            {/* Basic Information */}
            <BasicInformation
                reportHeaderUrl={reportHeaderUrl}
                address={{
                    suburb: mapInfo.suburb,
                    state: mapInfo.state,
                    postcide: mapInfo.postcode,
                    council: mapInfo.council,
                    address: mapInfo.address,
                    coordinate: {
                        lat: mapInfo.coordinate.lat,
                        lng: mapInfo.coordinate.lng,
                    },
                }}
                basicInfo={{
                    developmentType: projectInfo.developmentType,
                    permit: projectInfo.planningPermitNo,
                    projectName: 'N/A',
                    assessor: userInfo.assessorName,
                    email: userInfo.assessorEmail,
                }}
            />

            <IdmReportTemplate
                reportHeaderUrl={reportHeaderUrl}
                osdMethod={templateData.method}
                totalSiteArea={templateData.totalSiteArea}
                siteAreaDrainingToOSD={templateData.siteAreaDrainingToOSD}
                templateData={templateData}
                osdResult={osdResult}
                OSDCompany={osdCompany}
                OSDMaterial={osdMaterial}
                storageType={storageType}
            />

            <SpecImagePages
                osdCompany={osdCompany}
                manufactureDocuments={manufactureDocuments}
                manufactureImageLinks={manufactureImageLinks}
                reportHeaderUrl={reportHeaderUrl}
            />
        </Document>
    );
};

// main function
export const generateIdmReport = async (
    userInfo,
    projectInfo,
    mapInfo,
    osdResult, // osdResultDataBuf
    templateData, // osd
    storageType,
    osdCompany,
    osdMaterial,
    manufactureImageLinks,
    manufactureDocuments,
    reportHeaderUrl,
) => {
    try {
        const reportBlob = await pdf(
            getIdmReport(
                userInfo,
                projectInfo,
                mapInfo,
                osdResult,
                templateData,
                storageType,
                osdCompany,
                osdMaterial,
                manufactureImageLinks,
                manufactureDocuments,
                reportHeaderUrl,
            ),
        ).toBlob();

        return reportBlob;
    } catch (err) {
        console.error(err);
    }
};
