const NestedItems = ({ style, items }) => {
    return (
        <>
            {items.map((item, index) =>
                style === 'ordered' ? (
                    <ol key={index} className="doc-ordered-list" style={{ marginLeft: '2rem' }}>
                        <li>{item.content}</li>
                        {item.items.length > 0 && <NestedItems items={item.items} style={style} />}
                    </ol>
                ) : (
                    <ul key={index} className="doc-ordered-list" style={{ marginLeft: '2rem' }}>
                        <li>{item.content}</li>
                        {item.items.length > 0 && <NestedItems items={item.items} style={style} />}
                    </ul>
                ),
            )}
        </>
    );
};

const RenderDoc = ({ blocks }) => {
    const elements = blocks.map((block, id) => {
        switch (block.type) {
            case 'paragraph': {
                return (
                    <p key={id} className="doc-paragraph">
                        {block.data.text}
                    </p>
                );
            }
            case 'list': {
                return <NestedItems style={block.data.style} items={block.data.items} />;
            }
            case 'image': {
                const imageSrc = block.data.file.url;
                return (
                    <img key={id} className="doc-image" src={imageSrc} style={{ width: '100%' }} />
                );
            }
        }
    });

    return <div className="doc">{elements}</div>;
};

export default RenderDoc;
