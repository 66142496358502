import { sails_api } from './globalConstant';

export const calculateTotalArea = (area) => {
    return area.reduce((acc, area) => {
        if (area['areaSize'] !== '') {
            return acc + parseFloat(area['areaSize']);
        }
        return acc;
    }, 0);
};

export const countTreatmentNodes = (area) => {
    return area.reduce((acc, area) => {
        if (area['treatmentType'] !== 'None') {
            return acc + 1;
        }
        return acc;
    }, 0);
};

export const getBioretentionMaxRatio = async (
    areaType,
    treatmentType,
    rainfallStation,
    catchmentType,
    isPervious,
) => {
    const maxRatio = await sails_api
        .get(
            `/maxRatio${isPervious ? 'Pervious': ''}/${rainfallStation}/${catchmentType}/${areaType}/${treatmentType}`,
        )
        .then((res) => res.data.maxRatio)
        .catch((error) => console.error(error));
    return { maxRatio };
};

export const getRainwaterTankMaxRatio = async (
    areaType,
    treatmentType,
    rainfallStation,
    catchmentType,
) => {
    // TODO
    // const minSourceArea = await sails_api
    //     .get(
    //         "RWTminSourceArea/" +
    //             rainfallStation +
    //             "/" +
    //             catchmentType +
    //             "/" +
    //             areaType +
    //             "/" +
    //             treatmentType
    //     )
    //     .then((res) => {
    //         console.log(res);
    //         return res.data.minSrcArea;
    //     })
    //     .catch((error) => console.error(error));
    const maxRatio = await sails_api
        .get(
            '/maxRatio/' +
                rainfallStation +
                '/' +
                catchmentType +
                '/' +
                areaType +
                '/' +
                treatmentType,
        )
        .then((res) => res.data.maxRatio)
        .catch((error) => console.error(error));
    return { maxRatio };
};
