import React, { useState } from 'react';
import { FaCalculator } from 'react-icons/fa';
import validator from 'validator';

// components
import FormRow from './FormRow';
import DevAreasFooter from './DevAreasFooter';
import runoffCoeffTooltipImage from '../../../assets/tooltip-images/IDM pop up runoff coefficient.png';

// utils
import { isFloat } from '../../../utils/common';
let timeoutId = null;

const PostDevAreas = ({
    postDevAreas,
    setPostDevAreas,
    TC = {},
    setTC,
    sumPreDevAreas,
    sumPostDevAreas,
    setShowTCCalculator,
    calculatedCy,
    options = { hideCy: false },
}) => {
    const [showTooltipError, setShowTooltipError] = useState({});

    const handleTCInputChange = (e, _, numberCheck = false) => {
        let { name, value } = e.target;

        // Clear any existing timeout
        if (timeoutId !== null) {
            clearTimeout(timeoutId);
            setShowTooltipError({});
        }

        if (numberCheck && !isFloat(e.target.value)) return;

        const newObject = { ...TC };
        newObject[name] = value;

        setTC(newObject);
    };

    const handleInputChange = (e, index, numberCheck = false) => {
        let { name, value } = e.target;

        // Clear any existing timeout
        if (timeoutId !== null) {
            clearTimeout(timeoutId);
            setShowTooltipError({});
        }

        if (numberCheck && !isFloat(e.target.value)) return;

        // Clone
        const bufArray = [...postDevAreas];

        // Modify
        if (name === 'impervious' && value === 'custom') {
            bufArray[index][name] = null;
            bufArray[index].cy = 0;
        } else if (name === 'impervious' && value && calculatedCy) {
            bufArray[index][name] = JSON.parse(value);
            bufArray[index].cy =
                calculatedCy.cyPre[bufArray[index][name] ? 'impervious' : 'pervious'];
        } else if (
            // Accepts values 0 <= Cy <= 1
            name.startsWith('cy') &&
            value.length > 0 &&
            !validator.isFloat(value, { max: 1 })
        ) {
            setShowTooltipError({ ...showTooltipError, [`${name}-${index}`]: true });
            timeoutId = setTimeout(() => {
                setShowTooltipError({ ...showTooltipError, [`${name}-${index}`]: false });
            }, 4000);
            return;
        } else {
            bufArray[index][name] = value;
        }

        // Update
        setPostDevAreas(bufArray);
    };
    return (
        <div className="osd-form-section-container osd-blue-border">
            <div className="osd-form-section">
                <span className="title-2 span-7">Post-Development Areas</span>
            </div>
            <FormRow
                formRow={[TC]}
                columns={[
                    null,
                    null,
                    TC.post !== undefined && {
                        id: 'post',
                        label: 'TC Post (min)',
                        numberCheck: true,
                        labelToolTip: {
                            content:
                                'Post-development Time of Concentration (TC Post) is determined based on longest flow path from top to bottom of catchment. TC Post is calculated using QUDM methods (blue button on the right) or directly specified in this field. Time of Concentration has a minimum of 5 minutes as recommended by QUDM. Refer to QUDM version 4 page 66, click ? below.',
                            link: 'https://ipweaq.intersearch.com.au/ipweaqjspui/bitstream/1/4983/1/2042%20QUDM%20FINAL%2018%20August%202017%20%282%29.pdf',
                        },
                    },

                    TC.post !== undefined && {
                        id: 'tcPostCalculator',
                        inputType: 'button',
                        icon: <FaCalculator />,
                        numberCheck: true,
                        onClick: () => setShowTCCalculator('post'),
                        title: 'Calculate TC',
                    },
                    TC.uncontrolled !== undefined && {
                        id: 'uncontrolled',
                        label: 'TCU (min)',
                        numberCheck: true,
                        placeholder: 'Optional',
                        labelToolTip: {
                            content:
                                'Uncontrolled Area Time of Concentration (TC Uncontrolled) is determined based on longest flow path from top to bottom of uncontrolled catchment. TC Uncontrolled is calculated using QUDM methods (blue button on the right) or directly specified in this field. Time of Concentration has a minimum of 5 minutes as recommended by QUDM.Refer to QUDM version 4 page 66, click ? below.',
                            link: 'https://ipweaq.intersearch.com.au/ipweaqjspui/bitstream/1/4983/1/2042%20QUDM%20FINAL%2018%20August%202017%20%282%29.pdf',
                        },
                    },
                    TC.uncontrolled !== undefined && {
                        id: 'tcUncontrolledCalculator',
                        inputType: 'button',
                        icon: <FaCalculator />,
                        numberCheck: true,
                        onClick: () => setShowTCCalculator('uncontrolled'),
                        title: 'Calculate TC',
                    },
                ]}
                handleInputChange={handleTCInputChange}
            />
            <FormRow
                formRow={postDevAreas}
                columns={[
                    { id: 'areaName', label: 'Catchment Name', className: 'span-2' },
                    { id: 'surfaceArea', label: 'Area (m²)', numberCheck: true },
                    {
                        id: 'uncontrolled',
                        label: 'Of Which Uncontrolled (m²)',
                        numberCheck: true,
                        labelToolTip: {
                            content: (
                                <div>
                                    <img
                                        alt=""
                                        src="https://csw-public.s3.ap-southeast-2.amazonaws.com/osd/boyds/Uncontrolled.png"
                                    />
                                </div>
                            ),
                            style: { maxWidth: '55rem' },
                        },
                    },
                    !options.hideImperviousness
                        ? {
                              id: 'impervious',
                              label: 'Imperviousness',
                              inputType: 'dropdown',
                              options: [
                                  { label: 'User Define', value: 'custom' },
                                  { label: 'Impervious', value: true },
                                  { label: 'Pervious', value: false },
                              ],
                              labelToolTip: {
                                  content: (
                                      <div>
                                          <img
                                              alt=""
                                              src="https://csw-public.s3.ap-southeast-2.amazonaws.com/osd/boyds/C+-+Value+Pop+Up+Image.png"
                                          />
                                          <p>
                                              Refers to the C-Value in Rational Method (Q=CIA).
                                              Image shows some options to determine C- Values based
                                              on established guidelines. C-Values are also applied
                                              to any corresponding uncontrolled area for this
                                              catchment.
                                          </p>
                                      </div>
                                  ),
                                  link: 'https://drive.google.com/file/d/12ytsxESvJYlP6d5xj-YZlO1gFnaNDRhl/view?usp=sharing',
                                  style: { maxWidth: '55rem' },
                              },
                          }
                        : undefined,
                    !options.hideImperviousness && calculatedCy
                        ? {
                              id: 'cy',
                              label: 'C-Value Post',
                              numberCheck: true,
                              disabled: (row) => typeof row?.impervious === 'boolean',
                              errorToolTip: {
                                  content: 'Value must be between 0 < Cy <= 1',
                              },
                          }
                        : undefined,
                    options.showRunoffCoefficient && {
                        id: 'runoffCoefficient',
                        label: 'Runoff Coeff',
                        numberCheck: true,
                        labelToolTip: {
                            content: (
                                <div>
                                    <img src={runoffCoeffTooltipImage} style={{ width: '100%' }} />
                                </div>
                            ),
                            link: 'https://www.designmanual.com.au/assets/files/documents/IDM/IDM_Version_5.4_.pdf',
                        },
                    },
                ]}
                showTooltipError={showTooltipError}
                handleInputChange={handleInputChange}
                onDelete={(index) => {
                    const bufArray = postDevAreas.filter((_, i) => i !== index);
                    setPostDevAreas(bufArray);
                }}
            />
            <DevAreasFooter
                onAdd={() => {
                    const newItem = {
                        id: postDevAreas[postDevAreas.length - 1].id + 1,
                        areaName: '',
                        surfaceArea: '',
                        uncontrolled: '0',
                        impervious: true,
                    };
                    if (options.showRunoffCoefficient) {
                        newItem['runoffCoefficient'] = '';
                    }
                    const bufArray = [...postDevAreas, newItem];
                    setPostDevAreas(bufArray);
                }}
                type="Post"
                sum1={sumPostDevAreas}
                sum2={sumPreDevAreas}
            />
        </div>
    );
};

export default PostDevAreas;
