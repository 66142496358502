import React, { useContext, useEffect } from 'react';
import TreatmentTypeDropdown from '../Dropdown/TreatmentTypeDropdown';
import styles from './AreaForm.module.css';
import { BiX } from 'react-icons/bi';
import ContextProvider from '../../../../utils/contextProvider';
import { useDispatch, useSelector } from 'react-redux';
import { updateArea, removeArea, setAreaResults } from '../../../../utils/redux/WSUD/areaSlice';
import {
    setEOLResult,
    setEOLResultUpdateRequired,
} from '../../../../utils/redux/WSUD/siteDetailsSlice';
import { occupantsRequired } from '../../../../utils/occupantsDictionary';
import {
    TooltipButton,
    TooltipContainer,
    TooltipContent,
} from '../../../styled-components/TooltipComponents';
import ControlledInput from '../ControlledInput';
import { liveResultCalculator } from './LiveStormResult/liveResultCalculator';
import { getTreatmentSizePlaceholder } from '../../../../utils/treatmentTypes';
import { getBioretentionMaxRatio, getRainwaterTankMaxRatio } from '../../../../utils/wsudAreaUtils';
import { isNumber } from '../../../../utils/common';

const checkMissingInput = (area) => {
    if (
        area['areaName'] === '' ||
        area['areaType'] === '' ||
        area['areaSize'] === '' ||
        area.apiBaseRoute === undefined
    ) {
        return {
            alert: `Please specify an ${
                area['areaName'] === ''
                    ? 'area name'
                    : area['areaType'] === ''
                      ? 'area type'
                      : area['areaSize'] === ''
                        ? 'area'
                        : ''
            } to continue`,
        };
    }

    if (occupantsRequired[area.treatmentType] && area['numberOfOccupants'] === '') {
        return {
            alert: 'Please specify the number of occupants to continue',
        };
    }
    if (area['treatmentType'] !== 'None' && area.treatmentSize === '') {
        return {
            alert: 'Please specify the treatment size to continue',
        };
    }
    return false;
};

const customToolTips = {
    'Permeable Pavement 10mm': `Set treatment size as either the opening area of the Permeable Pavement 
    or the void ratio of the porous pavement, as specified by the manufacturer. If unknown, set treatment as 10% of the total permeable pavement area.`,
    'Tree Pit 100mm': `Enter the total area of all Tree Pits used to treat this catchment. 
    100mm in the Tree Pit name refers to the Extended Detention Depth (EDD)`,
};

// type is either pervious or impervious
const AreaFormInputRow = ({ area, index, areaTypes }) => {
    const {
        type,
        rainfallStation,
        catchmentType,
        state,
        cityCouncil,
        setAreaResultsUpToDate,
        setWsudReady,
    } = useContext(ContextProvider);

    const disableTreatmentSize = area.treatmentType === 'None';
    const dispatch = useDispatch();
    const siteDetails = useSelector((state) => state.siteDetails);

    const handleUpdateAreaResults = async () => {
        if (area.areaName && area.areaType && area.areaSize)
            liveResultCalculator(
                area,
                state,
                cityCouncil,
                rainfallStation,
                catchmentType,
                siteDetails.rainwaterDemand,
                type === 'pervious',
            ).then((res) => {
                if (res.newTreatmentSizeValue) {
                    handleInputChange('treatmentSize', res.newTreatmentSizeValue);
                }

                dispatch(setAreaResults({ type, index, results: res }));
                setAreaResultsUpToDate(null);
            });
        else {
            dispatch(setAreaResults({ type, index, results: null }));
        }
    };

    const getBounds = async (updatedRow) => {
        if (updatedRow.areaType === '' || updatedRow.treatmentType === 'None') return null;
        if (
            updatedRow.apiBaseRoute === 'getBioretentionResults' ||
            updatedRow.apiBaseRoute === 'PerviousBioretentionResults'
        ) {
            return await getBioretentionMaxRatio(
                updatedRow.areaType,
                updatedRow.treatmentType,
                rainfallStation,
                catchmentType,
                type === 'pervious',
            );
        } else if (updatedRow.apiBaseRoute === 'getRainwatertankResults') {
            return await getRainwaterTankMaxRatio(
                updatedRow.areaType,
                updatedRow.treatmentType,
                rainfallStation,
                catchmentType,
            );
        }
        return null;
    };

    // options = {numberOnly: bool, updateTreatmentTypes: bool, updateResult: bool}
    const handleInputChange = async (field, value, options) => {
        if (options?.numberOnly && value !== '' && !isNumber(value)) {
            return;
        }

        const updatedRow = { ...area, [field]: value };

        if (options?.newApiBaseRoute) {
            updatedRow['apiBaseRoute'] = options?.newApiBaseRoute;
        }

        if (options?.updateBounds) {
            updatedRow['bounds'] = await getBounds(updatedRow);
        }

        if (options?.updateResult) {
            const missingInput = checkMissingInput(updatedRow);
            if (!missingInput) {
                updatedRow.resultUpdateRequired = true;
                dispatch(setEOLResultUpdateRequired(true)); // hide EOL Results
            } else {
                updatedRow['result'] = missingInput;
                updatedRow.resultUpdateRequired = false;
                setWsudReady(false);
                dispatch(setEOLResult(null));
            }
        }
        dispatch(updateArea({ type, index, updatedRow }));
    };

    useEffect(() => {
        if (areaTypes.length === 0) return;
        if (areaTypes.length === 1) {
            handleInputChange('areaType', areaTypes[0], {
                updateTreatmentTypes: true,
                updateResult: true,
            });
        } else if (area.areaType !== '' && !areaTypes.includes(area.areaType)) {
            handleInputChange('areaType', '', {
                updateTreatmentTypes: true,
                updateResult: true,
            });
        }
    }, [areaTypes]);

    useEffect(() => {
        if (area.resultUpdateRequired) {
            const timer = setTimeout(() => handleUpdateAreaResults(), 2000);
            return () => clearTimeout(timer);
        }
    }, [area]);

    const handleRemoveArea = () => {
        dispatch(removeArea({ type, index }));
        setAreaResultsUpToDate(null);
    };

    return (
        <div key={`area-${index}`} className={styles['area-row']}>
            <div className={styles['form-group']}>
                {index === 0 && <label htmlFor={`areaName-${index}`}>Area Name</label>}
                <ControlledInput
                    type="text"
                    id={`areaName-${index}`}
                    value={area.areaName}
                    onChange={(e) =>
                        handleInputChange('areaName', e.target.value, {
                            updateResult: true, // needed because we dont want to calculate without a name
                        })
                    }
                />
            </div>

            <div className={styles['form-group']}>
                {index === 0 && (
                    <label htmlFor={`areaType-${index}`}>
                        Area Type
                        <TooltipContainer>
                            <TooltipButton>❓</TooltipButton>
                            <TooltipContent width="10vw">
                                <span>
                                    Please select 'Road' as catchment type to model balconies,
                                    terraces and trafficable roofs in accordance with local
                                    modelling guidelines.
                                </span>
                            </TooltipContent>
                        </TooltipContainer>
                    </label>
                )}
                <select
                    id={`areaType-${index}`}
                    value={area.areaType}
                    onChange={(e) =>
                        handleInputChange('areaType', e.target.value, {
                            updateTreatmentTypes: true,
                            updateResult: true,
                            updateBounds: true,
                        })
                    }
                    disabled={!areaTypes.length}
                >
                    <option value="">Select...</option>
                    {areaTypes.map((type, i) => (
                        <option key={`areaType-option-${i}`} value={type}>
                            {type}
                        </option>
                    ))}
                </select>
            </div>

            <div className={styles['form-group']}>
                {index === 0 && <label htmlFor={`areaSize-${index}`}>Area</label>}
                <ControlledInput
                    id={`areaSize-${index}`}
                    value={area.areaSize}
                    placeholder="Area (m²)"
                    onChange={(e) =>
                        handleInputChange('areaSize', e.target.value, {
                            numberOnly: true,
                            updateResult: true,
                        })
                    }
                />
            </div>

            <div className={styles['form-group']}>
                {index === 0 && (
                    <label htmlFor={`treatmentType-${index}`}>
                        Treatment Type
                        <TooltipContainer>
                            <TooltipButton
                                style={{
                                    opacity:
                                        customToolTips[area.treatmentType] === undefined ? 0 : 1,
                                }}
                            >
                                ❓
                            </TooltipButton>
                            {customToolTips[area.treatmentType] !== undefined && (
                                <TooltipContent width="10vw">
                                    <span>{customToolTips[area.treatmentType]}</span>
                                </TooltipContent>
                            )}
                        </TooltipContainer>
                    </label>
                )}
                <TreatmentTypeDropdown
                    index={index}
                    currValue={area.treatmentType}
                    areaType={area.areaType.toString()}
                    handleInputChange={handleInputChange}
                />
            </div>

            <div className={styles['form-group']}>
                {index === 0 && <label htmlFor={`treatmentSize-${index}`}>Treatment Size</label>}
                <ControlledInput
                    id={`treatmentSize-${index}`}
                    value={disableTreatmentSize ? '' : area.treatmentSize}
                    onChange={(e) =>
                        handleInputChange('treatmentSize', e.target.value, {
                            numberOnly: true,
                            updateResult: true,
                        })
                    }
                    placeholder={getTreatmentSizePlaceholder(area.apiBaseRoute)}
                    disabled={disableTreatmentSize}
                />
            </div>

            <div className={styles['form-group']}>
                {index === 0 && <label htmlFor={`numberOfOccupants-${index}`}># Occupants</label>}
                <ControlledInput
                    id={`numberOfOccupants-${index}`}
                    value={occupantsRequired[area.treatmentType] ? area.numberOfOccupants : ''}
                    onChange={(e) =>
                        handleInputChange('numberOfOccupants', e.target.value, {
                            numberOnly: true,
                            updateResult: true,
                        })
                    }
                    disabled={!occupantsRequired[area.treatmentType]}
                />
            </div>
            <BiX onClick={() => handleRemoveArea(index)} className={styles['icon']} />
        </div>
    );
};

export default AreaFormInputRow;
