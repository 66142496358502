import React, { useEffect } from 'react';
import AuditorLogo from '../../assets/Auditor Logo.png';
import ConverterLogo from '../../assets/File_Converter_1.png';
import { sails_api } from '../../utils/globalConstant';
import { selectMapInfo } from '../../utils/redux/mapInfoSlice';
import { useSelector } from 'react-redux';

const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    margin: '20px 0',
};

const saveProjectStyle = {
    color: '#d9534f',
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center',
};

const projectInstructionsStyle = {
    color: '#d9534f',
    fontSize: '16px',
    lineHeight: '1.5',
    textAlign: 'center',
};

const linkStyle = {
    color: '#0275d8',
    textDecoration: 'none',
    fontWeight: 'bold',
};

const toolsWithSave = ['wsud', 'osds4vips', 'auditor'];

const SaveProjectMessage = ({ tool }) => {
    const mapInfo = useSelector(selectMapInfo);
    useEffect(() => {
        const fetchAndSaveData = async () => {
            if (tool === 'osds4vips') {
                const savedAEPS = JSON.parse(sessionStorage.getItem('AEPS'));
                const savedOSDMethod = JSON.parse(sessionStorage.getItem('OSDMethod'));
                const savedLocationId = sessionStorage.getItem('locationId');
                if (!savedAEPS) {
                    try {
                        const res = await sails_api.get(
                            `osd/aep?latitude=${mapInfo.coordinate.lat}&longitude=${mapInfo.coordinate.lng}`,
                        );
                        sessionStorage.setItem('AEPS', JSON.stringify(res.data));
                    } catch (error) {
                        console.error('Error fetching AEPS:', error);
                    }
                } else {
                    sessionStorage.setItem('Saved_AEPS', JSON.stringify(savedAEPS));
                }
                if (!savedOSDMethod) {
                    try {
                        const res = await sails_api.get(
                            `OSD/osdAndWQMethods/${mapInfo.state}/${mapInfo.council}`,
                        );
                        sessionStorage.setItem('OSDMethod', JSON.stringify(res.data.osd_method));
                    } catch (error) {
                        console.error('Error fetching OSD method:', error);
                        sessionStorage.setItem('OSDMethod', JSON.stringify('Swinburne')); // Default method
                    }
                } else {
                    sessionStorage.setItem('Saved_OSDMethod', JSON.stringify(savedOSDMethod));
                }
                if (!savedLocationId) {
                    const locationId = `${mapInfo.coordinate.lat}-${mapInfo.coordinate.lng}`;
                    sessionStorage.setItem('locationId', locationId);
                } else {
                    sessionStorage.setItem('Saved_LocationId', savedLocationId);
                }
            }
        };

        fetchAndSaveData();
    }, [tool, mapInfo]);

    return (
        <>
            {!toolsWithSave.includes(tool) ? (
                <div style={{ ...containerStyle, ...saveProjectStyle }}>
                    Save Project Features Coming Soon!
                </div>
            ) : tool === 'auditor' ? (
                <div className="d-flex align-items-center justify-content-between w-100 px-5">
                    <img src={AuditorLogo} alt="Auditor Logo" style={{ height: '90px' }} />
                    <img src={ConverterLogo} alt="Converter Logo" style={{ height: '90px' }} />
                </div>
            ) : (
                <div style={{ ...containerStyle, ...projectInstructionsStyle }}>
                    You can save this project via the Save Project Button at the bottom of this
                    page. Go to the Project History Tab on the User Portal to continue working on it
                    at any point. For older SUS files, please contact Mircea at{' '}
                    <a href="mailto:mircea@cleanstormwater.com.au" style={linkStyle}>
                        mircea@cleanstormwater.com.au
                    </a>
                </div>
            )}
        </>
    );
};

export default SaveProjectMessage;
