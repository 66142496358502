// module
import React from "react";
import { StyleSheet, View, Text, Image, Link } from "@react-pdf/renderer";

function DefaultTemplate({ nodeInfo, image, title, documentLinks }) {
  const styles = StyleSheet.create({
    title: {
      fontSize: 14,
      textAlign: "center",
      marginBottom: "3%",
    },
    image: {
      width: "100%",
    },
    link: {
      textAlign: "center",
      fontSize: 9,
      marginBottom: 5,
    },
    // TABLE
    table: {
      padding: 5,
    },
    tableTitle: {
      width: "100%",
      textAlign: "center",
      fontSize: 14,
      marginBottom: 5,
    },
    tableRow: {
      flexDirection: "row",
    },
    tableCol: {
      flex: 1,
      textAlign: "center",
      fontSize: 9,
      border: 1,
      padding: 5,
    },
  });

  return (
    <>
      <Text style={styles.tableTitle}>
        Specifications and Typical Drawings - {title}
      </Text>
      {image && <Image style={styles.image} src={image} />}
      {documentLinks
        ? documentLinks.map((link, index) => {
            return (
              <Link
                key={link["manufacture_device_document_link"]}
                src={link["manufacture_device_document_link"]}
                style={styles.link}
              >
                {link["document_name"]
                  ? link["document_name"]
                  : `document ${index}`}
              </Link>
            );
          })
        : null}
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <Text style={styles.tableCol}>Catchment Name</Text>
          <Text style={styles.tableCol}>Total High-flow bypass(L/s)</Text>
        </View>
        {nodeInfo.map((node) => {
          return (
            <View style={styles.tableRow} key={node["impAreaName"]}>
              <Text style={styles.tableCol}>
                {node["impAreaName"] ? node["impAreaName"] : "N/A"}
              </Text>
              <Text style={styles.tableCol}>
                {node["impAreaTreatSize"] ? node["impAreaTreatSize"] : "N/A"}
              </Text>
            </View>
          );
        })}
      </View>
    </>
  );
}
export default DefaultTemplate;
