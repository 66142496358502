import React from 'react';
import { FormGroup, FormControl, FormLabel, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

export default function AppendixItem({ item, updateReportNoteAppendixItem }) {
    const dispatch = useDispatch();

    return (
        <div>
            <FormGroup>
                <FormLabel>Title</FormLabel>
                <FormControl
                    type="text"
                    value={item.title}
                    onChange={(e) =>
                        dispatch(
                            updateReportNoteAppendixItem({
                                itemId: item.id,
                                columnName: 'title',
                                value: e.target.value,
                            }),
                        )
                    }
                    placeholder="Enter title"
                />
            </FormGroup>
            <FormGroup>
                <FormLabel>Content</FormLabel>
                <FormControl
                    as="textarea"
                    rows={3}
                    value={item.appendixData}
                    onChange={(e) =>
                        dispatch(
                            updateReportNoteAppendixItem({
                                itemId: item.id,
                                columnName: 'appendixData',
                                value: e.target.value,
                            }),
                        )
                    }
                    placeholder="Enter content"
                />
            </FormGroup>
            {/* Image uploader */}
            <FormGroup>
                <FormLabel className="h5">Upload Image</FormLabel>
                <Form.Control
                    type="file"
                    onChange={(e) => {
                        new Promise((resolve) => {
                            const fileReader = new FileReader();
                            fileReader.onload = () => {
                                const imageDataUrl = fileReader.result;
                                dispatch(
                                    updateReportNoteAppendixItem({
                                        itemId: item.id,
                                        columnName: 'uploadedImage',
                                        value: imageDataUrl,
                                    }),
                                );
                                resolve();
                            };
                            fileReader.readAsDataURL(e.target.files[0]);
                        });
                    }}
                />
            </FormGroup>
        </div>
    );
}
