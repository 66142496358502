import React from 'react';

// components
import FormRow from './FormRow';

// utils
import { isFloat } from '../../../utils/common';

const SiteArea = ({ siteArea, setSiteArea }) => {
    const handleInputChange = (e, _, numberCheck = false) => {
        let { name, value } = e.target;

        if (numberCheck && !isFloat(e.target.value)) {
            return;
        }
        const bufArray = { ...siteArea };
        bufArray[name] = value;
        setSiteArea(bufArray);
    };

    return (
        <div className="osd-form-section-container osd-blue-border">
            <FormRow
                formRow={[siteArea]}
                columns={[
                    {
                        id: 'totalSiteArea',
                        label: 'Total Site Area (m²):',
                        numberCheck: true,
                        className: 'span-2',
                    },
                    null,
                    {
                        id: 'imperviousArea',
                        label: 'Site Area Draining to OSD (m²):',
                        numberCheck: true,
                        className: 'span-2',
                    },
                    null,
                ]}
                handleInputChange={handleInputChange}
            />
        </div>
    );
};

export default SiteArea;
