import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { LAMBDA_API } from "../globalConstant";

export const getUserPermission = createAsyncThunk(
  "userPermission/getUserPermission",
  async (payload) => {
    const endpoint = "checkPaymentRequired";
    const requestPayload = {
      endpoint: endpoint,
      params: payload,
    };
    const response = await axios.post(`${LAMBDA_API}/user`, requestPayload);

    return {
      needToPay: response.data.results.needToPay,
      isInWhiteList: response.data.results.isInWhiteList,
    };
  }
);
const userPermissionSlice = createSlice({
  name: "userPermission",
  initialState: {
    hasToPay: true,
    inWhiteList: false,
    validatingPayment: false,
  },
  reducers: {
    setHasToPay: (state, action) => {
      state.hasToPay = action.payload;
    },
    setInWhiteList: (state, action) => {
      state.inWhiteList = action.payload;
    },
    setValidatingPayment: (state, action) => {
      state.validatingPayment = action.payload;
    },
  },
});

export const { setHasToPay, setInWhiteList, setValidatingPayment } =
  userPermissionSlice.actions;
export const selectUserPermission = (state) => state.userPermission;
export default userPermissionSlice.reducer;
