import { createSlice } from '@reduxjs/toolkit';

const overallFlowReductionSlice = createSlice({
    name: 'overallFlowReduction',
    initialState: {
        sourceFlow: 0,
        residualFlow: 0,
        totalVolumeReused: 0,
        totalReduction: 0,
    },
    reducers: {
        setOverallFlowReduction: (state, action) => {
            return { ...state, ...action.payload };
        },
        updateSourceFlow: (state, action) => {
            state.sourceFlow = action.payload;
        },
        updateResidualFlow: (state, action) => {
            state.residualFlow = action.payload;
        },
        updateTotalVolumeReused: (state, action) => {
            state.totalVolumeReused = action.payload;
        },
        updateTotalReduction: (state, action) => {
            state.totalReduction = action.payload;
        },
    },
});

export const {
    setOverallFlowReduction,
    updateSourceFlow,
    updateResidualFlow,
    updateTotalVolumeReused,
    updateTotalReduction,
} = overallFlowReductionSlice.actions;

export const selectOverallFlowReduction = (state) => state.overallFlowReduction;
export default overallFlowReductionSlice.reducer;
