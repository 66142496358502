import React from 'react';
import { Document, pdf } from '@react-pdf/renderer';
import SuLiteReportTemplate from './SuLiteReportTemplate';

// methods
const getSuLiteReport = (
    userInfo,
    projectInfo,
    mapInfo,
    imperviousNodes,
    eolTreatment,
    eolTreatmentSize,
    overallResults,
    siteArea,
    calculateTnRate,
    rainwaterDemand,
) => {
    return (
        <Document>
            <SuLiteReportTemplate
                userInfo={userInfo}
                projectInfo={projectInfo}
                mapInfo={mapInfo}
                imperviousNodes={imperviousNodes}
                eolTreatment={eolTreatment}
                eolTreatmentSize={eolTreatmentSize}
                overallResults={overallResults}
                siteArea={siteArea}
                calculateTnRate={calculateTnRate}
                rainwaterDemand={rainwaterDemand}
            />
        </Document>
    );
};

/**
 * Generate the Su Lite Report
 *
 * @param {Object} userInfo
 * @param {string} userInfo.assessorEmail
 * @param {string} userInfo.assessorName
 *
 * @param {Object} projectInfo
 * @param {string} projectInfo.planningPermitNo
 * @param {string} projectInfo.developmentType
 *
 * @param {Object} mapInfo
 * @param {string} mapInfo.addressMain
 * @param {string} mapInfo.council
 * @param {string} mapInfo.zipcode
 * @param {string} mapInfo.state
 * @param {string} mapInfo.suburb
 * @param {string} mapInfo.lat
 * @param {string} mapInfo.lng
 *
 * @param {Object} imperviousNodes
 * @param {Object} PerviousNodes
 *
 * @param {string} reportHeaderIconUrl - URL for the report header icon
 *
 * @returns {Blob}
 */
export const generateSuLiteReport = async (
    userInfo,
    projectInfo,
    mapInfo,
    imperviousNodes,
    eolTreatment,
    eolTreatmentSize,
    overallResults,
    siteArea,
    targetReductions,
    rainwaterDemand,
) => {
    // Calculate TN Rate
    let reduction = 0;
    let sumResidualTN = 0;
    let sumSourceTN = 0;
    let calculateTnRate = 0;
    for (const node of imperviousNodes) {
        if (node.results !== undefined) {
            sumSourceTN += +node.results.sourceTN;
            sumResidualTN += +node.results.residualTN;
        }
    }
    if (!eolTreatment.includes('')) sumResidualTN = +overallResults.eolResults.tn;
    if (sumSourceTN !== 0) {
        reduction = (1 - sumResidualTN / sumSourceTN) * 100;
        calculateTnRate = ((reduction / targetReductions.tn) * 100).toFixed(1);
    }

    try {
        const reportBlob = await pdf(
            getSuLiteReport(
                userInfo,
                projectInfo,
                mapInfo,
                imperviousNodes,
                eolTreatment,
                eolTreatmentSize,
                overallResults,
                siteArea,
                calculateTnRate,
                rainwaterDemand,
            ),
        ).toBlob();

        return reportBlob;
    } catch (err) {
        console.error(err);
    }
};
