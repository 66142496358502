import React from 'react';
import { StyleSheet, View, Page, Text, Image } from '@react-pdf/renderer';

import ReportHeader from '../ReportHeader';
import Table from '../Table';
import ReportFooter from '../ReportFooter';
import { TCCalculatorTemplate } from '../tc-calculation/TCCalculatorTemplate';
import {
    tankHeightTable,
    flowAndVolumeTable,
    stormDetailsTable,
    cValueTable,
    catchmentDetailsTable,
} from '../ReportBlocks';

const defaultStyles = {
    borderStyle: '1px solid #658cbb',
    textAlignment: 'center',
    headerBorder: true,
    fontSize: 9,
    headerFontSize: 9,
    headerTextAlign: 'center',
    tableWidth: '100%',
    marginTop: 15,
    headerTextDecoration: 'none',
};

const createStyles = (newStyles = defaultStyles) => {
    return {
        section: { marginTop: newStyles.marginTop },
        table: {
            display: 'table',
            width: newStyles.tableWidth,
            border: newStyles.borderStyle,
            borderBottom: 0,
            borderRight: 0,
            borderLeft: 0,
            borderTop: newStyles.headerBorder ? newStyles.borderStyle : 0,
        },

        tableRow: {
            flexDirection: 'row',
            borderBottom: newStyles.borderStyle,
        },

        headerCell: {
            fontSize: newStyles.headerFontSize,
            textAlign: newStyles.headerTextAlign,
            borderRight: newStyles.headerBorder ? newStyles.borderStyle : 0,
            borderLeft: newStyles.headerBorder ? newStyles.borderStyle : 0,
            height: '100%',
            padding: 5,
            textDecoration: newStyles.headerTextDecoration,
        },

        tableCell: {
            fontSize: newStyles.fontSize,
            textAlign: newStyles.textAlignment,
            borderRight: newStyles.borderStyle,
            borderLeft: newStyles.borderStyle,
            height: '100%',
            padding: 5,
        },
    };
};

const generateAllTableData = (data) => {
    return [
        catchmentDetailsTable(
            data.osdResult.report.site_calcs,
            data.osdResult.report.runoff_coefficient,
            { isPsdProvided: data.osd.psd },
        ),

        stormDetailsTable(data.osdResult.report.storm_details),

        cValueTable(
            'C-Value (Pre Dev)',
            data.osdResult.report.c_value_pre,
            data.osdResult.intermediate_results,
            {
                isPsdProvided: data.osd.psd,
                isPreDev: true,
                isControlled: true,
                showAppendix: false,
            },
        ),

        cValueTable(
            'C-Value (Post Dev Controlled)',
            data.osdResult.report.c_value_post,
            data.osdResult.intermediate_results,
            {
                isPreDev: false,
                isControlled: true,
                showAppendix: false,
            },
        ),

        cValueTable(
            'C-Value (Post Dev Uncontrolled)',
            data.osdResult.report.c_value_post,
            data.osdResult.intermediate_results,
            {
                isPreDev: false,
                isControlled: false,
                showAppendix: true,
            },
        ),

        flowAndVolumeTable(
            data.osdResult.report.flow_and_vol,
            data.osdResult.max_volume,
            data.osd.climateAdjustment,
        ),

        tankHeightTable(data.osdResult.report.orifice),
    ];
};

const RationalReportTemplate = ({ data }) => {
    const styles = StyleSheet.create({
        body: {
            paddingTop: 30,
            paddingBottom: 30,
            paddingHorizontal: 35,
            marginBottom: 10,
        },
        section: {
            marginBottom: 10,
            padding: 15,
            display: 'flex',
            flexDirection: 'row',
        },
        mainTitle: {
            fontSize: 18,
            fontweight: 1000,
            textAlign: 'center',
            marginTop: 5,
            marginBottom: 5,
        },
        catchmentTimes: {
            marginTop: 10,
            fontSize: 15,
            width: '100%',
            justifyContent: 'end',
            display: 'flex',
        },
        disclaimer: {
            fontSize: 9,
            marginTop: 15,
            lineHeight: 1.3,
        },
    });

    return (
        <Page style={styles.body} wrap>
            <ReportHeader reportHeaderUrl={data.reportHeaderIconUrl} />
            <View style={{ pageBreakAfter: 'always' }}>
                <Text style={styles.mainTitle}>OSD Design Summary Report</Text>
                <Text style={{ fontSize: 12, marginTop: 10 }}>
                    Method for OSD calculations: {data.osd.method.toUpperCase()}
                </Text>
            </View>

            {/* OSD System Specified */}
            <View>
                <Text style={{ fontSize: 10, marginTop: 10 }}>Device Name: {data.OSDCompany}</Text>
                <Text style={{ fontSize: 10, marginTop: 10 }}>
                    Device Type: {data.OSDMaterial} - {data.storageType}
                </Text>
            </View>
            {/* ... */}

            {/* TC Calculator */}
            <TCCalculatorTemplate
                tcData={data.osd.tcData}
                createStyles={createStyles}
                defaultStyles={defaultStyles}
            />
            {/* Site Details */}
            <View style={{ marginBottom: 50 }}>
                {generateAllTableData(data).map((table, index) => (
                    <>
                        {table.styles ? (
                            <Table
                                tableKey={index}
                                columnNames={table.columns}
                                tableData={table.items}
                                headerWidths={table.headerWidths}
                                cellWidths={table.cellWidths}
                                customCellWidths={table.customCellWidths}
                                tableStyles={table.styles}
                            />
                        ) : (
                            <></>
                        )}
                        {table.appendix ?? <></>}
                    </>
                ))}

                {data.osd.method?.toLowerCase() === 'rational method' ? (
                    data.osd.psd ? (
                        <Text style={styles.disclaimer}>
                            NOTE:{'\n'} * Uncontrolled areas are assumed to be accounted for in the
                            calculation of PSD when PSD is provided by Council.
                        </Text>
                    ) : (
                        <></>
                    )
                ) : (
                    data.osdResult.report.plots?.plot_selected_aep && (
                        <Image src={data.osdResult.report.plots?.plot_selected_aep} />
                    )
                )}
            </View>
            <ReportFooter />
        </Page>
    );
};

export default RationalReportTemplate;
